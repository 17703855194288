
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../common/PageTitle';
import Loader from '../../../common/components/Loader';
import { triggerAlert } from '../../../utils/CommonFunctions';
import { useForm } from 'react-hook-form';
import { FetchSettingData, UpdateSetting } from '../../../utils/ApiClient';
import { onlyNumbers } from '../../../utils/Constants';

export default function Setting() {
    const [isLoading, setIsloading] = useState(false);

    const [formData, setFormData] = useState({
        transactional_input1: '',
        transactional_input2: '',
        transactional_input3: '',
        promotional_input1: '',
        promotional_input2: '',
        promotional_input3: '',
        chatbot_input1: '',
        chatbot_input2: '',
        chatbot_input3: '',
        access_token: '',
        appid: '',
        version: ''
    });
    const { register: registerEdit, handleSubmit: handleSubmitEdit, formState: { errors: errorsEdit }, setValue: setValueEdit, reset: resetEdit, trigger, getValues } = useForm({
        defaultValues: formData
    });


    const [editMode, setEditMode] = useState(false);
    const [syncPromotionalTransactional, setSyncPromotionalTransactional] = useState(false);
    const [syncChatbotTransactional, setSyncChatbotTransactional] = useState(false);

const [noData,setNoData]=useState(false)


    const handleEditMode = () => {
        setEditMode(true);
    };

    const handleUpdate = async (data) => {
        try {
            console.log(data, "edidfdhsk");
            const api_input = {
                "wa_permanent_access_token": data.access_token,
                "wa_app_id": data.appid,
                "wa_version": data.version,
                "phone_number_id": data.transactional_input1,
                "waba_id": data.transactional_input2,
                "bussiness_number": data.transactional_input3,
                "promotional_number": data.promotional_input1,
                "promo_waba_id": data.promotional_input2,
                "promo_phone_number_id": data.promotional_input3,
                "chatbot_number": data.chatbot_input1,
                "chatbot_waba_id": data.chatbot_input2,
                "chatbot_phone_number_id": data.chatbot_input3
            }
            const response = await UpdateSetting(api_input)
            console.log(response, "response123");
            if (response.data.error_code === 200) {
                setEditMode(false)
                triggerAlert('success', 'success', 'Updated successfully!!');
                FetchData()
            }

        } catch (error) {
            triggerAlert('error', '', "Something went wrong!");
        }
    };

    const handleCheckboxChangePromotional = () => {
        const isChecked = !syncPromotionalTransactional;
        setSyncPromotionalTransactional(isChecked);

        if (isChecked) {
            // Copy transactional values to promotional fields
            setValueEdit('promotional_input1', getValues('transactional_input1'));
            setValueEdit('promotional_input2', getValues('transactional_input2'));
            setValueEdit('promotional_input3', getValues('transactional_input3'))
        } else {
            // Clear promotional fields if checkbox is unchecked
            setValueEdit('promotional_input1', '');
            setValueEdit('promotional_input2', '');
            setValueEdit('promotional_input3', '');
        }
    };

    const handleCheckboxChangeChatbot = () => {
        const isChecked = !syncChatbotTransactional;
        setSyncChatbotTransactional(isChecked);

        if (isChecked) {
            // Copy transactional values to chatbot fields
            setValueEdit('chatbot_input1', getValues('transactional_input1'));
            setValueEdit('chatbot_input2', getValues('transactional_input2'));
            setValueEdit('chatbot_input3', getValues('transactional_input3'));
        } else {
            // Clear chatbot fields if checkbox is unchecked
            setValueEdit('chatbot_input1', '');
            setValueEdit('chatbot_input2', '');
            setValueEdit('chatbot_input3', '');
        }
    };

    const handleCancelEdit = () => {
        setEditMode(false);
        setSyncPromotionalTransactional(false)
        setSyncChatbotTransactional(false)
        resetEdit();
        FetchData()
        trigger(); // Ensure no errors are being triggered
    };

    const FetchData = async () => {
        setIsloading(true);
        try {
            const response = await FetchSettingData();
            console.log(response, "response");

            if (response.data.error_code === 200) {
                const {
                    phone_number_id,
                    waba_id,
                    bussiness_number,
                    promo_phone_number_id,
                    promo_waba_id,
                    promotional_number,
                    chatbot_phone_number_id,
                    chatbot_waba_id,
                    chatbot_number,
                    wa_permanent_access_token,
                    wa_app_id,
                    wa_version
                } = response.data.results;

                if ((phone_number_id &&
                    waba_id &&
                    bussiness_number &&
                    promo_phone_number_id &&
                    promo_waba_id &&
                    promotional_number &&
                    chatbot_phone_number_id &&
                    chatbot_waba_id &&
                    chatbot_number &&
                    wa_permanent_access_token &&
                    wa_app_id &&
                    wa_version)===null){
                        setNoData(true)
                    }

                    setFormData({
                        transactional_input1: phone_number_id,
                        transactional_input2: waba_id,
                        transactional_input3: bussiness_number,
                        promotional_input1: promo_phone_number_id,
                        promotional_input2: promo_waba_id,
                        promotional_input3: promotional_number,
                        chatbot_input1: chatbot_phone_number_id,
                        chatbot_input2: chatbot_waba_id,
                        chatbot_input3: chatbot_number,
                        access_token: wa_permanent_access_token,
                        appid: wa_app_id,
                        version: wa_version
                    });
                setValueEdit('transactional_input1', phone_number_id);
                setValueEdit('transactional_input2', waba_id);
                setValueEdit('transactional_input3', bussiness_number);
                setValueEdit('promotional_input1', promo_phone_number_id);
                setValueEdit('promotional_input2', promo_waba_id);
                setValueEdit('promotional_input3', promotional_number);
                setValueEdit('chatbot_input1', chatbot_phone_number_id);
                setValueEdit('chatbot_input2', chatbot_waba_id);
                setValueEdit('chatbot_input3', chatbot_number);
                setValueEdit('access_token', wa_permanent_access_token);
                setValueEdit('appid', wa_app_id);
                setValueEdit('version', wa_version);

                ;
            }
        } catch (error) {
            triggerAlert('error', '', "Something went wrong!");
        } finally {
            setIsloading(false);
        }
    };






    useEffect(() => {
        FetchData();

    }, [])


    useEffect(() => {

        // fetchAllUsers()
        if (editMode) {
            trigger();
        }
    }, [trigger])
    console.log('Form Data:', formData);
    console.log('Edit Mode:', editMode);
    console.log('Sync Promotional Transactional:', syncPromotionalTransactional);
    console.log('Sync Chatbot Transactional:', syncChatbotTransactional);
console.log(noData,"655656");

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div id="content-page" className="content-page" style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
                <div className="col-md-9 mx-auto">
                    <PageTitle heading="Settings" />
                    {isLoading && (
                        <div className='loader-overlay text-white'>
                            <Loader />
                        </div>
                    )}
                    <div className="tab-content" id="myTabContent">
                        <div className="card tab-pane mb-0 fade show active" id="user-content-103" role="tabpanel" style={{ flex: 1 }}>
                            <div className="chat-head">
                                <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3 border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 text-primary fw-500">
                                            {editMode ? "Edit Details" : "View Details"}
                                        </h5>
                                    </div>

                                    {!editMode && !noData && (
                                        <div className="chat-header-icons d-inline-flex ms-auto">
                                            <button type="button" onClick={handleEditMode} className="btn btn-primary d-flex align-items-center btn-sm">
                                                <span className="material-symbols-outlined">edit_note</span>
                                                <span className="d-none d-lg-block ms-1">Edit</span>
                                            </button>
                                        </div>
                                    )}
                                </header>
                            </div>

                            <div className="card-body chat-body bg-body chat-contacts" style={{ flex: 1 }}>
                                <form onSubmit={handleSubmitEdit(handleUpdate)}>
                                    <div className="row mt-1">
                                        {/* Transactional */}
                                        <div className="col-md-4 mb-1">
                                            <h5 className="mb-1">Transactional</h5>
                                            <div className="border p-2">
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="transactionalInput1">
                                                        Phone Number Id<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="transactionalInput1"
                                                        // value={formData?.transactional_input1}
                                                        {...registerEdit('transactional_input1', {
                                                            pattern: onlyNumbers,
                                                            required: 'Number is required',
                                                        })

                                                        }

                                                        disabled={!editMode || syncPromotionalTransactional || syncChatbotTransactional}
                                                    />
                                                    {errorsEdit.transactional_input1 && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.transactional_input1.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="transactionalInput2">
                                                        WABA Id<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="transactionalInput2"
                                                        // value={formData?.transactional_input2}
                                                        {...registerEdit('transactional_input2'
                                                            , {
                                                                pattern: onlyNumbers,
                                                                required: 'Number is required',
                                                            }
                                                        )}
                                                        disabled={!editMode || syncPromotionalTransactional || syncChatbotTransactional}
                                                    />
                                                    {errorsEdit.transactional_input2 && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.transactional_input2.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="transactionalInput3">
                                                        Business / Transactional Number<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="transactionalInput3"
                                                        // value={formData?.transactional_input3}
                                                        {...registerEdit('transactional_input3'
                                                            , {
                                                                pattern: onlyNumbers,
                                                                required: 'Number is required',
                                                            }
                                                        )}
                                                        disabled={!editMode || syncPromotionalTransactional || syncChatbotTransactional}
                                                    />
                                                    {errorsEdit.transactional_input3 && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.transactional_input3.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Promotional */}
                                        <div className="col-md-4 mb-1">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="mb-1">Promotional</h5>
                                                {editMode && (
                                                    <div>
                                                        <label className="form-check-label me-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                checked={syncPromotionalTransactional}
                                                                onChange={handleCheckboxChangePromotional}
                                                            />
                                                            Sync
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="border p-2">
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="promotionalInput1">
                                                        Promotional Phone Number Id<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="promotionalInput1"
                                                        // value={formData?.promotional_input1}
                                                        {...registerEdit('promotional_input1', {
                                                            pattern: onlyNumbers,
                                                            required: 'Number is required',
                                                        })

                                                        }
                                                        disabled={!editMode}
                                                    />
                                                    {errorsEdit.promotional_input1 && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.promotional_input1.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="promotionalInput2">
                                                        Promotional WABA Id<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="promotionalInput2"
                                                        // value={formData?.promotional_input2}
                                                        {...registerEdit('promotional_input2', {
                                                            pattern: onlyNumbers,
                                                            required: 'Number is required',
                                                        })}
                                                        disabled={!editMode}
                                                    />
                                                    {errorsEdit.promotional_input2 && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.promotional_input2.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="promotionalInput3">
                                                        Promotional Number<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="promotionalInput3"
                                                        // value={formData?.promotional_input3}
                                                        {...registerEdit('promotional_input3', {
                                                            pattern: onlyNumbers,
                                                            required: 'Number is required',
                                                        })}
                                                        disabled={!editMode}
                                                    />
                                                    {errorsEdit.promotional_input3 && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.promotional_input3.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Chatbot */}
                                        <div className="col-md-4 mb-1">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="mb-1">Chatbot</h5>
                                                {editMode && (
                                                    <div>
                                                        <label className="form-check-label me-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                checked={syncChatbotTransactional}
                                                                onChange={handleCheckboxChangeChatbot}
                                                            />
                                                            Sync
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="border p-2">
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="chatbotInput1">
                                                        Chatbot Phone Number Id<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="chatbotInput1"
                                                        // value={formData?.chatbot_input1}
                                                        {...registerEdit('chatbot_input1', {
                                                            pattern: onlyNumbers,
                                                            required: 'Number is required',
                                                        })}
                                                        disabled={!editMode}
                                                    />
                                                    {errorsEdit.chatbot_input1 && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.chatbot_input1.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="chatbotInput2">
                                                        Chatbot WABA Id<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="chatbotInput2"
                                                        // value={formData?.chatbot_input2}
                                                        {...registerEdit('chatbot_input2', {
                                                            pattern: onlyNumbers,
                                                            required: 'Number is required',
                                                        })}
                                                        disabled={!editMode}
                                                    />
                                                    {errorsEdit.chatbot_input2 && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.chatbot_input2.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="chatbotInput3">
                                                        Chatbot Number<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="chatbotInput3"
                                                        // value={formData?.chatbot_input3}
                                                        {...registerEdit('chatbot_input3', {
                                                            pattern: onlyNumbers,
                                                            required: 'Number is required',
                                                        })}
                                                        disabled={!editMode}
                                                    />
                                                    {errorsEdit.chatbot_input3 && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.chatbot_input3.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* OAuth Section */}
                                    <div className="row mt-3">
                                        <div className="col-md-12 mb-1">
                                            <div className="border p-2">
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="accessToken">
                                                        Access Token<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="accessToken"
                                                        // value={formData?.access_token}
                                                        {...registerEdit('access_token', {
                                                            // pattern: onlyNumbers,
                                                            required: ' Access Token required',
                                                        })}
                                                        disabled={!editMode}
                                                    />
                                                    {errorsEdit.access_token && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.access_token.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="oauthClientId">
                                                        AppID<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="oauthClientId"
                                                        // value={formData?.appid}
                                                        {...registerEdit('appid', {
                                                            // pattern: onlyNumbers,
                                                            required: ' AppID Token required',
                                                        })}
                                                        disabled={!editMode}
                                                    />
                                                    {errorsEdit.appid && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.appid.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-1">
                                                    <label className="form-label" htmlFor="oauthClientSecret">
                                                        Version<span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="oauthClientSecret"
                                                        // value={formData?.version}
                                                        {...registerEdit('version', {
                                                            // pattern: onlyNumbers,
                                                            required: 'Version required',
                                                        })}
                                                        disabled={!editMode}
                                                    />

                                                    {errorsEdit.version && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsEdit.version.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-end mt-3">
                                        {editMode && (
                                            <>
                                                <button type="button" className="btn btn-secondary me-2" onClick={handleCancelEdit}>Cancel</button>
                                                <button type="submit" className="btn btn-primary">Save</button>
                                            </>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

