
import Swal from "sweetalert2";

export const ERROR_MESSAGE = "Something went wrong";

//common validation 

export const onlyAlphabets = {
    value: /^[A-Za-z]+$/,
    message: 'Please enter only alphabets',
};

export const onlyAlphabetsandSpaces = {
    value: /^[A-Za-z\s]+$/, // Include \s for space character
    message: 'Please enter only alphabets',
};

export const onlyNumbers = {
    value: /^[0-9]+$/,
    message: 'Please enter only numbers',
}
export const onlyNumbersandSpaces = {
    value: /^[0-9\s]+$/,
    message: 'Please enter only numbers',
}

export const onlyAlphaNumeric = {
    value: /^[a-zA-Z0-9]+$/,
    message: 'Please enter only numbers and alphabets',
};
export const onlyAlphaNumericSpaces = {
    value: /^[a-zA-Z0-9\s]+$/,
    message: 'Please enter only numbers and alphabets',
};

export const noSpacesValidation = (value) => {
    return !/\s/.test(value) || "Spaces are not allowed.";
};

export const noEmptySpacesValidation = (value) => {
    return value.trim().length > 0 || "Input cannot be only spaces.";
};


export const createPattern = (allowedSpecialChars) => {  //allows  special characters as per the requirements along with alphanumeric values
    // Escape special characters in the allowedSpecialChars string
    const escapedSpecialChars = allowedSpecialChars.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    const pattern = new RegExp(`^[a-zA-Z0-9${escapedSpecialChars} ]+$`);
    const message = `Please enter only numbers, alphabets and ${allowedSpecialChars}`;
    return { value: pattern, message: message };
};

export const MaxLengthValidation = (maxLength) => ({
    value: maxLength,
    message: `Maximum length exceeded. Maximum ${maxLength} characters allowed.`,
});
export const MinLengthValidation = (minLength) => ({
    value: minLength,
    message: `Minimum length is not reached. Minimum ${minLength} characters allowed.`,
});

export const emailValidation = {
    value: /^\S+@\S+\.\S+$/,
    message: 'Please enter a valid email address',

};

export const passwordPattern = {
    value: /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/,
    message: "Password must be at least 6 characters long and include at least one letter and one number.",
};


export const usernameValidations = {
    maxLength: MaxLengthValidation(150),
    minLength: MinLengthValidation(8),
    pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "Invalid email format",
    },
}
export const passwordValidations = {
    maxLength: MaxLengthValidation(15),
    minLength: MinLengthValidation(6),
    pattern: {
        value:
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/,
        message:
            "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.",
    },
}

export const formatDate = (dateInput, format) => {
    // const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // const dd = String(date.getDate()).padStart(2, '0');
    // const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    // let monthName = month[date.getUTCMonth()];
    // const yyyy = date.getFullYear();
    // // console.log('month', month);

    // switch (format) {
    //     case 'dd-mm-yyyy':
    //         return `${dd}-${mm}-${yyyy}`;
    //     case 'dd/mm/yyyy':
    //         return `${dd}/${mm}/${yyyy}`;
    //     case 'mm/dd/yyyy':
    //         return `${mm}/${dd}/${yyyy}`;
    //     case 'month dd, yyyy':
    //         return `${monthName} ${dd}, ${yyyy}`;
    //     // Add more format cases as needed
    //     default:
    //         return `${dd}-${mm}-${yyyy}`;
    // }

    // If the input is a string, convert it to a Date object
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
    // console.log('date', date)

    // Check if the date is not null and is a valid Date object
    if (!date || isNaN(date.getTime())) {
        return '-';
    } else {

        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        let monthName = month[date.getUTCMonth()];
        const yyyy = date.getFullYear();

        switch (format) {
            case 'dd-mm-yyyy':
                return `${dd}-${mm}-${yyyy}`;
            case 'yyyy-mm-dd':
                return `${yyyy}-${mm}-${dd}`;
            case 'dd/mm/yyyy':
                return `${dd}/${mm}/${yyyy}`;
            case 'mm/dd/yyyy':
                return `${mm}/${dd}/${yyyy}`;
            case 'month dd, yyyy':
                return `${monthName} ${dd}, ${yyyy}`;
            // Add more format cases as needed
            default:
                return `${dd}-${mm}-${yyyy}`;
        }
    }
};

export const simpleAlert = (text) => {
    return Swal.fire(text)
}