import React from 'react'

export default function Landing() {
    return (
        <div>
            <div class="iq-top-navbar">
                <nav class="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0">
                    <div class="container-fluid navbar-inner">
                        <div class="d-flex align-items-center  gap-3 pb-2 pb-lg-0">
                            <a href="index.php" class="d-flex align-items-center gap-2 iq-header-logo">
                                <img src="/assets/images/logo.svg" class="img-fluid" width="200" />
                            </a>
                        </div>
                        <ul class="navbar-nav navbar-list">
                            <li class="nav-item">
                                <a href="#" class="d-flex align-items-center">
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        <i class="fa fa-credit-card" aria-hidden="true"></i> Payments</button>
                                </a>
                            </li>
                            <li class="nav-item me-3">
                                <a href="#" class="d-flex align-items-center">
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        <i class="fa fa-question-circle-o" aria-hidden="true"></i> Support</button>
                                </a>
                            </li>

                            <li class="nav-item dropdown user-dropdown">
                                <a href="javascript:void(0);" class="d-flex align-items-center dropdown-toggle" id="drop-down-arrow" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="/assets/images/user/1.jpg" class="img-fluid rounded-circle me-3" alt="user" loading="lazy" />
                                </a>
                                <div class="sub-drop dropdown-menu caption-menu w-25" aria-labelledby="drop-down-arrow">
                                    <div class="card shadow-none m-0">
                                        <div class="card-header ">
                                            <div class="header-title">
                                                <h5 class="mb-0 ">Hello Bni Cyst</h5>
                                            </div>
                                        </div>
                                        <div class="card-body p-0 ">
                                            <div class="d-flex align-items-center iq-sub-card border-0">
                                                <span class="material-symbols-outlined">
                                                    line_style
                                                </span>
                                                <div class="ms-3">
                                                    <a href="#" class="mb-0 h6">
                                                        Profile
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-center iq-sub-card">
                                                <span class="material-symbols-outlined">
                                                    login
                                                </span>
                                                <div class="ms-3">
                                                    <a href="/login" class="mb-0 h6">
                                                        Sign out
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>


            <div class="position-relative">
            </div>
            <div id="content-page" class="content-page">
                <div class="container">
                    <div class="d-flex flex-column justify-content-center align-items-center auto-vh-100  ">
                        <div class="iq-maintenance1">
                            <div class="text-center">
                                <img src="/assets/images/landing.png" class="img-fluid" alt="" loading="lazy" width="400" />
                            </div>
                            <h3 class=" mb-1 text-center text-primary fw-500">Welcome Back Kehar Singh !!</h3>
                            <p class="text-center mb-0">Unify Your Social Media Strategy and Seamlessly Manage All Your Accounts from a Single Platform</p>
                        </div>
                        <div class="row mt-1 mb-3 justify-content-center  g-4">
                            <div class="col-lg-4 col-md-6">
                                <div class="card text-center h-100">
                                    <div class="card-body">
                                        <img src="/assets/images/icon/facebook_land.png" class="img-fluid avatar-80 mt-3 mb-3" alt="" loading="lazy" />
                                        <h5 class="card-title mt-1 text-center text-warning">Social Media Management</h5>
                                        <p class="mb-0 text-center">The ultimate social media management platform designed to deliver unparalleled capabilities and comprehensive solutions for both brands and agencies</p>
                                        <a class="btn btn-primary mt-3 px-4 mb-2" href="/workspace/workspace">
                                            <span class="d-flex align-items-center">
                                                Explore</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="card text-center h-100">
                                    <div class="card-body">
                                        <img src="/assets/images/icon/user-landing.png" class="img-fluid avatar-80 mt-3 mb-3" alt="" loading="lazy" />
                                        <h5 class="card-title mt-1 text-center text-warning">Messaging and Communication</h5>
                                        <p class="mb-0 text-center">Our platform ensures seamless communication and effective marketing through SMS, WhatsApp, and Telegram.</p>
                                        <a class="btn btn-primary mt-3 px-4 mb-2" href="/whatsapp">
                                            <span class="d-flex align-items-center">
                                                Explore</span></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
