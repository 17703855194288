import React, { useEffect, useState } from 'react'
import { triggerAlert } from '../../../utils/CommonFunctions';
import { GetAutomationList, GetChatBotList, updateChatbotAutomation } from '../../../utils/ApiClient';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker'; // Ensure you have this package installed
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS for DatePicker

export default function Automation() {
    const [listAuto, setListAuto] = useState([]);
    const [listChatbot, setListChatbot] = useState([]);
    const { control, handleSubmit } = useForm();
    const [extraTimes, setExtraTimes] = useState({}); // State to store extra time ranges for each day
    const [isLoading, setIsLoading] = useState(false);
    const [defaultChatbotId, setDefaultChatbotId] = useState("");

    const GetAutoList = async () => {
        try {
            const response = await GetAutomationList(); // API Call
            if (response.data.error_code === 200) {
                const items = response.data.results;
                setListAuto(items); // Store fetched data in state
            }
        } catch (error) {
            triggerAlert('error', '', error?.response?.data?.message || "Something went wrong!");
        }
    };


    const fetchChatbots = async () => {
        try {
            const response = await GetChatBotList();
            if (response.data.error_code === 200) {
                setListChatbot(response.data.results);
            }
        } catch (error) {
            triggerAlert('error', '', error?.response?.data ? error?.response?.data?.message : "Something went wrong!");
        }
    };


    useEffect(() => {
        fetchChatbots();
        GetAutoList(); // Fetch the automation list when the component mounts
    }, []);


    const handleChatbotChange = (e, index) => {
        const selectedChatbotId = e.target.value;
        // Handle the chatbot change logic here (e.g., update state)
    };
    const handleDefaultChatbotChange = (e) => {
        const selectedDefaultChatbotId = e.target.value;  // Get the selected chatbot ID
        setDefaultChatbotId(selectedDefaultChatbotId);    // Update the state with the selected chatbot
    };
    const getDayName = (day) => {
        if (day === 0) {
            return "Default";
        }

        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return days[day - 1];  // Adjust to make 1 = Sunday
    };

    const convertTimeToDate = (time) => {
        const now = new Date();
        now.setHours(time);
        now.setMinutes(0);
        now.setSeconds(0);
        return now;
    };

    const getDefaultChatbotId = () => {
        const defaultChatbot = listAuto.find(item => item.default_chatbot_id !== null);
        return defaultChatbot ? defaultChatbot.chat_bot_id : '';
    };

    // const handleAddTimeRange = (dayIndex) => {
    //     const newExtraTime = {
    //         timing_id: Date.now(), // Unique ID for the new extra time
    //         chat_bot_id: "", // Default value for the select
    //         start_time: null, // Initialize with null
    //         end_time: null, // Initialize with null
    //     };
    //     setExtraTimes((prev) => ({
    //         ...prev,
    //         [dayIndex]: [...(prev[dayIndex] || []), newExtraTime], // Add new extra time to the specific day
    //     }));
    // };

    const handleAddTimeRange = (dayIndex) => {
        setExtraTimes((prev) => {
            const newTimingId = Date.now();  // Unique ID for new time slot
            const newTimeSlot = {
                timing_id: newTimingId,
                day: parseInt(dayIndex, 10),  // Ensure day is correctly set
                chat_bot_id: "",
                start_time: null,  // Default start time
                end_time: null,   // Default end time
            };

            const updatedTimes = [...(prev[dayIndex] || []), newTimeSlot];  // Add new timeslot
            return { ...prev, [dayIndex]: updatedTimes };  // Update state
        });
    };



    const handleRemoveTimeRange = (dayIndex, timingId) => {
        // console.log("handleRemoveTimeRange", dayIndex, timingId)
        setExtraTimes((prev) => {
            const updatedDay = prev[dayIndex]?.filter((time) => time.timing_id !== timingId) || [];
            return { ...prev, [dayIndex]: updatedDay }; // Update the specific day's timings
        });
    };
    // console.log("extra", extraTimes)


    const handleChatbotChangeExtraTime = (dayIndex, timingId, value) => {
        setExtraTimes((prev) => {
            const updatedDay = prev[dayIndex].map((time) => {
                if (time.timing_id === timingId) {
                    return { ...time, chat_bot_id: value }; // Update the chat_bot_id
                }
                return time;
            });
            return { ...prev, [dayIndex]: updatedDay };
        });
    };


    const groupedByDay = listAuto
        .filter(item => item.default_chatbot_id === null) // Exclude default rows
        .reduce((acc, item) => {
            if (!acc[item.day]) {
                acc[item.day] = [];
            }
            acc[item.day].push(item);
            return acc;
        }, {});

    // After you group the timings by day, initialize the extraTimes
    useEffect(() => {
        const initialExtraTimes = {};
        listAuto.forEach(item => {
            if (!initialExtraTimes[item.day]) {
                initialExtraTimes[item.day] = [];
            }
            // Only add the items for non-default days
            if (item.day !== 0) {
                initialExtraTimes[item.day].push(item);
            }
        });
        setExtraTimes(initialExtraTimes);
    }, [listAuto]); // Run this effect when listAuto changes

    const updateChatbotTimings = async (data) => {
        // Collect the time slots from extraTimes
        const timingsPayload = Object.entries(extraTimes).map(([dayIndex, timeSlots]) => {
            return timeSlots.map((item) => ({
                day: item.day, // Day of the week (e.g., 0 for Sunday, 1 for Monday, etc.)
                start_time: item.start_time, // Start time as integer (e.g., 1 for 1:00 AM)
                end_time: item.end_time, // End time as integer (e.g., 16 for 4:00 PM)
                chat_bot_id: item.chat_bot_id // Selected chatbot ID
            }));
        }).flat();

        // Collect default chatbot
        const defaultChatbotPayload = {
            default_chat_bot_id: defaultChatbotId, // Get the default chatbot ID from the selection
        };

        // Final payload structure
        const payload = {
            automation_data: timingsPayload,
            ...defaultChatbotPayload, // Add default chatbot to the payload
        };

        // console.log("Payload to be sent to the API:", payload);

        setIsLoading(true);
        try {
            const response = await updateChatbotAutomation(payload);
            const response_data = response.data;

            if (response_data.error_code === 201) {
                setIsLoading(false);
                fetchChatbots();
                triggerAlert('success', 'success', 'Updated Successfully!!');
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            const response_data = error?.response?.data;
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    };


    return (
        <>
            <div>
                <div class="position-relative">
                </div>
                <div id="content-page" class="content-page">
                    <div class="container">
                        <div class="row w-100 mb-4 mt-3">
                            <div class="d-flex align-items-center justify-content-between flex-wrap">
                                <h4 class="fw-bold text-primary">Chatbots Automation</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-lg-12">
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <div class="header-title">
                                            <h5 class="card-title text-warning">Sahiti@vitelglobal.com </h5>
                                            <p class="mb-0">Chatbots Automation</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <a type="button" class="btn btn-primary   d-flex align-items-center">
                                                <span class=" ">Chatbots List</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <h5 class="mb-3 fw-500">Working hours</h5>
                                        <form onSubmit={handleSubmit(updateChatbotTimings)}>
                                            {Object.entries(extraTimes).map(([dayIndex, timeSlots]) => (
                                                <div key={dayIndex}>
                                                    {timeSlots.map((item, index) => (
                                                        <div key={item.timing_id} className="row align-items-center mb-3">
                                                            {index === 0 ? (
                                                                <>
                                                                    {/* Your full details for the first time slot */}
                                                                    <div className="col-md-1">
                                                                        <p>{getDayName(item.day)}</p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <Controller
                                                                            control={control}
                                                                            name={`startTime-${item.timing_id}`}
                                                                            render={({ field }) => (
                                                                                <DatePicker
                                                                                    {...field}
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                    timeIntervals={60}
                                                                                    timeCaption="Start Time"
                                                                                    timeFormat="HH:mm"
                                                                                    dateFormat="HH:mm"
                                                                                    className="form-control"
                                                                                    selected={convertTimeToDate(item.start_time)} // Convert API time value to Date
                                                                                    onChange={(date) => {
                                                                                        const hours = date.getHours(); // Get the hours (0-23)
                                                                                        field.onChange(hours); // Store as integer (1 for 1 AM, 16 for 4 PM)
                                                                                        setExtraTimes((prev) => {
                                                                                            const updatedTimes = [...(prev[dayIndex] || [])];
                                                                                            const updatedTime = updatedTimes.find(time => time.timing_id === item.timing_id);
                                                                                            if (updatedTime) {
                                                                                                updatedTime.start_time = hours; // Update to integer time value
                                                                                            }
                                                                                            return { ...prev, [dayIndex]: updatedTimes }; // Return updated state
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <p className="text-center">To</p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <Controller
                                                                            control={control}
                                                                            name={`endTime-${item.timing_id}`}
                                                                            render={({ field }) => (
                                                                                <DatePicker
                                                                                    {...field}
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                    timeIntervals={60}
                                                                                    timeCaption="End Time"
                                                                                    timeFormat="HH:mm"
                                                                                    dateFormat="HH:mm"
                                                                                    className="form-control"
                                                                                    selected={convertTimeToDate(item.end_time)} // Convert API time value to Date
                                                                                    onChange={(date) => {
                                                                                        const hours = date.getHours(); // Get the hours (0-23)
                                                                                        field.onChange(hours); // Store as integer
                                                                                        setExtraTimes((prev) => {
                                                                                            const updatedTimes = [...(prev[dayIndex] || [])];
                                                                                            const updatedTime = updatedTimes.find(time => time.timing_id === item.timing_id);
                                                                                            if (updatedTime) {
                                                                                                updatedTime.end_time = hours; // Update to integer time value
                                                                                            }
                                                                                            return { ...prev, [dayIndex]: updatedTimes }; // Return updated state
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <select
                                                                            className="form-select"
                                                                            value={item.chat_bot_id}
                                                                            onChange={(e) => handleChatbotChangeExtraTime(dayIndex, item.timing_id, e.target.value)}
                                                                        >
                                                                            <option value="" hidden>Select Chatbot</option>
                                                                            {listChatbot.map(chatbot => (
                                                                                <option key={chatbot.chat_bot_id} value={chatbot.chat_bot_id}>
                                                                                    {chatbot.chat_bot_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success px-4"
                                                                            onClick={() => handleAddTimeRange(dayIndex)}
                                                                        >
                                                                            Add
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="col-md-3 offset-md-1">
                                                                        <DatePicker
                                                                            selected={convertTimeToDate(item.start_time)} // Convert API time value to Date
                                                                            showTimeSelect
                                                                            showTimeSelectOnly
                                                                            timeIntervals={60}
                                                                            timeCaption="Start Time"
                                                                            timeFormat="HH:mm"
                                                                            dateFormat="HH:mm"
                                                                            className="form-control"
                                                                            onChange={(date) => {
                                                                                const hours = date.getHours(); // Get the hours (0-23)
                                                                                setExtraTimes((prev) => {
                                                                                    const updatedTimes = [...(prev[dayIndex] || [])];
                                                                                    const updatedTime = updatedTimes.find(time => time.timing_id === item.timing_id);
                                                                                    if (updatedTime) {
                                                                                        updatedTime.start_time = hours; // Update to integer time value
                                                                                    }
                                                                                    return { ...prev, [dayIndex]: updatedTimes }; // Return updated state
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <p className="text-center">To</p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <DatePicker
                                                                            selected={convertTimeToDate(item.end_time)} // Convert API time value to Date
                                                                            showTimeSelect
                                                                            showTimeSelectOnly
                                                                            timeIntervals={60}
                                                                            timeCaption="End Time"
                                                                            timeFormat="HH:mm"
                                                                            dateFormat="HH:mm"
                                                                            className="form-control"
                                                                            onChange={(date) => {
                                                                                const hours = date.getHours(); // Get the hours (0-23)
                                                                                setExtraTimes((prev) => {
                                                                                    const updatedTimes = [...(prev[dayIndex] || [])];
                                                                                    const updatedTime = updatedTimes.find(time => time.timing_id === item.timing_id);
                                                                                    if (updatedTime) {
                                                                                        updatedTime.end_time = hours; // Update to integer time value
                                                                                    }
                                                                                    return { ...prev, [dayIndex]: updatedTimes }; // Return updated state
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <select
                                                                            className="form-select"
                                                                            value={item.chat_bot_id}
                                                                            onChange={(e) => handleChatbotChangeExtraTime(dayIndex, item.timing_id, e.target.value)}
                                                                        >
                                                                            <option value="" hidden>Select Chatbot</option>
                                                                            {listChatbot.map(chatbot => (
                                                                                <option key={chatbot.chat_bot_id} value={chatbot.chat_bot_id}>
                                                                                    {chatbot.chat_bot_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger"
                                                                            onClick={() => handleRemoveTimeRange(dayIndex, item.timing_id)}
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}

                                            {/* Default Chatbot Selection */}
                                            <div className="row mt-4">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="defaultChatbotSelect">Default Chatbot</label>
                                                        <select
                                                            className="form-select"
                                                            id="defaultChatbotSelect"
                                                            value={defaultChatbotId}  // Bind the state variable to the value
                                                            onChange={handleDefaultChatbotChange}  // Update state when selection changes
                                                        >
                                                            <option value="" hidden>Select Chatbot</option>
                                                            {listChatbot.map(chatbot => (
                                                                <option key={chatbot.chat_bot_id} value={chatbot.chat_bot_id}>
                                                                    {chatbot.chat_bot_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className='col-md-12 text-danger'>
                                                    Note: Please note that the chatbot will be active outside the designated working hours and days (during non-working hours) by default.
                                                </div>
                                                <div className="form-group mb-3 text-end">
                                                    <button className="btn btn-success px-4" type="submit">Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        </>
    )
}
