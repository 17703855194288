import React, { useEffect, useRef, useState } from 'react'
import PageTitle from '../../../common/PageTitle';
import useLocation from '../../../common/components/customhooks/Location';
import InfiniteScrollWrapper from '../../../common/components/InfinityScrollWrapper';
import { downloadFile, extractFileName, formatDateTime, formatTimeToAmandPM, getBase64, getFormattedDate, triggerAlert, truncateName } from '../../../utils/CommonFunctions';
import { fetchWAContactList, fetchWhatsappUserChatHistory, forwardWhatsappMessage, listContact, reactToWhatsappMessage, sendWhatsappContacts, sendWhatsappLocation, sendWhatsappMessage, sendWhatsappVideoandAudio } from '../../../utils/ApiClient';
import SpinnerLoader from '../../../common/components/SpinnerLoader';
import Emojis from '../../../common/components/Emojis';
import { useForm } from 'react-hook-form';
import DynamicLocation from '../../../common/components/DynamicLocation';
import { Modal, ModalFooter } from 'react-bootstrap';
import TextToSpeech from '../../../common/components/TextToSpeech';
import LazyLoadImage from '../../../common/components/LazyLoadImage';
import SpeechToText from '../../../common/components/SpeechToText';
import VideoRecording from '../../../common/components/VideoRecording';
import AudioRecorder from '../../../common/components/AudioRecording';
import { useSocket } from '../../../SocketContext';

export default function WAInbox() {

    const { register, handleSubmit, control, formState: { errors }, reset, watch, clearErrors, setValue, setError, unregister } = useForm();
    const MAX_FILE_LIMIT = 10;

    const contactsContainerRef = useRef(null);
    const chatContainerRef = useRef(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState("");
    const [showEmojis, setShowEmojis] = useState(false);
    const { location, loading, handleGetLocation } = useLocation();
    const [selectKey, setSelectKey] = useState("unread");
    const [isLoadingContacts, setIsLoadingContacts] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [loadingMoreContacts, setLoadingMoreContacts] = useState(false);
    const [activeUserNum, setActiveUserNum] = useState(null); // To track the active chat
    const [activeUserName, setActiveUserName] = useState(null); // To track the active chat
    const [unseenCounts, setUnseenCounts] = useState({});
    const [hasMoreContacts, setHasMoreContacts] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [showAboveScreen, setShowAboveScreen] = useState(false);

    const [userChatHistory, setUserChatHistory] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);

    const [totalNumberPages, setTotalNumberPages] = useState(0); //To track the contact list

    const [page, setPage] = useState(0); // To track the active chat
    const [totalPages, setTotalPages] = useState(0); // To track the active chat
    const [pageNumber, setPageNumber] = useState(0); // To track the active chat
    const pageSize = 10;

    const [showContactModal, setShowContactModal] = useState(false);
    const [showForwardModal, setShowForwardModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [contacts, setContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const [isHovered, setIsHovered] = useState(null);
    const [showEmojisReaction, setShowEmojisReaction] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [replyInfo, setReplyInfo] = useState({});
    const [isAudioRecording, setIsAudioRecording] = useState(false);

    const handleMouseOver = (id) => {
        setIsHovered(id);
    };

    const handleMouseOut = () => {
        setIsHovered(null);
    };


    const handleShowContactModal = () => setShowContactModal(true);
    const handleShowContactCloseModal = () => setShowContactModal(false);
    const handleShowForwardModal = () => setShowForwardModal(true);
    const handleForwardCloseModal = () => setShowForwardModal(false);
    const handleShowVideoModal = () => setShowVideoModal(true);
    const handleVideoCloseModal = () => setShowVideoModal(false);


    const handleForwardShow = (id) => {
        setSelectedMessageId(id)
        handleShowForwardModal();
        fetchAllContacts();
    }
    const handleReplyShow = (id, text, direction) => {
        setShowAboveScreen(true)
        setReplyInfo({
            text: text,
            id: id,
            direction: direction

        })
    }
    const handleContactShow = () => {
        handleShowContactModal();
        fetchAllContacts();
    }

    const handleSelect = (e) => {
        setContactList([])
        const type = e.target.value;
        if (type) {
            setSelectKey(type);
            fetchContacts(type, 1);
        }
    }

    const handleDownload = (url) => {
        const filename = url.substring(url.lastIndexOf('/') + 1);
        downloadFile(url, filename);
    };

    const formReset = () => {
        reset();
    }

    const handleEmojiSelect = (emoji) => {
        // console.log("emoji", emoji)
        // Append selected emoji to the message field
        const currentMessage = watch("message") || ""; // Get current message value
        setValue("message", currentMessage + emoji); // Append emoji to the message
    };

    /////////////////////////// speech to text handle function///////////////////////////
    // Function that will handle transcription from child component
    const handleTranscription = (newTranscription) => {
        // setTranscription(newTranscription);
        const prev_message = watch("message");
        setValue("message", prev_message + newTranscription);
        // console.log("newTranscription", newTranscription)
    };
    /////////////////////////////////////////////////////////////////////////////////
    const handleContactClick = (activenum, activename) => {
        if (activenum) {
            setActiveUserNum(activenum);
            setActiveUserName(activename);
            // Reset unseen count to 0 when contact is clicked
            setUnseenCounts(prevCounts => ({
                ...prevCounts,
                [activenum]: 0
            }));
            // updateSeenStatus(activenum);
            fetchWhatsappChatHistory(1, activenum);
            formReset();
        }
    }
    /////////////////////////left sidebar functions///////////////////////////////////////
    const fetchContacts = async (type, pageNumber, searchkey) => {
        // console.log('pageNumber_fetching', pageNumber);
        // setIsLoadingContacts(true);
        try {
            const params = {
                select_key: type,
                page_number: pageNumber,
                page_size: pageSize,
                keyword: searchkey ? searchkey : ""
            }

            const response = await fetchWAContactList(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                const total_pages = response_data.results.pagination.total_pages;
                setTotalNumberPages(total_pages);
                setIsLoadingContacts(false);
                const sortedContacts = [...items].reverse();

                if (pageNumber === 1) {
                    setContactList(sortedContacts);
                }
                return sortedContacts;
                // triggerAlert('success', 'success', 'Recharged Successfully!!');
            } else {
                setIsLoadingContacts(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoadingContacts(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }
    const fetchMoreContacts = async () => {
        try {
            // Fetch data for the next page
            const nextPageNumber = pageNumber + 1;
            const nextPageNumberData = await fetchContacts(selectKey, nextPageNumber);

            // Check if the returned data is an array
            if (Array.isArray(nextPageNumberData)) {
                // Append the new data to the existing number list
                setContactList(prevNumberList => [...prevNumberList, ...nextPageNumberData]);

                // Increment the page number only after successful fetch
                setPageNumber(nextPageNumber);
            } else {
                console.error('The fetched data is not an array:', nextPageNumberData);
                // Handle the case where the data is not an array                
            }

        } catch (error) {
            console.error('Failed to fetch more data:', error);
        }
    };
    const handleFetchMoreContacts = async () => {

        if (pageNumber <= totalNumberPages) {

            // Preserve current scroll position
            const currentScrollHeight = contactsContainerRef.current.scrollHeight;
            const currentScrollTop = contactsContainerRef.current.scrollTop;

            setLoadingMoreContacts(true);
            await fetchMoreContacts(); // Fetch more data for the next page
            setLoadingMoreContacts(false);
            // Calculate new scroll position
            const newScrollHeight = contactsContainerRef.current.scrollHeight;

            // Scroll only if the user was at the bottom before loading more data
            if (currentScrollTop + contactsContainerRef.current.clientHeight >= currentScrollHeight) {
                contactsContainerRef.current.scrollTop = newScrollHeight;
            } else {
                // Preserve the current scroll position if the user was not at the bottom
                contactsContainerRef.current.scrollTop = newScrollHeight - (currentScrollHeight - currentScrollTop);
            }
            // Check if the next page will be the last
            if (pageNumber + 1 === totalNumberPages) {
                setHasMoreContacts(false); // No more pages to load
            }
        } else {
            //console.log('else');
            alert('No more pages to load');
            setHasMoreContacts(false); // No more pages to load
        }
    };
    ////////////////////////// right sidebar functions ///////////////////////////////
    const fetchWhatsappChatHistory = async (page, usernum) => {
        setIsLoading(true);
        if (page) setPage(page);

        try {
            const params = {
                page: page,
                page_size: pageSize,
                user_number: usernum,
            };
            const response = await fetchWhatsappUserChatHistory(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                const total_pages = response_data.results.pagination.total_pages;
                setTotalPages(total_pages)
                setIsLoading(false);
                const sortedChatHistory = [...items].reverse();
                if (page === 1) {
                    setUserChatHistory(sortedChatHistory);
                }
                return sortedChatHistory; // Return the sorted data
            } else {
                setIsLoading(false);
                return []; // Return an empty array on error
            }
        } catch (error) {
            setIsLoading(false);
            const response_data = error?.response?.data;
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
            return []; // Return an empty array on exception
        }
    };
    ////////////////////// Send Whatsapp message function //////////////////////////////////
    const sendWhatsappMessageAPI = async (data) => {
        // return
        setIsLoading(true);

        try {
            const params = {
                person_name: activeUserName,
                to_number: activeUserNum,
                // person_name: "Amritha Sunny",
                // to_number: "917356889520",
                ...data
            }

            if (uploadedFiles) params.base64_files = uploadedFiles;
            if (replyInfo?.id) params.reply_msg_id = replyInfo.id;
            // console.log("reply", params)
            // return
            const response = await sendWhatsappMessage(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;

                setIsLoading(false);
                formReset();
                fetchWhatsappChatHistory(1, activeUserNum)
                if (replyInfo?.id) {
                    setReplyInfo({});
                    setShowAboveScreen(false);
                    setUploadedFiles([])

                }
                // triggerAlert('success', 'success', 'Recharged Successfully!!');
            } else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }

    }
    ////////////////////// Send Location ///////////////////////////////////
    const sendWhatsappLocationAPI = async () => {
        // return
        setIsLoading(true);

        try {
            const params = {
                person_name: activeUserName,
                to_number: activeUserNum,
                // person_name: "Amritha Sunny",
                // to_number: "917356889520",
                latitude: location?.latitude,
                longitude: location?.longitude,

            }

            // if (data.msg_type == 'MMS') params.base64_files = mmsFile;
            const response = await sendWhatsappLocation(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;

                // setMMSFile({})
                setIsLoading(false);

                fetchWhatsappChatHistory(1, activeUserNum);
                // triggerAlert('success', 'success', 'Recharged Successfully!!');
            } else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }

    }

    const fetchMoreData = async (page) => {
        try {
            // Wait for the data from the next page
            const nextPageData = await fetchWhatsappChatHistory(page, activeUserNum);

            // Append the new data to the existing chat history
            setUserChatHistory(prevChatHistory => [...nextPageData, ...prevChatHistory]);
            // Increment the page number
            // setPage(prevPage => prevPage + 1);
        } catch (error) {
            console.error('Failed to fetch more data:', error);
        }
    };
    const handleFetchMoreData = async () => {
        if (page < totalPages) {
            // Preserve current scroll position
            const currentScrollHeight = chatContainerRef.current.scrollHeight;
            const currentScrollTop = chatContainerRef.current.scrollTop;

            setLoadingMore(true);
            await fetchMoreData(page + 1); // Fetch more data for the next page

            setLoadingMore(false);

            // Calculate new scroll position
            const newScrollHeight = chatContainerRef.current.scrollHeight;
            chatContainerRef.current.scrollTop = newScrollHeight - (currentScrollHeight - currentScrollTop);
        } else {
            setHasMore(false); // No more pages to load
        }
    };

    /////////////////////////////////// Send contacts /////////////////////////////////////
    // fetch all contacts
    const fetchAllContacts = async (searchKeyword = "") => {
        setIsLoading(true);
        try {
            const params = {
                keyword: searchKeyword
            };
            const response = await listContact(params);
            const response_data = response.data;

            // Check if the response contains users
            if (response_data.error_code === 200) {
                const data = response_data.results;
                if (data.length > 0) {
                    setContacts(data);
                } else {
                    // No users found, handle this gracefully
                    setContacts([]);

                }
            } else {
                // Handle other non-success cases
                setContacts([]);

            }
        } catch (error) {
            const response_data = error?.response?.data;
            triggerAlert('error', '', response_data ? response_data.message : "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    }
    // handle single contact select
    const handleCheckboxChange = (user) => {
        setSelectedContacts((prevContacts) => {
            const isSelected = prevContacts.some(
                contact => contact.number === user.contact_number
            );

            if (isSelected) {
                // Remove contact if already selected (based on number)
                return prevContacts.filter(contact => contact.number !== user.contact_number);
            } else {
                // Add new contact (based on name and number)
                return [
                    ...prevContacts,
                    { name: user.contact_name, number: user.contact_number }
                ];
            }
        });
    };
    // handle contact search
    const handleContactSearch = (e) => {
        const searchKeyword = e.target.value;
        fetchAllContacts(searchKeyword)
    }
    // handle send contact 
    const sendContact = async () => {
        // return
        if (selectedContacts.length === 0) {
            triggerAlert('info', '', 'Please select atleast one contact')
            return
        };
        setIsLoading(true);

        try {
            const api_input = {
                person_name: activeUserName,
                to_number: activeUserNum,
                // person_name: "Amritha Sunny",
                // to_number: "917356889520",
                contact_list: selectedContacts && selectedContacts
            }

            const response = await sendWhatsappContacts(api_input);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                setSelectedContacts([]);
                handleShowContactCloseModal();
                fetchWhatsappChatHistory(1, activeUserNum);
                triggerAlert('success', 'success', 'Successfully sent!!');
            } else {
                setSelectedContacts([])
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }

        } catch (error) {
            setSelectedContacts([])
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }
    ////////////////////////////////////////send video recording /////////////////////////////
    const handleRecordingComplete = async (base64_files) => {
        // console.log('Received Base64 Data:', base64Data);
        // Perform any action with the Base64 data, such as sending it to a server
        // return
        if (!base64_files) {
            triggerAlert('info', '', 'Please try again after sometime!!')
            return
        };
        setIsLoading(true);

        try {
            const api_input = {
                person_name: activeUserName,
                to_number: activeUserNum,
                // person_name: "Amritha Sunny",
                // to_number: "917356889520",
                base64_files
            }
            // console.log("api_input", api_input)
            // return

            const response = await sendWhatsappVideoandAudio(api_input);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                handleVideoCloseModal();
                fetchWhatsappChatHistory(1, activeUserNum);
                triggerAlert('success', 'success', 'Successfully sent!!');
            } else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }

        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    };

    /////////////////////////////////////// forward message ///////////////////////////
    const forwardMessage = async () => {
        if (selectedContacts.length === 0) {
            triggerAlert('info', '', 'Please select atleast one contact')
            return
        };
        setIsLoading(true);

        try {
            const api_input = {
                person_name: activeUserName,
                to_number: activeUserNum,
                // person_name: "Amritha Sunny",
                // to_number: "917356889520",
                whats_app_id: selectedMessageId,
                contact_list: selectedContacts && selectedContacts
            }

            const response = await forwardWhatsappMessage(api_input);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                setSelectedContacts([])
                handleForwardCloseModal()
                fetchWhatsappChatHistory(1, activeUserNum);
                triggerAlert('success', 'success', 'Successfully sent!!');
            } else {
                setSelectedContacts([])
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }

        } catch (error) {
            setSelectedContacts([])
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }

    useEffect(() => {
        if (initialLoad) {
            // Scroll to the bottom when the component mounts
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            }
            setInitialLoad(false);

        } else {
            // Reset hasMore when userChatHistory changes
            setHasMore(true); // or set it to an appropriate value based on your data logic
        }
    }, [userChatHistory, initialLoad]);

    // useEffect(() => {
    //     setInterval(function time() {
    //         var d = new Date();
    //         var hours = 24 - d.getHours();
    //         var min = 60 - d.getMinutes();
    //         if ((min + '').length == 1) {
    //             min = '0' + min;
    //         }
    //         var sec = 60 - d.getSeconds();
    //         if ((sec + '').length == 1) {
    //             sec = '0' + sec;
    //         }
    //         setTimer(hours + ':' + min + ':' + sec)
    //     }, 1000);
    //     fetchContacts(selectKey);
    // }, [])
    useEffect(() => {
        if (location) {
            // Once the location is available, call the API
            sendWhatsappLocationAPI(location.latitude, location.longitude);
        }
    }, [location]);

    ////////////////////////
    const fileInputRef = useRef(null);

    // Handle the "Upload files" click to trigger file input
    const handleAttachmentClick = () => {
        fileInputRef.current.click();
        setIsUploading(true); // Hide other elements when uploading
    };
    const handleFileUpload = async (event) => {
        const files = event.target.files;
        const selectedFiles = Array.from(files);

        // Check if the total file count exceeds the maximum limit
        if (uploadedFiles.length + selectedFiles.length > MAX_FILE_LIMIT) {
            triggerAlert('info', "", 'Maximum 10 files are allowed...');
            return;
        }

        if (files && files.length > 0) {
            const newFilesArray = [];
            for (let i = 0; i < files.length; i++) {

                if (uploadedFiles.length + newFilesArray.length < MAX_FILE_LIMIT) {
                    const base64File = await getBase64(files[i]);
                    const base64WithoutPrefix = base64File.substring(base64File.indexOf(",") + 1);
                    newFilesArray.push({
                        file_name: files[i].name,
                        file_type: files[i].type,
                        file_size: files[i].size,
                        file: base64WithoutPrefix,
                        preview: base64File
                    });
                } else {
                    break; // Stop adding files after reaching the limit
                }
            }

            // Update the state to include both old and new files
            setUploadedFiles((prevFiles) => [...prevFiles, ...newFilesArray]);
            setShowAboveScreen(true);
            // setIsUploading(false);
        }
    };

    const handleRemoveFile = (index, type) => {
        if (type === "all") {
            setShowAboveScreen(false);
            setIsUploading(false);
            setUploadedFiles([]);
        } else {
            const newUploadedFiles = uploadedFiles.filter((_, i) => i !== index);
            if (newUploadedFiles.length === 0) {
                setShowAboveScreen(false);
                setIsUploading(false);
                setUploadedFiles([]);
            } else {
                setUploadedFiles(newUploadedFiles);
            }
        }

    };
    const handleRemoveReply = () => {
        setShowAboveScreen(false);
        setReplyInfo({});
        setSelectedMessageId(null);
    }

    const handleReactionSelect = async (emoji) => {
        try {
            const api_input = {
                person_name: activeUserName,
                to_number: activeUserNum,
                // person_name: "Amritha Sunny",
                // to_number: "917356889520",
                message: emoji,
                msg_id: isHovered
            }

            const response = await reactToWhatsappMessage(api_input);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                setIsHovered(null);
                fetchWhatsappChatHistory(1, activeUserNum);
                // triggerAlert('success', 'success', 'Successfully sent!!');
            } else {
                setSelectedContacts([])
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }

        } catch (error) {
            setSelectedContacts([])
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }

    const handleAudioRecording = () => {
        setIsAudioRecording(true);
    }

    const handleSaveAudio = async (audioFileDetails) => {
        if (!audioFileDetails) {
            triggerAlert('info', '', 'Please try again after sometime!!')
            return
        };
        setIsLoading(true);

        try {
            const api_input = {
                person_name: activeUserName,
                to_number: activeUserNum,
                // person_name: "Amritha Sunny",
                // to_number: "917356889520",
                base64_files: [audioFileDetails]
            }
            // console.log("api_input", api_input)
            // return

            const response = await sendWhatsappVideoandAudio(api_input);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                setIsAudioRecording(false)
                fetchWhatsappChatHistory(1, activeUserNum);
                triggerAlert('success', 'success', 'Successfully sent!!');
            } else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }

        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }

    };

    const handleAudioCancel = () => {
        setIsAudioRecording(false);
        fetchWhatsappChatHistory(1, activeUserNum);
    }
    //////////////////////////////////// Receiving socket /////////////////////////////
    const { socket } = useSocket();

    useEffect(() => {
        if (!socket) return;

        if (!activeUserNum) return; // Ensure both selectedNumber and activeUserNum are not null or undefined

        // Set up event listener for receiving chat messages
        socket.on('send_whatsapp_message', (newData) => {
            if (newData.from_no == activeUserNum) {
                // console.log('right panel', activeUserNum);
                fetchWhatsappChatHistory(1, activeUserNum);
            } else {
                setPageNumber(1);
                fetchContacts(selectKey, 1);
            }
        });

        // Clean up on unmount
        return () => {
            socket.off('send_whatsapp_message');
        };
    }, [socket, activeUserNum]);

    return (
        <main class="main-content mt-3 mb-4">
            <div class="container content-inner" id="page_layout">
                <PageTitle heading="Inbox" showWarningButton="Bulk Upload" showPrimaryButton="Create Contact" />
                <div class="row w-100">
                    <div class="col-md-3">
                        <aside class="sidebar-chat sidebar-base border-end shadow-none  rounded-2" data-sidebar="responsive">
                            <div class="chat-search pt-3 px-3 ">
                                <div class="chat-searchbar mt-4 mb-2 ">
                                    <div class="form-group chat-search-data m-0">
                                        <input type="text" class="form-control round" id="chat-search" placeholder="Search" />
                                        <i class="material-symbols-outlined">
                                            search
                                        </i>
                                    </div>
                                </div>
                                <div class="select-chat mt-3">
                                    <select class="form-select" id="exampleFormControlSelect1" onChange={handleSelect} value={selectKey}>
                                        <option value="all">All chats</option>
                                        <option value="read">Read</option>
                                        <option value="unread">Unread</option>
                                        <option value="hrs"  >Last 24 hours</option>
                                        {/* <option value="exp" class="text-danger">Expired</option> */}
                                    </select>
                                </div>
                            </div>
                            <div class="sidebar-body pt-0 data-scrollbar chat-group mb-5 pb-5 pe-2"
                                id="scrollableDivContacts" ref={contactsContainerRef}
                                style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}
                            >
                                {/* <!-- Sidebar Menu Start --> */}
                                <ul class="nav navbar-nav iq-main-menu  mt-3" id="sidebar-menu" role="tablist">
                                    <InfiniteScrollWrapper
                                        dataLength={contactList.length}
                                        next={handleFetchMoreContacts}
                                        hasMore={hasMoreContacts} // Stop loading more data when false
                                        inverse={false} // This ensures the scroll direction is "up"
                                        loader={null}
                                        scrollableTarget="scrollableDivContacts"
                                    >
                                        {loadingMoreContacts && <h4 className='text-center text-danger'><SpinnerLoader /></h4>}
                                        {contactList.length > 0 ?
                                            contactList.map((item, index) => (
                                                <li key={item.from_no} class={`nav-item iq-chat-list ${activeUserNum == item.from_no ? 'active' : ''}`} onClick={() => handleContactClick(item.from_no, item.contact_name)}>
                                                    <a href={`#user-content-${activeUserNum}`} class={`nav-link  d-flex gap-1 ${activeUserNum == item.from_no ? 'active' : ''}`} data-bs-toggle="tab" role="tab" aria-controls={`#user-content-${activeUserNum}`} aria-selected="true">
                                                        <div class="position-relative">
                                                            <span class="badge badge-pill bg-soft-danger font-weight-normal ms-auto me-1 badge-45 md-14 rounded-circle p-2 ">{item.contact_name ? item.contact_name[0] : '-'}</span>
                                                        </div>
                                                        <div class="d-flex align-items-center w-100 iq-userlist-data">
                                                            <div class="d-flex flex-grow-1 flex-column">
                                                                <div class="d-flex align-items-center gap-1">
                                                                    <p class="mb-0 text-ellipsis short-1 flex-grow-1 iq-userlist-name fw-500">{item.contact_name ? truncateName(item.contact_name, 15) : '-'}</p>

                                                                    {/* <small class="text-capitalize">5min</small> */}
                                                                </div>
                                                                <div class="d-flex align-items-center gap-2">
                                                                    <small class="text-ellipsis short-1 flex-grow-1 chat-small">
                                                                        {item.from_no ? item.from_no : '-'}</small>
                                                                    {/* {item.un_seen_count != null &&
                                                                        <span class="badge rounded-pill bg-success badge-30">{item.un_seen_count ? item.un_seen_count : '0'}</span>
                                                                    } */}
                                                                    {unseenCounts[item.from_no] > 0 && (
                                                                        <span className="badge rounded-pill bg-success badge-30">
                                                                            {unseenCounts[item.from_no]}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="d-flex flex-grow-1 flex-column">
                                                                <div class="d-flex align-items-center gap-1">
                                                                    <small class="text-capitalize">
                                                                        {item.created_at ? formatDateTime(item.created_at, 'yyyy-mm-dd') : '-'}, <br />
                                                                        {item.created_at ? formatDateTime(item.created_at, 'hh:mm:ss') : '-'}
                                                                    </small>
                                                                    <div class="d-flex align-items-center gap-2">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            )) : <p className='text-center'>No contacts found!</p>}

                                    </InfiniteScrollWrapper>

                                </ul>
                                {/* <!-- Sidebar Menu End --> */}
                            </div>
                        </aside>
                    </div>
                    <div class="col-md-9">
                        <div class="tab-content" id="myTabContent">
                            <div class="card tab-pane mb-0 fade show active" id="user-content-101" role="tabpanel">
                                {userChatHistory.length > 0 ?
                                    <>
                                        <div class="chat-head">
                                            <header class="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3 border-bottom rounded-top">
                                                <div class="d-flex align-items-center">
                                                    <div class="position-relative">
                                                        <span class="badge badge-pill bg-soft-danger font-weight-normal ms-auto me-1 badge-45 md-14 rounded-circle p-2 ">{activeUserName ? activeUserName[0] : '-'}</span>
                                                    </div>
                                                    <div class="d-flex align-items-center w-100 iq-userlist-data">
                                                        <div class="d-flex flex-grow-1 flex-column">
                                                            <div class="d-flex align-items-center h-19">
                                                                <p class="mb-0 text-ellipsis short-1 flex-grow-1 iq-userlist-name fw-500">{activeUserName ? activeUserName : '-'}</p>
                                                                <a href="#" class="btn btn-icon btn-soft-success btn-sm ms-3 rounded-pill" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">
                                                                    <span class="btn-inner">
                                                                        <i class="material-symbols-outlined md-18"> visibility</i>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div class="d-flex align-items-center gap-2">
                                                                <small class="text-ellipsis short-1 flex-grow-1 chat-small">{activeUserNum ? activeUserNum : '-'}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat-header-icons d-inline-flex ms-auto">
                                                    <div id="the-final-countdown" class="color-full">
                                                        <p style={{ marginBottom: 0 }}>{timer}</p>
                                                        <h5 class="text-center  time-remain"  >TIME REMAINING</h5>

                                                    </div>
                                                </div>
                                            </header>
                                        </div>
                                        <div class="card-body chat-body bg-body">
                                            <div
                                                id="scrollableDiv"
                                                ref={chatContainerRef}
                                                style={{ overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }} // column-reverse to start at the bottom
                                            >
                                                <InfiniteScrollWrapper
                                                    dataLength={userChatHistory.length}
                                                    next={handleFetchMoreData}
                                                    hasMore={hasMore} // Stop loading more data when false
                                                    inverse={true} // This ensures the scroll direction is "up"
                                                    loader={null}
                                                    scrollableTarget="scrollableDiv"
                                                >
                                                    {loadingMore && <h4 className='text-center text-danger'><SpinnerLoader /></h4>}
                                                    {userChatHistory.map((item, index) => {
                                                        // Format the date of the current message
                                                        const messageDate = getFormattedDate(item.created_at, 'mm-dd-yyyy');
                                                        // Get the date of the previous message (or null if it's the first message)
                                                        const prevMessageDate = index > 0 ? getFormattedDate(userChatHistory[index - 1].created_at, 'mm-dd-yyyy') : null;
                                                        return (
                                                            <React.Fragment key={item.whats_app_id}>
                                                                {/* Render the date header if it's the first message or the date has changed */}
                                                                {(index === 0 || messageDate !== prevMessageDate) && (
                                                                    <div className="chat-day-title">
                                                                        <span className="main-title">{messageDate}</span>
                                                                    </div>
                                                                )}
                                                                {item.direction == 'IN' ?
                                                                    <div class="iq-message-body iq-other-user  gap-0" onMouseOver={() => handleMouseOver(item.message_id)}
                                                                        onMouseOut={handleMouseOut}
                                                                        style={{ position: "relative" }} // Ensuring positioning for absolute icon placement
                                                                    >

                                                                        <div class="chat-profile">
                                                                            <span class="badge badge-pill bg-soft-info font-weight-normal ms-auto me-2 badge-45 md-14 rounded-circle p-2 "><span class="material-symbols-outlined">person_outline</span></span>
                                                                        </div>

                                                                        <div class="iq-chat-text">
                                                                            <div class="d-flex align-items-center justify-content-start">
                                                                                <div class="iq-chating-content ">
                                                                                    <div class="d-flex align-items-center gap-1">
                                                                                        <p class="mb-0 text-ellipsis short-1 flex-grow-1 iq-userlist-chat text-info">{activeUserName ? activeUserName : item.from_no ? item.from_no : '-'}</p>
                                                                                        <div class="chat-lft p-1">
                                                                                            <div class="dropdown-container">
                                                                                                <div class="dropdown chat-drop-l">
                                                                                                    <span class="material-symbols-outlined fs-2" id="dropdownMenuButton9 " data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                                                                                        keyboard_arrow_down
                                                                                                    </span>
                                                                                                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton9">
                                                                                                        <a class="dropdown-item" href="#/" onClick={() => handleReplyShow(item.message_id, item.message, item.direction)}>Reply</a>
                                                                                                        <a class="dropdown-item" href="#/" onClick={() => handleForwardShow(item.whats_app_id)}>Forward</a>
                                                                                                        {/* <a class="dropdown-item" href="javascript:void(0);">Delete</a> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {item.reply_body_type &&
                                                                                        <div className='bg-light rounded px-1'>
                                                                                            <p class="fw-semibold mb-0">{item.reply_msg_name ? item.reply_msg_name : null}</p>
                                                                                            <p class="mr-2 mb-1 px-2">{item.reply_msg_body ? item.reply_msg_body : null}</p>
                                                                                        </div>
                                                                                    }

                                                                                    {item.message_type == "text" ?
                                                                                        <p class="mr-2 mb-3">{item.message ? item.message : null}
                                                                                        </p>
                                                                                        : item.message_type == "image" ?
                                                                                            <div>
                                                                                                <img src={item.attachment_path} class="img-fluid rounded" alt="chat-img" style={{ width: '316px' }} />
                                                                                            </div>
                                                                                            : item.message_type == "location" ?
                                                                                                <div>
                                                                                                    <DynamicLocation url={item.image_id} />
                                                                                                </div>
                                                                                                : item.message_type == "audio" ?
                                                                                                    <div>
                                                                                                        <audio controls onError={() => console.error("Failed to load audio")}>
                                                                                                            <source
                                                                                                                src={item.attachment_path?.replace('//', '/')} // Clean URL format
                                                                                                                type="audio/mp4" // Adjust MIME type if needed
                                                                                                            />
                                                                                                            Your browser does not support the audio tag.
                                                                                                        </audio>
                                                                                                    </div>
                                                                                                    : item.message_type == "video" ?
                                                                                                        <div>
                                                                                                            <div id="trailer" class="section d-flex justify-content-center embed-responsive embed-responsive-4by3" style={{ height: "20rem", width: "100%" }}>
                                                                                                                <video class="embed-responsive-item" controls controlsList="nodownload">
                                                                                                                    <source src={item.attachment_path} type="video/mp4" />
                                                                                                                    Your browser does not support the video tag.
                                                                                                                </video>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : item.message_type == "contacts" ?
                                                                                                            <div>
                                                                                                                <div class="d-flex justify-content-between  ">
                                                                                                                    <div class="">
                                                                                                                        <span class="badge badge-pill bg-soft-danger font-weight-normal ms-auto me-1 badge-45 md-14 rounded-circle p-2 ">
                                                                                                                            {/* <span class="material-symbols-outlined"> */}
                                                                                                                            {item.message ? item.message[0] : "-"}
                                                                                                                            {/* </span> */}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    <div class="w-100 ">
                                                                                                                        <div class="">
                                                                                                                            <h5 class=" me-1 pe-5 send-cntct">{item.message ? item.message : "-"}</h5>
                                                                                                                        </div>
                                                                                                                        <p class="mb-0">{item.image_id ? item.image_id : "-"}</p>
                                                                                                                        <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {/* <hr class="my-1" /> */}
                                                                                                                <div class="mb-2">
                                                                                                                    <div class="d-flex justify-content-between align-items-center">
                                                                                                                        {/* <div class="d-flex align-items-center me-3">
                                                                                                                        <a href="#/">    <span class="card-text-1 ms-1">Message</span></a>
                                                                                                                    </div>
                                                                                                                    <div class="d-flex align-items-center me-3">
                                                                                                                        <a href="#/"><span class="card-text-1 ms-1">Add a group</span></a>
                                                                                                                    </div> */}
                                                                                                                    </div>
                                                                                                                    <span class="card-text-2">
                                                                                                                        5.2k people love it
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            : null}


                                                                                    <div class="chat-time-position">
                                                                                        <div class="chat-time-right">
                                                                                            <small class="text-capitalize">{item.created_at ? formatTimeToAmandPM(item.created_at) : '-'}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    {item.reaction_message_id &&
                                                                                        <div className='chat-reaction p-1'>
                                                                                            <div className='reaction'>
                                                                                                <small>{item.reaction_emoji}</small>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                {(item.message_type == "text" || item.message_type == "contacts") &&
                                                                                    <TextToSpeech text={item.message_type == "contact" ? item.message + item.image_id : item.message} />
                                                                                }
                                                                                {/* Conditionally render the smile icon */}
                                                                                {isHovered === item.message_id && (
                                                                                    <>

                                                                                        <a href="#/" class="d-flex align-items-center pe-3" onClick={() => setShowEmojisReaction(!showEmojisReaction)}>
                                                                                            <svg class="icon-24" width="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clip-path="url(#clip0_156_599)">
                                                                                                    <path d="M20.4853 4.01473C18.2188 1.74823 15.2053 0.5 12 0.5C8.79469 0.5 5.78119 1.74823 3.51473 4.01473C1.24819 6.28119 0 9.29469 0 12.5C0 15.7053 1.24819 18.7188 3.51473 20.9853C5.78119 23.2518 8.79469 24.5 12 24.5C15.2053 24.5 18.2188 23.2518 20.4853 20.9853C22.7518 18.7188 24 15.7053 24 12.5C24 9.29469 22.7518 6.28119 20.4853 4.01473ZM12 23.0714C6.17091 23.0714 1.42856 18.3291 1.42856 12.5C1.42856 6.67091 6.17091 1.92856 12 1.92856C17.8291 1.92856 22.5714 6.67091 22.5714 12.5C22.5714 18.3291 17.8291 23.0714 12 23.0714Z" fill="currentcolor"></path>
                                                                                                    <path d="M9.40398 9.3309C8.23431 8.16114 6.33104 8.16123 5.16136 9.3309C4.88241 9.60981 4.88241 10.0621 5.16136 10.3411C5.44036 10.62 5.89266 10.62 6.17157 10.3411C6.78432 9.72836 7.78126 9.7284 8.39392 10.3411C8.53342 10.4806 8.71618 10.5503 8.89895 10.5503C9.08171 10.5503 9.26457 10.4806 9.40398 10.3411C9.68293 10.0621 9.68293 9.60986 9.40398 9.3309Z" fill="currentcolor"></path>
                                                                                                    <path d="M18.8384 9.3309C17.6688 8.16123 15.7655 8.16114 14.5958 9.3309C14.3169 9.60981 14.3169 10.0621 14.5958 10.3411C14.8748 10.62 15.3271 10.62 15.606 10.3411C16.2187 9.72836 17.2156 9.72831 17.8284 10.3411C17.9679 10.4806 18.1506 10.5503 18.3334 10.5503C18.5162 10.5503 18.699 10.4806 18.8384 10.3411C19.1174 10.0621 19.1174 9.60986 18.8384 9.3309Z" fill="currentcolor"></path>
                                                                                                    <path d="M18.3335 13.024H5.6668C5.2723 13.024 4.95251 13.3438 4.95251 13.7383C4.95251 17.6243 8.11409 20.7859 12.0001 20.7859C15.8862 20.7859 19.0477 17.6243 19.0477 13.7383C19.0477 13.3438 18.728 13.024 18.3335 13.024ZM12.0001 19.3573C9.14366 19.3573 6.77816 17.215 6.42626 14.4525H17.574C17.2221 17.215 14.8566 19.3573 12.0001 19.3573Z" fill="currentcolor"></path>
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath>
                                                                                                        <rect width="24" height="24" fill="white" transform="translate(0 0.5)"></rect>
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </a>
                                                                                        {showEmojisReaction && <Emojis reaction={true} allowExpand={false} onEmojiSelect={handleReactionSelect} />}
                                                                                    </>

                                                                                )}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div class="iq-message-body iq-current-user" onMouseOver={() => handleMouseOver(item.message_id)}
                                                                        onMouseOut={handleMouseOut}>
                                                                        <div class="iq-chat-text">
                                                                            <div class="d-flex align-items-center justify-content-end">
                                                                                {/* Conditionally render the smile icon */}
                                                                                {isHovered === item.message_id && (
                                                                                    <>
                                                                                        {showEmojisReaction && <Emojis reaction={true} allowExpand={false} onEmojiSelect={handleReactionSelect} />}
                                                                                        <a href="#/" class="d-flex align-items-center pe-3" onClick={() => setShowEmojisReaction(!showEmojisReaction)}>
                                                                                            <svg class="icon-24" width="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clip-path="url(#clip0_156_599)">
                                                                                                    <path d="M20.4853 4.01473C18.2188 1.74823 15.2053 0.5 12 0.5C8.79469 0.5 5.78119 1.74823 3.51473 4.01473C1.24819 6.28119 0 9.29469 0 12.5C0 15.7053 1.24819 18.7188 3.51473 20.9853C5.78119 23.2518 8.79469 24.5 12 24.5C15.2053 24.5 18.2188 23.2518 20.4853 20.9853C22.7518 18.7188 24 15.7053 24 12.5C24 9.29469 22.7518 6.28119 20.4853 4.01473ZM12 23.0714C6.17091 23.0714 1.42856 18.3291 1.42856 12.5C1.42856 6.67091 6.17091 1.92856 12 1.92856C17.8291 1.92856 22.5714 6.67091 22.5714 12.5C22.5714 18.3291 17.8291 23.0714 12 23.0714Z" fill="currentcolor"></path>
                                                                                                    <path d="M9.40398 9.3309C8.23431 8.16114 6.33104 8.16123 5.16136 9.3309C4.88241 9.60981 4.88241 10.0621 5.16136 10.3411C5.44036 10.62 5.89266 10.62 6.17157 10.3411C6.78432 9.72836 7.78126 9.7284 8.39392 10.3411C8.53342 10.4806 8.71618 10.5503 8.89895 10.5503C9.08171 10.5503 9.26457 10.4806 9.40398 10.3411C9.68293 10.0621 9.68293 9.60986 9.40398 9.3309Z" fill="currentcolor"></path>
                                                                                                    <path d="M18.8384 9.3309C17.6688 8.16123 15.7655 8.16114 14.5958 9.3309C14.3169 9.60981 14.3169 10.0621 14.5958 10.3411C14.8748 10.62 15.3271 10.62 15.606 10.3411C16.2187 9.72836 17.2156 9.72831 17.8284 10.3411C17.9679 10.4806 18.1506 10.5503 18.3334 10.5503C18.5162 10.5503 18.699 10.4806 18.8384 10.3411C19.1174 10.0621 19.1174 9.60986 18.8384 9.3309Z" fill="currentcolor"></path>
                                                                                                    <path d="M18.3335 13.024H5.6668C5.2723 13.024 4.95251 13.3438 4.95251 13.7383C4.95251 17.6243 8.11409 20.7859 12.0001 20.7859C15.8862 20.7859 19.0477 17.6243 19.0477 13.7383C19.0477 13.3438 18.728 13.024 18.3335 13.024ZM12.0001 19.3573C9.14366 19.3573 6.77816 17.215 6.42626 14.4525H17.574C17.2221 17.215 14.8566 19.3573 12.0001 19.3573Z" fill="currentcolor"></path>
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath>
                                                                                                        <rect width="24" height="24" fill="white" transform="translate(0 0.5)"></rect>
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </a>
                                                                                    </>

                                                                                )}
                                                                                {(item.message_type == "text_message" || item.message_type == "contact") &&
                                                                                    <TextToSpeech text={item.message_type == "contact" ? item.message + item.image_id : item.message} />
                                                                                }

                                                                                <div class="iq-chating-content position-relative">
                                                                                    {(item.message_type == "MARKETING" || item.message_type == "UTILITY" || item.message_type == "AUTHENTICATION") &&
                                                                                        <div className={`badge chat-template fw-semibold ${item.message_type == "MARKETING" ? "badge-success" : item.message_type == "UTILITY" ? 'badge-warning' : "badge-warning"}`}>
                                                                                            {item.message_type}
                                                                                        </div>
                                                                                    }

                                                                                    <div class="align-items-center gap-1">
                                                                                        {item.forwarded == 1 &&
                                                                                            <>
                                                                                                <p class="mb-0 text-ellipsis short-1 flex-grow-1 iq-userlist-chat text-secondary"><i class="fas fa-share"></i>&nbsp; Forwarded</p>
                                                                                                <hr className="my-1" style={{ borderTop: "1px solid #232121 !important" }} />
                                                                                            </>
                                                                                        }

                                                                                        <div class="chat-right ">
                                                                                            <div class="dropdown-container">

                                                                                                <div class="dropdown chat-drop">
                                                                                                    <span class="material-symbols-outlined fs-2" id="dropdownMenuButton9 " data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                                                                                        keyboard_arrow_down
                                                                                                    </span>
                                                                                                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton9" >
                                                                                                        <a class="dropdown-item " href="#/" onClick={() => handleReplyShow(item.message_id, item.message, item.direction)}>Reply</a>
                                                                                                        <a class="dropdown-item" href="#/" onClick={() => handleForwardShow(item.whats_app_id)}>Forward</a>
                                                                                                        {/* <a class="dropdown-item " href="javascript:void(0);">Delete</a> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {item.reply_body_type &&
                                                                                        <div className='bg-light rounded px-1'>
                                                                                            <p class="fw-semibold mb-0">{item.reply_msg_name ? item.reply_msg_name : null}</p>
                                                                                            <p class="mr-2 mb-1 px-2">{item.reply_msg_body ? item.reply_msg_body : null}</p>
                                                                                        </div>
                                                                                    }

                                                                                    {item.message_type == "text_message" ?
                                                                                        <p class="mr-2 mb-1 px-1">{item.message ? item.message : null}
                                                                                        </p>
                                                                                        : item.message_type == "image" ?
                                                                                            <div>
                                                                                                <img src={item.attachment_path} class="img-fluid rounded" alt="chat-img" style={{ width: '316px' }} />
                                                                                            </div>
                                                                                            : item.message_type == "location" ?
                                                                                                <div>
                                                                                                    <DynamicLocation url={item.image_id} />
                                                                                                </div>
                                                                                                : item.message_type == "audio" ?
                                                                                                    <div>
                                                                                                        <audio controls onError={() => console.error("Failed to load audio")}>
                                                                                                            <source
                                                                                                                src={item.attachment_path?.replace('//', '/')} // Clean URL format
                                                                                                                type="audio/mp4" // Adjust MIME type if needed
                                                                                                            />
                                                                                                            Your browser does not support the audio tag.
                                                                                                        </audio>
                                                                                                    </div>
                                                                                                    : item.message_type == "video" ?
                                                                                                        <div>
                                                                                                            <div id="trailer" class="section d-flex justify-content-center embed-responsive embed-responsive-4by3" style={{ height: "20rem", width: "100%" }}>
                                                                                                                <video class="embed-responsive-item" controls controlsList="nodownload">
                                                                                                                    <source src={item.attachment_path} type="video/mp4" />
                                                                                                                    Your browser does not support the video tag.
                                                                                                                </video>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : item.message_type == "contact" ?
                                                                                                            <div>
                                                                                                                <div class="d-flex justify-content-between  ">
                                                                                                                    <div class="">
                                                                                                                        <span class="badge badge-pill bg-soft-danger font-weight-normal ms-auto me-1 badge-45 md-14 rounded-circle p-2 ">
                                                                                                                            {/* <span class="material-symbols-outlined"> */}
                                                                                                                            {item.message ? item.message[0] : "-"}
                                                                                                                            {/* </span> */}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    <div class="w-100 ">
                                                                                                                        <div class="">
                                                                                                                            <h5 class=" me-1 pe-5 send-cntct">{item.message ? item.message : "-"}</h5>
                                                                                                                        </div>
                                                                                                                        <p class="mb-0">{item.image_id ? item.image_id : "-"}</p>
                                                                                                                        <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            : (item.message_type == "MARKETING" || item.message_type == "UTILITY" || item.message_type == "AUTHENTICATION") &&

                                                                                                            <div>
                                                                                                                <div className="d-flex justify-content-between">
                                                                                                                    <div className="w-100">
                                                                                                                        {item.template_data?.response?.data?.length > 1 ? (
                                                                                                                            // Loop through the template data and find the matching name
                                                                                                                            item?.template_data?.response?.data?.map((template, index) =>
                                                                                                                                template?.name === item?.image_id && (

                                                                                                                                    <ul class="list-inline mb-0" key={index}>
                                                                                                                                        <li class="list-inline-item">
                                                                                                                                            <div class="p-1 ctext-wrap-content-file-size">
                                                                                                                                                {/* <img src="https://customer.vitelsms.com/uploads/template_images/6.jpg" alt="" class="border d-flex" width="300" height="200" /> */}
                                                                                                                                                <div class="main_box_send1 m-auto border p-3 bg-light bg-gradient" style={{ width: '300px' }}>
                                                                                                                                                    {template?.components.find(c => c.type === "HEADER") && (
                                                                                                                                                        <b>
                                                                                                                                                            <span id="previewHeader_send" class="ng-binding"> {template?.components.find(c => c.type === "HEADER").text}</span>
                                                                                                                                                        </b>
                                                                                                                                                    )}
                                                                                                                                                    {template?.components.find(c => c.type === "BODY") && (
                                                                                                                                                        <span id="previewText_send"><pre class="ng-binding">
                                                                                                                                                            {template.components.find(c => c.type === "BODY").text}
                                                                                                                                                        </pre>

                                                                                                                                                        </span>
                                                                                                                                                    )}
                                                                                                                                                    {template?.components.find(c => c.type === "FOOTER") && (
                                                                                                                                                        <span id="previewFooter_send" ng-if="proms.data[0].components[2].text !=''" class="ng-binding ng-scope">{template.components.find(c => c.type === "FOOTER").text}</span>
                                                                                                                                                    )}

                                                                                                                                                    <hr className='text-dark' />

                                                                                                                                                    <div className="d-flex flex-column justify-content-between align-items-center">
                                                                                                                                                        <div className="d-flex align-items-center">
                                                                                                                                                            {/* Display arrow icon if buttons are present */}
                                                                                                                                                            {template?.components?.find(c => c.type === "BUTTONS")?.buttons?.length > 0 && (
                                                                                                                                                                <i className="fa fa-reply me-2" aria-hidden="true"></i>
                                                                                                                                                            )}

                                                                                                                                                            {/* Display Buttons */}
                                                                                                                                                            {template?.components?.find(c => c.type === "BUTTONS")?.buttons?.map((button, index) => (
                                                                                                                                                                <div key={index}>
                                                                                                                                                                    <span>{button.text}</span>
                                                                                                                                                                </div>
                                                                                                                                                            ))}
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                            </div>
                                                                                                                                        </li>
                                                                                                                                    </ul>
                                                                                                                                )
                                                                                                                            )
                                                                                                                        ) : item.template_data?.response?.data?.length == 1 ? (

                                                                                                                            <ul class="list-inline mb-0">
                                                                                                                                <li class="list-inline-item">
                                                                                                                                    <div class="p-1 ctext-wrap-content-file-size">
                                                                                                                                        {/* <img src="https://customer.vitelsms.com/uploads/template_images/6.jpg" alt="" class="border d-flex" width="300" height="200" /> */}
                                                                                                                                        <div class="main_box_send1 m-auto border p-3 bg-light bg-gradient" style={{ width: '300px' }}>
                                                                                                                                            <></>
                                                                                                                                            {item?.template_data?.response?.data[0]?.components.find(c => c.type === "HEADER") && (

                                                                                                                                                <b>
                                                                                                                                                    <span id="previewHeader_send" class="ng-binding"> {item.template_data.response.data[0].components.find(c => c.type === "HEADER").text}</span>
                                                                                                                                                </b>
                                                                                                                                            )}
                                                                                                                                            {item?.template_data?.response?.data[0]?.components.find(c => c.type === "BODY") && (
                                                                                                                                                <span id="previewText_send"><pre class="ng-binding">
                                                                                                                                                    {item.template_data.response.data[0].components.find(c => c.type === "BODY").text}
                                                                                                                                                </pre>

                                                                                                                                                </span>
                                                                                                                                            )}
                                                                                                                                            {item?.template_data?.response?.data[0]?.components.find(c => c.type === "FOOTER") && (
                                                                                                                                                <span id="previewFooter_send" ng-if="proms.data[0].components[2].text !=''" class="ng-binding ng-scope">{item.template_data.response.data[0].components.find(c => c.type === "FOOTER").text}</span>
                                                                                                                                            )}
                                                                                                                                            <hr className='text-dark' />

                                                                                                                                            <div className="d-flex flex-column justify-content-between align-items-center">
                                                                                                                                                <div className="d-flex align-items-center">


                                                                                                                                                    {/* Display Buttons */}
                                                                                                                                                    {item?.template_data?.response?.data[0]?.components?.find(c => c.type === "BUTTONS")?.buttons?.map((button, index) => (
                                                                                                                                                        <div key={index}>
                                                                                                                                                            {button.type == "QUICK_REPLY" && <i className="fa fa-reply me-2" aria-hidden="true"></i>}
                                                                                                                                                            <span>{button.text}</span>
                                                                                                                                                        </div>
                                                                                                                                                    ))}
                                                                                                                                                </div>
                                                                                                                                            </div>

                                                                                                                                        </div>

                                                                                                                                    </div>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                        ) : <p>No template found.</p>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                    }
                                                                                    <div class="d-flex justify-content-end">
                                                                                        <div style={{ width: "49px" }}>
                                                                                            <small class="text-capitalize">{item.created_at ? formatTimeToAmandPM(item.created_at) : '-'}</small>
                                                                                        </div>

                                                                                    </div>
                                                                                    {item.reaction_message_id &&
                                                                                        <div className='chat-reaction p-1'>
                                                                                            <div className='reaction'>
                                                                                                <small>{item.reaction_emoji}</small>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </React.Fragment>
                                                        )
                                                    }
                                                    )}
                                                </InfiniteScrollWrapper>
                                            </div>
                                        </div>
                                        <div class="card-footer px-3 py-3 border-top rounded-0">
                                            {!isAudioRecording ?
                                                <>
                                                    <div className="chat-container mb-2">
                                                        {showAboveScreen && (
                                                            <>
                                                                {uploadedFiles.length > 0 && (
                                                                    <div
                                                                        className="uploaded-files-preview"
                                                                        style={{
                                                                            position: 'relative',
                                                                            border: '2px solid rgb(94 89 89 / 40%)',
                                                                            padding: '10px',
                                                                            borderRadius: '5px',
                                                                            display: 'flex',
                                                                            flexWrap: 'wrap',
                                                                            maxWidth: '1000px',
                                                                            width: '100%',
                                                                            backgroundColor: '#f9f9f9',
                                                                        }}
                                                                    >
                                                                        <button
                                                                            onClick={() => handleRemoveFile('', 'all')}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '5px',
                                                                                right: '5px',
                                                                                background: 'black',
                                                                                color: 'white',
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                cursor: 'pointer',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                border: 'none',
                                                                                borderRadius: '50%',
                                                                                fontSize: '16px',
                                                                            }}
                                                                        >
                                                                            &times;
                                                                        </button>
                                                                        {uploadedFiles.map((fileObj, index) => (
                                                                            <div key={index} className="uploaded-file-preview" style={{
                                                                                position: 'relative',
                                                                                display: 'inline-block',
                                                                                margin: '10px',
                                                                                width: '100px',
                                                                                height: '75px',
                                                                                overflow: 'hidden',
                                                                                border: '1px solid #ccc',
                                                                                borderRadius: '5px',
                                                                                backgroundColor: '#f7f7f7',
                                                                            }}>
                                                                                {['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileObj.file_type?.split('/')[1].toLowerCase()) ? (
                                                                                    <img
                                                                                        src={fileObj.preview}
                                                                                        alt={`Uploaded Preview ${index + 1}`}
                                                                                        style={{
                                                                                            maxWidth: '100%',
                                                                                            maxHeight: '100%',
                                                                                            objectFit: 'cover',
                                                                                            display: 'block',
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                                                        <p style={{ fontSize: '12px', textAlign: 'center', margin: '0', marginTop: '5px' }}>
                                                                                            {fileObj.file_name}
                                                                                        </p>
                                                                                    </div>
                                                                                )}
                                                                                <button
                                                                                    onClick={() => handleRemoveFile(index, 'single')}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '5px',
                                                                                        right: '5px',
                                                                                        background: 'black',
                                                                                        color: 'white',
                                                                                        width: '20px',
                                                                                        height: '20px',
                                                                                        cursor: 'pointer',
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center',
                                                                                    }}
                                                                                >
                                                                                    &times;
                                                                                </button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}

                                                                {Object.keys(replyInfo)?.length > 0 && (
                                                                    <div
                                                                        className="reply-info-preview position-relative"
                                                                        style={{
                                                                            marginTop: '20px',
                                                                            padding: '10px',
                                                                            border: '1px solid #ccc',
                                                                            borderRadius: '5px',
                                                                            backgroundColor: '#f1f1f1',
                                                                        }}
                                                                    >
                                                                        {/* Render the reply info content */}
                                                                        <p>{replyInfo.direction === "IN" ? activeUserName : "you"}  :  {replyInfo.text}</p>
                                                                        <button
                                                                            onClick={handleRemoveReply}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '5px',
                                                                                right: '5px',
                                                                                background: 'black',
                                                                                color: 'white',
                                                                                width: '20px',
                                                                                height: '20px',
                                                                                cursor: 'pointer',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            &times;
                                                                        </button>

                                                                    </div>
                                                                )}
                                                            </>
                                                        )}

                                                    </div>

                                                    <>
                                                        <form onSubmit={handleSubmit(sendWhatsappMessageAPI)}>
                                                            <div class="d-flex align-items-center" >
                                                                <div class="chat-attagement d-flex">
                                                                    {!isUploading &&
                                                                        <a href="#/" class="d-flex align-items-center pe-3" onClick={() => setShowEmojis(!showEmojis)}>
                                                                            <svg class="icon-24" width="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clip-path="url(#clip0_156_599)">
                                                                                    <path d="M20.4853 4.01473C18.2188 1.74823 15.2053 0.5 12 0.5C8.79469 0.5 5.78119 1.74823 3.51473 4.01473C1.24819 6.28119 0 9.29469 0 12.5C0 15.7053 1.24819 18.7188 3.51473 20.9853C5.78119 23.2518 8.79469 24.5 12 24.5C15.2053 24.5 18.2188 23.2518 20.4853 20.9853C22.7518 18.7188 24 15.7053 24 12.5C24 9.29469 22.7518 6.28119 20.4853 4.01473ZM12 23.0714C6.17091 23.0714 1.42856 18.3291 1.42856 12.5C1.42856 6.67091 6.17091 1.92856 12 1.92856C17.8291 1.92856 22.5714 6.67091 22.5714 12.5C22.5714 18.3291 17.8291 23.0714 12 23.0714Z" fill="currentcolor"></path>
                                                                                    <path d="M9.40398 9.3309C8.23431 8.16114 6.33104 8.16123 5.16136 9.3309C4.88241 9.60981 4.88241 10.0621 5.16136 10.3411C5.44036 10.62 5.89266 10.62 6.17157 10.3411C6.78432 9.72836 7.78126 9.7284 8.39392 10.3411C8.53342 10.4806 8.71618 10.5503 8.89895 10.5503C9.08171 10.5503 9.26457 10.4806 9.40398 10.3411C9.68293 10.0621 9.68293 9.60986 9.40398 9.3309Z" fill="currentcolor"></path>
                                                                                    <path d="M18.8384 9.3309C17.6688 8.16123 15.7655 8.16114 14.5958 9.3309C14.3169 9.60981 14.3169 10.0621 14.5958 10.3411C14.8748 10.62 15.3271 10.62 15.606 10.3411C16.2187 9.72836 17.2156 9.72831 17.8284 10.3411C17.9679 10.4806 18.1506 10.5503 18.3334 10.5503C18.5162 10.5503 18.699 10.4806 18.8384 10.3411C19.1174 10.0621 19.1174 9.60986 18.8384 9.3309Z" fill="currentcolor"></path>
                                                                                    <path d="M18.3335 13.024H5.6668C5.2723 13.024 4.95251 13.3438 4.95251 13.7383C4.95251 17.6243 8.11409 20.7859 12.0001 20.7859C15.8862 20.7859 19.0477 17.6243 19.0477 13.7383C19.0477 13.3438 18.728 13.024 18.3335 13.024ZM12.0001 19.3573C9.14366 19.3573 6.77816 17.215 6.42626 14.4525H17.574C17.2221 17.215 14.8566 19.3573 12.0001 19.3573Z" fill="currentcolor"></path>
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath>
                                                                                        <rect width="24" height="24" fill="white" transform="translate(0 0.5)"></rect>
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </a>
                                                                    }
                                                                    <div class="card-header-toolbar pe-3">
                                                                        {!Object.keys(replyInfo)?.length > 0 &&
                                                                            <div class="dropdown">
                                                                                <div class="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                                                                    <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="icon-24" fill="none">
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 13.2501L20.5 10.7501L13.25 10.7501L13.25 3.5L10.75 3.5L10.75 10.7501L3.5 10.7501L3.5 13.2501L10.75 13.2501L10.75 20.5L13.25 20.5L13.25 13.2501L20.5 13.2501Z" fill="currentColor"></path>
                                                                                    </svg>
                                                                                </div>


                                                                                <div class="dropdown-menu dropdown-menu-right dropdown-upload" aria-labelledby="dropdownMenuButton" >
                                                                                    <a
                                                                                        className="dropdown-item d-flex align-items-center"
                                                                                        href="#/"
                                                                                        onClick={() => {
                                                                                            handleAttachmentClick();
                                                                                            // setImageUpload(false); // Assuming you want to set image upload to true
                                                                                        }}
                                                                                    >
                                                                                        <span className="material-symbols-outlined me-2 md-18">file_upload</span>
                                                                                        Upload files
                                                                                    </a>


                                                                                    {/* Hidden file input */}


                                                                                    <input
                                                                                        type="file"
                                                                                        ref={fileInputRef}
                                                                                        style={{ display: 'none' }}
                                                                                        onChange={handleFileUpload}
                                                                                        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf" // Accept multiple file types
                                                                                        multiple // Allow multiple files
                                                                                    // disabled={uploadedFiles.length >= MAX_FILE_LIMIT}
                                                                                    />
                                                                                    {!isUploading &&
                                                                                        <>
                                                                                            <a class="dropdown-item d-flex align-items-center" href="#/" onClick={handleAudioRecording}><span class="material-symbols-outlined me-2 md-18">
                                                                                                volume_up
                                                                                            </span>Audio</a>
                                                                                            <a class="dropdown-item d-flex align-items-center" href="#/" onClick={handleShowVideoModal}><span class="material-symbols-outlined me-2 md-18">
                                                                                                smart_display
                                                                                            </span>Video</a>
                                                                                            <a class="dropdown-item d-flex align-items-center" href="#/" onClick={handleGetLocation}><span class="material-symbols-outlined me-2 md-18">
                                                                                                location_on
                                                                                            </span>Location</a>
                                                                                            <a class="dropdown-item d-flex align-items-center" href="#/" onClick={handleContactShow}><span class="material-symbols-outlined me-2 md-18">
                                                                                                contacts
                                                                                            </span>Contacts</a>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {!isUploading ?
                                                                    <>
                                                                        <input type="text" class="form-control me-3" placeholder="Type your message" name='message'  {...register("message", {
                                                                            required: "Message is required",
                                                                        })} />
                                                                        {/* {location &&
                                                <div>
                                                    Current Location: Latitude: {location.latitude}, Longitude: {location.longitude}
                                                </div>
                                            } */}

                                                                        <div class="chat-attagement">
                                                                            <SpeechToText onTranscription={handleTranscription} />
                                                                        </div>
                                                                    </> :
                                                                    <div className='w-100 text-end me-3'>
                                                                        Total files selected: {uploadedFiles?.length}
                                                                    </div>
                                                                }

                                                                <button type="submit" class="btn btn-primary d-flex align-items-center">
                                                                    <svg class="icon-20" width="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M13.8325 6.67463L8.10904 12.4592L1.59944 8.38767C0.66675 7.80414 0.860765 6.38744 1.91572 6.07893L17.3712 1.55277C18.3373 1.26963 19.2326 2.17283 18.9456 3.142L14.3731 18.5868C14.0598 19.6432 12.6512 19.832 12.0732 18.8953L8.10601 12.4602" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                    </svg>
                                                                    <span class="d-none d-lg-block ms-1">Send</span>
                                                                </button>
                                                            </div>
                                                            <div className='mt-2'>
                                                                {showEmojis &&
                                                                    <Emojis onEmojiSelect={handleEmojiSelect} pickerSize={{ height: 400, width: 935 }} />
                                                                }
                                                            </div>
                                                        </form>
                                                    </>
                                                </> :
                                                <div className='w-100 text-end me-3 position-relative'>
                                                    <div className='mb-3'>
                                                        <button
                                                            onClick={handleAudioCancel}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-42px',
                                                                right: '-16px',
                                                                background: 'black',
                                                                color: 'white',
                                                                width: '25px',
                                                                height: '25px',
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                border: 'none',
                                                                borderRadius: '50%',
                                                                fontSize: '16px',
                                                            }}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                    <AudioRecorder onBase64Ready={handleSaveAudio} />
                                                </div>
                                            }
                                        </div>
                                    </>
                                    :
                                    <div class="card-body chat-body inbox-body bg-body">
                                        <div className='d-flex justify-content-center flex-column align-items-center'>


                                            {/* <img src='/assets/images/Inbox.jpg' alt='inbox' /> */}
                                            <LazyLoadImage src='/assets/images/Inbox.jpg' alt='inbox' />
                                            <p className='text-center'>Please select any one contact and view</p>
                                        </div>
                                    </div>

                                }
                            </div>

                        </div >
                    </div >
                </div >

            </div >

            <Modal show={showContactModal} onHide={handleShowContactCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Send Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div class="chat-searchbar">
                        <div class="form-group chat-search-data m-0">
                            <input type="text" class="form-control round" id="chat-search" placeholder="Search" onChange={handleContactSearch} />
                            <i class="material-symbols-outlined">
                                search
                            </i>
                        </div>
                    </div>
                    <ul className="nav navbar-nav iq-main-menu" style={{ border: "1px" }} id="sidebar-menu" role="tablist">

                        {/* Select All Checkbox */}
                        {/* <li className="nav-item mb-0">
                                <div className="form-check form-check-inline me-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="select-all"
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                    />
                                    <label className="form-check-label" htmlFor="select-all">Select All</label>
                                </div>
                            </li> */}
                        {contacts.length > 0 ? (
                            contacts.map((user) => (
                                <li
                                    key={user.id}
                                    className={`nav-item iq-chat-list`}
                                    role="tab"
                                >
                                    <div className="nav-link d-flex align-items-center gap-0" role="tab">
                                        <div className="form-check form-check-inline me-2">
                                            <input
                                                type="checkbox"
                                                className="form-check-input p-2"
                                                id={`checkbox-${user.id}`}
                                                checked={selectedContacts?.some(contact => contact.number === user.contact_number)}
                                                onChange={() => handleCheckboxChange(user)}
                                            />
                                        </div>
                                        <div className="d-flex gap-1 align-items-center">
                                            <div className="position-relative">
                                                <span className="badge badge-pill bg-soft-success font-weight-normal ms-auto me-1 badge-45 md-14 rounded-circle p-2">
                                                    {user.contact_name[0]}
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center w-100 iq-userlist-data">
                                                <div className="d-flex flex-grow-1 flex-column">
                                                    <div className="d-flex align-items-center gap-1">
                                                        <p className="mb-0 text-ellipsis short-1 user-chat flex-grow-1 iq-userlist-name fw-500">
                                                            {truncateName(user.contact_name, 17)}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <small className="text-ellipsis short-1 flex-grow-1 chat-small">
                                                            {user.contact_number}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <li className="nav-item no-user-found">
                                <div className="nav-link d-flex gap-0 justify-content-center">
                                    <span>No contacts found</span>
                                </div>
                            </li>
                        )}
                    </ul>

                </Modal.Body>
                <ModalFooter>
                    <div className='d-flex justify-content-end'>
                        {/* <div>
                            {selectedContacts && ( // Use parentheses for clarity
                                <>
                                    <h6>Selected Contacts:</h6>
                                    {selectedContacts.map((item) => (
                                        <div key={item.id || item.number}>
                                            Convert item.number to string if it's not already
                                            {typeof item.number !== 'string' ? String(item.number) : item.number}
                                            {Array.isArray(item.number) ? item.number.join(', ') : item.number}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div> */}
                        <button type="button" class="btn btn-warning d-flex align-items-center" onClick={handleShowContactCloseModal}>

                            <span class="d-none d-lg-block ms-1">Cancel</span>
                        </button>
                        <button type="button" class="btn btn-primary d-flex align-items-center ms-2" onClick={sendContact}>
                            <svg class="icon-20" width="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8325 6.67463L8.10904 12.4592L1.59944 8.38767C0.66675 7.80414 0.860765 6.38744 1.91572 6.07893L17.3712 1.55277C18.3373 1.26963 19.2326 2.17283 18.9456 3.142L14.3731 18.5868C14.0598 19.6432 12.6512 19.832 12.0732 18.8953L8.10601 12.4602" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <span class="d-none d-lg-block ms-1">Send</span>
                        </button>

                    </div>
                </ModalFooter>
            </Modal>
            <Modal show={showForwardModal} onHide={handleForwardCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Forward message to</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div class="chat-searchbar">
                        <div class="form-group chat-search-data m-0">
                            <input type="text" class="form-control round" id="chat-search" placeholder="Search" onChange={handleContactSearch} />
                            <i class="material-symbols-outlined">
                                search
                            </i>
                        </div>
                    </div>
                    <ul className="nav navbar-nav iq-main-menu" style={{ border: "1px" }} id="sidebar-menu" role="tablist">

                        {/* Select All Checkbox */}
                        {/* <li className="nav-item mb-0">
                                <div className="form-check form-check-inline me-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="select-all"
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                    />
                                    <label className="form-check-label" htmlFor="select-all">Select All</label>
                                </div>
                            </li> */}
                        {contacts.length > 0 ? (
                            contacts.map((user) => (
                                <li
                                    key={user.id}
                                    className={`nav-item iq-chat-list`}
                                    role="tab"
                                >
                                    <div className="nav-link d-flex align-items-center gap-0" role="tab">
                                        <div className="form-check form-check-inline me-2">
                                            <input
                                                type="checkbox"
                                                className="form-check-input p-2"
                                                id={`checkbox-${user.id}`}
                                                checked={selectedContacts?.some(contact => contact.number === user.contact_number)}
                                                onChange={() => handleCheckboxChange(user)}
                                            />
                                        </div>
                                        <div className="d-flex gap-1 align-items-center">
                                            <div className="position-relative">
                                                <span className="badge badge-pill bg-soft-success font-weight-normal ms-auto me-1 badge-45 md-14 rounded-circle p-2">
                                                    {user.contact_name[0]}
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center w-100 iq-userlist-data">
                                                <div className="d-flex flex-grow-1 flex-column">
                                                    <div className="d-flex align-items-center gap-1">
                                                        <p className="mb-0 text-ellipsis short-1 user-chat flex-grow-1 iq-userlist-name fw-500">
                                                            {truncateName(user.contact_name, 17)}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <small className="text-ellipsis short-1 flex-grow-1 chat-small">
                                                            {user.contact_number}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <li className="nav-item no-user-found">
                                <div className="nav-link d-flex gap-0 justify-content-center">
                                    <span>No contacts found</span>
                                </div>
                            </li>
                        )}
                    </ul>

                </Modal.Body>
                <ModalFooter>
                    <div className='d-flex justify-content-end'>
                        {/* <div>
                            {selectedContacts && ( // Use parentheses for clarity
                                <>
                                    <h6>Selected Contacts:</h6>
                                    {selectedContacts.map((item) => (
                                        <div key={item.id || item.number}>
                                            Convert item.number to string if it's not already
                                            {typeof item.number !== 'string' ? String(item.number) : item.number}
                                            {Array.isArray(item.number) ? item.number.join(', ') : item.number}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div> */}
                        <button type="button" class="btn btn-warning d-flex align-items-center" onClick={handleForwardCloseModal}>

                            <span class="d-none d-lg-block ms-1">Cancel</span>
                        </button>
                        <button type="button" class="btn btn-primary d-flex align-items-center ms-2" onClick={forwardMessage}>
                            <svg class="icon-20" width="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8325 6.67463L8.10904 12.4592L1.59944 8.38767C0.66675 7.80414 0.860765 6.38744 1.91572 6.07893L17.3712 1.55277C18.3373 1.26963 19.2326 2.17283 18.9456 3.142L14.3731 18.5868C14.0598 19.6432 12.6512 19.832 12.0732 18.8953L8.10601 12.4602" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <span class="d-none d-lg-block ms-1">Send</span>
                        </button>

                    </div>
                </ModalFooter>
            </Modal>
            <Modal show={showVideoModal} onHide={handleVideoCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Send Video Recording</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <VideoRecording onRecordingComplete={handleRecordingComplete} />


                </Modal.Body>

            </Modal>
        </main >
    )
}
