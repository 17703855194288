import React, { useState } from 'react'
import PageTitle from '../../../common/PageTitle'
import SkeletonLoader from '../../../common/components/SkeletonLoader'

export default function Templates() {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <div id="content-page" class="content-page">
                <div class="container">

                    <PageTitle heading="Templates" showWarningButton="Bulk Upload" showPrimaryButton="Create" />
                    <div class="row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="card">
                                <div class="card-header border-0">
                                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                                        <ul class="nav nav-pills  " id="pills-tab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Marketing</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" tabindex="-1">Utility</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false" tabindex="-1">Authentication</a>
                                            </li>
                                        </ul>
                                        <div class="d-flex align-items-center">
                                            <button type="button" class="btn btn-primary btn-sm  px-3 d-flex align-items-center">
                                                <span class="ms-2">Action  </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="tab-content" id="pills-tabContent-2">
                                        <div class="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <div class="table-responsive">
                                                {isLoading ? (
                                                    <SkeletonLoader />
                                                ) : (
                                                    <table class="table table-bordered table-hover table-striped">
                                                        <thead class=" bg-light text-nowrap">
                                                            <tr>
                                                                <th scope="col">Template Name</th>
                                                                <th scope="col">Template Type</th>
                                                                <th scope="col">Template Header</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Whatsapptest</th>
                                                                <td>Marketing</td>
                                                                <td>text of the printing and  {1}  text of the printing and {2}</td>
                                                                <td><span class="badge bg-danger border-radius  rounded-pill ">Rejected</span></td>
                                                                <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Template Testing</th>
                                                                <td>Marketing</td>
                                                                <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s {1}</td>
                                                                <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                                <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Intro Catalog Offerr</th>
                                                                <td>Marketing</td>
                                                                <td><strong>🚀 Upgrade Your Business Communications with Vitel Global!</strong><br /> 🔄 Upgrade your business communication with Vitel Global's scalable, top-tier Hosted VoIP and PBX systems, ensuring you're always ahead in tech. Here's what you get:<br /><br /><strong>Local Dialing &amp; Numbers: 📞🏠</strong> Provide a local presence with local dialing capabilities and numbers, enhancing customer trust and convenience.<br /> <strong>Call Delegation &amp; Screening: 📞👤</strong> Control and manage calls efficiently, ensuring that the right people handle the right conversations.<br /> <strong>Call Forwarding &amp; Flip: 📞🔁</strong> Seamlessly transfer calls between devices without interruption, ensuring you're always connected.<br /> <strong>Voicemail to Email: 📬✉️</strong> Never miss a message with voicemails automatically sent to your email, allowing for easy retrieval and response.<br /> <strong>Greetings &amp; Multi-level IVR: 🎙️📞🔊</strong>
                                                                    Create a professional first impression with custom greetings and an Interactive Voice Response system to guide callers.</td>
                                                                <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                                <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Test Temolate</th>
                                                                <td>Marketing</td>
                                                                <td>text of the printing and  {1}  text of the printing and {2}</td>
                                                                <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                                <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Whatsapptest</th>
                                                                <td>Marketing</td>
                                                                <td>text of the printing and  {1}  text of the printing and {2}</td>
                                                                <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                                <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                            <div class="table-responsive">
                                                <table class="table table-bordered table-hover table-striped">
                                                    <thead class=" bg-light text-nowrap">
                                                        <tr>
                                                            <th scope="col">Template Name</th>
                                                            <th scope="col">Template Type</th>
                                                            <th scope="col">Template Header</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Whatsapptest</th>
                                                            <td>Marketing</td>
                                                            <td>text of the printing and  {1}  text of the printing and {2}</td>
                                                            <td><span class="badge bg-danger border-radius  rounded-pill ">Rejected</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Template Testing</th>
                                                            <td>Marketing</td>
                                                            <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s {1}</td>
                                                            <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Intro Catalog Offerr</th>
                                                            <td>Marketing</td>
                                                            <td><strong>🚀 Upgrade Your Business Communications with Vitel Global!</strong><br /> 🔄 Upgrade your business communication with Vitel Global's scalable, top-tier Hosted VoIP and PBX systems, ensuring you're always ahead in tech. Here's what you get:<br /><br /><strong>Local Dialing &amp; Numbers: 📞🏠</strong> Provide a local presence with local dialing capabilities and numbers, enhancing customer trust and convenience.<br /> <strong>Call Delegation &amp; Screening: 📞👤</strong> Control and manage calls efficiently, ensuring that the right people handle the right conversations.<br /> <strong>Call Forwarding &amp; Flip: 📞🔁</strong> Seamlessly transfer calls between devices without interruption, ensuring you're always connected.<br /> <strong>Voicemail to Email: 📬✉️</strong> Never miss a message with voicemails automatically sent to your email, allowing for easy retrieval and response.<br /> <strong>Greetings &amp; Multi-level IVR: 🎙️📞🔊</strong> Create a professional first impression with custom greetings and an Interactive Voice Response system to guide callers.</td>
                                                            <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Test Temolate</th>
                                                            <td>Marketing</td>
                                                            <td>text of the printing and  {1}  text of the printing and {2}</td>
                                                            <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Whatsapptest</th>
                                                            <td>Marketing</td>
                                                            <td>text of the printing and  {1}  text of the printing and {2}</td>
                                                            <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                            <div class="table-responsive">
                                                <table class="table table-bordered table-hover table-striped">
                                                    <thead class=" bg-light text-nowrap">
                                                        <tr>
                                                            <th scope="col">Template Name</th>
                                                            <th scope="col">Template Type</th>
                                                            <th scope="col">Template Header</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Whatsapptest</th>
                                                            <td>Marketing</td>
                                                            <td>text of the printing and  {1}  text of the printing and {2}</td>
                                                            <td><span class="badge bg-danger border-radius  rounded-pill ">Rejected</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Template Testing</th>
                                                            <td>Marketing</td>
                                                            <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s {1}</td>
                                                            <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Intro Catalog Offerr</th>
                                                            <td>Marketing</td>
                                                            <td><strong>🚀 Upgrade Your Business Communications with Vitel Global!</strong><br /> 🔄 Upgrade your business communication with Vitel Global's scalable, top-tier Hosted VoIP and PBX systems, ensuring you're always ahead in tech. Here's what you get:<br /><br /><strong>Local Dialing &amp; Numbers: 📞🏠</strong> Provide a local presence with local dialing capabilities and numbers, enhancing customer trust and convenience.<br /> <strong>Call Delegation &amp; Screening: 📞👤</strong> Control and manage calls efficiently, ensuring that the right people handle the right conversations.<br /> <strong>Call Forwarding &amp; Flip: 📞🔁</strong> Seamlessly transfer calls between devices without interruption, ensuring you're always connected.<br /> <strong>Voicemail to Email: 📬✉️</strong> Never miss a message with voicemails automatically sent to your email, allowing for easy retrieval and response.<br /> <strong>Greetings &amp; Multi-level IVR: 🎙️📞🔊</strong> Create a professional first impression with custom greetings and an Interactive Voice Response system to guide callers.</td>
                                                            <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Test Temolate</th>
                                                            <td>Marketing</td>
                                                            <td>text of the printing and  {1}  text of the printing and {2}</td>
                                                            <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Whatsapptest</th>
                                                            <td>Marketing</td>
                                                            <td>text of the printing and  {1}  text of the printing and {2}</td>
                                                            <td><span class="badge bg-success border-radius  rounded-pill ">Approved</span></td>
                                                            <td><a href="#"><span class="badge badge-circle  p-6" title="View Template"><span class="material-symbols-outlined fs-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">remove_red_eye</span> </span></a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="Pages" tabindex="-1" aria-labelledby="Pages" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add pages to Sample workspace</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class=" ">
                                    <div class="card-header d-flex justify-content-center">
                                        <div class="header-title">
                                            <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab-1" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link active" id="pills-home-tab-fill" data-bs-toggle="pill" href="#pills-home-fill" role="tab" aria-controls="pills-home" aria-selected="true">
                                                        <img src="/assets/images/icon/facebook.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                    </a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-profile-tab-fill" data-bs-toggle="pill" href="#pills-profile-fill" role="tab" aria-controls="pills-profile" aria-selected="false" tabindex="-1"><img src="/assets/images/icon/twitter.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-contact-tab-fill" data-bs-toggle="pill" href="#pills-contact-fill" role="tab" aria-controls="pills-contact" aria-selected="false" tabindex="-1"><img src="/assets/images/icon/linkedin.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-google-tab-fill" data-bs-toggle="pill" href="#pills-google-fill" role="tab" aria-controls="pills-google" aria-selected="false" tabindex="-1"><img src="/assets/images/icon/google.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-insta-tab-fill" data-bs-toggle="pill" href="#pills-insta-fill" role="tab" aria-controls="pills-insta" aria-selected="false" tabindex="-1"><img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-youtube-tab-fill" data-bs-toggle="pill" href="#pills-youtube-fill" role="tab" aria-controls="pills-youtube" aria-selected="false" tabindex="-1"><img src="/assets/images/icon/youtube.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-tiktok-tab-fill" data-bs-toggle="pill" href="#pills-tiktok-fill" role="tab" aria-controls="pills-tiktok" aria-selected="false" tabindex="-1"><img src="/assets/images/icon/tiktok.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-pinterest-tab-fill" data-bs-toggle="pill" href="#pills-pinterest-fill" role="tab" aria-controls="pills-pinterest" aria-selected="false" tabindex="-1"><img src="/assets/images/icon/pinterest.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-adds-tab-fill" data-bs-toggle="pill" href="#pills-adds-fill" role="tab" aria-controls="pills-adds" aria-selected="false" tabindex="-1"><img src="/assets/images/icon/addon.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="card-body mt-2">
                                        <div class="tab-content" id="pills-tabContent-1">
                                            <div class="tab-pane fade active show" id="pills-home-fill" role="tabpanel" aria-labelledby="pills-home-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-user-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  mb-0">Add Facebook </h5>
                                                                    <h5 class=" d-flex justify-content-center align-items-center  ">Pages</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2">
                                                                        <i class="fa fa-users fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center mb-0"> Add Facebook </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">Groups</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Create a mockup  </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between pb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-profile-fill" role="tabpanel" aria-labelledby="pills-profile-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-user-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  ">Add 𝕏 (Twitter)</h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">Profiles</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Create a mockup  </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-contact-fill" role="tabpanel" aria-labelledby="pills-contact-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-building-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  ">Add LinkedIn</h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center  ">company pages</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2">
                                                                        <i class="fa fa-user-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Add LinkedIn</h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">profiles</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  "> Create a mockup</h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-google-fill" role="tabpanel" aria-labelledby="pills-google-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-briefcase fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  ">Add Google Business  </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">Profile pages</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Create a mockup </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center  ">
                                                                        <div>pages</div>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between  ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-insta-fill" role="tabpanel" aria-labelledby="pills-insta-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-briefcase fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  ">  Add Instagram </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">professional accounts</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2">
                                                                        <i class="fa fa-user-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Add Instagram</h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">profiles or pages</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  "> Create a mockup</h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-youtube-fill" role="tabpanel" aria-labelledby="pills-youtube-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-youtube-play fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  ">Add Youtube </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">Channels</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Create a mockup </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-tiktok-fill" role="tabpanel" aria-labelledby="pills-tiktok-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa  fa-play-circle-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center">Add TikTok </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">Accounts </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2">
                                                                        <i class="fa  fa-play-circle-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Add TikTok business</h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center">profile</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  "> Create a mockup </h5>
                                                                    <h5 class="d-flex justify-content-center align-items-center">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between  ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-pinterest-fill" role="tabpanel" aria-labelledby="pills-pinterest-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-briefcase fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center"> Add Pinterest </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center">business pages </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Create a mockup  </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center"> pages </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between  ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-adds-fill" role="tabpanel" aria-labelledby="pills-adds-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-file-text-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  ">Create a page for  </h5>
                                                                    <h5 class=" d-flex justify-content-center align-items-center  ">   universal content </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between  ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="/assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="modal fade" id="exampleModalCenter-view" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={{ display: "none" }} aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalCenterTitle">whatsapptest</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="card-body">
                                    <div class="d-flex flex-column justify-content-between">
                                        <h6 class="mb-1 fw-500">application {1}</h6>
                                        <p class="mb-1">text of the printing and {1} text of the printing and {2}</p>
                                        <p class="mb-1">test.com</p>
                                    </div>
                                    <hr />
                                    <div class="d-flex flex-column justify-content-between align-items-center">
                                        <div class="d-flex align-items-center  ">
                                            <i class="fa fa-reply me-2" aria-hidden="true  "></i>
                                            <span>welcome to sales 2</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalCenter-create" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={{ display: "none" }} aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalCenterTitle">Create Template</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-7">
                                        <form>
                                            <div class="form-group">
                                                <label class="form-label mb-3" for="t_name">Template Name<span class="text-danger ">* &nbsp;&nbsp;(Allows only lower case characters, numbers and underscores)</span></label>
                                                <input type="text" class="form-control" id=" " placeholder="Enter template name" />
                                                <div id=" " class="counts text-end">512 / 512</div>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-label mb-3" for="t_name">Template Category<span class="text-danger ">*</span></label>
                                                <select class="form-select" id="exampleFormControlSelect1">
                                                    <option selected="" disabled="">Select Category</option>
                                                    <option value="" selected="">Select Category</option>
                                                    {/* <!-- <option value="MARKETING">Marketing</option> --> */}
                                                    <option value="UTILITY">Utility</option>
                                                    <option value="MARKETING">Marketing</option>
                                                    <option value="AUTHENTICATION">Authentication</option>
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="col-md-5">

                                        <p class="fw-500 mb-3 text-center">Preview</p>
                                        <div class="main_box_send_auth m-auto border p-3 bg-light w-100 rounded" >
                                            <p><span id="previewVerify"><b>123456</b> is your verification code.</span><span id="previewSecurity" >For your security, do not share the code.</span></p>
                                            <p id="timeers" style={{ display: "none" }}><span>This code expires in <span id="time_preview"></span> minutes.</span></p>
                                            <hr />
                                            <span id="code_delivery_preview">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"></path></svg> <span id="previewcopy_code" class="ng-binding"></span><span id="previewauto_fill" class="ng-binding">Autofill</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"  >Create</button>
                                <button type="button" class="btn btn-warning">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
