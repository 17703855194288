
import { Navigate, Outlet } from 'react-router-dom';
import { isLogin } from '../utils/CommonFunctions';
import Layout from './Layout';
import { SocketProvider } from '../SocketContext';

const PrivateRoutes = () => {
    let auth = isLogin();
    return (
        auth ?
            <SocketProvider>
                <Layout >
                    <Outlet />
                </Layout>
            </SocketProvider>
            : <Navigate to='/login' />
    )
}

export default PrivateRoutes;


