import React, { useState, useEffect } from "react";
import Loader from '../../../common/components/Loader';
import { fetchTicketListData, fetchUserDetailsData } from '../../../utils/ApiClient';
import { triggerAlert, handleTableRowClick, ConfirmationAlert, formatDateTime } from '../../../utils/CommonFunctions';
import { Link } from "react-router-dom";
import PaginationComponent from "../../../common/components/PaginationComponent";

export default function ListTicket() {
    const [activeTab, setActiveTab] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadListStatus, setLoadListStatus] = useState(true);
    const [isLoading, setIsLoading] = useState();
    const [perPageLimit, setPerPageLimit] = useState(10);
    const [dataList, setDataList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageSlNo, setPageSlNo] = useState(0);
    const [userData, setUserData] = useState([]);

    const handleTabClick = (tab) => {
        console.log("tab", tab)
        setActiveTab(tab); // Update the active tab when a tab is clicked
        setCurrentPage(1);
        setLoadListStatus(true);
    };

    // Fetch ticket data
    const fetchTicketData = async (selectedTab, page) => {
        setIsLoading(true);
        try {
            const params = {
                page_number: page,
                page_size: perPageLimit,
                line_type: selectedTab,
            }
            const response = await fetchTicketListData(params);
            const response_data = response.data;
            setPageSlNo((page - 1) * perPageLimit);
            if (response_data.error_code === 200) {
                const data = Object.values(response.data.results.data);
                const total_pages = response.data.results.pagination.total_pages;
                const total_items = response.data.results.pagination.total_items;
                setIsLoading(false);
                setDataList(data);
                if (total_items == 0) {
                    setPageCount(0);
                } else {
                    setPageCount(total_pages);
                }

                // triggerAlert('success', 'success', 'Recharged Successfully!!');
            } else {
                setDataList([])
                setPageCount(0);
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }

    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setCurrentPage(selectedPage + 1);
    };

    let pgntn_props = {
        pageCount: pageCount,
        handlePageClick: handlePageClick,
        selectedPage: currentPage - 1,
    };

    const fetchUserDetails = async () => {
        setIsLoading(true);
        try {

            const response = await fetchUserDetailsData();
            const response_data = response.data;
            console.log("data", response_data.error_code)
            if (response_data.error_code == 200) {
                const data = response.data.results;
                setIsLoading(false);
                setUserData(data);


            } else {
                setUserData([])
                setIsLoading(false);
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }

    useEffect(() => {
        fetchTicketData(activeTab, currentPage);
    }, [activeTab, currentPage, perPageLimit]);

    useEffect(() => {
        fetchUserDetails();
    }, []);



    return (
        <>
            <div>
                <div class="position-relative">
                </div>
                <div id="content-page" class="content-page">
                    <div class="container">
                        <div class="row w-100 mb-4 mt-3">
                            <div class="d-flex align-items-center justify-content-between flex-wrap">
                                <h4 class="fw-bold text-primary">Show Ticket</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-lg-12">
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <div class="header-title">
                                            <h4 class="card-title text-warning">{userData.user_name}</h4>
                                            <p class="mb-0">- Support - Trouble Ticket System</p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="mb-4">
                                            <p class="fw-500 text-warning">Support representatives are available Monday through Friday 9:00 am to 5:30 pm EST.<br />
                                                All support inquiries will be reviewed in the order received.
                                            </p>
                                            <p class="fw-500 mb-0">Limited staff is available for emergency situations 24 hours a day 7 days a week.</p>
                                            <p class="fw-500"><a href="/support/create-ticket" class="text-info">Click here</a> to open a new trouble ticket.</p>
                                            <div class="d-flex align-items-center justify-content-between flex-wrap">
                                                <ul class="nav nav-pills  " id="pills-tab" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <a className={`nav-link ${activeTab === 1 ? "active" : ""}`} id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => handleTabClick(1)}>Open Tickets</a>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <a className={`nav-link ${activeTab === 4 ? "active" : ""}`} id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" tabindex="-1" onClick={() => handleTabClick(4)}>Closed Tickets</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <Loader />
                                        ) : (
                                            <div class="table-responsive">
                                                <table id="example" class="table table-striped table-bordered hover" cellspacing="0" width="100%">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Ticket Number</th>
                                                            <th scope="col">Issue Type</th>
                                                            <th scope="col">Opened On</th>
                                                            <th scope="col">Last Updated On</th>
                                                            <th scope="col">Updated by</th>
                                                            <th scope="col">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataList && dataList.length > 0 ? (
                                                            dataList.map((ticket, index) => (
                                                                <tr key={ticket.id}>
                                                                    <td>{pageSlNo + index + 1}</td>
                                                                    <td>#{ticket.ticket_number}</td>
                                                                    <td>{ticket.ticket_sub_category}</td>
                                                                    <td>{formatDateTime(ticket.register_date, "yyyy-mm-dd hh:mm:ss")}</td>
                                                                    <td>{formatDateTime(ticket.updated_date, "yyyy-mm-dd hh:mm:ss")}</td>
                                                                    <td>{ticket.updated_name ? ticket.updated_name : "-"}</td>
                                                                    <td>
                                                                        {/* <span
                                                                            className={`badge rounded-pill font-size-15 ${ticket.status === "closed" || ticket.status === 4
                                                                                ? "bg-success"
                                                                                : "bg-danger"
                                                                                }`}
                                                                            style={{ cursor: "pointer" }}
                                                                        > */}
                                                                       <span
                                                                            className={`badge rounded-pill font-size-15 ${ticket.status === "awaiting" || ticket.status === 2
                                                                                ? "bg-primary" // Blue background for 'awaiting'
                                                                                : ticket.status === "inprogress" || ticket.status === 5
                                                                                ? "bg-info" // Yellow background for 'schedule'
                                                                                : ticket.status === "schedule" || ticket.status === 3
                                                                                ? "bg-warning" // Yellow background for 'schedule'
                                                                                : ticket.status === "closed" || ticket.status === 4
                                                                                ? "bg-success" // Green background for 'closed'
                                                                                : "bg-danger" // Default background if none of the above
                                                                                }`}
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            <Link
                                                                                to="/support/show-ticket"
                                                                                state={{ ticketNumber: ticket.ticket_number }}
                                                                                style={{ color: "#ffffff" }}
                                                                            >
                                                                                {ticket.status === "closed" || ticket.status === 4
                                                                                    ? "Closed"
                                                                                    : ticket.ticket_status_value}
                                                                            </Link>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="7" style={{ textAlign: "center" }}>
                                                                    No data to display
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <PaginationComponent {...pgntn_props} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </div >
        </>
    )
}
