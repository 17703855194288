import React from 'react'

export default function Permissions() {
    return (
        <>
            <div class="tab-content" id="pills-tabContent-1">

                <div class="tab-pane fade" id="pills-Permissions-fill" role="tabpanel" aria-labelledby="pills-Permissions-tab-fill">
                    <div class=" ">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center" scope="col"></th>
                                            <th class="text-center" scope="col">View</th>
                                            <th class="text-center" scope="col">Approve</th>
                                            <th class="text-center" scope="col">Edit</th>
                                            <th class="text-center" scope="col">Admin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th class="text-center" scope="row">
                                                <div class="d-flex">
                                                    <div class="user-img img-fluid flex-shrink-0">
                                                        <img src="/assets/images/user/05.jpg" alt="story-img" class="rounded-circle avatar-40" loading="lazy" />
                                                    </div>
                                                    <div class=" ms-3">
                                                        <h6>Vitel global</h6>
                                                        <p class="mb-0">Jan 23, 20:21</p>
                                                    </div>
                                                </div>
                                            </th>
                                            <td class="text-center">
                                                <div class="form-check   ">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <div class="form-check   ">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <div class="form-check   ">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <div class="form-check   ">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-center" scope="row">
                                                <div class="d-flex">
                                                    <div class="user-img img-fluid flex-shrink-0">
                                                        <img src="/assets/images/user/05.jpg" alt="story-img" class="rounded-circle avatar-40" loading="lazy" />
                                                    </div>
                                                    <div class=" ms-3">
                                                        <h6>Vitel global</h6>
                                                        <p class="mb-0">Jan 23, 20:21</p>
                                                    </div>
                                                </div>
                                            </th>
                                            <td class="text-center">
                                                <div class="form-check   ">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <div class="form-check   ">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <div class="form-check   ">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <div class="form-check   ">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
