import React from 'react'
import PageTitle from '../../../common/PageTitle'

export default function ChatBot() {
    return (
        <>
            <div class="position-relative">
            </div>
            <div id="content-page" class="content-page">
                <div class="container">
                    <PageTitle heading="Chatbot flow" showPrimaryButton="Create Chatbots" />
                    {/* <div class="row mt-3">
                        <div class="d-flex align-items-center justify-content-between flex-wrap mb-4">
                            <h4 class="fw-bold text-primary">Chatbot flow</h4>
                            <div class="d-flex align-items-center">
                                <div><p class="mb-0 text-success fw-500">(You Can Add 7 More Chatbot)   </p></div>
                                <a type="button" class="btn btn-primary ms-2 d-flex align-items-center">
                                    <span class="ms-2">Create Chatbots</span>
                                </a>
                            </div>
                        </div>
                    </div> */}
                    <div class="row mb-5">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body ">

                                    <div class="table-responsive">
                                        <div class="dropdown text-end mb-3">
                                            <a href="javascript:void(0);" class="text-white px-3 btn btn-warning dropdown-toggle" id="dropdownMenuButton222" data-bs-toggle="dropdown" aria-expanded="false">
                                                Action
                                            </a>
                                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton222" style={{}}>
                                                <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="material-symbols-outlined md-18 me-1">edit_note</i>Edit</a></li>
                                                <li><a class="dropdown-item d-flex align-items-center" href="#"><i class="material-symbols-outlined md-18 me-1">content_copy</i>Clone</a></li>
                                            </ul>
                                        </div>
                                        <table id="example" class="table table-striped table-bordered hover" cellspacing="0" width="100%">
                                            <thead class="text-nowrap">
                                                <tr>
                                                    <th> Sl No</th>
                                                    <th>Chatbot Name </th>
                                                    <th>Chatbot Message </th>
                                                    <th>Chatbot Text</th>
                                                    <th>Timings</th>
                                                    <th>Created Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>01</td>
                                                    <td>Vitel sales </td>
                                                    <td>We specialize in providing cutting-edge cloud-based phone services, bulk SMS, and bulk WhatsApp messaging platforms tailored to businesses like yours.\nHow can I help you today?\n</td>
                                                    <td>Hello vitel</td>
                                                    <td>11:04:36</td>
                                                    <td>
                                                        2024-04-12
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>02</td>
                                                    <td>Vitel sales </td>
                                                    <td>We specialize in providing cutting-edge cloud-based phone  </td>
                                                    <td>Hello vitel</td>
                                                    <td>11:04:36</td>
                                                    <td>
                                                        2024-04-12
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>03</td>
                                                    <td>Vitel sales </td>
                                                    <td>We specialize in providing cutting-edge cloud-based phone services, bulk SMS, and bulk WhatsApp messaging platforms tailored to businesses like yours. </td>
                                                    <td>Hello vitel</td>
                                                    <td>11:04:36</td>
                                                    <td>
                                                        2024-04-12
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>04</td>
                                                    <td>Vitel sales </td>
                                                    <td>We specialize in providing cutting-edge cloud-based phone services, bulk SMS, and bulk WhatsApp messaging platforms tailored to businesses like yours. </td>
                                                    <td>Hello vitel</td>
                                                    <td>11:04:36</td>
                                                    <td>
                                                        2024-04-12
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>05</td>
                                                    <td>Vitel sales </td>
                                                    <td>We specialize in providing cutting-edge cloud-based phone services </td>
                                                    <td>Hello vitel</td>
                                                    <td>11:04:36</td>
                                                    <td>
                                                        2024-04-12
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>06</td>
                                                    <td>Vitel sales </td>
                                                    <td>We specialize in providing cutting-edge cloud-based phone services, bulk SMS, and bulk WhatsApp messaging platforms tailored to businesses like yours. n</td>
                                                    <td>Hello vitel</td>
                                                    <td>11:04:36</td>
                                                    <td>
                                                        2024-04-12
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="Pages" tabindex="-1" aria-labelledby="Pages" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add pages to Sample workspace</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class=" ">
                                    <div class="card-header d-flex justify-content-center">
                                        <div class="header-title">
                                            <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab-1" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link active" id="pills-home-tab-fill" data-bs-toggle="pill" href="#pills-home-fill" role="tab" aria-controls="pills-home" aria-selected="true">
                                                        <img src="assets/images/icon/facebook.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                    </a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-profile-tab-fill" data-bs-toggle="pill" href="#pills-profile-fill" role="tab" aria-controls="pills-profile" aria-selected="false" tabindex="-1"><img src="assets/images/icon/twitter.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-contact-tab-fill" data-bs-toggle="pill" href="#pills-contact-fill" role="tab" aria-controls="pills-contact" aria-selected="false" tabindex="-1"><img src="assets/images/icon/linkedin.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-google-tab-fill" data-bs-toggle="pill" href="#pills-google-fill" role="tab" aria-controls="pills-google" aria-selected="false" tabindex="-1"><img src="assets/images/icon/google.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-insta-tab-fill" data-bs-toggle="pill" href="#pills-insta-fill" role="tab" aria-controls="pills-insta" aria-selected="false" tabindex="-1"><img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-youtube-tab-fill" data-bs-toggle="pill" href="#pills-youtube-fill" role="tab" aria-controls="pills-youtube" aria-selected="false" tabindex="-1"><img src="assets/images/icon/youtube.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-tiktok-tab-fill" data-bs-toggle="pill" href="#pills-tiktok-fill" role="tab" aria-controls="pills-tiktok" aria-selected="false" tabindex="-1"><img src="assets/images/icon/tiktok.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-pinterest-tab-fill" data-bs-toggle="pill" href="#pills-pinterest-fill" role="tab" aria-controls="pills-pinterest" aria-selected="false" tabindex="-1"><img src="assets/images/icon/pinterest.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="pills-adds-tab-fill" data-bs-toggle="pill" href="#pills-adds-fill" role="tab" aria-controls="pills-adds" aria-selected="false" tabindex="-1"><img src="assets/images/icon/addon.png" class="img-fluid rounded" alt="Twitter" loading="lazy" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="card-body mt-2">
                                        <div class="tab-content" id="pills-tabContent-1">
                                            <div class="tab-pane fade active show" id="pills-home-fill" role="tabpanel" aria-labelledby="pills-home-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-user-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  mb-0">Add Facebook </h5>
                                                                    <h5 class=" d-flex justify-content-center align-items-center  ">Pages</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2">
                                                                        <i class="fa fa-users fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center mb-0"> Add Facebook </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">Groups</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Create a mockup  </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between pb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-profile-fill" role="tabpanel" aria-labelledby="pills-profile-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-user-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  ">Add 𝕏 (Twitter)</h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">Profiles</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Create a mockup  </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-contact-fill" role="tabpanel" aria-labelledby="pills-contact-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-building-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  ">Add LinkedIn</h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center  ">company pages</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2">
                                                                        <i class="fa fa-user-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Add LinkedIn</h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">profiles</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  "> Create a mockup</h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center  ">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-google-fill" role="tabpanel" aria-labelledby="pills-google-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-briefcase fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  ">Add Google Business  </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">Profile pages</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Create a mockup </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center  ">
                                                                        <div>pages</div>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between  ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-insta-fill" role="tabpanel" aria-labelledby="pills-insta-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-briefcase fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  ">  Add Instagram </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">professional accounts</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2">
                                                                        <i class="fa fa-user-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Add Instagram</h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">profiles or pages</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  "> Create a mockup</h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-youtube-fill" role="tabpanel" aria-labelledby="pills-youtube-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-youtube-play fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  ">Add Youtube </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">Channels</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Create a mockup </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-tiktok-fill" role="tabpanel" aria-labelledby="pills-tiktok-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa  fa-play-circle-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center">Add TikTok </h5>
                                                                    <h5 class="   d-flex justify-content-center align-items-center">Accounts </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2">
                                                                        <i class="fa  fa-play-circle-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Add TikTok business</h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center">profile</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  "> Create a mockup </h5>
                                                                    <h5 class="d-flex justify-content-center align-items-center">page</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between  ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-pinterest-fill" role="tabpanel" aria-labelledby="pills-pinterest-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-briefcase fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center"> Add Pinterest </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center">business pages </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class="  md-36 text-primary bg-round2 ">
                                                                        <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0  d-flex justify-content-center align-items-center  "> Create a mockup  </h5>
                                                                    <h5 class="  d-flex justify-content-center align-items-center"> pages </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between  ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-adds-fill" role="tabpanel" aria-labelledby="pills-adds-tab-fill">
                                                <div class="row">
                                                    <div class="col-sm-4 col-lg-4">
                                                        <a href="">
                                                            <div class="card py-3    border-dashed">
                                                                <div class="card-body text-center">
                                                                    <div class=" md-36 text-primary  bg-round2"  >
                                                                        <i class="fa fa-file-text-o fs-4" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5 class="mt-2 mb-0 d-flex justify-content-center align-items-center  ">Create a page for  </h5>
                                                                    <h5 class=" d-flex justify-content-center align-items-center  ">   universal content </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <h5 href="#" class="d-flex align-items-center mb-3"><i class="material-symbols-outlined filled text-success md-18 me-1">fiber_manual_record</i>Connected Pages</h5>
                                                    <div class="col-sm-4 col-lg-4  ">
                                                        <a href="">
                                                            <div class="card py-1    border-dashed">
                                                                <div class="card-header border-0 py-2">
                                                                    <div class="d-flex align-items-center justify-content-between  ">
                                                                        <div class="d-flex align-items-center">
                                                                            <i class="fa fa-flag-o fs-4 text-dark" aria-hidden="true"></i>
                                                                        </div>
                                                                        <span class="text-warning d-block line-height">
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white">check_circle </i>
                                                                            <i class="material-symbols-outlined md-18  bg-success rounded-circle text-white d-none">check_circle </i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body text-center py-1">
                                                                    <div class="  text-primary  ">
                                                                        <img src="assets/images/icon/insta.png" class="img-fluid rounded" alt="Twitter" loading="lazy" />
                                                                    </div>
                                                                    <h5 class="mt-2  d-flex justify-content-center align-items-center  "> Instagram</h5>
                                                                    <small class="mb-0">@vitel global</small>
                                                                    <p class="text-success pt-0">Connected</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
