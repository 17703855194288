import React, { useState, useEffect } from 'react';
import PageTitle from '../../../common/PageTitle';
import { useForm } from 'react-hook-form';
import { getToken, getCustomerId } from '../../../utils/CommonFunctions';
import Loader from '../../../common/components/Loader';
import { fetchChoosePlan, fetchCreatePlan } from '../../../utils/ApiClient';
import { triggerAlert } from '../../../utils/CommonFunctions';

export default function Recharge() {
    const token = getToken();
    const customer_id = getCustomerId();

    // State variables
    const [isLoading, setIsLoading] = useState(false); // For managing the loading state
    const [data, setData] = useState([]); // To store the plan data
    const [selectedPlanId, setSelectedPlanId] = useState(''); // To store the selected plan ID

    const { register, handleSubmit, formState: { errors }, setValue, clearErrors, reset } = useForm();

    // Fetch plans on component mount
    useEffect(() => {
        const fetchPlans = async () => {
            setIsLoading(true);
            try {
                // Fetch the available plans
                const response = await fetchChoosePlan();

                // Ensure response contains the data you need
                setData(response); // Set the fetched data to the state
            } catch (error) {
                console.error('Error fetching plans:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPlans();
    }, []);

    // Function to handle plan selection
    const planSelection = (event) => {
        const selectedPlanName = event.target.value;
        const selectedPlan = data.find(plan => plan.plan_name === selectedPlanName);

        if (selectedPlan) {
            setSelectedPlanId(selectedPlan.plan_id); // Store the selected plan ID
            setValue('planPrice', selectedPlan.plan_price);
            setValue('plan_credits', selectedPlan.plan_credits);
            clearErrors('plan'); // Clear error for 'plan' field if a valid plan is selected
        } else {
            setValue('planPrice', '');
            setValue('plan_credits', '');
            setSelectedPlanId(''); // Clear selected plan ID
        }
    };


    const onSubmit = async () => {
        if (!selectedPlanId) {
            console.error('Plan ID is not selected');
            triggerAlert('error', '', 'Please select a plan');
            return;
        }

        const api_input = {
            plan_id: selectedPlanId,
        };

        setIsLoading(true); // Show loading indicator

        try {
            const response = await fetchCreatePlan(api_input);

            if (response.error_code === 200) {
                triggerAlert('success', 'Success', 'Plan applied successfully');
                setIsLoading(true); // Show loader again after success
                // Set a timeout to delay the page refresh
                setTimeout(() => {
                    setIsLoading(false); // Hide loader
                    window.location.reload(); // Refresh the page
                }, 2000); // Adjust the delay as needed
            } else if (response.error_code === 400) {
                triggerAlert('error', '', response.message || 'Insufficient wallet amount');
                setIsLoading(false); // Stop loading after showing the error
            } else {
                triggerAlert('error', '', 'An unexpected error occurred');
                setIsLoading(false); // Stop loading after showing the error
            }
        } catch (error) {
            const response_data = error?.response?.data;
            console.log("response_data", response_data);
            if (response_data?.error_code === 400) {
                triggerAlert('error', '', response_data?.message);
            } else {
                triggerAlert('error', '', response_data ? response_data.message : "Something went wrong!");
            }
            setIsLoading(false); // Stop loading after showing the error
        }
    };

    return (
        <>
            {isLoading &&
                <div className='loader-overlay text-white'>
                    <Loader />
                </div>
            }
            <div className="position-relative">
            </div>

            <div id="content-page" className="content-page">

                <div className="container">
                    <PageTitle heading="Choose A Plan" />

                    <div className="text-center my-4">
                        <h2>Plans. Pricing.</h2>
                        <h3>Unlimited Growth.</h3>
                        <p>Improve Your Productivity with Our Customized Plans and Choose the Best One That Suits Your Business.</p>
                    </div>

                    <div className="row">
                        <div className='col-12'>
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className="row">
                                            <div className="mb-3 col-md-4">
                                                <label className="col-form-label">Select a Plan</label>
                                                <select
                                                    className={`form-select ${errors.plan ? 'is-invalid' : ''}`}
                                                    name="plan"
                                                    {...register('plan', { required: 'Please select a plan' })}
                                                    onChange={planSelection}
                                                    aria-label="Default select example"
                                                >
                                                    <option value="" hidden>Select a Plan</option>
                                                    {data.map((item, index) => (
                                                        <option key={index} value={item.plan_name}>
                                                            {item.plan_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.plan && <div className="invalid-feedback">{errors.plan.message}</div>}
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label className="col-form-label">Plan Price</label>
                                                <input
                                                    className={`form-control ${errors.planPrice ? 'is-invalid' : ''}`}
                                                    name="planPrice"
                                                    {...register('planPrice')}
                                                    placeholder="Plan Price"
                                                    readOnly
                                                />
                                                {errors.planPrice && <div className="invalid-feedback">{errors.planPrice.message}</div>}
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label className="col-form-label">Plan Credits</label>
                                                <input
                                                    className={`form-control ${errors.plan_credits ? 'is-invalid' : ''}`}
                                                    name="plan_credits"
                                                    {...register('plan_credits')}
                                                    placeholder="Plan Credits"
                                                    readOnly
                                                />
                                                {errors.plan_credits && <div className="invalid-feedback">{errors.plan_credits.message}</div>}
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
