import React from 'react'

export default function WorkSpace() {
    return (
        <div>
            <div class="iq-top-navbar">
                <nav class="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0">
                    <div class="container-fluid navbar-inner">
                        <div class="d-flex align-items-center   gap-3 pb-2 pb-lg-0">
                            <a href="index.php" class="d-flex align-items-center gap-2 iq-header-logo">
                                <img src="/assets/images/logo.svg" class="img-fluid" width="200" />
                            </a>
                        </div>
                        <ul class="navbar-nav navbar-list">
                            <li class="nav-item dropdown user-dropdown">
                                <a href="javascript:void(0);" class="d-flex align-items-center dropdown-toggle" id="drop-down-arrow" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="/assets/images/user/1.jpg" class=" rounded-circle me-3" alt="user" loading="lazy" />
                                </a>
                                <div class="sub-drop dropdown-menu caption-menu" aria-labelledby="drop-down-arrow">
                                    <div class="card shadow-none m-0">
                                        <div class="card-header ">
                                            <div class="header-title">
                                                <h5 class="mb-0 ">Hello Bni Cyst</h5>
                                            </div>
                                        </div>
                                        <div class="card-body p-0 ">
                                            <div class="d-flex align-items-center iq-sub-card border-0">
                                                <span class="material-symbols-outlined">
                                                    line_style
                                                </span>
                                                <div class="ms-3">
                                                    <a href=" " class="mb-0 h6">
                                                        Profile
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center iq-sub-card border-0">
                                                <span class="material-symbols-outlined">
                                                    edit_note
                                                </span>
                                                <div class="ms-3">
                                                    <a href=" " class="mb-0 h6">
                                                        Admin
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center iq-sub-card border-0">
                                                <span class="material-symbols-outlined">
                                                    manage_accounts
                                                </span>
                                                <div class="ms-3">
                                                    <a href=" " class="mb-0 h6">
                                                        Work Stations
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center iq-sub-card">
                                                <span class="material-symbols-outlined">
                                                    login
                                                </span>
                                                <div class="ms-3">
                                                    <a href="/login" class="mb-0 h6">
                                                        Sign out
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div>
                <div class="position-relative">
                </div>
                <div id="content-page" class="content-page">
                    <div class="container">
                        <div class="row w-100 mb-4 mt-5">
                            <div class="d-flex align-items-center justify-content-between flex-wrap">
                                <h4 class="fw-bold text-primary">Work Space</h4>
                                <div class="iq-search-bar device-search  position-relative">
                                    <form action="#" class="searchbox" data-bs-toggle="modal" data-bs-target="#searchmodal">
                                        <a class="search-link d-none d-lg-block" href="javascript:void(0);">
                                            <span class="material-symbols-outlined">search</span>
                                        </a>
                                        <input type="text" class="text search-input form-control bg-soft-primary " placeholder="Search here..." />
                                        <a class="d-lg-none d-flex d-none d-lg-block" href="javascript:void(0);" >
                                            <span class="material-symbols-outlined">search</span>
                                        </a>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-sm-6 col-md-4">
                                <div class="card border-dashed  " style={{ cursor: 'pointer' }} onclick="window.location.href='landing.php'">
                                    <div class="card-header border-0 d-flex justify-content-between">
                                        <div class="header-title">
                                            <h5 class="card-title">Vitel Global Communication LLC</h5>
                                        </div>
                                        <div class="card-header-toolbar d-flex align-items-center">
                                            <div class="dropdown">
                                                <div class="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" role="button" onclick="event.stopPropagation();">
                                                    <span class="material-symbols-outlined">more_horiz</span>
                                                </div>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" style={{}}>
                                                    <a class="dropdown-item d-flex align-items-center" href="landing.php" onclick="event.stopPropagation();">
                                                        <span class="material-symbols-outlined me-2 md-18">visibility</span>View</a>
                                                    <a class="dropdown-item d-flex align-items-center" href="#" onclick="event.stopPropagation();" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-edit">
                                                        <span class="material-symbols-outlined me-2 md-18">edit_note</span>Edit</a>
                                                    <a class="dropdown-item d-flex align-items-center" href="workstation_usermanagement.php" onclick="event.stopPropagation();">
                                                        <span class="material-symbols-outlined me-2 md-18">manage_accounts</span>User Management</a>
                                                    <a class="dropdown-item d-flex align-items-center" href="workstation_addpage.php" onclick="event.stopPropagation();">
                                                        <span class="material-symbols-outlined me-2 md-18">add</span>Add Channel/Page</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row gy-3 justify-content-center pb-5">
                                            <div class="col-lg-2 col-sm-4 col-3">
                                                <a href="https://www.facebook.com" target="_blank" class="d-inline-block" onclick="event.stopPropagation();">
                                                    <span class="image">
                                                        <img src="/assets/images/icon/facebook1.png" class="img-fluid" width="45" />
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="col-lg-2 col-sm-4 col-3">
                                                <a href="https://www.twitter.com" target="_blank" class="d-inline-block" onclick="event.stopPropagation();">
                                                    <span class="image">
                                                        <img src="/assets/images/icon/twitter4.png" class="img-fluid" width="45" />
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="col-lg-2 col-sm-4 col-3">
                                                <a href="https://www.pinterest.com" target="_blank" class="d-inline-block" onclick="event.stopPropagation();">
                                                    <span class="image">
                                                        <img src="/assets/images/icon/pinterest3.png" class="img-fluid" width="45" />
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="col-lg-2 col-sm-4 col-3">
                                                <a href="https://www.linkedin.com" target="_blank" class="d-inline-block" onclick="event.stopPropagation();">
                                                    <span class="image">
                                                        <img src="/assets/images/icon/linkedin2.png" class="img-fluid" width="45" />
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="card border-dashed  " style={{ cursor: 'pointer' }} onclick="window.location.href='landing.php'">
                                    <div class="card-header border-0 d-flex justify-content-between">
                                        <div class="header-title">
                                            <h5 class="card-title">Pranathi Software Services</h5>
                                        </div>
                                        <div class="card-header-toolbar d-flex align-items-center">
                                            <div class="dropdown">
                                                <div class="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" role="button" onclick="event.stopPropagation();">
                                                    <span class="material-symbols-outlined">more_horiz</span>
                                                </div>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" style={{}}>
                                                    <a class="dropdown-item d-flex align-items-center" href="landing.php" onclick="event.stopPropagation();">
                                                        <span class="material-symbols-outlined me-2 md-18">visibility</span>View</a>
                                                    <a class="dropdown-item d-flex align-items-center" href="#" onclick="event.stopPropagation();" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-edit">
                                                        <span class="material-symbols-outlined me-2 md-18">edit_note</span>Edit</a>
                                                    <a class="dropdown-item d-flex align-items-center" href="workstation_usermanagement.php" onclick="event.stopPropagation();">
                                                        <span class="material-symbols-outlined me-2 md-18">manage_accounts</span>User Management</a>
                                                    <a class="dropdown-item d-flex align-items-center" href="workstation_addpage.php" onclick="event.stopPropagation();">
                                                        <span class="material-symbols-outlined me-2 md-18">add</span>Add Channel/Page</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row gy-3 justify-content-center pb-5">
                                            <div class="col-lg-2 col-sm-4 col-3">
                                                <a href="https://www.facebook.com" target="_blank" class="d-inline-block" onclick="event.stopPropagation();">
                                                    <span class="image">
                                                        <img src="/assets/images/icon/facebook1.png" class="img-fluid" width="45" />
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="col-lg-2 col-sm-4 col-3">
                                                <a href="https://www.twitter.com" target="_blank" class="d-inline-block" onclick="event.stopPropagation();">
                                                    <span class="image">
                                                        <img src="/assets/images/icon/twitter4.png" class="img-fluid" width="45" />
                                                    </span>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="card border-dashed  " style={{ cursor: 'pointer' }} onclick="window.location.href='landing.php'">
                                    <div class="card-header border-0 d-flex justify-content-between">
                                        <div class="header-title">
                                            <h5 class="card-title">Varun Digital Media</h5>
                                        </div>
                                        <div class="card-header-toolbar d-flex align-items-center">
                                            <div class="dropdown">
                                                <div class="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" role="button" onclick="event.stopPropagation();">
                                                    <span class="material-symbols-outlined">more_horiz</span>
                                                </div>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" style={{}}>
                                                    <a class="dropdown-item d-flex align-items-center" href="landing.php" onclick="event.stopPropagation();">
                                                        <span class="material-symbols-outlined me-2 md-18">visibility</span>View</a>
                                                    <a class="dropdown-item d-flex align-items-center" href="#" onclick="event.stopPropagation();" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-edit">
                                                        <span class="material-symbols-outlined me-2 md-18">edit_note</span>Edit</a>
                                                    <a class="dropdown-item d-flex align-items-center" href="workstation_usermanagement.php" onclick="event.stopPropagation();">
                                                        <span class="material-symbols-outlined me-2 md-18">manage_accounts</span>User Management</a>
                                                    <a class="dropdown-item d-flex align-items-center" href="workstation_addpage.php" onclick="event.stopPropagation();">
                                                        <span class="material-symbols-outlined me-2 md-18">add</span>Add Channel/Page</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row gy-3 justify-content-center pb-5">
                                            <div class="col-lg-2 col-sm-4 col-3">
                                                <a href="https://www.facebook.com" target="_blank" class="d-inline-block" onclick="event.stopPropagation();">
                                                    <span class="image">
                                                        <img src="/assets/images/icon/facebook1.png" class="img-fluid" width="45" />
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="col-lg-2 col-sm-4 col-3">
                                                <a href="https://www.twitter.com" target="_blank" class="d-inline-block" onclick="event.stopPropagation();">
                                                    <span class="image">
                                                        <img src="/assets/images/icon/twitter4.png" class="img-fluid" width="45" />
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="col-lg-2 col-sm-4 col-3">
                                                <a href="https://www.pinterest.com" target="_blank" class="d-inline-block" onclick="event.stopPropagation();">
                                                    <span class="image">
                                                        <img src="/assets/images/icon/pinterest3.png" class="img-fluid" width="45" />
                                                    </span>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-crtuser">
                                    <div class="card py-3    border-dashed">
                                        <div class="card-body text-center">
                                            <div class="  md-36 text-primary bg-round2 ">
                                                <i class="fa fa-plus-square-o fs-4" aria-hidden="true"></i>
                                            </div>
                                            <h5 class="mt-2  d-flex justify-content-center align-items-center">Add New <br /> Workstations</h5>
                                        </div>
                                    </div>
                                </a>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="modal fade" id="exampleModalCenter-crtuser" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={{ display: 'none' }} aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalCenterTitle">Create a Workstation</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="text-center text-primary fw-bold">A Workstation is a group of channels/Accounts and collaborators</p>
                                <form>
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <label class="form-label" for="validationDefault01">Workstation name</label>
                                            <input type="text" class="form-control" id="validationDefault01" required="" placeholder="Enter Workstation name" />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label class="form-label" for="validationDefault04">Workstation timezone (optional)</label>
                                            <select class="form-select" id="validationDefault04" required="">
                                                <option selected="" disabled="" value="">Choose timezone </option>
                                                <option>Calcutta +5:30</option>
                                                <option>Calcutta +5:30</option>
                                                <option>Calcutta +5:30</option>
                                                <option>Calcutta +5:30</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <a href="workstation_addpage.php" type="button" class="btn btn-primary px-5">Submit</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="modal fade" id="exampleModalCenter-edit" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={{ display: 'none' }} aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalCenterTitle">Edit</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="text-center text-primary fw-bold">A Workstation is a group of channels/Accounts and collaborators</p>
                                <form>
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <label class="form-label" for="validationDefault01">Workstation name</label>
                                            <input type="text" class="form-control" id="validationDefault01" required="" placeholder="Enter Workstation name" />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label class="form-label" for="validationDefault04">Workstation timezone (optional)</label>
                                            <select class="form-select" id="validationDefault04" required="">
                                                <option selected="" disabled="" value="">Choose timezone </option>
                                                <option>Calcutta +5:30</option>
                                                <option>Calcutta +5:30</option>
                                                <option>Calcutta +5:30</option>
                                                <option>Calcutta +5:30</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <a href="workstation_addpage.php" type="button" class="btn btn-primary px-5">Submit</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
