import React, { useState, useEffect } from 'react'
import PageTitle from '../../../common/PageTitle';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { onlyAlphabets, onlyNumbers, MaxLengthValidation, MinLengthValidation, onlyAlphabetsandSpaces, emailValidation, noEmptySpacesValidation, } from "../../../utils/Constants";
import { triggerAlert, getBase64, ConfirmationAlert } from '../../../utils/CommonFunctions';
import { CreateCoustomerContacts, fetchUsers, UpdateUser, UploadBulk, DeleteUser } from "../../../utils/ApiClient"
import SkeletonLoader from "../../../common/components/SkeletonLoader"

export default function AllContacts() {
    const [showModal2, setShowModal2] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, SetSelectedUser] = useState(null)
    const [editMode, SetEditMode] = useState(false)
    const [favourite, setFavourite] = useState(selectedUser?.favourite || 0);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [users, setUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');



    const { register, handleSubmit, control, formState: { errors }, setValue, watch, reset } = useForm();
    const { register: registerEdit, handleSubmit: handleSubmitEdit, control: controlEdit, formState: { errors: errorsEdit }, setValue: setValueEdit, watch: watchEdit, reset: resetEdit, trigger } = useForm({
        defaultValues: {
            contact_name: selectedUser?.contact_name || '',
            contact_number: selectedUser?.contact_number || '',
            designation: selectedUser?.designation || '',
            email: selectedUser?.email || '',
        }
    })


    const handleSearch = (event) => {
        setSearchKeyword(event.target.value);
    };

    const { register: registerBulk, handleSubmit: HandleSubmitBulk, formState: { errors: errorsBulk }, reset: resetBulk } = useForm();


    const validateFile = (file) => {
        if (!file || file.length === 0) {
            return 'Please select a file.';
        }
        const selectedFile = file[0];
        const isCSV = selectedFile.type === 'text/csv';
        const isValidSize = selectedFile.size <= 1024 * 1024;

        if (!isCSV) {
            return 'Please upload a valid CSV file.';
        }
        if (!isValidSize) {
            return 'File size should be less than 1MB.';
        }
        return true;
    };


    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        setSelectedUsers(isChecked ? users.map(user => user.id) : []);
    };

    const handleCheckboxChange = (userId) => {
        setSelectedUsers(prevSelectedUsers =>
            prevSelectedUsers.includes(userId)
                ? prevSelectedUsers.filter(id => id !== userId)
                : [...prevSelectedUsers, userId]
        );
    };
    const toggleDropdown = () => {
        setShowDropdown((prevState) => !prevState);
    };


    const HandleSubmitBulkupdate = async (data) => {
        try {

            if (data.file[0]) {
                const file = await getBase64(data.file[0])

                const trimmedFile = file.split(',')[1];
                const response = await UploadBulk({ file: trimmedFile });


                if (response.data.error_code === 201) {
                    fetchAllUsers()
                    triggerAlert('success', 'success', 'Bulk created successfully!!');
                    setShowDropdown(false);
                    setShowModal1(false);
                    setSearchKeyword("")
                }
            }
            resetBulk();
        } catch (error) {
            const response_data = error?.response?.data
            if (response_data?.error_code === 400) {
                triggerAlert('error', ' ', response_data?.message);
            } else {
                triggerAlert('error', ' ', response_data ? response_data.message : "Something went wrong!");
            }
        }

    };




    const handleShowModal2 = (e) => {
        e.preventDefault();
        setShowModal2(true);
    };

    // const handleShowModal1 = (e) => {
    //     e.preventDefault();
    //     setShowModal1(true);
    // };

    const handleShowModal1 = () => {
        setShowDropdown(false); // Close the dropdown
        setShowModal1(true); // Open the Bulk upload modal
    };

    const handleFavouriteToggle = () => {
        if (editMode) {
            setFavourite(prev => (prev === 1 ? 0 : 1));
        }
    };

    // const HandleEditMode = () => {
    //     setShowDropdown((prevState) => !prevState);
    //     SetEditMode(true)

    // }


    const HandleEditMode = () => {
        // Close the dropdown when entering edit mode
        setShowDropdown(false);
        SetEditMode(true); // Set to edit mode
    };

    const handleCancelEdit = () => {
        // Reset form to initial values
        SetEditMode(false);  // Exit edit mode
        resetEdit({
            contact_name: selectedUser?.contact_name || '',
            contact_number: selectedUser?.contact_number || '',
            designation: selectedUser?.designation || '',
            email: selectedUser?.email || '',
        });

        // Clear all validation errors
        trigger(); // Ensure no errors are being triggered

    };


    const HandleUpdate = async (data) => {
        try {

            const updatedData = { ...data, id: selectedUser?.id, favourite };

            const response = await UpdateUser(updatedData)

            if (response.data.error_code === 200) {
                SetEditMode(false)
                fetchAllUsers()
                triggerAlert('success', 'success', 'user Updated successfully!!');
                setShowDropdown(false);
                SetSelectedUser(selectedUser?.id);
                setSearchKeyword(" ")
            }
        } catch (error) {
            const response_data = error?.response?.data
            if (response_data?.error_code === 400) {
                triggerAlert('error', ' ', response_data?.message);
            } else {
                triggerAlert('error', ' ', response_data ? response_data.message : "Something went wrong!");
            }
        }

    }

    const handleCloseModal2 = () => setShowModal2(false);
    const handleCloseModal1 = () => setShowModal1(false);




    const HandleSubmit = async (data) => {


        try {
            const params = data
            const response = await CreateCoustomerContacts(params);


            const response_data = response.data;
            if (response_data.error_code === 201) {
                triggerAlert('success', 'success', 'user created successfully!!');
                fetchAllUsers()
                handleCloseModal2();
                setShowDropdown(false);
                setSearchKeyword(" ")
                reset();
            } else {
                triggerAlert('error', ' ', 'Failed to create  user');
            }

        } catch (error) {
            const response_data = error?.response?.data
            if (response_data?.error_code === 400) {
                triggerAlert('error', ' ', response_data?.message);
            } else {
                triggerAlert('error', ' ', response_data ? response_data.message : "Something went wrong!");
            }
        }

    };
    // Fetch user data
    const fetchAllUsers = async (page = 1, searchKeyword = "") => {
        setIsLoading(true);
        try {
            const params = {
                page: page,
                page_size: 100,
                keyword: searchKeyword
            };
            const response = await fetchUsers(params);
            const response_data = response.data;

            // Check if the response contains users
            if (response_data.error_code === 200) {
                const data = Object.values(response_data.results.data);
                if (data.length > 0) {
                    setUsers(data);
                    SetSelectedUser(data[0]);
                } else {
                    // No users found, handle this gracefully
                    setUsers([]);
                    SetSelectedUser(null); // Clear selected user

                }
            } else {
                // Handle other non-success cases
                setUsers([]);
                triggerAlert('error', ' ', 'Failed to fetch users.');
            }
        } catch (error) {
            const response_data = error?.response?.data;
            triggerAlert('error', '', response_data ? response_data.message : "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    }

    const handleUserClick = (user) => {
        // Set the selected user
        SetSelectedUser(user);

        // Reset the form and clear validation errors
        resetEdit({
            contact_name: user.contact_name || '',
            contact_number: user.contact_number || '',
            designation: user.designation || '',
            email: user.email || '',
        });

        // Optionally, you might want to switch to edit mode if needed
        SetEditMode(true);
    };

    React.useEffect(() => {
        if (selectedUser) {
            setValueEdit('contact_name', selectedUser.contact_name);
            setValueEdit('contact_number', selectedUser.contact_number);
            setValueEdit('designation', selectedUser.designation);
            setValueEdit('email', selectedUser.email);
        }
    }, [selectedUser, setValueEdit]);

    const deleteMultiple = async () => {
        try {
            if (selectedUsers.length === 0) {
                triggerAlert('info', ' ', 'At least select one user');
            } else {
                ConfirmationAlert('You want to continue!', 'Continue', async () => {
                    const response = await DeleteUser({ contact_ids: selectedUsers });

                    if (response.status === 204) {
                        fetchAllUsers();
                        setShowDropdown(false);
                        triggerAlert('success', 'Success', 'User deleted successfully!');
                    }
                });
            }
        } catch (error) {
            triggerAlert('error', ' ', error?.response?.data ? error.response.data.message : 'Something went wrong!');
        }
    };


    useEffect(() => {

        // fetchAllUsers()
        if (editMode) {
            trigger();
        }
    }, [trigger])

    useEffect(() => {
        if (selectedUser) {
            setFavourite(selectedUser.favourite === "1" ? 1 : 0);
        }

        if (selectedUsers.length === users.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedUsers, users.length, selectedUser]);

    useEffect(() => {
        fetchAllUsers(currentPage + 1, searchKeyword);
    }, [currentPage, searchKeyword]);

    console.log(showDropdown, "showDropdown");

    const truncatenName = (message, length = 50) => {
        if (message.length > length) {
            return message.substring(0, length) + '...';
        }
        return message;
    };

    return (
        <>

            <div id="content-page" className="content-page">

                <div className="container">
                    <><PageTitle heading="Contact List" showPrimaryButton="Create Contact" showWarningButton="Bulk Upload" onPrimaryClick={handleShowModal2} onWarningClick={handleShowModal1} />
                        <div class="row w-100">
                            <div class="col-md-3">
                                <aside class="sidebar-chat sidebar-base border-end shadow-none  rounded-2" data-sidebar="responsive">
                                    <div class="chat-search pt-3 px-3 ">

                                        <div class="chat-searchbar mt-4 mb-2 d-flex">
                                            <div class="form-group chat-search-data m-0">
                                                <input
                                                    type="text"
                                                    className="form-control round"
                                                    id="chat-search"
                                                    placeholder="Search"
                                                    value={searchKeyword}
                                                    onChange={handleSearch}
                                                />
                                                <i class="material-symbols-outlined">
                                                    search
                                                </i>
                                            </div>

                                            <div className="chat-header-icons d-inline-flex ms-auto">
                                                <div className="dropdown d-flex align-items-center justify-content-center dropdown-custom">
                                                    <span
                                                        className="material-symbols-outlined show"
                                                        role="button"
                                                        onClick={toggleDropdown}
                                                    >
                                                        more_horiz
                                                    </span>

                                                    {showDropdown && (
                                                        <div className="dropdown-menu dropdown-menu-end show" aria-labelledby="dropdownMenuButton9"
                                                            style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(-8px, 34px)" }}>
                                                            <button
                                                                className="dropdown-item d-flex align-items-center"
                                                                onClick={handleShowModal1}
                                                            >
                                                                <i className="material-symbols-outlined md-18 me-1">upload</i>Bulk upload
                                                            </button>
                                                            <button onClick={handleShowModal2} className="dropdown-item d-flex align-items-center">
                                                                <i className="material-symbols-outlined md-18 me-1">add_circle</i>Create
                                                            </button>
                                                            <button onClick={deleteMultiple} className="dropdown-item d-flex align-items-center" >
                                                                <i className="material-symbols-outlined md-18 me-1">delete</i>Delete
                                                            </button>
                                                            <button onClick={HandleEditMode} className="dropdown-item d-flex align-items-center" >
                                                                <i className="material-symbols-outlined md-18 me-1">edit_note</i>Edit
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>




                                            <Modal show={showModal1} onHide={handleCloseModal1} size="lg" centered>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Bulk Contact <span className="required text-danger">*</span> <span className="modal-span">(Upload a CSV file)</span></Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <form onSubmit={HandleSubmitBulk(HandleSubmitBulkupdate)}>
                                                        <div className="card-header d-flex justify-content-between align-items-center">
                                                            <div className="header-title text-warning">
                                                                <p>Please use the below given sample file format for the upload.</p>
                                                            </div>
                                                            <div className="card-header-toolbar d-flex align-items-center">
                                                                <a
                                                                    href="https://customer.vitelsms.com/plugin/docs/bulk_uploads_sample.csv"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="btn btn-sm btn-primary me-2 d-flex"
                                                                >
                                                                    <span className="material-symbols-outlined">attach_file_add</span>
                                                                    Sample.csv
                                                                </a>
                                                            </div>
                                                        </div>

                                                        <Form.Group controlId="formFile" className="mb-3">
                                                            <Form.Label>Choose File</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                accept=".csv"
                                                                {...registerBulk('file', { validate: validateFile })}
                                                            />
                                                            {errorsBulk.file && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                {errorsBulk.file.message}
                                                            </div>}
                                                        </Form.Group>

                                                        <Modal.Footer>
                                                            <button type="submit" class="btn btn-primary">Upload</button>
                                                        </Modal.Footer>
                                                    </form>
                                                </Modal.Body>
                                            </Modal>

                                            {/* Create Contact Modal */}
                                            <Modal show={showModal2} onHide={handleCloseModal2} size="lg" centered>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Create Contact</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <form onSubmit={handleSubmit(HandleSubmit)}>

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-label" for="exampleInputText1">Name<span class="text-danger">*</span> </label>
                                                                    <input
                                                                        type="text"
                                                                        name="contact_name"
                                                                        id="city"
                                                                        className="form-control"
                                                                        placeholder="Enter Your Name"
                                                                        {...register('contact_name', {
                                                                            required: 'Name is required',
                                                                            pattern: onlyAlphabetsandSpaces,
                                                                            maxLength: MaxLengthValidation(100),
                                                                        })} />
                                                                    {errors.contact_name && (
                                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                            {errors.contact_name.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-label" for="exampleInputText1">Email<span class="text-danger">*</span> </label>
                                                                    <input
                                                                        type="text"
                                                                        name="email"
                                                                        id="city"
                                                                        className="form-control"
                                                                        placeholder="Enter Your Email"
                                                                        {...register('email', {
                                                                            required: 'Email Is required',
                                                                            pattern: emailValidation,
                                                                        })} />
                                                                    {errors.email && (
                                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                            {errors.email.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-label" for="exampleInputText1">Phone No<span class="text-danger">*</span> </label>
                                                                    <input
                                                                        type="text"
                                                                        name="contact_number"
                                                                        id="city"
                                                                        className="form-control"
                                                                        placeholder="Enter Your ContactNumber"
                                                                        {...register('contact_number', {
                                                                            required: 'Phone no is required',
                                                                            maxLength: MaxLengthValidation(15),
                                                                            minLength: MinLengthValidation(10),
                                                                            pattern: onlyNumbers,
                                                                        })} />
                                                                    {errors.contact_number && (
                                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                            {errors.contact_number.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-label" for="exampleInputText1">Designation<span class="text-danger">*</span> </label>
                                                                    <input
                                                                        type="text"
                                                                        name="designation"

                                                                        id="city"
                                                                        className="form-control"
                                                                        placeholder="Enter Your Designation"
                                                                        {...register('designation', {
                                                                            required: 'Designation Is required',
                                                                            pattern: onlyAlphabetsandSpaces,
                                                                            maxLength: MaxLengthValidation(100),
                                                                        })} />
                                                                    {errors.designation && (
                                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                            {errors.designation.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <Modal.Footer>
                                                            <button type="submit" class="btn btn-primary">Create</button>
                                                        </Modal.Footer>
                                                    </form>
                                                </Modal.Body>
                                            </Modal>



                                            {/* </div> */}
                                        </div>
                                    </div>

                                    <div className="sidebar-body pt-0 data-scrollbar chat-scrollbar   pe-2">
                                        {/* <!-- Sidebar Menu Start --> */}
                                        <ul className="nav navbar-nav iq-main-menu" style={{ border: "1px" }} id="sidebar-menu" role="tablist">
                                            <li className="nav-item static-item mb-0">
                                                <a className="nav-link static-item disabled mb-0" href="#" tabIndex="-1">
                                                    <h5 className="default-icon"> TOTAL CONTACTS {users.length}</h5>
                                                </a>
                                            </li>

                                            {/* Select All Checkbox */}
                                            <li className="nav-item mb-0">
                                                <div className="form-check form-check-inline me-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="select-all"
                                                        checked={selectAll}
                                                        onChange={handleSelectAllChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="select-all">Select All</label>
                                                </div>
                                            </li>
                                            {users.length > 0 ? (
                                                users.map((user) => (
                                                    <li
                                                        key={user.id}
                                                        className={`nav-item iq-chat-list ${selectedUser?.id === user.id ? 'active row_selected' : ''}`}
                                                        onClick={() => handleUserClick(user)}
                                                        role="tab"
                                                    >
                                                        <div className="nav-link d-flex gap-0" role="tab">
                                                            <div className="form-check form-check-inline me-2">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input p-2"
                                                                    id={`checkbox-${user.id}`}
                                                                    checked={selectedUsers.includes(user.id)}
                                                                    onChange={() => handleCheckboxChange(user.id)}
                                                                />
                                                            </div>
                                                            <div className="d-flex gap-1 align-items-center">
                                                                <div className="position-relative">
                                                                    <span className="badge badge-pill bg-soft-success font-weight-normal ms-auto me-1 badge-45 md-14 rounded-circle p-2">
                                                                        {user.contact_name[0]}
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex align-items-center w-100 iq-userlist-data">
                                                                    <div className="d-flex flex-grow-1 flex-column">
                                                                        <div className="d-flex align-items-center gap-1">
                                                                            <p className="mb-0 text-ellipsis short-1 user-chat flex-grow-1 iq-userlist-name fw-500">
                                                                                {truncatenName(user.contact_name, 20)}
                                                                            </p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <small className="text-ellipsis short-1 flex-grow-1 chat-small">
                                                                                {user.contact_number}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="nav-item no-user-found">
                                                    <div className="nav-link d-flex gap-0 justify-content-center">
                                                        <span>No user found</span>
                                                    </div>
                                                </li>
                                            )}


                                        </ul>
                                        {/* <!-- Sidebar Menu End --> */}
                                    </div>



                                </aside>
                            </div>




                            <div className="col-md-9">
                                <div className="tab-content" id="myTabContent">
                                    <div className="card tab-pane mb-0 fade show active" id="user-content-103" role="tabpanel">
                                        <div className="chat-head">
                                            <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3 border-bottom">
                                                <div className="d-flex align-items-center">
                                                    <h5 className="mb-0 text-primary fw-500">
                                                        {editMode ? "Edit Contact" : "View Contact"}
                                                    </h5>
                                                </div>

                                                {!editMode && (
                                                    <div className="chat-header-icons d-inline-flex ms-auto">
                                                        <button type="submit" onClick={HandleEditMode} className="btn btn-primary d-flex align-items-center btn-sm">
                                                            <span className="material-symbols-outlined">edit_note</span>
                                                            <span className="d-none d-lg-block ms-1">Edit</span>
                                                        </button>
                                                    </div>
                                                )}
                                            </header>
                                        </div>

                                        <div className="card-body chat-body bg-body chat-contacts">
                                            <form onSubmit={handleSubmitEdit(HandleUpdate)}>
                                                <div className="row mt-3">
                                                    {/* Contact Name */}
                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label" htmlFor="contactName">Contact Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={editMode ? undefined : selectedUser?.contact_name}
                                                            {...registerEdit('contact_name', {
                                                                required: editMode ? 'Name is required' : false,
                                                                pattern: editMode ? onlyAlphabetsandSpaces : undefined,
                                                                maxLength: MaxLengthValidation(100),

                                                            })}
                                                            disabled={!editMode} />
                                                        {errorsEdit.contact_name && (
                                                            <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                {errorsEdit.contact_name.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Phone Number */}
                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label" htmlFor="phoneNumber">Phone number</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={editMode ? undefined : selectedUser?.contact_number}
                                                            {...registerEdit('contact_number', {
                                                                required: 'Phone number is required',
                                                                maxLength: MaxLengthValidation(15),
                                                                minLength: MinLengthValidation(10),
                                                                pattern: onlyNumbers,
                                                            })}
                                                            disabled={!editMode} />
                                                        {errorsEdit.contact_number && (
                                                            <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                {errorsEdit.contact_number.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Designation */}
                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label" htmlFor="designation">Designation</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={editMode ? undefined : selectedUser?.designation}
                                                            {...registerEdit('designation', {
                                                                required: 'Designation is required',
                                                                pattern: onlyAlphabetsandSpaces,
                                                                maxLength: MaxLengthValidation(100),
                                                            })}
                                                            disabled={!editMode} />
                                                        {errorsEdit.designation && (
                                                            <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                {errorsEdit.designation.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Email */}
                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label" htmlFor="email">Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            value={editMode ? undefined : selectedUser?.email}
                                                            {...registerEdit('email', {
                                                                required: 'Email is required',
                                                                pattern: emailValidation,
                                                            })}
                                                            disabled={!editMode} />
                                                        {errorsEdit.email && (
                                                            <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                {errorsEdit.email.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Favourite */}
                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label mb-0" htmlFor="favourite">Favourite</label>
                                                        <a
                                                            href="#"
                                                            onClick={handleFavouriteToggle}
                                                            style={{
                                                                pointerEvents: editMode ? 'auto' : 'none',
                                                                color: favourite === 1 ? 'darkorange' : 'gray',
                                                                cursor: editMode ? 'pointer' : 'default'
                                                            }}
                                                        >
                                                            <span className="d-flex align-items-center mt-2">
                                                                <i className="fa fa-star fs-3" aria-hidden="true"></i>
                                                            </span>
                                                        </a>
                                                    </div>

                                                    {editMode && (
                                                        <div className="col-md-12 mb-3">
                                                            <div className="d-flex justify-content-end gap-3">
                                                                <button type="submit" className="btn btn-success px-4 d-flex align-items-center">
                                                                    <span>Update</span>
                                                                </button>
                                                                <button type="button" onClick={handleCancelEdit} className="btn btn-secondary px-4 d-flex align-items-center">
                                                                    <span>Cancel</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div></>

                </div>



            </div>



        </>
    )
}