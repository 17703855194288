import React, { useEffect, useState } from 'react'
import PageTitle from '../../../common/PageTitle';
import LazyLoadImage from '../../../common/components/LazyLoadImage';
import { useForm } from 'react-hook-form';
import { MaxLengthValidation, MinLengthValidation, onlyAlphabetsandSpaces, onlyAlphaNumericSpaces, onlyNumbers } from '../../../utils/Constants';
import { fetchBrands, fetchCountryList, fetchStateList } from '../../../utils/ApiClient';
import { triggerAlert } from '../../../utils/CommonFunctions';

export default function Brands() {

    //////////////////////////// Use form //////////////////////
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        clearErrors,
        setError,
        getValues,
        setValue,
        reset
    } = useForm();

    //////////////////////////states/////////////////////////////////////////////

    const [showCreateBrand, setShowCreateBrand] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [countryData, setCountriesData] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState(null);
    const [stateData, setStatesData] = useState([]);

    ////////////////////// handle functions ///////////////////////
    const handleCreateBrand = () => {
        setShowCreateBrand(true);
    }

    ///////////////////// select APIs /////////////////////////1
    const fetchCountriesData = async () => {
        //setIsLoading(true);
        try {
            const response = await fetchCountryList();
            const respdata = response.data.results.data;
            setCountriesData(respdata);
            // setIsLoading(false);
        } catch (error) {
            console.log(error)
            // triggerAlert("error", "", "No data available");
        }
    };
    const fetchStatesData = async (countryCode) => {
        // setIsLoading(true);
        try {
            const response = await fetchStateList(countryCode);
            const respdata = response.data.results.data;
            setStatesData(respdata);
            // setIsLoading(false);
        } catch (error) {
            //triggerAlert('error','','No data available');
        }
    };


    const filteredStates = stateData.filter(
        (state) => state.country_code_char2 === selectedCountryCode
    );

    const createBrand = async (data) => {
        console.log("Data", data)
        // Create a new FormData instance
        const formData = new FormData();

        // Iterate over each key-value pair in the data object
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("profile_id", "");
        console.log("Data", data, formData)

        // Log FormData contents to check if values are appended correctly
        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }
        setIsLoading(true);
        try {

            const response = await fetchBrands(formData);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                setShowCreateBrand(false);
                triggerAlert('success', 'success', 'Brand created Successfully!!');
                reset();
            } else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }
    useEffect(() => {
        fetchCountriesData();

    }, []);
    return (
        <div>
            <div class="position-relative">
            </div>
            <div id="content-page" class="content-page">
                <div class="container">

                    <PageTitle heading="Brands" showPrimaryButton="Create Brand" onPrimaryClick={handleCreateBrand} />
                    <div class="row mb-5">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body ">
                                    {!showCreateBrand ?
                                        <div className='d-flex justify-content-center flex-column align-items-center'>
                                            {/* <img src='/assets/images/Inbox.jpg' alt='inbox' /> */}
                                            <LazyLoadImage src='/assets/images/Campaign.jpg' alt='Campaign' />
                                            <p className='text-center'>Please create a brand to view</p>
                                        </div>
                                        :
                                        <form onSubmit={handleSubmit(createBrand)}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="firstName">First Name <span className='text-danger'>*</span></label>
                                                        <input type="text" class="form-control"
                                                            name="firstName"
                                                            id="firstName"
                                                            placeholder="Enter First Name"
                                                            {...register("firstName", {
                                                                required: "First Name is required",
                                                                pattern: onlyAlphabetsandSpaces,
                                                                maxLength: MaxLengthValidation(100),
                                                                // minLength: MinLengthValidation(6)
                                                            })} />
                                                        {errors.firstName && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.firstName.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="lastName">Last Name  <span className='text-danger'>*</span></label>
                                                        <input class="form-control" type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            placeholder="Enter Last Name"
                                                            {...register("lastName", {
                                                                required: "Last Name is required",
                                                                pattern: onlyAlphabetsandSpaces,
                                                                maxLength: MaxLengthValidation(100),
                                                                // minLength: MinLengthValidation(6)
                                                            })} />
                                                        {errors.lastName && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.lastName.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="companyName">Company Name  <span className='text-danger'>*</span></label>
                                                        <input type="text" class="form-control"
                                                            name="companyName"
                                                            id="companyName"
                                                            placeholder="Enter Company name"
                                                            {...register("companyName", {
                                                                required: "Company name is required",
                                                                pattern: onlyAlphaNumericSpaces,
                                                                minLength: MinLengthValidation(2),
                                                                maxLength: MaxLengthValidation(100)
                                                            })} />
                                                        {errors.companyName && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.companyName.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="displayName">Display name  <span className='text-danger'>*</span></label>
                                                        <input type="text" class="form-control"
                                                            name="displayName"
                                                            id="displayName"
                                                            placeholder="Enter Display name"
                                                            {...register("displayName", {
                                                                required: "Display Name is required",
                                                                pattern: onlyAlphaNumericSpaces,
                                                                minLength: MinLengthValidation(2),
                                                                maxLength: MaxLengthValidation(100)
                                                            })} />
                                                        {errors.displayName && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.displayName.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="paymentName">
                                                        EIN <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="ein"
                                                        id="paymentName"
                                                        className="form-control"
                                                        placeholder="Enter EIN"
                                                        {...register("ein", {
                                                            required: "EIN is required",
                                                            maxLength: MaxLengthValidation(12),
                                                            minLength: MinLengthValidation(5),
                                                            pattern: onlyNumbers,
                                                        })}
                                                    />
                                                    {errors.ein && (
                                                        <div
                                                            style={{ color: "red", fontSize: "14px", marginTop: "5px" }}
                                                        >
                                                            {errors.ein.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="einIssuingCountry">
                                                        EIN issuing country<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="einIssuingCountry"
                                                        id="einIssuingCountry"
                                                        className="form-control"
                                                        placeholder="Enter EIN"
                                                        {...register("einIssuingCountry", {
                                                            required: "EIN is required",
                                                            maxLength: MaxLengthValidation(12),
                                                            minLength: MinLengthValidation(5),
                                                            pattern: onlyAlphabetsandSpaces,
                                                        })}
                                                    />
                                                    {errors.einIssuingCountry && (
                                                        <div
                                                            style={{ color: "red", fontSize: "14px", marginTop: "5px" }}
                                                        >
                                                            {errors.einIssuingCountry.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="phone">Phone  <span className='text-danger'>*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="phone"
                                                            {...register("phone", {
                                                                required: "Phone number is required",
                                                                pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: 'Please enter a valid phone number',
                                                                }
                                                            })}
                                                        />
                                                        {errors.phone && (
                                                            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                                                                {errors.phone.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="street">Street  <span className='text-danger'>*</span></label>
                                                        <input type="text" class="form-control"
                                                            name="street"
                                                            id="street"
                                                            placeholder="Enter Street"
                                                            {...register("street", {
                                                                required: "Street is required",
                                                            })}
                                                        />
                                                        {errors.street && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.street.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="city">City  <span className='text-danger'>*</span></label>
                                                        <input type="text" class="form-control"
                                                            name="city"
                                                            id="city"
                                                            placeholder="Enter City"
                                                            {...register("city", {
                                                                required: "City is required",
                                                            })}
                                                        />
                                                        {errors.city && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.city.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="country">Country  <span className='text-danger'>*</span></label>
                                                        <select id="inputState" class="form-select"
                                                            name="country"
                                                            {...register("country", {
                                                                required: "Country is required",
                                                            })}
                                                            onChange={(e) => {
                                                                setSelectedCountryCode(e.target.value);
                                                                fetchStatesData(e.target.value);
                                                                setValue('state', '')
                                                            }} autoComplete="off">
                                                            <option value="" hidden>Select</option>
                                                            {countryData.map((item, index) => (
                                                                <option value={item.country_code_char2}>
                                                                    {item.country_name}
                                                                </option>
                                                            ))}
                                                            {/* <option value="" disabled>Select Country</option>
                                                                        <option>Andhra Pradesh</option>
                                                                        <option>Arunachal Pradesh</option>
                                                                        <option>Andaman and Nicobar Islands</option> */}

                                                        </select>
                                                        {errors.country && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.country.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="state">State  <span className='text-danger'>*</span></label>
                                                        <select id="inputState" class="form-select"
                                                            name="state"
                                                            {...register("state", {
                                                                required: "State is required",
                                                            })} autoComplete="off"
                                                        >
                                                            <option value="" hidden>Select</option>
                                                            {filteredStates.map((item, index) => (
                                                                <option value={item.state_subdivision_name}>
                                                                    {item.state_subdivision_name}
                                                                </option>
                                                            ))}

                                                            {/* <option value="" disabled>Select Country</option>
                                                                        <option>Andhra Pradesh</option>
                                                                        <option>Arunachal Pradesh</option>
                                                                        <option>Andaman and Nicobar Islands</option> */}


                                                        </select>
                                                        {errors.state && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.state.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="postalCode">Postal Code  <span className='text-danger'>*</span></label>
                                                        <input type="text" class="form-control" style={{ position: 'relative' }}
                                                            name="postalCode"
                                                            id="postalCode"
                                                            placeholder="Enter Postal Code"
                                                            {...register("postalCode", {
                                                                required: "Postal Code is required",
                                                                pattern: onlyNumbers,
                                                                minLength: MinLengthValidation(4),
                                                                maxLength: MaxLengthValidation(10)
                                                            })}
                                                        />
                                                        {errors.postalCode && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.postalCode.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="email">Email  <span className='text-danger'>*</span></label>
                                                        <input type="email" class="form-control"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Enter Email Name"
                                                            {...register("email", {
                                                                required: "Email is required",
                                                                maxLength: MaxLengthValidation(150),
                                                                minLength: MinLengthValidation(8),
                                                                pattern: {
                                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                    message: "Invalid email format",
                                                                },
                                                            })}
                                                            autocomplete="off" />
                                                        {errors.email && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.email.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="website">Website  <span className='text-danger'>*</span></label>
                                                        <input type="text" class="form-control"
                                                            name="website"
                                                            id="website"
                                                            placeholder="Enter Website URL"
                                                            {...register("website", {
                                                                required: "Website is required",
                                                                minLength: MinLengthValidation(10),
                                                                maxLength: MaxLengthValidation(100)
                                                            })} />
                                                        {errors.website && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.website.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="website">Brand Relationship <span className="text-danger">*</span></label>
                                                        <select className="form-select" id="exampleFormControlSelect1" name="brandRelationship"
                                                            {...register("brandRelationship", {
                                                                required: "Brand Relationship is required",
                                                            })}>
                                                            <option value="" hidden>Select</option>
                                                            <option value="BASIC_ACCOUNT">BASIC_ACCOUNT</option>
                                                            <option value="SMALL_ACCOUNT">SMALL_ACCOUNT</option>
                                                            <option value="MEDIUM_ACCOUNT">MEDIUM_ACCOUNT</option>
                                                            <option value="LARGE_ACCOUNT">LARGE_ACCOUNT</option>
                                                            <option value="KEY_ACCOUNT">KEY_ACCOUNT</option>

                                                        </select>
                                                        {errors.brandRelationship && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.brandRelationship.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="website">Vertical <span className="text-danger">*</span></label>

                                                        <select className="form-select" id="exampleFormControlSelect1" name="vertical"
                                                            {...register("vertical", {
                                                                required: "Vertical is required",
                                                            })}>
                                                            <option value="" hidden>Select</option>
                                                            <option value="PROFESSIONAL">Professional Services</option>
                                                            <option value="REAL_ESTATE">Real Estate</option>
                                                            <option value="HEALTHCARE">Healthcare and Life Sciences</option>
                                                            <option value="HUMAN_RESOURCES">HR, Staffing or Recruitment</option>
                                                            <option value="ENERGY">Entertainment</option>
                                                            <option value="REAL_ESTATE">Real Estate</option>
                                                            <option value="RETAIL">Retail and Consumer Products</option>
                                                            <option value="TRANSPORTATION">Transportation or Logistics</option>
                                                            <option value="AGRICULTURE">Agriculture</option>
                                                            <option value="INSURANCE">Insurance</option>
                                                            <option value="POSTAL">Postal and Delivery</option>
                                                            <option value="EDUCATION">Education</option>
                                                            <option value="HOSPITALITY">Hospitality and Travel</option>
                                                            <option value="FINANCIAL">Financial Services</option>
                                                            <option value="POLITICAL">Political</option>
                                                            <option value="GAMBLING">Gambling and Lottery</option>
                                                            <option value="LEGAL">Legal</option>
                                                            <option value="CONSTRUCTION">Construction, Materials, and Trade Services</option>
                                                            <option value="NGO">Non-profit Organization</option>
                                                            <option value="MANUFACTURING">Manufacturing</option>
                                                            <option value="GOVERNMENT">Government Services and Agencies</option>
                                                            <option value="TECHNOLOGY">Information Technology Services</option>
                                                            <option value="COMMUNICATION">Media and Communication</option>


                                                        </select>
                                                        {errors.vertical && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.vertical.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="website">Mock <span className="text-danger">*</span></label>

                                                        <select className="form-select" id="exampleFormControlSelect1" name="mock"
                                                            {...register("mock", {
                                                                required: "mock is required",
                                                            })}
                                                            autoComplete="off" >
                                                            <option value="true">True</option>
                                                            <option value="false">False</option>
                                                        </select>
                                                        {errors.mock && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.mock.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="lastName">Tag </label>
                                                        <input class="form-control" type="text"
                                                            name="tag"
                                                            id="tag"
                                                            placeholder="Enter Tag"
                                                            {...register("tag", {
                                                                // required: "tag is required",
                                                                pattern: onlyAlphabetsandSpaces,
                                                                maxLength: MaxLengthValidation(100),
                                                                // minLength: MinLengthValidation(6)
                                                            })} />
                                                        {errors.tag && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.tag.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row justify-content-end'>
                                                <div className="col-md-1 px-3 pb-5 border-0">
                                                    <button type="button" className="btn btn-warning  d-block mt-3" onClick={() => setShowCreateBrand(false)}>Cancel</button>
                                                </div>
                                                <div className="col-md-1 px-3 pb-5 border-0">
                                                    <button type="submit" className="btn btn-primary d-block mt-3">Submit</button>
                                                </div>
                                            </div>

                                        </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
