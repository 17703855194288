import React, { useState } from 'react'
import PageTitle from '../../../common/PageTitle'
import { Controller, useForm } from 'react-hook-form';
import DynamicSelect from '../../../common/components/selects/DynamicSelect';
import { formatDateTime, getBase64, triggerAlert } from '../../../utils/CommonFunctions';
import { noEmptySpacesValidation } from '../../../utils/Constants';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import MultiSelectDyGet from '../../../common/components/selects/MultiSelectDyGet';
import { sendSMSOrMMS } from '../../../utils/ApiClient';
import Loader from '../../../common/components/Loader';
import Emojis from '../../../common/components/Emojis';
import CreatableMultiSelectDyGet from '../../../common/components/selects/CreatableMultiSelectDyGet';

export default function Compose() {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const currentDate = new Date();

    const [showSchedule, setShowSchedule] = useState(false);
    const [mmsFile, setMMSFile] = useState({}); // To track the active chat
    const [isLoading, setIsLoading] = useState(false);
    const [sendToNumbers, setSendToNumbers] = useState([]);
    const [activeTab, setActiveTab] = useState('contact');
    const [groupContacts, setGroupContacts] = useState([]);
    const [showEmojis, setShowEmojis] = useState(false);

    ///////////////////////// Basic form /////////////////////////////////////////
    const { register, handleSubmit, formState: { errors }, setValue, reset, control, getValues, watch, setError, clearErrors } = useForm(
        {
            defaultValues: {
                scheduled_date: showSchedule ? currentDate : null, // Initialize with current date
            }
        }
    );
    const messageType = watch('msg_type', 'SMS'); // Default to 'sms'
    const formReset = () => {
        reset();
        setValue('to_number', null);
        setValue('from_number', null);
        setValue('group_id', null);
    }
    const handleTabClick = (tabId) => {
        formReset();
        setActiveTab(tabId);
    };
    ////////////////////// select a number functions /////////////////////////////////
    const handleToSelect = async (selectedOption) => {
        // Check if selectedOption is an array before processing
        if (Array.isArray(selectedOption)) {
            // Map over the selectedOption to ensure values are numbers where applicable
            const selectedValues = selectedOption.map((item) => {
                // Convert string to number if it's numeric, otherwise leave it unchanged
                const numericValue = !isNaN(item.value) && typeof item.value === 'string'
                    ? Number(item.value)
                    : item.value;

                return {
                    ...item,
                    value: numericValue
                };
            });

            // console.log("selectedValues", selectedValues); // Check the modified selected values

            // Extract the values as an array of numbers/strings
            const valuesArray = selectedValues.map(item => String(item.value));

            setSendToNumbers(valuesArray); // Assuming you need the array of values (numbers/strings)
            setValue("to_number", selectedValues); // Set the selected options (objects) in form state
        } else {
            console.error("Selected option is not an array.");
        }
    };

    const handleNumberSelect = async (selectedOption) => {
        const number = selectedOption ? selectedOption.value : null;
        setValue('from_number', number);
    };
    const handleTemplateChange = async (selectedOption) => {
        const message = selectedOption ? selectedOption.message : null;
        console.log("message", selectedOption)
        setValue('message', message);
        setValue('template', selectedOption ? selectedOption.value : null);
    };

    const handleGroupSelect = (selectedOption) => {
        const selectedValues = selectedOption?.map((item) => item.value);

        // Flatten the group_contacts arrays for the selected groups and include the group_id
        const selectedGroupContacts = selectedOption?.flatMap((item) =>
            (item.group_contacts || []).map(contact => ({ ...contact, group_id: item.value }))
        );
        // Set the sendToNumbers state with the selected group values
        setSendToNumbers(selectedValues);

        // Update group contacts: Remove contacts from deselected groups and add contacts from newly selected groups
        setGroupContacts((prev) => {
            // Get all the selected group IDs
            const selectedGroupIds = new Set(selectedOption.map(group => group.value));
            // Filter out contacts from groups that are no longer selected
            const filteredPrev = prev.filter(contact => selectedGroupIds.has(contact.group_id));

            // Merge previous and new contacts, ensuring no duplicates (same contact id & group id)
            const combinedContacts = [...filteredPrev];

            selectedGroupContacts.forEach(newContact => {
                const isDuplicate = combinedContacts.some(
                    contact => contact.id === newContact.id && contact.group_id === newContact.group_id
                );
                if (!isDuplicate) {
                    combinedContacts.push(newContact);  // Add if not already in the combined list
                }
            });

            return combinedContacts;
        });

        // Set the selected group_id in the form state
        setValue('group_id', selectedOption);
    };


    const handleMMSFilechange = async (e) => {
        const file = e.target.files[0];
        let items = {};

        if (!file) return;

        // Check if the file is a PDF
        if (file.type === "application/pdf") {
            setError('mmsfile', {
                type: 'manual',
                message: "PDF files are not allowed.",
            });
            e.target.value = ''; // Clear the input
            setMMSFile(items); // Set error state
            return;
        }

        // Check if the file size exceeds 2MB
        if (file.size > 2 * 1024 * 1024) {
            setError('mmsfile', {
                type: 'manual',
                message: "File size should not exceed 2MB.",
            });
            e.target.value = ''; // Clear the input
            setMMSFile(items); // Set error state
            return;
        }

        try {
            clearErrors('mmsfile')
            // Convert file to base64
            const base64 = await getBase64(file);
            const base64WithoutPrefix = base64.substring(base64.indexOf(",") + 1);
            items = {
                ...items,
                file_name: file.name,
                file_type: file?.name?.split(".")[1],
                file_size: file.size,
                file: base64WithoutPrefix,
                preview: base64 // Store the full base64 string for preview
            };
            console.log("items", items)
            setMMSFile(items);
        } catch (error) {
            // console.error("Error converting file to base64:", error);
            items.error = "Failed to process the file.";
            setMMSFile(items);
        }
    }

    ///////////////////// compose SMS or MMS message ///////////////////////////////
    const composeSMSorMMSMessage = async (data) => {
        // return
        setIsLoading(true);
        // if (usernum) setActiveUserNum(usernum)
        try {
            const params = data;
            params.from_number = String(params.from_number);
            params.type = activeTab;
            if (activeTab === 'contact') {
                params.to_number = sendToNumbers
                params.group_id = []
            } else {
                params.group_id = sendToNumbers
                params.to_number = []
            }
            if (data.scheduled_date) params.scheduled_date = formatDateTime(params.scheduled_date, 'yyyy-mm-dd hh:mm:ss');
            if (data.msg_type == 'MMS') params.base64_files = mmsFile;

            // console.log("params", params)
            // return
            const response = await sendSMSOrMMS(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                formReset();
                triggerAlert('success', 'success', 'Message sent Successfully!!');
            } else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Unable to send message!!');
            }
        } catch (error) {
            const response_data = error?.response?.data
            console.error(error)
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }

    const handleEmojiSelect = (emoji) => {
        console.log("emoji", emoji)
        const currentMessage = watch("message") || ""; // Get current message value
        setValue("message", currentMessage + emoji); // Append emoji to the message
    };

    return (
        <div>
            <main class="main-content mt-3">

                <div class="container content-inner  " id="page_layout">

                    <PageTitle heading="Compose" />
                    {isLoading &&
                        <div className='loader-overlay text-white'>
                            <Loader />
                        </div>
                    }
                    <div class="row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="tab-content" id="myTabContent">
                                <div class="card tab-pane mb-0 fade show active" id="user-content-103" role="tabpanel">
                                    <form onSubmit={handleSubmit(composeSMSorMMSMessage)}>
                                        <div class="card-header border-0">
                                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a className={`nav-link ${activeTab
                                                        === 'contact' ? 'active' : ''}`} id="contact-tab" data-bs-toggle="pill" href="#contact" role="tab" aria-controls="contact" aria-selected={activeTab === 'contact'} onClick={() => handleTabClick('contact')}>
                                                        <i className="fa-solid fa-address-book" aria-hidden="true"></i>
                                                        <span className="ms-2">Contacts</span>
                                                    </a>

                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className={`nav-link ${activeTab === 'groups' ? 'active' : ''}`} id="groups-tab" data-bs-toggle="pill" href="#groups" role="tab" aria-controls="groups" aria-selected={activeTab === 'groups'} tabindex="-1" onClick={() => handleTabClick('groups')}>
                                                        <i className="fa fa-users" aria-hidden="true"></i>
                                                        <span className="ms-2">Groups</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="card-body bg-body">

                                            <div class="row mt-3">
                                                <div class="col-md-6 mb-3">
                                                    <div className='d-flex justify-content-between'>

                                                        <label class="form-label" for="validationDefault01">To <span className='text-danger'>*</span></label>
                                                        {activeTab === 'contact' ? (
                                                            <span>Recipients : {watch('to_number') ? watch('to_number').length : 0}</span>
                                                        ) : activeTab === 'groups' ? (
                                                            <span>Recipients : {groupContacts ? groupContacts.length : 0}</span>
                                                        ) : null}
                                                    </div>
                                                    {activeTab === 'contact' ?
                                                        <>

                                                            <Controller
                                                                name="to_number"
                                                                {...register('to_number', { required: 'To is required' })}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <CreatableMultiSelectDyGet
                                                                        {...field}
                                                                        apiUrl={api_url + 'sms/get_all_contact_list/'}
                                                                        placeholder="Select phone number"
                                                                        mapOption={result => ({
                                                                            value: result.contact_number,
                                                                            label: result.contact_number,
                                                                        })}
                                                                        value={field.value}
                                                                        onSelect={handleToSelect} // Use the updated handleToSelect function
                                                                    />
                                                                )}
                                                            />

                                                            {errors.to_number && (
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "14px",
                                                                        marginTop: "5px",
                                                                    }}
                                                                >
                                                                    {errors.to_number.message}
                                                                </div>
                                                            )}
                                                        </>
                                                        :
                                                        <>

                                                            <Controller
                                                                name="group_id"
                                                                {...register('group_id', { required: 'Group is required' })}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <MultiSelectDyGet
                                                                        {...field}
                                                                        apiUrl={api_url + 'contact/template_groups_list/'} // API URL for fetching group names
                                                                        placeholder="Select group"
                                                                        mapOption={result => ({
                                                                            value: result.id,
                                                                            label: result.group_name,
                                                                            group_contacts: result.group_contacts,

                                                                        })}
                                                                        value={field.value}
                                                                        onSelect={handleGroupSelect} // Triggered when a group is selected
                                                                    />
                                                                )}
                                                            />
                                                            {errors.group_id && (
                                                                <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                                                                    {errors.group_id.message}
                                                                </div>
                                                            )}

                                                            {errors.group_id && (
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "14px",
                                                                        marginTop: "5px",
                                                                    }}
                                                                >
                                                                    {errors.group_id.message}
                                                                </div>
                                                            )}
                                                        </>
                                                    }

                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label class="form-label" for="validationDefault02">From <span className='text-danger'>*</span></label>
                                                    <Controller
                                                        name="from_number"
                                                        {...register('from_number',
                                                            { required: 'From is required' }
                                                        )}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <DynamicSelect
                                                                {...field}
                                                                apiUrl={api_url + 'sms/get_company_number_list/'}
                                                                placeholder="Select phone number"
                                                                mapOption={result => ({
                                                                    value: result.requested_no,
                                                                    label: result.requested_no,
                                                                })}
                                                                value={field.value}
                                                                onSelect={handleNumberSelect}
                                                            />
                                                        )}
                                                    />
                                                    {errors.from_number && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                                marginTop: "5px",
                                                            }}
                                                        >
                                                            {errors.from_number.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label for="validationCustomUsername" class="form-label">Template</label>
                                                    <Controller
                                                        name="template"
                                                        {...register('template',
                                                            // { required: 'Department is required' }
                                                        )}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <DynamicSelect
                                                                {...field}
                                                                apiUrl={api_url + 'sms/get_message_template_list/'}
                                                                placeholder="Select a template"
                                                                mapOption={result => ({
                                                                    value: result.id,
                                                                    label: result.template_name,
                                                                    message: result.template_message
                                                                })}
                                                                value={field.value}
                                                                onSelect={handleTemplateChange}
                                                            />
                                                        )}
                                                    />
                                                    {errors.template && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                                marginTop: "5px",
                                                            }}
                                                        >
                                                            {errors.template.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label class="form-label" for="validationDefault03">Select Type <span className='text-danger'>*</span></label>
                                                    <div className='d-flex align-items-center mt-2'>
                                                        <div class="me-3">
                                                            <Controller
                                                                name="msg_type"
                                                                control={control}
                                                                defaultValue="SMS"
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            {...field}
                                                                            type="radio"
                                                                            id="SMS"
                                                                            value="SMS"
                                                                            checked={field.value === 'SMS'}
                                                                        />
                                                                        &nbsp;
                                                                        <label htmlFor="SMS"> SMS</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div class="me-3">
                                                            <Controller
                                                                name="msg_type"
                                                                control={control}
                                                                defaultValue="SMS"
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            {...field}
                                                                            type="radio"
                                                                            id="MMS"
                                                                            value="MMS"
                                                                            checked={field.value === 'MMS'}
                                                                        />
                                                                        &nbsp;
                                                                        <label htmlFor="MMS"> MMS</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errors.msg_type && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                                marginTop: "5px",
                                                            }}
                                                        >
                                                            {errors.msg_type.message}
                                                        </div>
                                                    )}

                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label class="form-label" for="validationDefault05">
                                                        Message <span className="text-danger">*</span>
                                                    </label>
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="Enter message"
                                                        {...register("message", {
                                                            required: "Message is required",
                                                            validate: noEmptySpacesValidation,
                                                        })}
                                                    />
                                                    {errors.message && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                                marginTop: "5px",
                                                            }}
                                                        >
                                                            {errors.message.message}
                                                        </div>
                                                    )}

                                                    {/* Wrap Total Characters and Emoji button in a flexbox */}
                                                    {/* <div className="d-flex justify-content-between align-items-center mt-2"> */}
                                                    <span>Total No Of Characters: {watch("message") ? watch("message").length : 0}</span>
                                                    {/* <a
                                                            href="#/"
                                                            className="d-flex align-items-center"
                                                            onClick={() => setShowEmojis(!showEmojis)}
                                                            style={{ marginLeft: "10px" }} // Add space between characters count and emoji button
                                                        >
                                                            <svg
                                                                class="icon-24"
                                                                width="24"
                                                                viewBox="0 0 24 25"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g clipPath="url(#clip0_156_599)">
                                                                    <path
                                                                        d="M20.4853 4.01473C18.2188 1.74823 15.2053 0.5 12 0.5C8.79469 0.5 5.78119 1.74823 3.51473 4.01473C1.24819 6.28119 0 9.29469 0 12.5C0 15.7053 1.24819 18.7188 3.51473 20.9853C5.78119 23.2518 8.79469 24.5 12 24.5C15.2053 24.5 18.2188 23.2518 20.4853 20.9853C22.7518 18.7188 24 15.7053 24 12.5C24 9.29469 22.7518 6.28119 20.4853 4.01473ZM12 23.0714C6.17091 23.0714 1.42856 18.3291 1.42856 12.5C1.42856 6.67091 6.17091 1.92856 12 1.92856C17.8291 1.92856 22.5714 6.67091 22.5714 12.5C22.5714 18.3291 17.8291 23.0714 12 23.0714Z"
                                                                        fill="currentcolor"
                                                                    ></path>
                                                                    <path
                                                                        d="M9.40398 9.3309C8.23431 8.16114 6.33104 8.16123 5.16136 9.3309C4.88241 9.60981 4.88241 10.0621 5.16136 10.3411C5.44036 10.62 5.89266 10.62 6.17157 10.3411C6.78432 9.72836 7.78126 9.7284 8.39392 10.3411C8.53342 10.4806 8.71618 10.5503 8.89895 10.5503C9.08171 10.5503 9.26457 10.4806 9.40398 10.3411C9.68293 10.0621 9.68293 9.60986 9.40398 9.3309Z"
                                                                        fill="currentcolor"
                                                                    ></path>
                                                                    <path
                                                                        d="M18.8384 9.3309C17.6688 8.16123 15.7655 8.16114 14.5958 9.3309C14.3169 9.60981 14.3169 10.0621 14.5958 10.3411C14.8748 10.62 15.3271 10.62 15.606 10.3411C16.2187 9.72836 17.2156 9.72831 17.8284 10.3411C17.9679 10.4806 18.1506 10.5503 18.3334 10.5503C18.5162 10.5503 18.699 10.4806 18.8384 10.3411C19.1174 10.0621 19.1174 9.60986 18.8384 9.3309Z"
                                                                        fill="currentcolor"
                                                                    ></path>
                                                                    <path
                                                                        d="M18.3335 13.024H5.6668C5.2723 13.024 4.95251 13.3438 4.95251 13.7383C4.95251 17.6243 8.11409 20.7859 12.0001 20.7859C15.8862 20.7859 19.0477 17.6243 19.0477 13.7383C19.0477 13.3438 18.728 13.024 18.3335 13.024ZM12.0001 19.3573C9.14366 19.3573 6.77816 17.215 6.42626 14.4525H17.574C17.2221 17.215 14.8566 19.3573 12.0001 19.3573Z"
                                                                        fill="currentcolor"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </div> */}

                                                    {/* Show emoji picker below */}
                                                    {/* <div className="mt-2" style={{ position: "relative" }}>
                                                        {showEmojis && (
                                                            <div style={{ position: "absolute", zIndex: 1000, top: "100%", left: 0, backgroundColor: "#fff", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", overflowY: "auto", maxHeight: "300px" }}>
                                                                <Emojis onEmojiSelect={handleEmojiSelect} pickerSize={{ height: 300, width: 650 }} />
                                                            </div>
                                                        )}
                                                    </div> */}
                                                    {/* <Emojis onEmojiSelect={handleEmojiSelect} /> */}

                                                </div>
                                                {showSchedule &&
                                                    <div class="col-md-6 mb-3">
                                                        <label class="form-label" for="validationDefault05">Schedule</label>
                                                        <div className="w-100" >
                                                            <Controller
                                                                control={control}
                                                                name="scheduled_date" // Assign the name here
                                                                class="form-control "

                                                                render={({ field, }) => (
                                                                    <DatePicker
                                                                        selected={field.value ? new Date(field.value) : new Date()}
                                                                        onChange={(date
                                                                        ) => {
                                                                            setValue("scheduled_date", date);
                                                                            field.onChange(date ? date : null);
                                                                        }}
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm"
                                                                        timeIntervals={30}
                                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                                        placeholderText="YYYY-MM-DD HH:MM:SS"
                                                                        className="form-control custom-datepicker-width" // Use Bootstrap's form-control class for full width

                                                                    // minDate={new Date()}
                                                                    // showMonthDropdown showYearDropdown
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                        {errors.scheduled_date && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.scheduled_date.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                {messageType === 'MMS' && (
                                                    <div class="col-md-6 mb-3">
                                                        <label class="form-label" for="validationDefault05">Attachment </label>
                                                        <input
                                                            type="file"
                                                            name="mmsfile"
                                                            class="form-control"
                                                            // {...registerIn("mmsfile")}
                                                            onChange={handleMMSFilechange}
                                                        />
                                                        {errors.mmsfile && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.mmsfile.message}
                                                            </div>
                                                        )}
                                                        {/* Preview the image if the file is an image */}
                                                        {mmsFile?.file_type && ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(mmsFile.file_type.toLowerCase()) && (
                                                            <div style={{ marginTop: '10px' }}>
                                                                <img
                                                                    src={mmsFile.preview}
                                                                    alt="Preview"
                                                                    style={{
                                                                        width: '100px',
                                                                        // height: '10em',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                />
                                                            </div>
                                                        )}

                                                    </div>

                                                )}

                                            </div>

                                        </div>
                                        <div class="card-footer px-3 py-3  rounded-0">
                                            <div class="d-flex  justify-content-end">
                                                <button type="button" class="btn btn-info  px-4 d-flex align-items-center me-2" onClick={() => setShowSchedule(!showSchedule)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10" /><polyline points="12 6 12 12 16 14" /></svg>
                                                    &nbsp;<span class=""> Schedule </span></button>

                                                <button type="button" class="btn btn-warning  px-4 d-flex align-items-center me-2" onClick={formReset}><span >Cancel </span></button>
                                                <button type="submit" class="btn btn-success  px-4 d-flex align-items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13" /><polygon points="22 2 15 22 11 13 2 9 22 2" /></svg>
                                                    &nbsp;<span class="">Send </span></button>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </div >
    )
}
