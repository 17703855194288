import React, { useEffect, useState } from 'react'
import PageTitle from '../../../common/PageTitle'
import { useForm, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { PaymentDetailsGet, PaymentSaveAuto, saveLowBalanceSave } from '../../../utils/ApiClient';
import { triggerAlert, simpleAlert, getCustomerId } from '../../../utils/CommonFunctions';
import Loader from "../../../common/components/Loader"
export default function PaymentSetting() {

    const [isEnabled, setIsEnabled] = useState(false);
    const [isEnabledLBN, setIsEnabledLBN] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const customer_id = getCustomerId();
    const { register, handleSubmit, formState: { errors }, control, setValue, reset } = useForm();
    const { register: registerLBN, handleSubmit: handleSubmitLBN, formState: { errors: errorsLBN }, setValue: setValueLBN, reset: resetLBN, controlLBN } = useForm();
    const props = {
        title: "Payment Setting | Pay As You Go",
        description: "Premium Multipurpose Admin & Dashboard Template"
    }
    const getPaymentSettingDetails = async () => {
        setIsLoading(true);
        try {
            const response = await PaymentDetailsGet()
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const autoPaymentStatus = response_data.results.data.auto_payment_status;
                const lowBalanceNotificationStatus = response_data.results.data.low_balance_notification_status;
                setIsLoading(false);
                if (autoPaymentStatus === "yes") {
                    setIsEnabled(true);
                } else {
                    setIsEnabled(false);
                }

                if (lowBalanceNotificationStatus === "yes") {
                    setIsEnabledLBN(true);
                } else {
                    setIsEnabledLBN(false);
                }

                setValue('threshold_amount', response_data.results.data.threshold_amount || 10);
                setValue('auto_recharge_amount', response_data.results.data.auto_recharge_amount || 0);
                setValueLBN('low_balance_threshold_amount', response_data.results.data.low_balance_threshold_amount || 10);

            } else if (response.status === 204) {
                simpleAlert('Please try after sometime!');
                setIsLoading(false);
            } else {
                triggerAlert('error', '', 'Something went wrong..');
                setIsLoading(false);
            }
        } catch (error) {
            triggerAlert('error', '', 'Something went wrong..');
            setIsLoading(false);
        }
    }


    const handleSwitchChange = () => {
        setIsEnabled(!isEnabled);
        reset(); // Call the reset function after toggling
    };

    const handleSwitchChangeLBN = () => {
        setIsEnabledLBN(!isEnabledLBN);
        resetLBN();
    };


    const handleAutoPaymentSave = async (data) => {
        console.log(data, "data");
        let api_input = "";

        if (isEnabled) {
            api_input = {

                "customer_id": customer_id,
                "auto_payment_status": 'yes',
                "threshold_amount": Number(data.threshold_amount),
                "auto_recharge_amount": Number(data.auto_recharge_amount)

            }
        } else {
            api_input = {

                "customer_id": customer_id,
                "auto_payment_status": 'no',
                "threshold_amount": 10,
                "auto_recharge_amount": 0

            }
        }
        console.log(api_input, "api_input");
        setIsLoading(true);
        try {
            const response = await PaymentSaveAuto(api_input)
            console.log(response, "responseresponse");

            const response_data = response.data;
            if (response_data.error_code === 200) {
                const successMessage = isEnabled
                    ? 'Successfully enabled auto payment!'
                    : 'Successfully disabled auto payment!';

                triggerAlert('success', 'Success', successMessage);
                setIsLoading(false);
                // reset();
                getPaymentSettingDetails();
            } else if (response.status === 204) {
                simpleAlert('Please try after sometime!');
                setIsLoading(false);
            } else {
                triggerAlert('error', '', 'Something went wrong..');
                setIsLoading(false);
            }

        } catch (error) {

            triggerAlert('error', '', 'Something went wrong..');
            setIsLoading(false);
        }


    }
    const handleLowBalanceSave = async (data) => {
        console.log(data);
        let api_input = ""
        if (isEnabledLBN) {
            api_input = {
                "low_balance_notification_status": 'yes', //change later with no status 
                "low_balance_threshold_amount": Number(data.low_balance_threshold_amount),
            }
        } else {
            api_input = {
                "low_balance_notification_status": 'no', //change later with no status 
                "low_balance_threshold_amount": 0,
            }
        }

        setIsLoading(true);
        try {
            const response = await saveLowBalanceSave(api_input)
            const response_data = response.data;


            if (response_data.error_code === 200) {
                const successMessage = isEnabledLBN
                    ? 'Successfully enabled low balance notification!'
                    : 'Successfully disabled low balance notification!';

                triggerAlert('success', 'Success', successMessage);
                setIsLoading(false);
                // resetLBN(data);
                getPaymentSettingDetails();
            } else if (response.status === 204) {
                simpleAlert('Please try after sometime!');
                setIsLoading(false);
            } else {
                triggerAlert('error', '', 'Something went wrong..');
                setIsLoading(false);
            }

        } catch (error) {

            triggerAlert('error', '', 'Something went wrong..');
            setIsLoading(false);
        }

    }
    useEffect(() => {
        getPaymentSettingDetails();
    }, []);


    return (
        <>
            <div class="position-relative">
            </div>
            <div id="content-page" class="content-page">
                <div class="container">
                    <PageTitle heading="Payment Settings"{...props} />
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between">
                                    <div class="header-title">
                                        <h5 class="card-title text-warning">Auto Payment Settings</h5>
                                    </div>
                                </div>
                                <div class="card-body">

                                    <div class="alert bg-soft-success border-0 d-flex align-items-center" role="alert">
                                        <div class="ms-2">
                                            <h5 class="text-primary mb-2 fw-500">Auto Payment Settings</h5>
                                            <p class="mb-0">Automate your payments for convenience and uninterrupted service.</p>
                                            <p class="mb-0"><b>We Suggest :</b> It is advisable to keep the auto payment settings enabled at all times for hassle-free services.</p>
                                            <p class="mb-0">Minimum Threshold Amount: $10</p>
                                            <p>Recharge Amount:$10</p>
                                        </div>
                                    </div>

                                    <form
                                        onSubmit={handleSubmit(handleAutoPaymentSave)}
                                    >

                                        <div class="row">
                                            <div className="col-md-12 mb-3 d-flex align-items-center">
                                                <div className="form-check form-switch form-check-inline mb-3">
                                                    <input
                                                        className="form-check-input form-check-2"
                                                        type="checkbox"
                                                        id="customSwitch1"
                                                        checked={isEnabled}
                                                        onChange={handleSwitchChange}
                                                    />
                                                    <label className="form-check-label ms-2 mt-2" htmlFor="customSwitch1">
                                                        Enable / Disable Auto Payment
                                                    </label>
                                                </div>
                                            </div>
                                            {isEnabled && (
                                                <>
                                                    <div className="col-md-6 mb-3">
                                                        <Form.Group className="my-3">
                                                            <Form.Label>Threshold Amount</Form.Label>
                                                            <Controller
                                                                name="threshold_amount"
                                                                control={control}
                                                                defaultValue=""
                                                                render={({ field }) => (
                                                                    <div>
                                                                        <Form.Control {...field} type="text" placeholder="Enter threshold amount" className="form-control" />
                                                                        {/* {errors.threshold_amount && <span className="text-danger">{errors.threshold_amount.message}</span>} */}
                                                                    </div>
                                                                )}
                                                                rules={{
                                                                    required: 'Threshold amount is required.',
                                                                    pattern: { value: /^\d+$/, message: 'Invalid Amount. Only numbers are allowed.' },
                                                                    validate: value => parseFloat(value) >= 10 || 'The minimum amount required is $10.'
                                                                }}
                                                            />
                                                            {errors.threshold_amount && (
                                                                <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                    {errors.threshold_amount.message}
                                                                </div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <Form.Group className="my-3">
                                                            <Form.Label>Recharge Amount</Form.Label>
                                                            <Controller
                                                                name="auto_recharge_amount"
                                                                control={control}
                                                                defaultValue=""
                                                                render={({ field }) => (
                                                                    <div>
                                                                        <Form.Control {...field} type="text" placeholder="Enter recharge amount" className="form-control" />
                                                                        {/* {errors.auto_recharge_amount && <span className="text-danger">{errors.auto_recharge_amount.message}</span>} */}
                                                                    </div>
                                                                )}
                                                                rules={{
                                                                    required: 'Recharge amount is required.',
                                                                    pattern: { value: /^\d+$/, message: 'Invalid Amount. Only numbers are allowed.' },
                                                                    validate: value => parseFloat(value) >= 10 || 'The minimum amount required is $10.'
                                                                }}
                                                            />
                                                            {errors.auto_recharge_amount && (
                                                                <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                    {errors.auto_recharge_amount.message}
                                                                </div>
                                                            )}
                                                        </Form.Group>

                                                    </div>
                                                </>

                                            )}

                                            <div class="form-group mb-0 text-end">
                                                <button class="btn btn-primary px-5" type="submit">Save</button>
                                            </div>
                                        </div>


                                    </form>



                                </div>
                            </div>
                        </div>
                        {isLoading ? (
                            <div className='loader-overlay'>
                                <Loader />
                            </div>

                        ) : null}
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between">
                                    <div class="header-title">
                                        <h5 class="card-title text-warning">Low Balance Notification</h5>
                                    </div>
                                </div>
                                <div class="card-body">

                                    <div class="alert bg-soft-info border-0 d-flex align-items-center" role="alert">
                                        <div class="ms-2">
                                            <h5 class="text-primary mb-2 fw-500">Low Balance Notification</h5>
                                            <p class="mb-0">Sends a daily alert to the <b>Billing Email Group</b> when your account balance drops below the threshold.</p>
                                            <p class="mb-0"><b>It is Strongly recommended </b>that you use this feature and set the threshold to a level that allows for enough time to post and proccess a payment</p>
                                            <p class="mb-0">Minimum Threshold Amount: 1</p>
                                        </div>
                                    </div>

                                    <form
                                        onSubmit={handleSubmitLBN(handleLowBalanceSave)}
                                    >
                                        <div class="row">
                                            <div className="col-md-12 mb-3 d-flex align-items-center">
                                                <div className="form-check form-switch form-check-inline mb-3">
                                                    <input
                                                        className="form-check-input form-check-2"
                                                        type="checkbox"
                                                        id="LowBalanceNotificationSwitch"
                                                        checked={isEnabledLBN}
                                                        onChange={handleSwitchChangeLBN}
                                                    />
                                                    <label className="form-check-label ms-2 mt-2" htmlFor="LowBalanceNotificationSwitch">
                                                        Enable / Disable Low Balance Notification
                                                    </label>
                                                </div>
                                            </div>
                                            {isEnabledLBN && (

                                                <div className="col-md-6 mb-3">
                                                    <Form.Group className="my-3">
                                                        <Form.Label>Threshold Amount</Form.Label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            {...registerLBN('low_balance_threshold_amount', {
                                                                required: 'Threshold Amount is required',
                                                                pattern: {
                                                                    value: /^[0-9]*$/,
                                                                    message: 'Invalid Amount. Only numbers are allowed.',
                                                                },
                                                                validate: value => parseFloat(value) >= 10 || 'The minimum amount required is $10.',
                                                            })}
                                                            name="low_balance_threshold_amount"
                                                            placeholder="Enter threshold amount"
                                                        />
                                                        {errorsLBN.low_balance_threshold_amount && (
                                                            <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                {errorsLBN.low_balance_threshold_amount.message}
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                    {/* Submit Button */}

                                                </div>

                                            )}
                                            <div className="form-group mb-0 text-end mt-3">
                                                <button className="btn btn-primary px-5" type="submit">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>



                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}
