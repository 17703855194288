import React, { useEffect, useRef, useState } from 'react'
import PageTitle from '../../../common/PageTitle';
import useLocation from '../../../common/components/customhooks/Location';
import InfiniteScrollWrapper from '../../../common/components/InfinityScrollWrapper';
import { downloadFile, extractFileName, formatDateTime, formatTimeToAmandPM, triggerAlert } from '../../../utils/CommonFunctions';
import { fetchWAContactList, fetchWhatsappUserChatHistory, sendWhatsappLocation, sendWhatsappMessage } from '../../../utils/ApiClient';
import SpinnerLoader from '../../../common/components/SpinnerLoader';
import Emojis from '../../../common/components/Emojis';
import { useForm } from 'react-hook-form';
import DynamicLocation from '../../../common/components/DynamicLocation';
import { Modal } from 'react-bootstrap';
import TextToSpeech from '../../../common/components/TextToSpeech';
import LazyLoadImage from '../../../common/components/LazyLoadImage';
import SpeechToText from '../../../common/components/SpeechToText';

export default function WAInbox() {

    const { register, handleSubmit, control, formState: { errors }, reset, watch, clearErrors, setValue, setError, unregister } = useForm();

    const contactsContainerRef = useRef(null);
    const chatContainerRef = useRef(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState("");
    const [showEmojis, setShowEmojis] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const { location, loading, handleGetLocation } = useLocation();
    const [selectKey, setSelectKey] = useState("unread");
    const [isLoadingContacts, setIsLoadingContacts] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [loadingMoreContacts, setLoadingMoreContacts] = useState(false);
    const [activeUserNum, setActiveUserNum] = useState(null); // To track the active chat
    const [activeUserName, setActiveUserName] = useState(null); // To track the active chat
    const [unseenCounts, setUnseenCounts] = useState({});
    const [hasMoreContacts, setHasMoreContacts] = useState(true);

    const [userChatHistory, setUserChatHistory] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);

    const [totalNumberPages, setTotalNumberPages] = useState(0); //To track the contact list

    const [page, setPage] = useState(0); // To track the active chat
    const [totalPages, setTotalPages] = useState(0); // To track the active chat
    const [pageNumber, setPageNumber] = useState(0); // To track the active chat
    const pageSize = 10;

    const [showContactModal, setShowContactModal] = useState(false);


    const [hasMore, setHasMore] = useState(true);
    const handleShowContactModal = () => setShowContactModal(true);
    const handleShowContactCloseModal = () => setShowContactModal(false);

    const handleSelect = (e) => {
        setContactList([])
        const type = e.target.value;
        if (type) {
            setSelectKey(type);
            fetchContacts(type, 1);
        }
    }

    const handleDownload = (url) => {
        const filename = url.substring(url.lastIndexOf('/') + 1);
        downloadFile(url, filename);
    };

    const formReset = () => {
        reset();
    }

    const handleEmojiSelect = (emoji) => {
        console.log("emoji", emoji)
        // Append selected emoji to the message field
        const currentMessage = watch("message") || ""; // Get current message value
        setValue("message", currentMessage + emoji); // Append emoji to the message
    };

    /////////////////////////// speech to text handle function///////////////////////////
    // Function that will handle transcription from child component
    const handleTranscription = (newTranscription) => {
        // setTranscription(newTranscription);
        const prev_message = watch("message");
        setValue("message", prev_message + newTranscription);
        console.log("newTranscription", newTranscription)
    };
    /////////////////////////////////////////////////////////////////////////////////
    const handleContactClick = (activenum, activename) => {
        if (activenum) {
            setActiveUserNum(activenum);
            setActiveUserName(activename);
            // Reset unseen count to 0 when contact is clicked
            setUnseenCounts(prevCounts => ({
                ...prevCounts,
                [activenum]: 0
            }));
            // updateSeenStatus(activenum);
            fetchWhatsappChatHistory(1, activenum);
            formReset();
        }
    }
    /////////////////////////left sidebar functions///////////////////////////////////////
    const fetchContacts = async (type, pageNumber, searchkey) => {
        console.log('pageNumber_fetching', pageNumber);
        // setIsLoadingContacts(true);
        try {
            const params = {
                select_key: type,
                page_number: pageNumber,
                page_size: pageSize,
                keyword: searchkey ? searchkey : ""
            }

            const response = await fetchWAContactList(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                const total_pages = response_data.results.pagination.total_pages;
                setTotalNumberPages(total_pages);
                setIsLoadingContacts(false);
                const sortedContacts = [...items].reverse();

                if (pageNumber === 1) {
                    setContactList(sortedContacts);
                }
                return sortedContacts;
                // triggerAlert('success', 'success', 'Recharged Successfully!!');
            } else {
                setIsLoadingContacts(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoadingContacts(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }
    const fetchMoreContacts = async () => {
        try {
            // Fetch data for the next page
            const nextPageNumber = pageNumber + 1;
            const nextPageNumberData = await fetchContacts(selectKey, nextPageNumber);

            // Check if the returned data is an array
            if (Array.isArray(nextPageNumberData)) {
                // Append the new data to the existing number list
                setContactList(prevNumberList => [...prevNumberList, ...nextPageNumberData]);

                // Increment the page number only after successful fetch
                setPageNumber(nextPageNumber);
            } else {
                console.error('The fetched data is not an array:', nextPageNumberData);
                // Handle the case where the data is not an array                
            }

        } catch (error) {
            console.error('Failed to fetch more data:', error);
        }
    };
    const handleFetchMoreContacts = async () => {

        if (pageNumber <= totalNumberPages) {

            // Preserve current scroll position
            const currentScrollHeight = contactsContainerRef.current.scrollHeight;
            const currentScrollTop = contactsContainerRef.current.scrollTop;

            setLoadingMoreContacts(true);
            await fetchMoreContacts(); // Fetch more data for the next page
            setLoadingMoreContacts(false);
            // Calculate new scroll position
            const newScrollHeight = contactsContainerRef.current.scrollHeight;

            // Scroll only if the user was at the bottom before loading more data
            if (currentScrollTop + contactsContainerRef.current.clientHeight >= currentScrollHeight) {
                contactsContainerRef.current.scrollTop = newScrollHeight;
            } else {
                // Preserve the current scroll position if the user was not at the bottom
                contactsContainerRef.current.scrollTop = newScrollHeight - (currentScrollHeight - currentScrollTop);
            }
            // Check if the next page will be the last
            if (pageNumber + 1 === totalNumberPages) {
                setHasMoreContacts(false); // No more pages to load
            }
        } else {
            //console.log('else');
            alert('No more pages to load');
            setHasMoreContacts(false); // No more pages to load
        }
    };
    ////////////////////////// right sidebar functions ///////////////////////////////
    const fetchWhatsappChatHistory = async (page, usernum) => {
        setIsLoading(true);
        if (page) setPage(page);

        try {
            const params = {
                page: page,
                page_size: pageSize,
                user_number: usernum,
            };
            const response = await fetchWhatsappUserChatHistory(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                const total_pages = response_data.results.pagination.total_pages;
                setTotalPages(total_pages)
                setIsLoading(false);
                const sortedChatHistory = [...items].reverse();
                if (page === 1) {
                    setUserChatHistory(sortedChatHistory);
                }
                return sortedChatHistory; // Return the sorted data
            } else {
                setIsLoading(false);
                return []; // Return an empty array on error
            }
        } catch (error) {
            setIsLoading(false);
            const response_data = error?.response?.data;
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
            return []; // Return an empty array on exception
        }
    };
    ////////////////////// Send Whatsapp message function //////////////////////////////////
    const sendWhatsappMessageAPI = async (data) => {
        // return
        setIsLoading(true);

        try {
            const params = {
                // person_name:activeUserName,
                // to_number: [activeUserNum], 
                person_name: "Amritha Sunny",
                to_number: "917356889520",
                ...data
            }

            // if (data.msg_type == 'MMS') params.base64_files = mmsFile;
            const response = await sendWhatsappMessage(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;

                // setMMSFile({})
                setIsLoading(false);

                // fetchChatHistory(1, activeUserNum)
                // triggerAlert('success', 'success', 'Recharged Successfully!!');
            } else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }

    }
    ////////////////////// Send Location ///////////////////////////////////
    const sendWhatsappLocationAPI = async () => {
        // return
        setIsLoading(true);

        try {
            const params = {
                // person_name:activeUserName,
                // to_number: [activeUserNum], 
                person_name: "Amritha Sunny",
                to_number: "917356889520",
                latitude: location?.latitude,
                longitude: location?.longitude,

            }

            // if (data.msg_type == 'MMS') params.base64_files = mmsFile;
            const response = await sendWhatsappLocation(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;

                // setMMSFile({})
                setIsLoading(false);

                // fetchChatHistory(1, activeUserNum)
                // triggerAlert('success', 'success', 'Recharged Successfully!!');
            } else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }

    }

    const fetchMoreData = async () => {
        try {
            // Wait for the data from the next page
            const nextPageData = await fetchWhatsappChatHistory(page + 1, activeUserNum);

            // Append the new data to the existing chat history
            setUserChatHistory(prevChatHistory => [...nextPageData, ...prevChatHistory]);
            // Increment the page number
            setPage(prevPage => prevPage + 1);
        } catch (error) {
            console.error('Failed to fetch more data:', error);
        }
    };
    const handleFetchMoreData = async () => {
        if (page < totalPages) {
            // Preserve current scroll position
            const currentScrollHeight = chatContainerRef.current.scrollHeight;
            const currentScrollTop = chatContainerRef.current.scrollTop;

            setLoadingMore(true);
            await fetchMoreData(page + 1); // Fetch more data for the next page
            setLoadingMore(false);

            // Calculate new scroll position
            const newScrollHeight = chatContainerRef.current.scrollHeight;
            chatContainerRef.current.scrollTop = newScrollHeight - (currentScrollHeight - currentScrollTop);
        } else {
            setHasMore(false); // No more pages to load
        }
    };
    useEffect(() => {
        if (initialLoad) {
            // Scroll to the bottom when the component mounts
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            }
            setInitialLoad(false);
        }
    }, [userChatHistory, initialLoad]);

    useEffect(() => {
        setInterval(function time() {
            var d = new Date();
            var hours = 24 - d.getHours();
            var min = 60 - d.getMinutes();
            if ((min + '').length == 1) {
                min = '0' + min;
            }
            var sec = 60 - d.getSeconds();
            if ((sec + '').length == 1) {
                sec = '0' + sec;
            }
            setTimer(hours + ':' + min + ':' + sec)
        }, 1000);
        fetchContacts(selectKey);
    }, [])
    useEffect(() => {
        if (location) {
            // Once the location is available, call the API
            sendWhatsappLocationAPI(location.latitude, location.longitude);
        }
    }, [location]);
    return (
        <main class="main-content mt-3 mb-4">
            <div class="container content-inner" id="page_layout">
                <PageTitle heading="Inbox" showWarningButton="Bulk Upload" showPrimaryButton="Create Contact" />
                <div class="row w-100">
                    <div class="col-md-3">
                        <aside class="sidebar-chat sidebar-base border-end shadow-none  rounded-2" data-sidebar="responsive">
                            <div class="chat-search pt-3 px-3 ">
                                <div class="chat-searchbar mt-4 mb-2 ">
                                    <div class="form-group chat-search-data m-0">
                                        <input type="text" class="form-control round" id="chat-search" placeholder="Search" />
                                        <i class="material-symbols-outlined">
                                            search
                                        </i>
                                    </div>
                                </div>
                                <div class="select-chat mt-3">
                                    <select class="form-select" id="exampleFormControlSelect1" onChange={handleSelect} value={selectKey}>
                                        <option value="all">All chats</option>
                                        <option value="read">Read</option>
                                        <option value="unread">Unread</option>
                                        <option value="hrs"  >Last 24 hours</option>
                                        {/* <option value="exp" class="text-danger">Expired</option> */}
                                    </select>
                                </div>
                            </div>
                            <div class="sidebar-body pt-0 data-scrollbar chat-group mb-5 pb-5 pe-2"
                                id="scrollableDivContacts" ref={contactsContainerRef}
                                style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}
                            >
                                {/* <!-- Sidebar Menu Start --> */}
                                <ul class="nav navbar-nav iq-main-menu  mt-3" id="sidebar-menu" role="tablist">
                                    <InfiniteScrollWrapper
                                        dataLength={contactList.length}
                                        next={handleFetchMoreContacts}
                                        hasMore={hasMoreContacts} // Stop loading more data when false
                                        inverse={false} // This ensures the scroll direction is "up"
                                        loader={null}
                                        scrollableTarget="scrollableDivContacts"
                                    >
                                        {loadingMoreContacts && <h4 className='text-center text-danger'><SpinnerLoader /></h4>}
                                        {contactList.length > 0 ?
                                            contactList.map((item, index) => (
                                                <li key={item.from_no} class={`nav-item iq-chat-list ${activeUserNum == item.from_no ? 'active' : ''}`} onClick={() => handleContactClick(item.from_no, item.contact_name)}>
                                                    <a href={`#user-content-${activeUserNum}`} class={`nav-link  d-flex gap-1 ${activeUserNum == item.from_no ? 'active' : ''}`} data-bs-toggle="tab" role="tab" aria-controls={`#user-content-${activeUserNum}`} aria-selected="true">
                                                        <div class="position-relative">
                                                            <span class="badge badge-pill bg-soft-danger font-weight-normal ms-auto me-1 badge-45 md-14 rounded-circle p-2 ">{item.contact_name ? item.contact_name[0] : '-'}</span>
                                                        </div>
                                                        <div class="d-flex align-items-center w-100 iq-userlist-data">
                                                            <div class="d-flex flex-grow-1 flex-column">
                                                                <div class="d-flex align-items-center gap-1">
                                                                    <p class="mb-0 text-ellipsis short-1 flex-grow-1 iq-userlist-name fw-500">{item.contact_name ? item.contact_name : '-'}</p>
                                                                    <small class="text-capitalize">
                                                                        {item.created_at ? formatDateTime(item.created_at, 'yyyy-mm-dd') : '-'}, <br />
                                                                        {item.created_at ? formatDateTime(item.created_at, 'hh:mm:ss') : '-'}
                                                                    </small>
                                                                    {/* <small class="text-capitalize">5min</small> */}
                                                                </div>
                                                                <div class="d-flex align-items-center gap-2">
                                                                    <small class="text-ellipsis short-1 flex-grow-1 chat-small">{item.from_no ? item.from_no : '-'}</small>
                                                                    {/* {item.un_seen_count != null &&
                                                                        <span class="badge rounded-pill bg-success badge-30">{item.un_seen_count ? item.un_seen_count : '0'}</span>
                                                                    } */}
                                                                    {unseenCounts[item.from_no] > 0 && (
                                                                        <span className="badge rounded-pill bg-success badge-30">
                                                                            {unseenCounts[item.from_no]}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            )) : <p className='text-center'>No contacts found!</p>}

                                    </InfiniteScrollWrapper>

                                </ul>
                                {/* <!-- Sidebar Menu End --> */}
                            </div>
                        </aside>
                    </div>
                    <div class="col-md-9">
                        <div class="tab-content" id="myTabContent">
                            <div class="card tab-pane mb-0 fade show active" id="user-content-101" role="tabpanel">
                                {userChatHistory.length > 0 ?
                                    <>
                                        <div class="chat-head">
                                            <header class="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3 border-bottom rounded-top">
                                                <div class="d-flex align-items-center">
                                                    <div class="position-relative">
                                                        <span class="badge badge-pill bg-soft-danger font-weight-normal ms-auto me-1 badge-45 md-14 rounded-circle p-2 ">{activeUserName ? activeUserName[0] : '-'}</span>
                                                    </div>
                                                    <div class="d-flex align-items-center w-100 iq-userlist-data">
                                                        <div class="d-flex flex-grow-1 flex-column">
                                                            <div class="d-flex align-items-center h-19">
                                                                <p class="mb-0 text-ellipsis short-1 flex-grow-1 iq-userlist-name fw-500">{activeUserName ? activeUserName : '-'}</p>
                                                                <a href="#" class="btn btn-icon btn-soft-success btn-sm ms-3 rounded-pill" data-bs-toggle="modal" data-bs-target="#exampleModalCenter-view">
                                                                    <span class="btn-inner">
                                                                        <i class="material-symbols-outlined md-18"> visibility</i>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div class="d-flex align-items-center gap-2">
                                                                <small class="text-ellipsis short-1 flex-grow-1 chat-small">{activeUserNum ? activeUserNum : '-'}</small>
                                                                {/* <!--  <span class="badge rounded-pill bg-success badge-30">20</span> --> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat-header-icons d-inline-flex ms-auto">
                                                    <div id="the-final-countdown" class="color-full">
                                                        <p style={{ marginBottom: 0 }}>{timer}</p>
                                                        <h5 class="text-center  time-remain"  >TIME REMAINING</h5>

                                                    </div>
                                                </div>
                                            </header>
                                        </div>
                                        <div class="card-body chat-body inbox-body bg-body">
                                            <div
                                                id="scrollableDiv"
                                                ref={chatContainerRef}
                                                style={{ overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }} // column-reverse to start at the bottom
                                            >
                                                <InfiniteScrollWrapper
                                                    dataLength={userChatHistory.length}
                                                    next={handleFetchMoreData}
                                                    hasMore={hasMore} // Stop loading more data when false
                                                    inverse={true} // This ensures the scroll direction is "up"
                                                    loader={null}
                                                    scrollableTarget="scrollableDiv"
                                                >
                                                    {loadingMore && <h4 className='text-center text-danger'><SpinnerLoader /></h4>}
                                                    {userChatHistory.map((item, index) => {
                                                        // Format the date of the current message
                                                        const messageDate = formatDateTime(item.created_at, 'mm-dd-yyyy');
                                                        // Get the date of the previous message (or null if it's the first message)
                                                        const prevMessageDate = index > 0 ? formatDateTime(userChatHistory[index - 1].create_date, 'mm-dd-yyyy') : null;
                                                        return (
                                                            <React.Fragment key={item.whats_app_id}>
                                                                {/* Render the date header if it's the first message or the date has changed */}
                                                                {(index === 0 || messageDate !== prevMessageDate) && (
                                                                    <div className="chat-day-title">
                                                                        <span className="main-title">{messageDate}</span>
                                                                    </div>
                                                                )}
                                                                {item.direction == 'IN' ?
                                                                    <div class="iq-message-body iq-other-user  gap-0">
                                                                        <div class="chat-profile">
                                                                            <span class="badge badge-pill bg-soft-info font-weight-normal ms-auto me-2 badge-45 md-14 rounded-circle p-2 "><span class="material-symbols-outlined">person_outline</span></span>
                                                                        </div>

                                                                        <div class="iq-chat-text">
                                                                            <div class="d-flex align-items-center justify-content-start">
                                                                                <div class="iq-chating-content ">
                                                                                    <div class="d-flex align-items-center gap-1">
                                                                                        <p class="mb-0 text-ellipsis short-1 flex-grow-1 iq-userlist-chat text-info">{item.from_no ? item.from_no : '-'}</p>
                                                                                        <div class="chat-lft p-1">
                                                                                            <div class="dropdown-container">
                                                                                                <div class="dropdown chat-drop-l">
                                                                                                    <span class="material-symbols-outlined fs-2" id="dropdownMenuButton9 " data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                                                                                        keyboard_arrow_down
                                                                                                    </span>
                                                                                                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton9">
                                                                                                        <a class="dropdown-item" href="javascript:void(0);">Replay</a>
                                                                                                        <a class="dropdown-item" href="javascript:void(0);">Forward</a>
                                                                                                        <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {item.message_type == "text" ?
                                                                                        <p class="mr-2 mb-3">{item.message ? item.message : null}
                                                                                        </p>
                                                                                        : item.message_type == "image" ?
                                                                                            <div>
                                                                                                <img src="https://customer.vitelglobal.com/plugin/images/vitel-login.png" class="img-fluid rounded" alt="chat-img" style={{ width: '316px' }} />
                                                                                            </div>
                                                                                            : item.message_type == "video" ?
                                                                                                <div>
                                                                                                    <div id="trailer" class="section d-flex justify-content-center embed-responsive embed-responsive-4by3">
                                                                                                        <video class="embed-responsive-item" controls="">
                                                                                                            <source src="assets/TMMTrailer18.mp4" type="video/mp4" />
                                                                                                            Your browser does not support the video tag.
                                                                                                        </video>
                                                                                                    </div>
                                                                                                </div>
                                                                                                : item.message_type == "contacts" ?
                                                                                                    <div>
                                                                                                        <div class="d-flex justify-content-between  ">
                                                                                                            <div class="chat-profile">
                                                                                                                <span class="badge badge-pill bg-soft-danger font-weight-normal ms-auto me-2 badge-45 md-14 rounded-circle p-2 "><span class="material-symbols-outlined">person_outline</span></span>
                                                                                                            </div>
                                                                                                            <div class="w-100 ">
                                                                                                                <div class="">
                                                                                                                    <h5 class=" me-1 pe-5 send-cntct">{item.message ? item.message : "-"}</h5>
                                                                                                                </div>
                                                                                                                <p class="mb-0">{item.image_id ? item.image_id : "-"}</p>
                                                                                                                <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <hr class="my-1" />
                                                                                                        <div class="mb-2">
                                                                                                            {/* <div class="d-flex justify-content-between align-items-center">
                                                                                                        <div class="d-flex align-items-center me-3">
                                                                                                            <a href="#/">    <span class="card-text-1 ms-1">Message</span></a>
                                                                                                        </div>
                                                                                                        <div class="d-flex align-items-center me-3">
                                                                                                            <a href="#/"><span class="card-text-1 ms-1">Add a group</span></a>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                            <span class="card-text-2">
                                                                                                                5.2k people love it
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    : null}


                                                                                    <div class="chat-time-position">
                                                                                        <div class="chat-time-right">
                                                                                            <small class="text-capitalize">{item.created_at ? formatTimeToAmandPM(item.created_at) : '-'}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {(item.message_type == "text" || item.message_type == "contacts") &&
                                                                                    <TextToSpeech text={item.message} />
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div class="iq-message-body iq-current-user">
                                                                        <div class="iq-chat-text">
                                                                            <div class="d-flex align-items-center justify-content-end">
                                                                                <div class="iq-chating-content  ">
                                                                                    {(item.message_type == "text" || item.message_type == "contacts") &&
                                                                                        <TextToSpeech text={item.message} />
                                                                                    }
                                                                                    <div class="d-flex align-items-center gap-1">
                                                                                        <p class="mb-0 text-ellipsis short-1 flex-grow-1 iq-userlist-chat text-secondary">You</p>
                                                                                        <div class="chat-right ">
                                                                                            <div class="dropdown-container">

                                                                                                <div class="dropdown chat-drop">
                                                                                                    <span class="material-symbols-outlined fs-2" id="dropdownMenuButton9 " data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                                                                                        keyboard_arrow_down
                                                                                                    </span>
                                                                                                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton9" >
                                                                                                        <a class="dropdown-item " href="javascript:void(0);">Replay</a>
                                                                                                        <a class="dropdown-item " href="javascript:void(0);">Forward</a>
                                                                                                        <a class="dropdown-item " href="javascript:void(0);">Delete</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {item.message_type == "text" ?
                                                                                        <p class="mr-2 mb-1 px-1">{item.message ? item.message : null}
                                                                                        </p>
                                                                                        : item.message_type == "image" ?
                                                                                            <div>
                                                                                                <img src="https://customer.vitelglobal.com/plugin/images/vitel-login.png" class="img-fluid rounded" alt="chat-img" style={{ width: '316px' }} />
                                                                                            </div>
                                                                                            : item.message_type == "video" ?
                                                                                                <div>
                                                                                                    <div id="trailer" class="section d-flex justify-content-center embed-responsive embed-responsive-4by3">
                                                                                                        <video class="embed-responsive-item" controls="">
                                                                                                            <source src="assets/TMMTrailer18.mp4" type="video/mp4" />
                                                                                                            Your browser does not support the video tag.
                                                                                                        </video>
                                                                                                    </div>
                                                                                                </div>
                                                                                                : item.message_type == "contacts" ?
                                                                                                    <div>
                                                                                                        <div class="d-flex justify-content-between  ">
                                                                                                            <div class="chat-profile">
                                                                                                                <span class="badge badge-pill bg-soft-danger font-weight-normal ms-auto me-2 badge-45 md-14 rounded-circle p-2 "><span class="material-symbols-outlined">person_outline</span></span>
                                                                                                            </div>
                                                                                                            <div class="w-100 ">
                                                                                                                <div class="">
                                                                                                                    <h5 class=" me-1 pe-5 send-cntct">{item.message ? item.message : "-"}</h5>
                                                                                                                </div>
                                                                                                                <p class="mb-0">{item.image_id ? item.image_id : "-"}</p>
                                                                                                                <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <hr class="my-1" />
                                                                                                        <div class="mb-2">
                                                                                                            {/* <div class="d-flex justify-content-between align-items-center">
                                                                                                        <div class="d-flex align-items-center me-3">
                                                                                                            <a href="#/">    <span class="card-text-1 ms-1">Message</span></a>
                                                                                                        </div>
                                                                                                        <div class="d-flex align-items-center me-3">
                                                                                                            <a href="#/"><span class="card-text-1 ms-1">Add a group</span></a>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                            <span class="card-text-2">
                                                                                                                5.2k people love it
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    : null}
                                                                                    <div class="d-flex justify-content-end">
                                                                                        <div style={{ width: "49px" }}>
                                                                                            <small class="text-capitalize">{item.created_at ? formatTimeToAmandPM(item.created_at) : '-'}</small>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </React.Fragment>
                                                        )
                                                    }
                                                    )}
                                                </InfiniteScrollWrapper>
                                            </div>
                                        </div>
                                        <div class="card-footer px-3 py-3 border-top rounded-0">
                                            <form onSubmit={handleSubmit(sendWhatsappMessageAPI)}>
                                                <div class="d-flex align-items-center" >
                                                    <div class="chat-attagement d-flex">
                                                        <a href="#/" class="d-flex align-items-center pe-3" onClick={() => setShowEmojis(!showEmojis)}>
                                                            <svg class="icon-24" width="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_156_599)">
                                                                    <path d="M20.4853 4.01473C18.2188 1.74823 15.2053 0.5 12 0.5C8.79469 0.5 5.78119 1.74823 3.51473 4.01473C1.24819 6.28119 0 9.29469 0 12.5C0 15.7053 1.24819 18.7188 3.51473 20.9853C5.78119 23.2518 8.79469 24.5 12 24.5C15.2053 24.5 18.2188 23.2518 20.4853 20.9853C22.7518 18.7188 24 15.7053 24 12.5C24 9.29469 22.7518 6.28119 20.4853 4.01473ZM12 23.0714C6.17091 23.0714 1.42856 18.3291 1.42856 12.5C1.42856 6.67091 6.17091 1.92856 12 1.92856C17.8291 1.92856 22.5714 6.67091 22.5714 12.5C22.5714 18.3291 17.8291 23.0714 12 23.0714Z" fill="currentcolor"></path>
                                                                    <path d="M9.40398 9.3309C8.23431 8.16114 6.33104 8.16123 5.16136 9.3309C4.88241 9.60981 4.88241 10.0621 5.16136 10.3411C5.44036 10.62 5.89266 10.62 6.17157 10.3411C6.78432 9.72836 7.78126 9.7284 8.39392 10.3411C8.53342 10.4806 8.71618 10.5503 8.89895 10.5503C9.08171 10.5503 9.26457 10.4806 9.40398 10.3411C9.68293 10.0621 9.68293 9.60986 9.40398 9.3309Z" fill="currentcolor"></path>
                                                                    <path d="M18.8384 9.3309C17.6688 8.16123 15.7655 8.16114 14.5958 9.3309C14.3169 9.60981 14.3169 10.0621 14.5958 10.3411C14.8748 10.62 15.3271 10.62 15.606 10.3411C16.2187 9.72836 17.2156 9.72831 17.8284 10.3411C17.9679 10.4806 18.1506 10.5503 18.3334 10.5503C18.5162 10.5503 18.699 10.4806 18.8384 10.3411C19.1174 10.0621 19.1174 9.60986 18.8384 9.3309Z" fill="currentcolor"></path>
                                                                    <path d="M18.3335 13.024H5.6668C5.2723 13.024 4.95251 13.3438 4.95251 13.7383C4.95251 17.6243 8.11409 20.7859 12.0001 20.7859C15.8862 20.7859 19.0477 17.6243 19.0477 13.7383C19.0477 13.3438 18.728 13.024 18.3335 13.024ZM12.0001 19.3573C9.14366 19.3573 6.77816 17.215 6.42626 14.4525H17.574C17.2221 17.215 14.8566 19.3573 12.0001 19.3573Z" fill="currentcolor"></path>
                                                                </g>
                                                                <defs>
                                                                    <clipPath>
                                                                        <rect width="24" height="24" fill="white" transform="translate(0 0.5)"></rect>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </a>
                                                        <div class="card-header-toolbar pe-3">
                                                            <div class="dropdown">
                                                                <div class="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                                                    <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="icon-24" fill="none">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 13.2501L20.5 10.7501L13.25 10.7501L13.25 3.5L10.75 3.5L10.75 10.7501L3.5 10.7501L3.5 13.2501L10.75 13.2501L10.75 20.5L13.25 20.5L13.25 13.2501L20.5 13.2501Z" fill="currentColor"></path>
                                                                    </svg>
                                                                </div>
                                                                <div class="dropdown-menu dropdown-menu-right dropdown-upload" aria-labelledby="dropdownMenuButton" >
                                                                    <a class="dropdown-item d-flex align-items-center" href="#/"><span class="material-symbols-outlined me-2 md-18">
                                                                        file_upload
                                                                    </span>Upload files</a>
                                                                    <a class="dropdown-item d-flex align-items-center" href="#/"><span class="material-symbols-outlined me-2 md-18">
                                                                        volume_up
                                                                    </span>Audio</a>
                                                                    <a class="dropdown-item d-flex align-items-center" href="#/"><span class="material-symbols-outlined me-2 md-18">
                                                                        smart_display
                                                                    </span>Video</a>
                                                                    <a class="dropdown-item d-flex align-items-center" href="#/" onClick={handleGetLocation}><span class="material-symbols-outlined me-2 md-18">
                                                                        location_on
                                                                    </span>Location</a>
                                                                    <a class="dropdown-item d-flex align-items-center" href="#/" onClick={handleShowContactModal}><span class="material-symbols-outlined me-2 md-18">
                                                                        contacts
                                                                    </span>Contacts</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control me-3" placeholder="Type your message" name='message'  {...register("message", {
                                                        required: "Message is required",
                                                    })} />
                                                    {/* {location &&
                                                <div>
                                                    Current Location: Latitude: {location.latitude}, Longitude: {location.longitude}
                                                </div>
                                            } */}
                                                    <div class="chat-attagement">
                                                        <SpeechToText onTranscription={handleTranscription} />
                                                    </div>
                                                    <button type="submit" class="btn btn-primary d-flex align-items-center">
                                                        <svg class="icon-20" width="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.8325 6.67463L8.10904 12.4592L1.59944 8.38767C0.66675 7.80414 0.860765 6.38744 1.91572 6.07893L17.3712 1.55277C18.3373 1.26963 19.2326 2.17283 18.9456 3.142L14.3731 18.5868C14.0598 19.6432 12.6512 19.832 12.0732 18.8953L8.10601 12.4602" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </svg>
                                                        <span class="d-none d-lg-block ms-1">Send</span>
                                                    </button>
                                                </div>
                                                <div className='mt-2'>
                                                    {showEmojis &&
                                                        <Emojis onEmojiSelect={handleEmojiSelect} pickerSize={{ height: 400, width: 935 }} />
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                    :
                                    <div class="card-body chat-body inbox-body bg-body">
                                        <div className='d-flex justify-content-center flex-column align-items-center'>


                                            {/* <img src='/assets/images/Inbox.jpg' alt='inbox' /> */}
                                            <LazyLoadImage src='/assets/images/Inbox.jpg' alt='inbox' />
                                            <p className='text-center'>Please select any one contact and view</p>
                                        </div>
                                    </div>

                                }
                            </div>

                        </div >
                    </div >
                </div >

            </div >

            <Modal show={showContactModal} onHide={handleShowContactCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Send Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div class="chat-searchbar">
                            <div class="form-group chat-search-data m-0">
                                <input type="text" class="form-control round" id="chat-search" placeholder="Search" />
                                <i class="material-symbols-outlined">
                                    search
                                </i>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                                <span class="required">Phone No<span class="text-danger">*</span></span>
                            </label>
                            <input type="text" class="form-control" placeholder="201-555-0123" />
                        </div>

                        <div class="form-group">
                            <label class="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                                <span class="required">Template<span class="text-danger">*</span></span>
                            </label>
                            <select class="form-select" id="exampleFormControlSelect1">
                                <option value="">Select your template</option>
                                <option value="template_testing">Template testing</option>
                                <option value="test">Test</option>
                                <option value="for_this_template">For this template</option>
                                <option value="marketing">Marketing</option>
                                <option value="newviteltemppromo">Newviteltemppromo</option>
                                <option value="vitelglobal_1">Vitelglobal 1</option>
                                <option value="test_temolate">Test temolate</option>
                                <option value="welcome_message">Welcome message</option>
                                <option value="test_temp">Test temp</option>
                                <option value="hello_world">Hello world</option>
                            </select>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


            <div class="modal fade" id="exampleModalCenter-view" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">View Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="text-center">
                                <div class="position-relative">
                                    <span class="badge badge-pill bg-soft-danger  badge-60 rounded-circle p-2 ">A</span>
                                </div>
                                <h4 class="mt-2 mb-4">+918179356796</h4>
                                <div class="d-flex  justify-content-around mb-3 align-items-center">
                                    <div class="me-20">
                                        <div class="fw-500 text-muted">Marketing opt-out</div>
                                    </div>

                                    <label class="form-check form-switch form-check-custom form-check-solid">
                                        <input class="form-check-input form-check-2" type="checkbox" id="status" />

                                    </label>
                                    {/* <!--end::Switch--> */}
                                </div>
                                <p class="text-danger">understand that it's our responsibility to stop sending marketing messages to customers who opt out. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </main >
    )
}
