import React, { useState } from 'react'
import PageTitle from '../../common/PageTitle'


import Invite from '../../pages/Profile/Invite';
import Members from '../../pages/Profile/Members';
import Permissions from '../../pages/Profile/Permissions';

export default function ManageUsers() {
    const heading = "Manage Users";
    const [activeTab, setActiveTab] = useState('Invite');



    return (
        <div id="content-page" class="content-page">
            <div class="container">
                <PageTitle heading={heading} />



                <div class="row mb-5">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header border-0">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className={`nav-link ${activeTab === 'Invite' ? 'active' : ''}`}
                                            id="pills-Invite-tab-fill"
                                            data-bs-toggle="pill"
                                            href="#pills-Invite-fill"
                                            role="tab"
                                            aria-controls="pills-Invite-fill"
                                            aria-selected={activeTab === 'Invite'}
                                            onClick={() => setActiveTab('Invite')}
                                        ><i className="fa fa-user-plus" aria-hidden="true"></i>
                                            <span className="ms-2">Invite</span></a>

                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className={`nav-link ${activeTab === 'Members' ? 'active' : ''}`}
                                            data-bs-toggle="pill"
                                            href="#pills-Members-fill"
                                            role="tab"
                                            aria-controls="pills-Members-fill"
                                            aria-selected={activeTab === 'Members'}
                                            onClick={() => setActiveTab('Members')}
                                        ><i className="fa fa-users" aria-hidden="true"></i>

                                            <span className="ms-2">Members</span></a>
                                    </li>
                                </ul>


                            </div>
                            <div class="card-body ">
                                <div class="tab-content" id="pills-tabContent-1">

                                    <div className={`tab-pane ${activeTab === 'Invite' ? 'active show' : ''}`} id="Invite" role="tabpanel">
                                        {activeTab === 'Invite' && <Invite />}
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'Members' ? 'active show' : ''}`} id="Members" role="tabpanel">
                                        {activeTab === 'Members' && <Members />}
                                    </div>
                                    {/* <div className={`tab-pane ${activeTab === 'Permissions' ? 'active show' : ''}`} id="Permissions" role="tabpanel">
                                        <Permissions />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}