import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import PageTitle from '../../../common/PageTitle';
import { MaxLengthValidation, MinLengthValidation, onlyAlphaNumericSpaces } from '../../../utils/Constants';
import { Modal } from 'react-bootstrap';
import { createCampaign } from '../../../utils/ApiClient';
import { triggerAlert } from '../../../utils/CommonFunctions';
import LazyLoadImage from '../../../common/components/LazyLoadImage';


export default function Campaign() {

    ///////////////////////// Basic form /////////////////////////////////////////
    const { register, handleSubmit, formState: { errors }, setValue, reset, control: control, clearErrors, getValues, setError, watch } = useForm({
        defaultValues: {
            subscriberOptin_value: "yes",
            subscriberOptout_value: "yes",
            subscriberHelp_value: "yes",
            numberPool_value: "yes",
            directLending_value: "yes",
            embeddedLink_value: "yes",
            embeddedPhone_value: "yes",
            affiliateMarketing_value: "yes",
            ageGated: "yes",
        }
    });

    const [activeUserNum, setActiveUserNum] = useState(null); // To track the active chat
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };

    const Addcampaign = async (data) => {

        console.log("Data", data)
        // Create a new FormData instance
        const formData = new FormData();

        // Iterate over each key-value pair in the data object
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("profile_id", "");
        formData.append("sample2_value", "");
        formData.append("sample3_value", "");
        formData.append("sample4_value", "");
        formData.append("sample5_value", "");
        formData.append("subUsecases_value", "dasas");
        console.log("Data", data, formData)

        // Log FormData contents to check if values are appended correctly
        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }
        setIsLoading(true);
        try {

            const response = await createCampaign(formData);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                handleClose();
                triggerAlert('success', 'success', 'Campaign created Successfully!!');
                reset();
            } else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    };
    return (
        <main class="main-content mt-3 mb-4">

            <div class="container content-inner  " id="page_layout">

                <PageTitle heading="Campaign" showPrimaryButton="Create Campaign" onPrimaryClick={handleShow} />

                <div class="row w-100">
                    <div class="col-md-3">
                        <aside class="sidebar-chat sidebar-base border-end shadow-none  rounded-2" data-sidebar="responsive">
                            <div class="chat-search pt-3 px-3 ">
                                <div class="chat-searchbar mt-4 mb-2 ">
                                    <div class="form-group chat-search-data m-0">
                                        <input type="text" class="form-control round" id="chat-search" placeholder="Search" />
                                        <i class="material-symbols-outlined">
                                            search
                                        </i>
                                    </div>
                                </div>

                            </div>
                            <div class="sidebar-body pt-0 data-scrollbar chat-group mb-5 pb-5 pe-2">


                                {/* <!-- Sidebar Menu End --> */}
                            </div>
                        </aside>
                    </div>
                    <div class="col-md-9">
                        <div class="tab-content" id="myTabContent">
                            <div class="card tab-pane mb-0 fade show active" id={`#user-content-${activeUserNum}`} role="tabpanel">
                                {/* {userChatHistory.length > 0 ?
 
                             <> */}

                                <div class="card-body chat-body inbox-body bg-body">
                                    <div className='d-flex justify-content-center flex-column align-items-center'>
                                        {/* <img src='/assets/images/Inbox.jpg' alt='inbox' /> */}
                                        <LazyLoadImage src='/assets/images/Campaign.jpg' alt='Campaign' />
                                        <p className='text-center'>Please create a campaign to view</p>
                                    </div>
                                </div>

                                {/* </>
 
                             : <NoMessages />
                         } */}
                            </div>
                        </div >
                    </div >
                </div >

            </div >

            {/* Add Campaign Modal Start*/}

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Create Campaign</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit(Addcampaign)}>
                    <Modal.Body>
                        <div class="row">


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="companyName">Campaign Name<span className="text-danger">*</span></label>
                                    <input type="text" class="form-control"
                                        name="campaign_name"
                                        id="campaign_name"
                                        placeholder="Enter Campaign name"
                                        {...register("campaign_name", {
                                            required: "Company name is required",
                                            pattern: onlyAlphaNumericSpaces,
                                            minLength: MinLengthValidation(6),
                                            maxLength: MaxLengthValidation(100)
                                        })} />
                                    {errors.campaign_name && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {errors.campaign_name.message}
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="companyName">Brand Name<span className="text-danger">*</span></label>

                                    <select className="form-select" id="exampleFormControlSelect1" name="brandId"
                                        {...register("brandId", {
                                            required: "Brand is required",
                                        })}
                                        autoComplete="off" >
                                        <option value="" hidden>Select</option>
                                        <option value="BASIC_ACCOUNT">BASIC_ACCOUNT</option>
                                        <option value="SMALL_ACCOUNT">SMALL_ACCOUNT</option>
                                        <option value="MEDIUM_ACCOUNT">MEDIUM_ACCOUNT</option>
                                        <option value="LARGE_ACCOUNT">LARGE_ACCOUNT</option>
                                        <option value="KEY_ACCOUNT">KEY_ACCOUNT</option>

                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="companyName">Use Cases<span className="text-danger">*</span></label>

                                    <select className="form-select" id="exampleFormControlSelect1" name="usecase"
                                        {...register("usecase", {
                                            required: "Usecase is required",
                                        })}
                                        autoComplete="off" >
                                        <option value="" hidden>Select</option>
                                        <option value="2FA">2FA</option>
                                        <option value="ACCOUNT_NOTIFICATION">Account Notification</option>
                                        <option value="AGENTS_FRANCHISES">Agents and Franchises</option>
                                        <option value="CARRIER_EXEMPT">Carrier Exemptions</option>
                                        <option value="CHARITY">Charity</option>
                                        <option value="CUSTOMER_CARE">Customer Care</option>
                                        <option value="DELIVERY_NOTIFICATION">Delivery Notification</option>
                                        <option value="EMERGENCY">Emergency</option>
                                        <option value="FRAUD_ALERT">Fraud Alert Messaging</option>
                                        <option value="HIGHER_EDUCATION">Higher Education</option>
                                        <option value="K12_EDUCATION">K-12 Education</option>
                                        <option value="LOW_VOLUME">Low Volume Mixed</option>
                                        <option value="M2M">Machine to Machine</option>
                                        <option value="MARKETING">Marketing</option>
                                        <option value="MIXED">Mixed</option>
                                        <option value="POLITICAL">Political</option>
                                        <option value="POLLING_VOTING">Polling and voting</option>
                                        <option value="PROXY">Proxy</option>
                                        <option value="PUBLIC_SERVICE_ANNOUNCEMENT">Public Service Announcement</option>
                                        <option value="SECURITY_ALERT">Security Alert</option>
                                        <option value="SOCIAL">Social</option>
                                        <option value="SOLE_PROPRIETOR">Sole Proprietor</option>
                                        <option value="SWEEPSTAKE">Sweepstake</option>
                                        <option value="TRIAL">Platform Free Trial</option>
                                        <option value="UCAAS_HIGH">UCaaS High Volume</option>
                                        <option value="UCAAS_LOW">UCaaS Low Volume</option>

                                    </select>
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="city">Description<span className="text-danger">*</span></label>
                                    <textarea
                                        class="form-control"
                                        name="description_value"
                                        id="description_value"
                                        placeholder="Enter Description"
                                        {...register("description_value", {
                                            required: "description_value is required",
                                        })}
                                    />
                                    {errors.description_value && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {errors.description_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="city">Sample1<span className="text-danger">*</span></label>
                                    <textarea class="form-control"
                                        name="sample1_value"
                                        id="sample1_value"
                                        placeholder="Enter Sample1"
                                        {...register("sample1_value", {
                                            required: "Sample1 is required",
                                        })}

                                    />
                                    {errors.sample1_value && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {errors.sample1_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="city">Message Flow<span className="text-danger">*</span></label>
                                    <textarea class="form-control"
                                        name="messageFlow_value"
                                        id="messageFlow_value"
                                        placeholder="Enter Message flow"
                                        {...register("messageFlow_value", {
                                            required: "Message Flow is required",
                                        })}

                                    />
                                    {errors.messageFlow_value && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {errors.messageFlow_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="city">Help Message<span className="text-danger">*</span></label>
                                    <textarea class="form-control"
                                        name="helpMessage_value"
                                        id="helpMessage_value"
                                        placeholder="Enter help message"
                                        {...register("helpMessage_value", {
                                            required: "Help Message is required",
                                        })}

                                    />
                                    {errors.helpMessage_value && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {errors.helpMessage_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="city">Privacy Policy Link</label>
                                    <textarea class="form-control"
                                        name="privacyPolicyLink_value"
                                        id="privacyPolicyLink_value"
                                        placeholder="Enter Privacy Policy Link"
                                        {...register("privacyPolicyLink_value", {
                                            // required: "Privacy Policy Link is required",
                                        })}

                                    />
                                    {errors.privacyPolicyLink_value && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {errors.privacyPolicyLink_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="city">Terms And Conditions Link</label>
                                    <textarea class="form-control"
                                        name="termsAndConditionsLink_value"
                                        id="termsAndConditionsLink_value"
                                        placeholder="Enter Terms And Conditions Link"
                                        {...register("termsAndConditionsLink_value", {
                                            // required: "Terms And Conditions Link is required",
                                        })}

                                    />
                                    {errors.termsAndConditionsLink_value && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {errors.termsAndConditionsLink_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="city">Embedded Link Sample</label>
                                    <textarea class="form-control"
                                        name="embeddedLinkSample_value"
                                        id="embeddedLinkSample_value"
                                        placeholder="Enter Embedded Link Sample"
                                        {...register("embeddedLinkSample_value", {
                                            // required: "Embedded Link Sample is required",
                                        })}

                                    />
                                    {errors.embeddedLinkSample_value && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {errors.embeddedLinkSample_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <h5 className="d-flex align-items-center fw-semibold form-label mb-2">
                                        Content Attributes
                                    </h5>

                                </div>
                            </div>


                            <div className="col-md-6">

                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <label className="form-check-label" htmlFor="subscriberOptin_value">
                                        Subscriber Opt In
                                    </label>
                                    <Controller
                                        control={control}
                                        name="subscriberOptin_value"
                                        render={({ field }) => (
                                            <div className="form-check form-switch mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="subscriberOptin_value"
                                                    checked={field.value === 'yes'}
                                                    onChange={(e) => setValue('subscriberOptin_value', e.target.checked ? 'yes' : 'no')}
                                                />

                                            </div>
                                        )}
                                    />
                                    {errors.subscriberOptin_value && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errors.subscriberOptin_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <label className="form-check-label" htmlFor="subscriberOptout_value">
                                        Subscriber Opt Out
                                    </label>
                                    <Controller
                                        control={control}
                                        name="subscriberOptout_value"
                                        render={({ field }) => (
                                            <div className="form-check form-switch mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="subscriberOptout_value"
                                                    checked={field.value === 'yes'}
                                                    onChange={(e) => setValue('subscriberOptout_value', e.target.checked ? 'yes' : 'no')}
                                                />

                                            </div>
                                        )}
                                    />
                                    {errors.subscriberOptout_value && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errors.subscriberOptout_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <label className="form-check-label" htmlFor="subscriberHelp_value">
                                        Subscriber Help
                                    </label>
                                    <Controller
                                        control={control}
                                        name="subscriberHelp_value"
                                        render={({ field }) => (
                                            <div className="form-check form-switch mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="subscriberHelp_value"
                                                    checked={field.value === 'yes'}
                                                    onChange={(e) => setValue('subscriberHelp_value', e.target.checked ? 'yes' : 'no')}
                                                />

                                            </div>
                                        )}
                                    />
                                    {errors.subscriberHelp_value && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errors.subscriberHelp_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <label className="form-check-label" htmlFor="numberPool_value">
                                        Number Pooling
                                    </label>
                                    <Controller
                                        control={control}
                                        name="numberPool_value"
                                        render={({ field }) => (
                                            <div className="form-check form-switch mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="numberPool_value"
                                                    checked={field.value === 'yes'}
                                                    onChange={(e) => setValue('numberPool_value', e.target.checked ? 'yes' : 'no')}
                                                />

                                            </div>
                                        )}
                                    />
                                    {errors.numberPool_value && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errors.numberPool_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <label className="form-check-label" htmlFor="directLending_value">
                                        Direct Lending
                                    </label>
                                    <Controller
                                        control={control}
                                        name="directLending_value"
                                        render={({ field }) => (
                                            <div className="form-check form-switch mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="directLending_value"
                                                    checked={field.value === 'yes'}
                                                    onChange={(e) => setValue('directLending_value', e.target.checked ? 'yes' : 'no')}
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.directLending_value && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errors.directLending_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <label className="form-check-label" htmlFor="embeddedLink_value">
                                        Embedded Link
                                    </label>
                                    <Controller
                                        control={control}
                                        name="embeddedLink_value"
                                        render={({ field }) => (
                                            <div className="form-check form-switch mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="embeddedLink_value"
                                                    checked={field.value === 'yes'}
                                                    onChange={(e) => setValue('embeddedLink_value', e.target.checked ? 'yes' : 'no')}
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.embeddedLink_value && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errors.embeddedLink_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <label className="form-check-label" htmlFor="embeddedPhone_value">
                                        Embedded Phone
                                    </label>
                                    <Controller
                                        control={control}
                                        name="embeddedPhone_value"
                                        render={({ field }) => (
                                            <div className="form-check form-switch mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="embeddedPhone_value"
                                                    checked={field.value === 'yes'}
                                                    onChange={(e) => setValue('embeddedPhone_value', e.target.checked ? 'yes' : 'no')}
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.embeddedPhone_value && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errors.embeddedPhone_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <label className="form-check-label" htmlFor="affiliateMarketing_value">
                                        Affiliate Marketing
                                    </label>
                                    <Controller
                                        control={control}
                                        name="affiliateMarketing_value"
                                        render={({ field }) => (
                                            <div className="form-check form-switch mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="affiliateMarketing_value"
                                                    checked={field.value === 'yes'}
                                                    onChange={(e) => setValue('affiliateMarketing_value', e.target.checked ? 'yes' : 'no')}
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.affiliateMarketing_value && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errors.affiliateMarketing_value.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <label className="form-check-label" htmlFor="ageGated">
                                        Age Gated Content
                                    </label>
                                    <Controller
                                        control={control}
                                        name="ageGated"
                                        render={({ field }) => (
                                            <div className="form-check form-switch mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="ageGated"
                                                    checked={field.value === 'yes'}
                                                    onChange={(e) => setValue('ageGated', e.target.checked ? 'yes' : 'no')}
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.ageGated && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errors.ageGated.message}
                                        </div>
                                    )}
                                </div>
                            </div>


                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="px-3 pb-5 border-0">
                            <button type="submit" className="btn btn-primary d-block mt-3">Submit</button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>

        </main >
    )

}
