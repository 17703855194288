import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import RightSidebar from './RightSidebar'
import Footer from './Footer'

export default function Layout({ children }) {
    return (
        <>
            <Header />
            <Sidebar />
            {children}{/* Placeholder for page content */}
            <RightSidebar />
            <Footer />

        </>
    )
}
