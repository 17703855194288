import React from 'react'

export default function DynamicLocation({ latitude, longitude }) {
    // Construct the Google Maps iframe URL dynamically based on latitude and longitude
    const googleMapUrl = `https://www.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&output=embed`;

    return (
        <div>
            <iframe
                src={googleMapUrl}
                width="300"
                height="200"
                style={{ border: 0 }}
                title='location'
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />
        </div>
    );
}
