import React, { useState } from 'react'
import PageTitle from '../../../common/PageTitle'
import { Controller, useForm } from 'react-hook-form';
import DynamicSelect from '../../../common/components/selects/DynamicSelect';
import { formatDateTime, getBase64, triggerAlert } from '../../../utils/CommonFunctions';
import { noEmptySpacesValidation } from '../../../utils/Constants';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import MultiSelectDyGet from '../../../common/components/selects/MultiSelectDyGet';
import { sendSMSOrMMS } from '../../../utils/ApiClient';
import Loader from '../../../common/components/Loader';
// import Emojis from '../../../common/components/Emojis';

export default function Compose() {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const currentDate = new Date();

    const [showSchedule, setShowSchedule] = useState(false);
    const [mmsFile, setMMSFile] = useState({}); // To track the active chat
    const [isLoading, setIsLoading] = useState(false);
    const [sendToNumbers, setSendToNumbers] = useState([]);
    const [activeTab, setActiveTab] = useState('contact');

    ///////////////////////// Basic form /////////////////////////////////////////
    const { register, handleSubmit, formState: { errors }, setValue, reset, control, getValues, watch, setError, clearErrors } = useForm(
        {
            defaultValues: {
                scheduled_date: showSchedule ? currentDate : null, // Initialize with current date
            }
        }
    );
    const messageType = watch('msg_type', 'SMS'); // Default to 'sms'
    const formReset = () => {
        reset();
        setValue('to_number', null);
        setValue('from_number', null);
        setValue('group_id', null);
    }
    const handleTabClick = (tabId) => {
        formReset();
        setActiveTab(tabId);
    };
    ////////////////////// select a number functions /////////////////////////////////
    const handleToSelect = async (selectedOption) => {
        const selectedValues = selectedOption?.map((item) => item.value).join(",");
        setSendToNumbers(selectedValues);
        setValue("to_number", selectedOption)
    };
    const handleNumberSelect = async (selectedOption) => {
        const number = selectedOption ? selectedOption.value : null;
        setValue('from_number', number);
    };
    const handleTemplateChange = async (selectedOption) => {
        const message = selectedOption ? selectedOption.message : null;
        console.log("message", selectedOption)
        setValue('message', message);
        setValue('template', selectedOption ? selectedOption.value : null);
    };
    const handleGroupSelect = async (selectedOption) => {
        const selectedValues = selectedOption?.map((item) => item.value);
        setSendToNumbers(selectedValues);
        setValue('group_id', selectedOption)
    };

    const handleMMSFilechange = async (e) => {
        const file = e.target.files[0];
        let items = {};

        if (!file) return;

        // Check if the file is a PDF
        if (file.type === "application/pdf") {
            setError('mmsfile', {
                type: 'manual',
                message: "PDF files are not allowed.",
            });
            e.target.value = ''; // Clear the input
            setMMSFile(items); // Set error state
            return;
        }

        // Check if the file size exceeds 2MB
        if (file.size > 2 * 1024 * 1024) {
            setError('mmsfile', {
                type: 'manual',
                message: "File size should not exceed 2MB.",
            });
            e.target.value = ''; // Clear the input
            setMMSFile(items); // Set error state
            return;
        }

        try {
            clearErrors('mmsfile')
            // Convert file to base64
            const base64 = await getBase64(file);
            const base64WithoutPrefix = base64.substring(base64.indexOf(",") + 1);
            items = {
                ...items,
                file_name: file.name,
                file_type: file?.name?.split(".")[1],
                file_size: file.size,
                file: base64WithoutPrefix,
                preview: base64 // Store the full base64 string for preview
            };
            console.log("items", items)
            setMMSFile(items);
        } catch (error) {
            // console.error("Error converting file to base64:", error);
            items.error = "Failed to process the file.";
            setMMSFile(items);
        }
    }

    ///////////////////// compose SMS or MMS message ///////////////////////////////
    const composeSMSorMMSMessage = async (data) => {
        // return
        setIsLoading(true);
        // if (usernum) setActiveUserNum(usernum)
        try {
            const params = data;
            params.from_number = String(params.from_number);
            params.type = activeTab;
            if (activeTab === 'contact') {
                params.to_number = [sendToNumbers]
                params.group_id = []
            } else {
                params.group_id = sendToNumbers
                params.to_number = []
            }
            if (data.scheduled_date) params.scheduled_date = formatDateTime(params.scheduled_date, 'yyyy-mm-dd hh:mm:ss');
            if (data.msg_type == 'MMS') params.base64_files = mmsFile;
            const response = await sendSMSOrMMS(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                formReset();
                triggerAlert('success', 'success', 'Message sent Successfully!!');
            } else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Unable to send message!!');
            }
        } catch (error) {
            const response_data = error?.response?.data
            console.error(error)
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }

    // const handleEmojiSelect = (emojiObject) => {
    //     console.log("emojidata", emojiObject.target.innerText)
    //     setValue("message", emojiObject.target.innerText);
    //     // setValue("message", emojiObject.emoji);
    // }
    return (
        <div>
            <main class="main-content mt-3">

                <div class="container content-inner  " id="page_layout">

                    <PageTitle heading="Compose" />
                    {isLoading &&
                        <div className='loader-overlay text-white'>
                            <Loader />
                        </div>
                    }
                    <div class="row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="tab-content" id="myTabContent">
                                <div class="card tab-pane mb-0 fade show active" id="user-content-103" role="tabpanel">
                                    <form onSubmit={handleSubmit(composeSMSorMMSMessage)}>
                                        <div class="card-header border-0">
                                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a className={`nav-link ${activeTab
                                                        === 'contact' ? 'active' : ''}`} id="contact-tab" data-bs-toggle="pill" href="#contact" role="tab" aria-controls="contact" aria-selected={activeTab === 'contact'} onClick={() => handleTabClick('contact')}>
                                                        <i className="fa-solid fa-address-book" aria-hidden="true"></i>
                                                        <span className="ms-2">Contacts</span>
                                                    </a>

                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className={`nav-link ${activeTab === 'groups' ? 'active' : ''}`} id="groups-tab" data-bs-toggle="pill" href="#groups" role="tab" aria-controls="groups" aria-selected={activeTab === 'groups'} tabindex="-1" onClick={() => handleTabClick('groups')}>
                                                        <i className="fa fa-users" aria-hidden="true"></i>
                                                        <span className="ms-2">Groups</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="card-body bg-body">

                                            <div class="row mt-3">
                                                <div class="col-md-6 mb-3">
                                                    <div className='d-flex justify-content-between'>

                                                        <label class="form-label" for="validationDefault01">To <span className='text-danger'>*</span></label>
                                                        {activeTab === 'contact' ?
                                                            <span>Recipients : {watch('to_number') ? watch('to_number').length : 0}</span> :
                                                            // <span>Recipients : {watch('group_id') ? watch('group_id').length : 0}</span>
                                                            null
                                                        }
                                                    </div>
                                                    {activeTab === 'contact' ?
                                                        <>

                                                            <Controller
                                                                name="to_number"
                                                                {...register('to_number',
                                                                    { required: 'To is required' }
                                                                )}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <MultiSelectDyGet
                                                                        {...field}
                                                                        apiUrl={api_url + 'sms/get_all_contact_list/'}
                                                                        placeholder="Select phone number"
                                                                        mapOption={result => ({
                                                                            value: result.contact_number,
                                                                            label: result.contact_number,
                                                                        })}
                                                                        value={field.value}
                                                                        onSelect={handleToSelect}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.to_number && (
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "14px",
                                                                        marginTop: "5px",
                                                                    }}
                                                                >
                                                                    {errors.to_number.message}
                                                                </div>
                                                            )}
                                                        </>
                                                        :
                                                        <>

                                                            <Controller
                                                                name="group_id"
                                                                {...register('group_id',
                                                                    { required: 'Group is required' }
                                                                )}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <MultiSelectDyGet
                                                                        {...field}
                                                                        apiUrl={api_url + 'contact/template_groups_list/'}
                                                                        placeholder="Select phone number"
                                                                        mapOption={result => ({
                                                                            value: result.id,
                                                                            label: result.group_name,
                                                                        })}
                                                                        value={field.value}
                                                                        onSelect={handleGroupSelect}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.group_id && (
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "14px",
                                                                        marginTop: "5px",
                                                                    }}
                                                                >
                                                                    {errors.group_id.message}
                                                                </div>
                                                            )}
                                                        </>
                                                    }

                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label class="form-label" for="validationDefault02">From <span className='text-danger'>*</span></label>
                                                    <Controller
                                                        name="from_number"
                                                        {...register('from_number',
                                                            { required: 'From is required' }
                                                        )}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <DynamicSelect
                                                                {...field}
                                                                apiUrl={api_url + 'sms/get_company_number_list/'}
                                                                placeholder="Select phone number"
                                                                mapOption={result => ({
                                                                    value: result.requested_no,
                                                                    label: result.requested_no,
                                                                })}
                                                                value={field.value}
                                                                onSelect={handleNumberSelect}
                                                            />
                                                        )}
                                                    />
                                                    {errors.from_number && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                                marginTop: "5px",
                                                            }}
                                                        >
                                                            {errors.from_number.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label for="validationCustomUsername" class="form-label">Template</label>
                                                    <Controller
                                                        name="template"
                                                        {...register('template',
                                                            // { required: 'Department is required' }
                                                        )}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <DynamicSelect
                                                                {...field}
                                                                apiUrl={api_url + 'sms/get_message_template_list/'}
                                                                placeholder="Select a template"
                                                                mapOption={result => ({
                                                                    value: result.id,
                                                                    label: result.template_name,
                                                                    message: result.template_message
                                                                })}
                                                                value={field.value}
                                                                onSelect={handleTemplateChange}
                                                            />
                                                        )}
                                                    />
                                                    {errors.template && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                                marginTop: "5px",
                                                            }}
                                                        >
                                                            {errors.template.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label class="form-label" for="validationDefault03">Select Type <span className='text-danger'>*</span></label>
                                                    <div className='d-flex align-items-center mt-2'>
                                                        <div class="me-3">
                                                            <Controller
                                                                name="msg_type"
                                                                control={control}
                                                                defaultValue="SMS"
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            {...field}
                                                                            type="radio"
                                                                            id="SMS"
                                                                            value="SMS"
                                                                            checked={field.value === 'SMS'}
                                                                        />
                                                                        &nbsp;
                                                                        <label htmlFor="SMS"> SMS</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div class="me-3">
                                                            <Controller
                                                                name="msg_type"
                                                                control={control}
                                                                defaultValue="SMS"
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            {...field}
                                                                            type="radio"
                                                                            id="MMS"
                                                                            value="MMS"
                                                                            checked={field.value === 'MMS'}
                                                                        />
                                                                        &nbsp;
                                                                        <label htmlFor="MMS"> MMS</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errors.msg_type && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                                marginTop: "5px",
                                                            }}
                                                        >
                                                            {errors.msg_type.message}
                                                        </div>
                                                    )}

                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label class="form-label" for="validationDefault05">Message <span className='text-danger'>*</span></label>
                                                    <textarea class="form-control" placeholder="Enter message"  {...register('message',
                                                        {
                                                            required: 'Message is required',
                                                            validate: noEmptySpacesValidation
                                                        }
                                                    )} />
                                                    {errors.message && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                                marginTop: "5px",
                                                            }}
                                                        >
                                                            {errors.message.message}
                                                        </div>
                                                    )}
                                                    <span>Total No Of Characters: {watch('message') ? watch('message').length : 0}</span>
                                                </div>
                                                {/* <Emojis onEmojiSelect={handleEmojiSelect} /> */}


                                                {showSchedule &&
                                                    <div class="col-md-6 mb-3">
                                                        <label class="form-label" for="validationDefault05">Schedule</label>
                                                        <div className="w-100" >
                                                            <Controller
                                                                control={control}
                                                                name="scheduled_date" // Assign the name here
                                                                class="form-control "

                                                                render={({ field, }) => (
                                                                    <DatePicker
                                                                        selected={field.value ? new Date(field.value) : new Date()}
                                                                        onChange={(date
                                                                        ) => {
                                                                            setValue("scheduled_date", date);
                                                                            field.onChange(date ? date : null);
                                                                        }}
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm"
                                                                        timeIntervals={30}
                                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                                        placeholderText="YYYY-MM-DD HH:MM:SS"
                                                                        className="form-control custom-datepicker-width" // Use Bootstrap's form-control class for full width

                                                                    // minDate={new Date()}
                                                                    // showMonthDropdown showYearDropdown
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                        {errors.scheduled_date && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.scheduled_date.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                {messageType === 'MMS' && (
                                                    <div class="col-md-6 mb-3">
                                                        <label class="form-label" for="validationDefault05">Attachment </label>
                                                        <input
                                                            type="file"
                                                            name="mmsfile"
                                                            class="form-control"
                                                            // {...registerIn("mmsfile")}
                                                            onChange={handleMMSFilechange}
                                                        />
                                                        {errors.mmsfile && (
                                                            <div
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errors.mmsfile.message}
                                                            </div>
                                                        )}
                                                        {/* Preview the image if the file is an image */}
                                                        {mmsFile?.file_type && ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(mmsFile.file_type.toLowerCase()) && (
                                                            <div style={{ marginTop: '10px' }}>
                                                                <img
                                                                    src={mmsFile.preview}
                                                                    alt="Preview"
                                                                    style={{
                                                                        width: '100px',
                                                                        // height: '10em',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                />
                                                            </div>
                                                        )}

                                                    </div>

                                                )}

                                            </div>

                                        </div>
                                        <div class="card-footer px-3 py-3  rounded-0">
                                            <div class="d-flex  justify-content-end">
                                                <button type="button" class="btn btn-info  px-4 d-flex align-items-center me-2" onClick={() => setShowSchedule(!showSchedule)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10" /><polyline points="12 6 12 12 16 14" /></svg>
                                                    &nbsp;<span class=""> Schedule </span></button>

                                                <button type="button" class="btn btn-warning  px-4 d-flex align-items-center me-2" onClick={formReset}><span >Cancel </span></button>
                                                <button type="submit" class="btn btn-success  px-4 d-flex align-items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13" /><polygon points="22 2 15 22 11 13 2 9 22 2" /></svg>
                                                    &nbsp;<span class="">Send </span></button>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </div >
    )
}
