import Swal from "sweetalert2";
import Cookies from 'js-cookie';

export const login = (token, username, email, user_type, user_id) => {
    //change this accordingly
    Cookies.set('admin_user_token', token);
    Cookies.set('username', username);
    Cookies.set('email', email);
    Cookies.set('user_type', user_type);
    Cookies.set('user_id', user_id);
}

export const logout = () => {
    //change this accordingly
    Cookies.remove('admin_user_token');
    Cookies.remove('username');
    Cookies.remove('email');
    Cookies.remove('user_type');
    Cookies.remove('user_id');

    Cookies.remove('menu_id_add');
    Cookies.remove('menu_id_edit');
    Cookies.remove('menu_id_delete');
    Cookies.remove('excel_export');
    Cookies.remove('admin_menu_data');
    Cookies.remove('admin_permission_data');

    return true;
}

export const isLogin = () => {
    //return true;
    if (Cookies.get('admin_user_token')) { //change this accordingly
        return true;
    }

}

export const getOnlyToken = () => {
    const token = Cookies.get('admin_user_token');
    return token;
}
export const getCustomerId = () => {
    const customerId = parseInt(Cookies.get('customer_id'), 10); //change this accordingly

    if (customerId) {
        return customerId;
    } else {
        return null;
    }
};


export const getToken = () => {
    const token = Cookies.get('admin_user_token'); //change this accordingly
    if (token) {
        return {

            headers: {
                Authorization: `${token}`
            }
        }
    } else {
        return null;
    }
};


export const getLoginData = () => {
    //return true;
    if (Cookies.get('user_id')) {
        let user_data = {
            "auth_user": Cookies.get('auth_user'),
            "username": Cookies.get('username'),
            "user_id": Cookies.get('user_id')
        };
        return user_data;
    }

}

export const getUserId = () => {
    const user_id = parseInt(Cookies.get('user_id'));

    if (user_id) {
        return user_id;
    } else {
        return null;
    }
};
// Sweetalert functions
export const triggerAlert = (icon, title, message) => {
    return Swal.fire({
        icon: icon,
        title: title,
        text: message,
        timer: 3000,
    })
}
export const simpleAlert = (text) => {
    return Swal.fire({
        text: text,
        timer: 3000,
    })
}

export const ConfirmationAlert = async (title, buttonText, buttonFunc) => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: title,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: buttonText,
        timer: null//3000,
    });
    if (result.isConfirmed) {
        buttonFunc();
    }
}

//export to excel functions
export const exportToCsv = (data, filename) => {
    const csvContent = Object.keys(data[0]).join(",") + "\n" +
        data.map(row => Object.values(row).join(",")).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv' }); // for large amount of data more than 3000 rows
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename + ".csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
};

//converting files to base64

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}
export const formatDate = (dateInput, format) => {

    // If the input is a string, convert it to a Date object
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

    // Check if the date is not null and is a valid Date object
    if (!date || isNaN(date.getTime())) {
        return '-';
    } else {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        const monthName = monthNames[date.getMonth()];
        const yyyy = date.getFullYear();

        switch (format) {
            case 'dd-mm-yyyy':
                return `${dd}-${mm}-${yyyy}`;
            case 'yyyy-mm-dd':
                return `${yyyy}-${mm}-${dd}`;
            case 'dd/mm/yyyy':
                return `${dd}/${mm}/${yyyy}`;
            case 'mm/dd/yyyy':
                return `${mm}/${dd}/${yyyy}`;
            case 'month dd, yyyy':
                return `${monthName} ${dd}, ${yyyy}`;
            // Add more format cases as needed
            default:
                return `${dd}-${mm}-${yyyy}`;
        }
    }
};

// Formatting date and time
export const formatDateTime = (dateTimeInput, format) => {
    let date, time;

    // Handle different formats of dateTimeInput
    if (typeof dateTimeInput === 'string') {
        // Case when dateTimeInput includes 'T' (ISO 8601 format)
        if (dateTimeInput.includes('T')) {
            [date, time] = dateTimeInput.split('T');
            date = new Date(dateTimeInput);
        } else {
            // Handle cases like 'Thu Aug 22 2024 18:44:49 GMT+0530 (India Standard Time)'
            date = new Date(dateTimeInput);
        }
    } else {
        // If it's not a string, assume it's a Date object
        date = new Date(dateTimeInput);
    }

    // Validate the date object
    if (!date || isNaN(date.getTime())) {
        return '-';
    }

    // Date components
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    const monthName = month[date.getMonth()];

    // Time components (default to 00:00:00 if not provided)
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let seconds = String(date.getSeconds()).padStart(2, '0');

    // Return formatted date and time based on the specified format
    switch (format) {
        case 'dd-mm-yyyy':
            return `${dd}-${mm}-${yyyy}`;
        case 'yyyy-mm-dd':
            return `${yyyy}-${mm}-${dd}`;
        case 'dd/mm/yyyy':
            return `${dd}/${mm}/${yyyy}`;
        case 'mm/dd/yyyy':
            return `${mm}/${dd}/${yyyy}`;
        case 'month dd, yyyy':
            return `${monthName} ${dd}, ${yyyy}`;
        case 'yyyy-mm-dd, hh:mm:ss':
            return `${yyyy}-${mm}-${dd}, ${hours}:${minutes}:${seconds}`;
        case 'yyyy-mm-dd hh:mm:ss':
            return `${yyyy}-${mm}-${dd} ${hours}:${minutes}:${seconds}`;
        case 'hh:mm:ss':
            return `${hours}:${minutes}:${seconds}`;
        case 'mm-dd-yyyy':
            return `${mm}-${dd}-${yyyy}`;
        // Add more format cases as needed
        default:
            return '-';
    }
};

//remove html tags 
export const removePTags = (inputString) => {
    if (inputString) {
        // Remove HTML tags
        const withoutPTags = inputString.replace(/<\/?[^>]+(>|$)/g, "");
        // Remove &nbsp;
        const removeBraces = withoutPTags.replace(/&nbsp;/g, '');
        return removeBraces;
    }
}

//download files
export const downloadFile = (url, filename) => {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;

            // Programatically click the link to trigger the download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(error => console.error('Error downloading file:', error));
};

//row selection 
export const handleTableRowClick = (value, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, row_id) => {

    if (selectedRowId === row_id) {
        setSelectedRow();
        setSelectedRowId();
    } else {
        setSelectedRow(value);
        setSelectedRowId(row_id);
    }

};

//generate random password of given characters
export const generatePassword = (noOfChar) => {
    // Use crypto.getRandomValues() for cryptographically strong randomness
    const randomValues = new Uint8Array(noOfChar);
    crypto.getRandomValues(randomValues);

    // const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*_+-?'; // Include symbols for stronger passwords
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // removed symbols 
    const password = Array.from(randomValues, (value) => charset[value % charset.length]).join('');

    return password;
}

export const togglePasswordVisibility = (inputId, class_name) => {
    const passwordInput = document.getElementById(inputId);
    const inputClass = document.querySelector(`.${class_name}`);
    console.log(inputClass)
    if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        inputClass.classList.add('bx-show');
        inputClass.classList.remove('bx-hide');
    } else {
        passwordInput.type = 'password';
        inputClass.classList.remove('bx-show');
        inputClass.classList.add('bx-hide');
    }
};

///transform strings
export function transformText(text, transformation) {
    switch (transformation) {
        case 'lowercase':
            return text.toLowerCase();
        case 'uppercase':
            return text.toUpperCase();
        case 'capitalize':
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        default:
            throw new Error(`Invalid transformation: ${transformation}`);
    }
}

///extract filename 
export function extractFileName(url) {
    const parts = url?.split('/');
    return parts[parts?.length - 1];
}


export const pageReload = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);

}

export const AlertWithButton = async (title, htmlCode, buttonText, buttonFunc) => {
    const result = await Swal.fire({
        title: title,
        html: htmlCode,
        confirmButtonText: buttonText,
    });
    if (result.isConfirmed) {
        buttonFunc();
    }
}



export const formattedDateTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export function formatTimeToAmandPM(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const formattedTime = hours + ':' + formattedMinutes + ' ' + ampm;
    return formattedTime;
}
export const truncateName = (message, length = 50) => {
    if (message.length > length) {
        return message.substring(0, length) + '...';
    }
    return message;
};




// Function to extract latitude and longitude from the URL
export const extractCoordinates = (url) => {
    const regex = /place\/(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(regex);
    if (match) {
        return {
            latitude: match[1],
            longitude: match[2]
        };
    }
    return { latitude: null, longitude: null };
};

// Function to get formatted date as Today, Yesterday, day of the week, or mm-dd-yyyy
export const getFormattedDate = (date) => {
    const messageDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today to midnight
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Check if the message date is today
    if (messageDate.toDateString() === today.toDateString()) {
        return 'Today';
    }

    // Check if the message date is yesterday
    if (messageDate.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
    }

    // Check if the message is within the current week
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the start of the week (Sunday)

    if (messageDate >= startOfWeek) {
        return messageDate.toLocaleString('en-US', { weekday: 'long' }); // Return the day name
    }

    // For older messages, return the formatted date
    return formatDateTime(messageDate, 'mm-dd-yyyy');
};