import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { triggerAlert } from '../../utils/CommonFunctions';
import { onlyAlphabetsandSpaces, onlyAlphaNumericSpaces, passwordValidations, onlyAlphabets, emailValidation, onlyNumbers, MinLengthValidation, MaxLengthValidation } from '../../utils/Constants'
import { registerSubmit, RegisterUser, Verify_OTP, ReSendOTP} from '../../utils/ApiClient';
import MetaTitle from '../../common/MetaTitle';

export default function Signup() {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const [captchaUrl, setCaptchaUrl] = useState("");
    const [userCaptchaInput, setUserCaptchaInput] = useState("");
    const [isCaptchaValid, setIsCaptchaValid] = useState("");
    const [stateData, setStatesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [sameAddress, setSameAddress] = useState(false);
    const [emailid, setEmail] = useState("");
    const [sussuid, setUid] = useState("");
    const [sussresp, setSussresp] = useState("");
    const [selectedCountryCode, setSelectedCountryCode] = useState(null);
    const [selectedCountryCodeCust, setSelectedCountryCodeCust] = useState(null);
    const [stateDataCust, setStatesDataCust] = useState([]);
    const [countryData, setCountriesData] = useState([]);
    const [disableAdd, setDisableAdd] = useState(null);
    const [otpverified, setOtpverify] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    
    const [planData, setPlanData] = useState([]);
    
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const otpInputsRef = useRef([]);

  const handleInputChange = (index, value) => {
    if (isNaN(value)) return; // Ensure only numbers are input

    let newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Auto-focus to the next input if value is entered
    if (value && index < otpInputsRef.current.length - 1) {
      otpInputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle backspace
    if (e.key === 'Backspace') {
      let newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        otpInputsRef.current[index - 1].focus();
      }
    }
  };

  const [errorOTP, setErrorOTP] = useState('');

  const otpVerify = async (e) => {
     
    e.preventDefault();
    const isEmpty = otp.some((val) => val === '');
    
    if (isEmpty) {
      setErrorOTP('All fields are required');
    } else {
      setIsLoading(true);
      // Update progress bar for Step 3 if no errors
      if (currentStep === 3) {
        const tab3 = document.querySelector('#progress-form__tab-3');
        tab3.setAttribute('data-complete', 'true');
        tab3.setAttribute('aria-selected', 'true');

      } 
      setErrorOTP('');
      const combinedOtp = otp.join('');
      console.log('isEmpty', combinedOtp); 
      console.log('otpVerify', combinedOtp)
      
      const api_input = {
        signup_otp: combinedOtp,
        user_id: sussuid
      }

      try {
        const response = await Verify_OTP(api_input);
        console.log('ress', response); 
        const response_data = response.data;
        console.log('response_data.error_code', response_data.error_code); 
        if (response_data.error_code == 200) {
          setIsLoading(false);
          setOtpverify(response_data.error_code);

        } else {
          setIsLoading(false);
          triggerAlert("error", "Oops...", "Invalid Otp..");

        }
      } catch (error) {
        // console.log("response_data.error_code22")
        setIsLoading(false);
        triggerAlert("error", "Oops...", "Invalid Otp..");
      }
    }
  };

  

  const clearOtpInputs = () => {
    setOtp(Array(6).fill(''));
    otpInputsRef.current.forEach(input => {
      if (input) input.value = '';
    });
  };
  const handleResendOtp = async () => {
    clearOtpInputs();
    const api_input = {
      user_id: sussuid
    }
    try {
      
      const response = await ReSendOTP(api_input);  
      console.log("responsesadsads", response);
      const response_data = response.data;
      console.log(response_data);
      // alert("sefsed")
      //console.log("sdfwsef");
      if (response_data.error_code == 200) {
        setOtpSent(true);
        triggerAlert("success", "Success", `OTP has been sent to your email :${emailid} `);
      } else {
        triggerAlert("error", "Oops...", "Unable to send the OTP..");
      }
    } catch (error) {
      // console.log("response_data.error_code22")
      triggerAlert("error", "Oops...", "Unable to send the OTP..");
    }
    //  setOtpverify("200");
    //console.log(otpverified)
  }

    const filteredStatesCust = stateDataCust.filter(
        (state) => state.country_code_char2 === selectedCountryCodeCust
      );

    const filteredStates = stateData.filter(
        (state) => state.country_code_char2 === selectedCountryCode
    );

    const handleCountryChange = (event) => {
        setSelectedCountryCode(event.target.value);
        fetchStatesData(event.target.value);
      };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        clearErrors,
        setError,
        getValues,
        setValue,
      } = useForm({
        mode: "onChange", // Enable onChange mode for real-time validation
      });

    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);

    const togglePassword = () => {
        setPasswordType(passwordType === "password" ? "text" : "password");
    };
    const [completedSteps, setCompletedSteps] = useState(0);


    const onSubmit = async (data) => {
        console.log(data);
        const response = await registerSubmit(data);
    };

    useEffect(() => {
        fetchCountriesData();
        fetchPlanData();
        
    }, []);

 

    const fetchStatesData = async (countryCode) => {
        // setIsLoading(true);
        try {
          const response = await axios.get(
            api_url + "customer/states_by_country_code/" + countryCode + "/"
          );
          const respdata = response.data.results.data;
          setStatesData(respdata);
          // setIsLoading(false);
        } catch (error) {
          //triggerAlert('error','','No data available');
        }
      };

    const fetchCountriesData = async () => {
        //setIsLoading(true);
        try {
            const response = await axios.get(api_url + "customer/countries_list/");
            const respdata = response.data.results.data;
            setCountriesData(respdata);
            // setIsLoading(false);
        } catch (error) {
            console.log(error)
            // triggerAlert("error", "", "No data available");
        }
    };
    const fetchPlanData = async () => {
        
        //setIsLoading(true);
        try {
            const response = await axios.get(api_url + "billing/plans/");           
            const respdata = response.data;             
            setPlanData(respdata);
            // setIsLoading(false);
        } catch (error) {
            console.log(error)
            // triggerAlert("error", "", "No data available");
        }
    };

    const registerUser = async (data) => {
        if (currentStep === 2) {
          const tab2 = document.querySelector('#progress-form__tab-2');
          tab2.setAttribute('data-complete', 'true');
          // tab2.setAttribute('aria-selected', 'true');
          // tab2.removeAttribute('aria-disabled');
          setCompletedSteps(2)
        }
        // console.log(data)
        // return
        setIsLoading(true);
        try {
        //   data.email = data.user_name;
          data.billed_address = sameAddress;
          console.log('datasdsasads', data)
    
          setEmail(data.email)
          // return
          const response = await RegisterUser(data);
          const response_data = response.data;

          console.log('responsesss', response); 
          console.log('response_data', response_data); 
          if (response_data.error_code == 201) {
            setIsLoading(false);
            //console.log(response_data.error_code)
            //console.log("response_data.error_code")
            setSussresp(response_data.error_code);
            setUid(response_data.results);
            setCompletedSteps(3)
            setCurrentStep(3);
            // console.log(otpverified)
          } else if (response_data.error_code == 400) {
            setIsLoading(false);
            triggerAlert("error", "Oops...", "Username Already Exist");
          } else {
            setIsLoading(false);
            //console.log("response_data.error_code11")
            triggerAlert("error", "Oops...", "Something went wrong..");
          }
        } catch (error) {
          setIsLoading(false);
          //console.log("response_data.error_code22")
          triggerAlert("error", "Oops...", "Something went wrong..");
        }
    
        // }
      };


      const handleNextClick = async () => {
        
        const values = getValues();        
        let hasError = 0;
    
        if (currentStep === 1) {
          const first_name = values.first_name?.trim();
          const last_name = values.last_name?.trim();
          const ceo_name = values.ceo_name?.trim();
          const ceo_mail = values.ceo_mail?.trim();
          const ceo_phone = values.ceo_phone?.trim();
        //   const user_name = values.user_name?.trim();
          const website = values.website?.trim();
          const company_name = values.company_name?.trim();
          const phone = values.phone?.trim();

          console.log('ceo_',ceo_phone); 
          if (!first_name) {
            clearErrors('first_name')
            hasError++
            setError("first_name", {
              type: "manual",
              message: "First Name is required",
            });
          }
          
    
    
          if (!ceo_name) {
            clearErrors('ceo_name')
            hasError++
            setError("ceo_name", {
              type: "manual",
              message: "Ceo Name is required",
            });
          }
    
          if (!values.user_name) {
            clearErrors('user_name')
            hasError++;
            setError("user_name", {
              type: "custom",
              message: "User name is required",
            });
          }
          
          if (!website) {
            clearErrors('website')
            hasError++
            setError("website", {
              type: "manual",
              message: "Website is required",
            });
          }

          if (!company_name) {
            clearErrors('company_name')
            hasError++
            setError("company_name", {
              type: "manual",
              message: "Company Name is required",
            });
          }

          if (!last_name) {
            clearErrors('last_name')
            hasError++
            setError("last_name", {
              type: "manual",
              message: "Last Name is required",
            });
          }
        
        if (!values.ceo_phone) {
             
            hasError++;
            setError("ceo_phone", {
              type: "manual",
              message: "Phone number is required",
            });
          } else if (!/^\d{10,14}$/.test(values.ceo_phone)) {            
            hasError++;
            setError("ceo_phone", {
              type: "manual",
              message: "Phone number must be between 10 and 14 digits",
            });
          }

          if (!values.phone) {
             
            hasError++;
            setError("phone", {
              type: "manual",
              message: "Phone number is required",
            });
          } else if (!/^\d{10,14}$/.test(values.phone)) {            
            hasError++;
            setError("phone", {
              type: "manual",
              message: "Phone number must be between 10 and 14 digits",
            });
          }

          
          if (!values.plan_id) {
            clearErrors('plan_id')
            hasError++;
            setError("plan_id", {
              type: "manual",
              message: "Plan is required",
            });
          }
          if (!ceo_mail) {
            clearErrors('ceo_mail')
            hasError++;
            setError("ceo_mail", {
              type: "manual",
              message: "Ceo Mail is required",
            });
          }
    
        
    
          if (!values.password) {
            clearErrors('password')
    
            hasError++;
            setError("password", {
              type: "manual",
              message: "Password is required",
            });
    
          }
          const PwPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/;
          if (!PwPattern.test(values.password)) {
            hasError++;
            clearErrors('password');
            setError("password", {
              type: "pattern",
              message: "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.",
            });
            return;
          }
    
          if (!values.confirm_password) {
            clearErrors('confirm_password')
            hasError++;
            setError("confirm_password", {
              type: "manual",
              message: "Confirm Password is required",
            });
          }
    
          if (values.password !== values.confirm_password) {
            clearErrors('password')
            clearErrors('confirm_password')
            hasError++;
            setError("confirm_password", {
              type: "manual",
              message: "Password is Not Matching",
            });
          }

          
    
          if (values.user_name !== "") {          
            try {
                console.log('emil validation'); 
              const response = await axios.get(
                api_url + "customer/exist_username/" + values.user_name + "/"
              );
              const error_code =response.data.error_code; 
              
              const response_message = response.data?.message;
              console.log('response_message',response_message); 
              if (error_code == 200) {
                hasError++;
                console.log('hasError',  hasError); 
                clearErrors('user_name'); // Clear any previous errors
                setError("user_name", {
                  type: "custom",
                  message: "Username already exists.",
                })
                setDisableAdd(true);
              } else if (error_code == 203) {           
                // setIsLoading(false); 
                // hasError++
                clearErrors('user_name'); // Clear any previous errors
                setError('user_name', {
                  type: 'manual',
                  message: 'Username available',
                });
                setDisableAdd(false);
                // setIsLoading(false);

              }
              else {
                clearErrors("user_name")
                setDisableAdd(false);
                // setIsLoading(false); 
              }
    
            } catch (error) {
              clearErrors("user_name")
              setDisableAdd(false);
              // setIsLoading(false); 
            }
          }
          
          // Update progress bar for Step 1 if no errors
          if (currentStep === 1 && hasError === 0) {
            console.log('sdsd' ); 
            const tab1 = document.querySelector('#progress-form__tab-1');
            tab1.setAttribute('data-complete', 'true');
            tab1.setAttribute('aria-selected', 'true');
            tab1.removeAttribute('aria-disabled');
            setCompletedSteps(1)
          }
        }
       
        // // Move to the next step if no errors
        if (hasError === 0) {    
            console.log('dsfdsf');          
          setCurrentStep((prevStep) => prevStep + 1);
          
        }
      };
      
      const handlePreviousClick = (step) => {   
        setCurrentStep(step);
      };


      const handleSameAddressChange = (e) => {
        const isChecked = e.target.checked;
        // console.log('setSameAddress', isChecked);
        setSameAddress(isChecked);
    
        if (isChecked) {
          // If the checkbox is checked, set presentAddress to currentAddress
          const AddressValue = getValues("address");
          const CityValue = getValues("city");
          const ZipValue = getValues("zipcode");
          const CountryValue = getValues("country");
          const StateValue = getValues("state");
          console.log('stateValue', StateValue)
    
          setValue("cust_state", StateValue);
          setValue("cust_country", CountryValue);
          setValue("cust_city", CityValue);
          setValue("cust_address", AddressValue);
          setValue("cust_zipcode", ZipValue);
    
          // Set fields as readonly
          // setFieldsReadonly(true);
        } else {
          // If unchecked, clear presentAddress
          setValue("cust_state", '');
          setValue("cust_country", '');
          setValue("cust_city", '');
          setValue("cust_address", '');
          setValue("cust_zipcode", '');
          // setSelectedValue("");
          // Set fields as editable
          // setFieldsReadonly(false);
        }
      };
      

      const fetchStatesDataCust = async (countryCode) => {
        // setIsLoading(true);
        try {
          const response = await axios.get(
            api_url + "customer/states_by_country_code/" + countryCode + "/"
          );
          const respdata = response.data.results.data;
          setStatesDataCust(respdata);
          // setIsLoading(false);
        } catch (error) {
          //triggerAlert('error','','No data available');
        }
      };
      const props = {
        title:  " Signup | Social media Sync ",
        description: "Premium Multipurpose Admin & Dashboard Template"
    }
      
    return (
        <>
        <MetaTitle {...props} />
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: 'none' }}>

            </div>
            <section class="fxt-template-animation fxt-template-layout33 loaded">
                <div class="fxt-content-wrap">
                    <div class="fxt-heading-content">
                        <div class="fxt-inner-wrap fxt-transformX-R-50 fxt-transition-delay-3">
                            <div class="fxt-transformX-R-50 fxt-transition-delay-10">
                            </div>
                            <div class="fxt-transformX-R-50 fxt-transition-delay-10">
                            </div>
                        </div>
                    </div>
                    <div class="fxt-form-content">
                        <div class="d-flex align-items-center"  >
                            <div class=" pt-5 pb-5 mt-5">
                                <div class="card-header text-center border-0">
                                    <a class=" mb-5" href="#"><img src="/assets/images/logo.svg" class="img-fluid" alt="logo" loading="lazy" width="260" /></a>
                                    <h3 class="mb-0 text-warning fw-500 mt-3 ms-5">Customer Signup</h3>
                                </div>
                                <div class="sign-in-from mt-3">
                                    <div class="row">
                                        <div class="col-md-3" style={{ display: otpverified == 200 ? "none" : "block" }}>
                                            <ul id="top-tabbar-vertical" class="p-0">                                       


                                            <li className={`step1 ${currentStep === 1 ? 'active' : ''}`} id="personal">
                                                <a
                                                href="#"
                                                id="progress-form__tab-1"
                                                className={`flex-1 px-0 pt-2 progress-form__tabs-item ${currentStep >= 1 ? 'active' : ''}`}
                                                aria-controls="progress-form__panel-1"
                                                aria-selected={currentStep === 1 ? 'true' : 'false'}
                                                aria-disabled={completedSteps < 1 ? 'true' : 'false'}
                                                onClick={() => handlePreviousClick(1)}
                                                >
                                                <i className="material-symbols-outlined border text-primary">person</i>
                                                <span className="d-block step" aria-hidden="true">User Information</span>
                                                </a>
                                            </li>
                                            
                                            <li className={`step2 ${currentStep === 2 ? 'active' : ''}`} id="contact">
                                                <a
                                                href="#"
                                                id="progress-form__tab-2"
                                                className={`flex-1 px-0 pt-2 progress-form__tabs-item ${currentStep >= 2 ? 'active' : ''}`}
                                                aria-controls="progress-form__panel-2"
                                                aria-selected={currentStep === 2 ? 'true' : 'false'}
                                                aria-disabled={completedSteps < 1 ? 'true' : 'false'}
                                                onClick={() => completedSteps >= 1 && handlePreviousClick(2)}
                                                >
                                                <i className="material-symbols-outlined border text-danger">receipt_long</i>
                                                <span className="d-block step" aria-hidden="true">Billing Information / Communication Address</span>
                                                </a>
                                            </li>
                                            <li className={`step3 ${currentStep === 3 ? 'active' : ''}`} id="official">
                                                <a
                                                href="#"
                                                id="progress-form__tab-3"
                                                className={`flex-1 px-0 pt-2 progress-form__tabs-item ${currentStep >= 3 ? 'active' : ''}`}
                                                aria-controls="progress-form__panel-3"
                                                aria-selected={currentStep === 3 ? 'true' : 'false'}
                                                aria-disabled={completedSteps < 1 ? 'true' : 'false'}
                                                onClick={() => completedSteps >= 1 && handlePreviousClick(3)}
                                                >
                                                <i className="material-symbols-outlined border text-danger">request_quote</i>
                                                <span className="d-block step" aria-hidden="true">OTP Verify</span>
                                                </a>
                                            </li>
                                            
                                            {/* <li id="official" class="step3">
                                                    <a href="javascript:void(0);">
                                                        <i class="material-symbols-outlined  border text-success">
                                                            map
                                                        </i><span>Communication Address</span>
                                                    </a>
                                                </li>
                                                <li id="payment" class="step4">
                                                    <a href="javascript:void(0);">
                                                        <i class="material-symbols-outlined  border text-warning">
                                                            request_quote
                                                        </i><span>Payment Information</span>
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div class="col-md-9">
                                            <form id="form-wizard3" class="text-start ps-4" onSubmit={handleSubmit(registerUser)}>
                                            <section role="tabpanel"
                                                aria-labelledby="progress-form__tab-1" tabindex="0" id={`step${currentStep}`}
                                                style={{
                                                    display:
                                                    currentStep === 1 && sussresp !== 201 ? "block" : "none",
                                                }}>
                                                    <div className="form-card text-left">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h4 className="mb-2 fw-500">User Information:</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="fname">First Name: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control"
                                                                        name="first_name"
                                                                        id="first_name"
                                                                        placeholder="Enter First Name"
                                                                        {...register("first_name", {
                                                                        required: "First Name is required",
                                                                        pattern: onlyAlphabetsandSpaces,
                                                                        maxLength: MaxLengthValidation(100),
                                                                        // minLength: MinLengthValidation(6)
                                                                        })} />
                                                                    {errors.first_name && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.first_name.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="lastName">Last Name: <span style={{ color: "red" }} >*</span></label>
                                                                    <input class="form-control" type="text"
                                                                    name="last_name"
                                                                    id="last_name"
                                                                    placeholder="Enter Last Name"
                                                                    {...register("last_name", {
                                                                    required: "Last Name is required",
                                                                    pattern: onlyAlphabetsandSpaces,
                                                                    maxLength: MaxLengthValidation(100),
                                                                    // minLength: MinLengthValidation(6)
                                                                    })} />
                                                                {errors.last_name && (
                                                                    <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "14px",
                                                                        marginTop: "5px",
                                                                    }}
                                                                    >
                                                                    {errors.last_name.message}
                                                                    </div>
                                                                )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="email">Email: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="email" class="form-control"
                                                                    name="email"
                                                                    id="email"
                                                                    placeholder="Enter Email Name"
                                                                    {...register("email", {
                                                                    required: "Email is required",
                                                                    maxLength: MaxLengthValidation(150),
                                                                    minLength: MinLengthValidation(8),
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                        message: "Invalid email format",
                                                                    },
                                                                    })}
                                                                    autocomplete="off" />
                                                                {errors.email && errors.email.type !== 'manual' && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.email.message}</div>)}
                                                                {errors.email?.type === 'manual' && (
                                                                    <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                    {errors.email.message}
                                                                    </div>
                                                                )}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="user_name">User Name: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control"
                                                                        name="user_name"
                                                                        id="user_name"
                                                                        placeholder="Enter User Name"
                                                                        {...register("user_name", {
                                                                        required: "User Name is required",
                                                                        pattern: onlyAlphabetsandSpaces,
                                                                        maxLength: MaxLengthValidation(100),
                                                                        // minLength: MinLengthValidation(6)
                                                                        })} />
                                                                   {errors.user_name && errors.user_name.type !== 'manual' && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.user_name.message}</div>)}
                                                                    {errors.user_name?.type === 'manual' && (
                                                                        <div style={{ color: 'green', fontSize: '14px', marginTop: '5px' }}>
                                                                        {errors.user_name.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="website">Website: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control"
                                                                        name="website"
                                                                        id="website"
                                                                        placeholder="Enter Website URL"
                                                                        {...register("website", {
                                                                        required: "Website is required",
                                                                        minLength: MinLengthValidation(10),
                                                                        maxLength: MaxLengthValidation(100)
                                                                        })} />
                                                                    {errors.website && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.website.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="password">Password: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="password" class="form-control"
                                                                        name="password"
                                                                        id="password"
                                                                        placeholder="Enter Password"
                                                                        {...register("password", {
                                                                        required: "Password is required",
                                                                        maxLength: MaxLengthValidation(15),
                                                                        minLength: MinLengthValidation(6),
                                                                        // pattern: {
                                                                        //   value: /^[a-zA-Z0-9@%+-]+$/,
                                                                        //   message: 'Password should contain numbers, alphabets or special symbols like @+-%',
                                                                        // },
                                                                        })}
                                                                        autocomplete="new-password" />
                                                                    {errors.password && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.password.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="confirm_password">Confirm Password: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="password" class="form-control"
                                                                        name="confirm_password"
                                                                        id="confirm_password"
                                                                        placeholder="Re Enter Password"
                                                                        {...register("confirm_password", {
                                                                        required: "Confirm password is required",
                                                                        maxLength: MaxLengthValidation(15),
                                                                        minLength: MinLengthValidation(6),
                                                                        // pattern: {
                                                                        //   value: /^[a-zA-Z0-9@%+-]+$/,
                                                                        //   message: 'Confirm Password should contain numbers, alphabets or special symbols like @+-%',
                                                                        // },
                                                                        })}
                                                                        autocomplete="new-password" />
                                                                    {errors.confirm_password && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.confirm_password.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="phone">Phone: <span style={{ color: "red" }} >*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter Phone Number"
                                                                        id="phone"
                                                                        {...register("phone", {
                                                                            required: "Phone number is required",
                                                                            pattern: {
                                                                                value: /^[0-9]+$/,
                                                                                message: 'Please enter a valid phone number',
                                                                            }
                                                                        })}
                                                                    />
                                                                    {errors.phone && (
                                                                        <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                                                                            {errors.phone.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="companyName">Company Name: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control"
                                                                        name="company_name"
                                                                        id="company_name"
                                                                        placeholder="Enter Company name"
                                                                        {...register("company_name", {
                                                                        required: "Company name is required",
                                                                        pattern: onlyAlphaNumericSpaces,
                                                                        minLength: MinLengthValidation(6),
                                                                        maxLength: MaxLengthValidation(100)
                                                                        })} />
                                                                    {errors.company_name && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.company_name.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="plan">Plan Name</label>
                                                                   

                                                                        <select id="inputState" class="form-select"
                                                                        name="plan_id"
                                                                        {...register("plan_id", {
                                                                        required: "Plan is required",
                                                                        })}
                                                                        
                                                                        autoComplete="off"
                                                                        
                                                                    >
                                                                        <option value="">-- Select</option>
                                                                        {planData.map((res, index) => (
                                                                        <option value={res.plan_name}>
                                                                            {res.plan_name}
                                                                        </option>
                                                                        ))}

                                                                       
                                                                    </select>
                                                                    {errors.plan_id && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.plan_id.message}
                                                                        </div>
                                                                    )}
                                                                  
                                                                </div>
                                                            </div>

                                                            <h5 className="mb-2 text-warning">CEO Information</h5>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="ceoName">CEO Name: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control"
                                                                        name="ceo_name"
                                                                        id="ceo_name"
                                                                        placeholder="Enter Ceo Name"
                                                                        {...register("ceo_name", {
                                                                        required: "Ceo Name is required",
                                                                        pattern: onlyAlphabetsandSpaces,
                                                                        maxLength: MaxLengthValidation(100),
                                                                        // minLength: MinLengthValidation(6)
                                                                        })} />
                                                                    {errors.ceo_name && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.ceo_name.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="ceoEmail">CEO Email: <span style={{ color: "red" }} >*</span></label>
                                                              
                                                                    <input type="ceo_mail" class="form-control"
                                                                        name="ceo_mail"
                                                                        id="ceo_mail"
                                                                        placeholder="Enter Ceo Email"
                                                                        {...register("ceo_mail", {
                                                                        required: "Ceo mail is required",
                                                                        maxLength: MaxLengthValidation(150),
                                                                        minLength: MinLengthValidation(8),
                                                                        pattern: {
                                                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                            message: "Invalid Ceo email format",
                                                                        },
                                                                        })}
                                                                        autocomplete="off" />
                                                                    {errors.ceo_mail && errors.ceo_mail.type !== 'manual' && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.ceo_mail.message}</div>)}
                                                                    {errors.ceo_mail?.type === 'manual' && (
                                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                                        {errors.ceo_mail.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="ceoContact">CEO Contact: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="ceo_phone" class="form-control"
                                                                        name="ceo_phone"
                                                                        id="ceo_phone"
                                                                        placeholder="Enter Phone number"
                                                                        {...register("ceo_phone", {
                                                                        required: "Phone number is required",
                                                                        // maxLength: MaxLengthValidation(14),
                                                                        // minLength: MinLengthValidation(10)
                                                                        })} />
                                                                    {errors.ceo_phone && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.ceo_phone.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {currentStep < 3 && (
                                                        <button type="button" class="btn btn-primary p-2 mt-3 px-5 float-end"
                                                        data-action="next"
                                                        onClick={handleNextClick}>Next </button>
                                                    )}

                                                    {/* <button
                                                        id="submit"
                                                        type="button"
                                                        name="next"
                                                        className="btn btn-primary next action-button float-end px-5 mt-3"
                                                        onClick={handleSubmit(onSubmit)}
                                                    >
                                                        Next
                                                    </button> */}
                                                </section>

                                                <section id={`step${currentStep}`} role="tabpanel"
                                                    aria-labelledby="progress-form__tab-2" tabindex="0" style={{
                                                        display:
                                                        currentStep === 2 && sussresp !== 201 ? "block" : "none",
                                                    }}>
                                                    <div className="form-card text-left">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h4 className="mb-3 fw-500">Billing Information</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="address">Address: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control"
                                                                        name="address"
                                                                        id="address"
                                                                        placeholder="Enter Address"
                                                                        {...register("address", {
                                                                        required: "Address is required",
                                                                        })}
                                                                        disabled={sameAddress}
                                                                    />
                                                                    {errors.address && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.address.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="city">City: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control"
                                                                        name="city"
                                                                        id="city"
                                                                        placeholder="Enter City"
                                                                        {...register("city", {
                                                                        required: "City is required",
                                                                        })}
                                                                        disabled={sameAddress}
                                                                    />
                                                                    {errors.city && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.city.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="country">Country: <span style={{ color: "red" }} >*</span></label>
                                                                    <select id="inputState" class="form-select"
                                                                    name="country"
                                                                    {...register("country", {
                                                                    required: "Country is required",
                                                                    })}
                                                                    onChange={(e) => {
                                                                    setSelectedCountryCode(e.target.value);
                                                                    fetchStatesData(e.target.value);
                                                                    setValue('state', '')
                                                                    }} autoComplete="off">
                                                                    <option value="">-- Select</option>
                                                                    {countryData.map((item, index) => (
                                                                    <option value={item.country_code_char2}>
                                                                        {item.country_name}
                                                                    </option>
                                                                    ))}
                                                                     {/* <option value="" disabled>Select Country</option>
                                                                        <option>Andhra Pradesh</option>
                                                                        <option>Arunachal Pradesh</option>
                                                                        <option>Andaman and Nicobar Islands</option> */}
                                                                   
                                                                </select>
                                                                {errors.country && (
                                                                    <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "14px",
                                                                        marginTop: "5px",
                                                                    }}
                                                                    >
                                                                    {errors.country.message}
                                                                    </div>
                                                                )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="state">State: <span style={{ color: "red" }} >*</span></label>
                                                                    <select id="inputState" class="form-select"
                                                                        name="state"
                                                                        {...register("state", {
                                                                        required: "State is required",
                                                                        })} autoComplete="off"
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {filteredStates.map((item, index) => (
                                                                        <option value={item.state_subdivision_name}>
                                                                            {item.state_subdivision_name}
                                                                        </option>
                                                                        ))}

                                                                        {/* <option value="" disabled>Select Country</option>
                                                                        <option>Andhra Pradesh</option>
                                                                        <option>Arunachal Pradesh</option>
                                                                        <option>Andaman and Nicobar Islands</option> */}
                                                                         

                                                                    </select>
                                                                    {errors.state && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.state.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="zipCode">Zip Code: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control" style={{ position: 'relative' }}
                                                                        name="zipcode"
                                                                        id="zipcode"
                                                                        placeholder="Enter Zip Code"
                                                                        {...register("zipcode", {
                                                                        required: "Zip Code is required",
                                                                        pattern: onlyNumbers,
                                                                        minLength: MinLengthValidation(4),
                                                                        maxLength: MaxLengthValidation(10)
                                                                        })}
                                                                    />
                                                                    {errors.zipcode && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            // marginTop: "5px",
                                                                            position: 'absolute'
                                                                        }}
                                                                        >
                                                                        {errors.zipcode.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                            <div className="col-12">
                                                                <h4 className="mb-3 fw-500">Communication Address:</h4>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        {/* <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="sameAsBilling"
                                                                            {...register("fname", {
                                                                                required: "First Name is required",
                                                                                pattern: {
                                                                                    value: /^[a-zA-Z0-9]*$/,
                                                                                    message: 'Please enter only numbers and alphabets',
                                                                                }
                                                                            })} /> */}

                                                                        {/* <label className="form-check-label fw-500" htmlFor="sameAsBilling">
                                                                            Same as Billing Address Information
                                                                        </label> */}

                                                                        <span className="d-flex align-items-center mb-2"
                                                                            style={{ color: "#000", textAlign: "center" }}>
                                                                            <input
                                                                            type="checkbox"
                                                                            className="ms-2"
                                                                            onChange={handleSameAddressChange}
                                                                            checked={
                                                                                sameAddress ? sameAddress : false
                                                                            }
                                                                            />&nbsp;
                                                                            Same as my billing information

                                                                        </span>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="address">Address: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control"
                                                                        name="cust_address"
                                                                        id="cust_address"
                                                                        placeholder="Enter Address"
                                                                        {...register("cust_address", {
                                                                        required: "Address is required",
                                                                        })}
                                                                        disabled={sameAddress}
                                                                    />
                                                                    {errors.cust_address && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.cust_address.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="city">City: <span style={{ color: "red" }} >*</span></label>
                                                                    <input type="text" class="form-control"
                                                                    name="cust_city"
                                                                    id="cust_city"
                                                                    placeholder="Enter City"
                                                                    {...register("cust_city", {
                                                                    required: "City is required",
                                                                    })}
                                                                    disabled={sameAddress}
                                                                />
                                                                {errors.cust_city && (
                                                                    <div
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "14px",
                                                                        marginTop: "5px",
                                                                    }}
                                                                    >
                                                                    {errors.cust_city.message}
                                                                    </div>
                                                                )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="country">Country: <span style={{ color: "red" }} >*</span></label>
                                                                    <select id="inputState" class="form-select"
                                                                        name="cust_country"
                                                                        {...register("cust_country", {
                                                                        required: "Country is required",
                                                                        })}
                                                                        onChange={(e) => {
                                                                        setSelectedCountryCodeCust(e.target.value);
                                                                        fetchStatesDataCust(e.target.value);
                                                                        setValue('cust_state', '')
                                                                        }}
                                                                        autoComplete="off"
                                                                        disabled={sameAddress}
                                                                    >
                                                                        <option value="">-- Select</option>
                                                                        {countryData.map((item, index) => (
                                                                        <option value={item.country_code_char2}>
                                                                            {item.country_name}
                                                                        </option>
                                                                        ))}

                                                                        {/* <option value="" disabled>Select Country</option>
                                                                        <option>Andhra Pradesh</option>
                                                                        <option>Arunachal Pradesh</option>
                                                                        <option>Andaman and Nicobar Islands</option> */}
                                                                    </select>
                                                                    {errors.cust_country && (
                                                                        <div
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                        >
                                                                        {errors.cust_country.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="state">State: <span style={{ color: "red" }} >*</span></label>
                                                                    {sameAddress ? (
                                                                        <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name="cust_state"
                                                                            {...register("cust_state")}
                                                                            disabled={sameAddress}
                                                                        />
                                                                        ) : (
                                                                        <select id="inputState" class="form-select"
                                                                            name="cust_state"
                                                                            {...register("cust_state", {
                                                                            required: "State is required",
                                                                            })}
                                                                            autoComplete="off"
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {filteredStatesCust.map((item, index) => (
                                                                            <option value={item.state_subdivision_name}>
                                                                                {item.state_subdivision_name}
                                                                            </option>
                                                                            ))}

                                                                        {/* <option value="" disabled>Select Country</option>
                                                                        <option>Andhra Pradesh</option>
                                                                        <option>Arunachal Pradesh</option>
                                                                        <option>Andaman and Nicobar Islands</option> */}
                                                                        </select>)}
                                                                        {errors.cust_state && (
                                                                        <div
                                                                            style={{
                                                                            color: "red",
                                                                            fontSize: "14px",
                                                                            marginTop: "5px",
                                                                            }}
                                                                        >
                                                                            {errors.cust_state.message}
                                                                        </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="zipCode">Zip Code: <span style={{ color: "red" }} >*</span></label>
                                                                        <input type="text" class="form-control" style={{ position: 'relative' }}
                                                                            name="cust_zipcode"
                                                                            id="cust_zipcode"
                                                                            placeholder="Enter Zip Code"
                                                                            {...register("cust_zipcode", {
                                                                            required: "Zip Code is required",
                                                                            pattern: onlyNumbers,
                                                                            minLength: MinLengthValidation(4),
                                                                            maxLength: MaxLengthValidation(10)
                                                                            })}
                                                                            disabled={sameAddress}
                                                                        />
                                                                        {errors.cust_zipcode && (
                                                                            <div
                                                                            style={{
                                                                                color: "red",
                                                                                fontSize: "14px",
                                                                                position: 'absolute'
                                                                            }}
                                                                            >
                                                                            {errors.cust_zipcode.message}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>                                                      

                                                        </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-12 text-end">
                                                            {/* {currentStep < 3 && (
                                                                <button type="button" class="btn btn-primary p-2 mt-3 px-5 float-end"
                                                                data-action="next"
                                                                onClick={handleNextClick}>Next </button>
                                                            )} */}

                                                            {currentStep < 3 && (
                                                                                    <button type="submit" class="btn btn-primary p-2 mt-3 px-5   float-end"
                                                                                    >Submit </button>
                                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    </section>
                                                </form>
                                                        <form
                                                // onSubmit={handleSubmit2(otpVerify)}
                                                >
                                                {/* <!-- Step 3 --> */}
                                                <section id="progress-form__Otp_verify" style={{
                                                    display:
                                                        sussresp === 201 && otpverified != 200 ? "block" : "none",
                                                    }}>
                                                    
                                                    <div className="card card-custom p-2 text-center">
                                                    <h6>Please enter the Code to verify your account</h6>
                                                    <div>
                                                        <span>A code has been sent to</span> <small>{emailid}</small>
                                                    </div>
                                                    <div
                                                        id="otp"
                                                        className="inputs d-flex flex-row justify-content-center mt-2"
                                                    >
                                                        {[...Array(6)].map((_, index) => (
                                                        <input
                                                            key={index}
                                                            className="m-2 text-center form-control rounded"
                                                            type="text"
                                                            maxLength="1"
                                                            value={otp[index]}
                                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                                            ref={(el) => (otpInputsRef.current[index] = el)}
                                                        />
                                                        ))}
                                                    </div>
                                                    {errorOTP && <p className="text-danger">{errorOTP}</p>}
                                                    <div className="mt-4">
                                                        <button className="btn btn-danger px-4 validate" onClick={otpVerify}>
                                                        Validate
                                                        </button>
                                                    </div>
                                                    <p className="resend text-muted mb-0 font-size-18 mt-4">
                                                        Didn't receive code?{" "}
                                                        <a
                                                        type="button"
                                                        href="#/"
                                                        className="text-danger text-decoration-underline"
                                                        onClick={handleResendOtp}
                                                        >
                                                        Resend
                                                        </a>
                                                    </p>
                                                    {/* {otpSent && (
                                                        <p className="text-success font-size-18">Otp has been sent!</p>
                                                    )} */}
                                                    </div>
                                                </section>

                                                </form>

                                                <section id="progress-form__thank-you" style={{ display: otpverified == 200 ? "block" : "none" }}>
                                                    <h2
                                                    className="mb-3 thank-h3"
                                                    style={{
                                                        color: "#000053",
                                                        fontSize: "32px",
                                                        marginBottom: "20px",
                                                        textAlign: "center",
                                                    }}
                                                    >
                                                    Thank you for your registration with
                                                    <br />
                                                    Vitel Global Communications LLC!
                                                    </h2>
                                                    <p
                                                    className="thank-p"
                                                    style={{
                                                        color: "#000",
                                                        fontSize: "18px",
                                                        textAlign: "center",
                                                        lineHeight: "1.556",
                                                    }}
                                                    >
                                                    We truly appreciate your interest and support. Should you have any questions or need assistance, please don't hesitate to reach out to us.
                                                    </p>
                                                    <p
                                                    className="thank-p"
                                                    style={{ color: "#000", textAlign: "center" }}
                                                    >
                                                    <b>
                                                        Best regards, <br />
                                                        The Vitel Support Team
                                                    </b>
                                                    </p>

                                                    <p className="thank-p text-center text-dark"><Link to="/login" className="fw-medium text-primary text-decoration-underline">Ready to begin?
                                                    </Link>  Log in to explore the amazing features that await you. </p>
                                                    {/* <p className="thank-p text-center text-dark">Already have an account ? <Link to="/login" className="fw-medium text-primary"> Login now
                                                    </Link> </p> */}

                                                </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
