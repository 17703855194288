import React, { useEffect, useState } from 'react'
import PageTitle from '../../../common/PageTitle'
import { Modal, Table } from 'react-bootstrap'
import { ConfirmationAlert, formatDateTime, transformText, triggerAlert } from '../../../utils/CommonFunctions';
import { addScheduleBroadcast, deleteScheduleBroadcast, fetchScheduleBroadcast, updateScheduleBroadcast } from '../../../utils/ApiClient';
import PaginationComponent from '../../../common/components/PaginationComponent';
import Loader from '../../../common/components/Loader';
import { Controller, useForm } from 'react-hook-form';
import { onlyAlphaNumericSpaces } from '../../../utils/Constants';
import MultiSelectDyGet from '../../../common/components/selects/MultiSelectDyGet';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DynamicSelect from '../../../common/components/selects/DynamicSelect';
import { useNavigate } from 'react-router-dom';

export default function ScheduleBroadcast() {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [scheduleBroadcastList, setScheduleBroadcastlist] = useState([]);
    const [recipientShow, setRecipientsShow] = useState(false);
    const [viewShow, setViewShow] = useState(false);
    const [modifyShow, setModifyShow] = useState(false);
    const [contactNumbers, setContactNumbers] = useState([]);
    const [modifyMode, setModifyMode] = useState("Add");
    const [selectedReceipientNumbers, setSelectedReceipientNumbers] = useState([]);

    const [pageCount, setPageCount] = useState(0);
    const [perPageLimit, setPerPageLimit] = useState(10);

    const { register, handleSubmit, control, formState: { errors }, setValue, watch, reset } = useForm({
        defaultValues: {
            scheduled_date: new Date()
        }
    });
    ///////////////// Modal functions ///////////////////////////
    const handleRecipientsClose = () => {
        setRecipientsShow(false);

    }
    const handleRecipientsShow = (phoneNumbers) => {
        setSelectedReceipientNumbers(phoneNumbers.split(',')); // Split phone numbers by comma
        setRecipientsShow(true);
    }
    const handleViewClose = () => {
        setViewShow(false);

    }
    const handleViewShow = () => setViewShow(true);
    const handleModifyClose = () => {
        setModifyShow(false);

    }
    const handleModifyShow = () => {
        setModifyShow(true);

    }

    const handleContactSelect = async (selectedOption) => {
        const selectedValues = selectedOption?.map((item) => item.id).join(",");
        setContactNumbers(selectedValues);
        setValue("to_number", selectedOption)
    };
    const handleTemplateSelect = async (selectedOption) => {
        setValue('temp_id', selectedOption ? selectedOption.value : null)
    };
    const handleGroupSelect = async (selectedOption) => {
        setValue('phone_numbers', selectedOption ? selectedOption.value : null)
    };
    /////////////// fetch broadcast list data ///////////////////
    const fetchScheduleBroadcastList = async (page) => {
        setIsLoading(true);
        try {
            if (page) setPageCount(page);
            const params = {
                page_number: page,
                page_size: perPageLimit,
            }
            const response = await fetchScheduleBroadcast(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const items = response_data.results.data;
                setIsLoading(false);
                setScheduleBroadcastlist(items);
                // triggerAlert('success', 'success', 'Recharged Successfully!!');
            } else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data;
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }

    ////////////////////////// Delete broadcast ///////////////////////////////////////////////////////////////////
    const deleteBroadcast = async (selectedRowId) => {
        if (selectedRowId) {
            ConfirmationAlert('You want to continue!', 'Continue', async () => {
                setIsLoading(true);

                try {
                    const params = {
                        id: selectedRowId
                    }
                    const response = await deleteScheduleBroadcast(params);

                    const response_data = response.data;

                    if (response.status === 204) {
                        setIsLoading(false);
                        triggerAlert('success', 'success', 'Broadcast deleted successfully');
                        fetchScheduleBroadcastList(1);
                    } else {
                        setIsLoading(false);
                        triggerAlert('error', 'Oops...', 'Failed to delete broadcast');

                    }
                } catch (error) {
                    const response_data = error?.response?.data
                    setIsLoading(false);
                    triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
                }
            })
        } else {
            triggerAlert("error", "Oops...", "Please select a broadcast");
        }
    }

    useEffect(() => {
        fetchScheduleBroadcastList(1);
    }, [])

    // Pagination
    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setPageCount(selectedPage);

        fetchScheduleBroadcastList(selectedPage + 1); // Increment the page number by 1 for server-side pagination
    };
    let props = {
        pageCount: pageCount,
        handlePageClick: handlePageClick,
    };

    const handleScheduleHistory = () => {
        navigate('/whatsapp/broadcast/broadcast_history')
    }

    const modifyScheduleBroadcast = async (data) => {
        // console.log("data", data)
        setIsLoading(true);
        try {
            const api_input = data;
            api_input.phone_numbers = api_input.type == "contact" ? contactNumbers : String(api_input.phone_numbers);
            api_input.scheduled_date = formatDateTime(api_input.scheduled_date, 'yyyy-mm-dd hh:mm:ss');
            let response;
            if (modifyMode == "Add") {
                response = await addScheduleBroadcast(api_input);
            }

            else {
                api_input.id = data.id
                response = await updateScheduleBroadcast(api_input);
            }
            const response_data = response.data;
            const response_status = modifyMode === "Add" ? 201 : 200;

            if (response.status === response_status) {
                const items = response_data;
                setIsLoading(false);
                handleModifyClose();
                fetchScheduleBroadcastList(1);
                triggerAlert('success', 'success', items.message || 'Schedule broadcast added Successfully!!');
            } else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Unable to add schedule broadcast');
            }
        } catch (error) {
            console.error(error)
            const response_data = error?.response?.data;
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }

    const setDefaultValuesforEdit = (item) => {
        setValue("temp_id", item.temp_id);
        setValue("scheduled_date", item.scheduled_date);
        setValue("type", item.type);
        setValue("id", item.id);
        if (item.type === "contact") {
            let device_list = item.phone_numbers;
            const device_array = device_list.split(',').map(item => ({ label: item, value: item }));
            setValue("to_number", device_array);
            setContactNumbers(device_list)
        } else {
            setValue("phone_numbers", item.group_id);
        }
    }

    const editBroadcast = (item) => {
        setModifyMode("Update");
        setDefaultValuesforEdit(item);
        handleModifyShow();
    }
    return (
        <>
            <div>
                <div class="position-relative">
                </div>
                <div id="content-page" class="content-page">
                    <div class="container">

                        <PageTitle heading="Scheduled Broadcast"

                            showPrimaryButton="Scheduled History" onPrimaryClick={handleScheduleHistory}
                            showWarningButton="New Broadcast" onWarningClick={() => {
                                setModifyMode("Add");
                                reset();
                                handleModifyShow();
                            }
                            } />
                        <div class="row mb-5">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body ">
                                        <div class="table-responsive">
                                            <Table id="example" class="table hover" cellspacing="0" width="100%" bordered >
                                                <thead style={{ backgroundColor: "rgb(237, 237, 237)" }}>
                                                    <tr>
                                                        <th>Broadcast Names</th>
                                                        <th>Broadcast Type</th>
                                                        <th>Scheduled</th>
                                                        <th>Recipients</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ? <div className='loader-overlay text-white'>
                                                        <Loader />
                                                    </div> :
                                                        scheduleBroadcastList?.length > 0 ?
                                                            scheduleBroadcastList.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.temp_name ? item.temp_name : '-'}</td>
                                                                    <td>{item.temp_type ? transformText(item.temp_type, 'capitalize') : '-'}</td>
                                                                    <td>{item.scheduled_date ? formatDateTime(item.scheduled_date, 'yyyy-mm-dd hh:mm:ss') : '-'}</td>
                                                                    <td><a href="#/"><span class="badge badge-circle2  p-6" title="View Template"
                                                                        onClick={() => handleRecipientsShow(item.phone_numbers)}
                                                                        style={{ cursor: 'pointer' }}>{item.recipient ? item.recipient : '-'}</span></a></td>
                                                                    <td>{item.status ? item.status === 1 ? "Scheduled" : "-" : '-'}</td>
                                                                    <td>
                                                                        <div class="d-flex   align-items-center  ">
                                                                            <a href="#/" onClick={handleViewShow}><span class="badge badge-circle2 text-info p-6 me-2" title="View Template"><span class="material-symbols-outlined fs-4">remove_red_eye</span> </span></a>
                                                                            <a href="#/" onClick={() => deleteBroadcast(item.id)}><span class="badge badge-circle2 text-danger p-6 me-2" title="View Template"><span class="material-symbols-outlined fs-4">delete</span> </span></a>
                                                                            <a href="#/" onClick={() => editBroadcast(item)}><span class="badge text-primary badge-circle2  p-6" title="View Template"><span class="material-symbols-outlined fs-4" >edit</span> </span></a>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            )) :
                                                            <tr>
                                                                <td colSpan="10" className="text-center">
                                                                    No data available
                                                                </td>
                                                            </tr>

                                                    }


                                                </tbody>
                                            </Table>
                                            <PaginationComponent {...props} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <Modal show={recipientShow} onHide={handleRecipientsClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Recipients</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-5">
                        {selectedReceipientNumbers?.length > 0 ? (
                            <ul>
                                {selectedReceipientNumbers.map((phone, index) => (
                                    <li key={index}>{phone}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>No phone numbers available</p>
                        )}
                    </Modal.Body>
                </Modal>
                <Modal show={viewShow} onHide={handleViewClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>View Template</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-5">
                        <div class="card-body bg-light-modal border p-4">
                            <div class="d-flex flex-column justify-content-between">
                                <h6 class="mb-1 fw-500">testing templte {1}</h6>
                                <p class="mb-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s {1}</p>
                                <span class="previewFooter_send ng-binding ">Not interested? Tap Stop promotions</span>
                            </div>
                            <hr />
                            <div class="d-flex flex-column justify-content-between align-items-center">
                                <div class="d-flex align-items-center  ">
                                    <i class="fa fa-reply me-2" aria-hidden="true  "></i>
                                    <span></span>welcome to sales 2
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Add BroadcastModal */}
                <Modal show={modifyShow} onHide={handleModifyClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{modifyMode ? modifyMode : ''} Scheduled Template</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit(modifyScheduleBroadcast)}>
                        <Modal.Body className="p-5">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="form-group  mb-2">
                                        <label class="form-label mb-2" for="t_name">Template Name<span class="text-danger">*</span>
                                        </label>
                                        <Controller
                                            name="temp_id"
                                            {...register('temp_id',
                                                { required: 'Template name is required' }
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <DynamicSelect
                                                    {...field}
                                                    apiUrl={api_url + 'boardcasting/templates/'}
                                                    placeholder="Select template"
                                                    mapOption={result => ({
                                                        value: result.id,
                                                        label: result.template_name,
                                                    })}
                                                    value={field.value}
                                                    onSelect={handleTemplateSelect}
                                                />
                                            )}
                                        />
                                        {errors.temp_id && (
                                            <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                {errors.temp_id.message}
                                            </div>
                                        )}
                                    </div>
                                    <div class="form-group  mb-2">
                                        <label class="form-label mb-2" for="type_of_contact">Select Contact or Group<span class="text-danger ">*</span></label>



                                        <select class="form-select"
                                            name="type"
                                            {...register("type", {
                                                required: "Contact is required",
                                            })}>
                                            <option value="" hidden>Select Contact</option>
                                            <option value="contact">Contact</option>
                                            <option value="group">Group</option>
                                        </select>
                                        {errors.type && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.type.message}
                                            </div>
                                        )}
                                    </div>
                                    {watch("type") === "contact" &&
                                        <div class="form-group  mb-2">
                                            <label class="form-label mb-2" for="type_of_contact">Select or enter phone number<span class="text-danger ">*</span></label>
                                            <Controller
                                                name="to_number"
                                                {...register('to_number',
                                                    { required: 'Contact is required' }
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <MultiSelectDyGet
                                                        {...field}
                                                        apiUrl={api_url + 'sms/get_all_contact_list/'}
                                                        placeholder="Select phone number"
                                                        mapOption={result => ({
                                                            value: result.contact_number,
                                                            label: result.contact_number,
                                                            id: result.id
                                                        })}
                                                        value={field.value}
                                                        onSelect={handleContactSelect}
                                                    />
                                                )}
                                            />
                                            {errors.to_number && (
                                                <div
                                                    style={{
                                                        color: "red",
                                                        fontSize: "14px",
                                                        marginTop: "5px",
                                                    }}>{errors.to_number.message}
                                                </div>
                                            )}
                                        </div>}
                                    {watch("type") === "group" &&
                                        <div class="form-group  mb-2">
                                            <label class="form-label mb-2" for="type_of_contact">Select Group<span class="text-danger ">*</span></label>

                                            <Controller
                                                name="phone_numbers"
                                                {...register('phone_numbers',
                                                    { required: 'Group is required' }
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <DynamicSelect
                                                        {...field}
                                                        apiUrl={api_url + 'contact/template_groups_list/'}
                                                        placeholder="Select phone number"
                                                        mapOption={result => ({
                                                            value: result.id,
                                                            label: result.group_name,
                                                        })}
                                                        value={field.value}
                                                        onSelect={handleGroupSelect}
                                                    />
                                                )}
                                            />
                                            {errors.phone_numbers && (
                                                <div
                                                    style={{
                                                        color: "red",
                                                        fontSize: "14px",
                                                        marginTop: "5px",
                                                    }}>{errors.phone_numbers.message}
                                                </div>
                                            )}
                                        </div>
                                    }

                                    <div class="form-group">
                                        <div class=" ">
                                            <label for="payment_due_at " class="form-label mb-2">Schedule (EST Timezone)</label>
                                            <div className="w-100" >
                                                <Controller
                                                    control={control}
                                                    name="scheduled_date" // Assign the name here
                                                    class="form-control "

                                                    render={({ field, }) => (
                                                        <DatePicker
                                                            selected={field.value ? new Date(field.value) : new Date()}
                                                            onChange={(date
                                                            ) => {
                                                                setValue("scheduled_date", date);
                                                                field.onChange(date ? date : null);
                                                            }}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={30}
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            placeholderText="YYYY-MM-DD HH:MM:SS"
                                                            className="form-control custom-datepicker-widthCol7" // Use Bootstrap's form-control class for full width

                                                        // minDate={new Date()}
                                                        // showMonthDropdown showYearDropdown
                                                        />
                                                    )}
                                                />
                                            </div>

                                            {errors.scheduled_date && (
                                                <div
                                                    style={{
                                                        color: "red",
                                                        fontSize: "14px",
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    {errors.scheduled_date.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {modifyMode === "Update" &&

                                        <input type="text" className="form-control" {...register('id')} name='id' hidden />
                                        // {/* {errors.description && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.description.message}</div>)} */}

                                    }



                                </div>
                                <div class="col-md-5">
                                    <p class="fw-500 mb-2 text-center">Preview</p>
                                    <div class="card-body bg-light-modal border p-4">
                                        <div class="d-flex flex-column justify-content-between">
                                            <h6 class="mb-1 fw-500">testing templte {1}</h6>
                                            <p class="mb-1"> </p>
                                            <span class="previewFooter_send ">Not interested? Tap Stop promotions</span>
                                        </div>
                                        <hr />
                                        <div class="d-flex flex-column justify-content-between align-items-center">
                                            <div class="d-flex align-items-center  ">
                                                <i class="fa fa-reply me-2" aria-hidden="true  "></i>
                                                <span></span>welcome to sales 2
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body >
                        <Modal.Footer>
                            <button type="button" class="btn btn-warning" onClick={handleModifyClose}>Cancel</button>

                            <button type="submit" class="btn btn-primary">{modifyMode ? modifyMode : "Save"}</button>
                        </Modal.Footer>
                    </form>
                </Modal >


            </div >
        </>
    )
}
