import React from 'react'
import MetaTitle from './MetaTitle'

export default function PageTitle({ heading, showPrimaryButton, showWarningButton, onPrimaryClick, onWarningClick, otherElements }) {
    const props = {
        title: heading + " | Social media Sync ",
        description: "Premium Multipurpose Admin & Dashboard Template"
    }

    return (
        <>
            <MetaTitle {...props} />
            <div className="row w-100 mb-4 mt-5">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                    {heading && <h4 className="fw-bold text-primary">{heading}</h4>}
                    <div className="d-flex align-items-center">
                        {otherElements}
                        {showWarningButton && (
                            <button
                                type="button"
                                className="btn btn-warning d-flex align-items-center"
                                onClick={onWarningClick}
                            >
                                <span className="ms-2">{showWarningButton}</span>
                            </button>
                        )}
                        {showPrimaryButton && (
                            <button
                                type="button"
                                className="btn btn-primary ms-2 d-flex align-items-center"
                                onClick={onPrimaryClick}
                            >
                                <span className="ms-2">{showPrimaryButton}</span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}


