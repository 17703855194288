import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { fetchSubUsersList, createSubUser, deleteSubUser, editSubUser, fetchSubUserPermission, UpdateSubUserPermission } from '../../utils/ApiClient';
import { triggerAlert, handleTableRowClick, ConfirmationAlert, simpleAlert } from '../../utils/CommonFunctions';
import { MaxLengthValidation, MinLengthValidation, onlyNumbers, passwordPattern } from "../../utils/Constants";
import PaginationComponent from "../../common/components/PaginationComponent";
import Loader from '../../common/components/Loader';
import Table from "react-bootstrap/Table";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Members = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const { register, handleSubmit, control, formState: { errors }, setValue, watch, reset } = useForm();
    const { register: register4, handleSubmit: handleSubmit4, formState: { errors: errors4 }, setValue: setValue4, control: control4, reset: reset4 } = useForm();

    const [subUserList, setSubUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPageLimit, setPerPageLimit] = useState(10);

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [permissionShow, setPermissionShow] = useState(false);
    const [userPermissionId, setUserPermissionId] = useState("");
    const [mgUserpermissionShow, setMgUsersPermission] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [existingPermissions, setExistingPermissions] = useState([]);
    const [checkedParentIds, setCheckedParentIds] = useState([]);
    const [checkedChildIds, setCheckedChildIds] = useState({});
    const [checkedActions, setCheckedActions] = useState({});


    const watchword = watch("password");

    const toggleVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    const onSubmit = async (data) => {


        setIsLoading(true);
        try {
            const params = data
            let response = "";
            if (data['sub_uesr_id']) {
                response = await editSubUser(data['sub_uesr_id'], params);
            } else {
                response = await createSubUser(params);
            }

            const response_data = response.data;
            const response_status = data['sub_uesr_id'] ? 200 : 201;
            setSelectedRowId(null);
            setSelectedRow(null);
            if (response_data.error_code === response_status) {
                fetchSubUsers();
                reset();
                setIsFormVisible(!isFormVisible);
                setIsLoading(false);
                triggerAlert('success', 'success', 'Sub user created successfully!!');
            } else {
                setSubUserList([])
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Failed to create sub user');
            }
        } catch (error) {
            const response_data = error?.response?.data;
            setSelectedRowId(null);
            setSelectedRow(null);
            if (response_data?.error_code === 400) {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', response_data?.message);
            } else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
            }

        }
        // Handle form submission
    };

    // Fetch user data
    const fetchSubUsers = async () => {
        setIsLoading(true);
        try {
            const params = {
                page: currentPage + 1,
                per_page: perPageLimit
            }
            const response = await fetchSubUsersList(params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const data = Object.values(response.data.results.data);
                const total_pages = response.data.results.pagination.total_pages;
                const total_items = response.data.results.pagination.total_items;
                setIsLoading(false);
                setSubUserList(data);
                if (total_items == 0) {
                    setPageCount(0);
                } else {
                    setPageCount(total_pages);
                }

                // triggerAlert('success', 'success', 'Recharged Successfully!!');
            } else {
                setSubUserList([])
                setPageCount(0);
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Recharge was unsuccessful');
            }
        } catch (error) {
            const response_data = error?.response?.data
            setIsLoading(false);
            triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
        }
    }
    // Delete User
    const deleteSubUsers = async () => {
        if (selectedRowId) {
            ConfirmationAlert('You want to continue!', 'Continue', async () => {
                setIsLoading(true);

                try {
                    const response = await deleteSubUser(selectedRowId);

                    const response_data = response.data;

                    if (response_data.error_code === 200) {
                        setIsLoading(false);
                        triggerAlert('success', 'success', 'Sub user deleted successfully');
                        fetchSubUsers();
                    } else {
                        setIsLoading(false);
                        triggerAlert('error', 'Oops...', 'Failed to delete sub user');

                    }
                } catch (error) {
                    const response_data = error?.response?.data
                    setIsLoading(false);
                    triggerAlert('error', 'Oops...', response_data ? response_data.message : "Something went wrong!");
                }
            })
        } else {
            triggerAlert('info', "", "Please select a sub user");
        }
    }
    // Edit sub user
    const editSubUsers = () => {
        if (selectedRowId) {
            setValue("name", selectedRow.name);
            setValue("phone_no", selectedRow.phone_no);
            setValue("watch_word", selectedRow.watch_word);
            setValue("password", selectedRow.watch_word);
            setValue("customer", selectedRow.customer);
            setValue("user_email", selectedRow.user_email);
            setValue("role", selectedRow.role);
            setValue("status", selectedRow.status);
            setValue("sub_uesr_id", selectedRow.id);

            setIsFormVisible(true);
        } else {
            triggerAlert("info", "", "Please select a contact");
        }
    };

    const cancelForm = () => {
        setIsFormVisible(!isFormVisible);
        reset()
    };
    // Pagination
    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setCurrentPage(selectedPage);

        fetchSubUsers(); // Increment the page number by 1 for server-side pagination
    };

    let props = {
        pageCount: pageCount,
        handlePageClick: handlePageClick,
    };
    // /////////////////////////////////////

    // Permission
    const handleMgUsersPermissionShow = () => setMgUsersPermission(true);

    const editSubUserPermission = async (id, name) => {
        setPermissionShow(true)

        try {
            const params = {
                sub_user_id: id
            }
            const response = await fetchSubUserPermission(params);
            const respdata = response.data.results.data;
            if (respdata?.permissions?.length === 0 && respdata?.menu_data?.length === 0) {
                setValue4("user_id", name);
                setUserPermissionId(id);
                handleMgUsersPermissionShow();
            } else {
                setValue4("user_id", name);
                setUserPermissionId(id);
                setMenuData(respdata.menu_data);
                setExistingPermissions(respdata.permissions);

                const permissions = respdata.permissions
                // Initialize checkedParentIds with parent IDs from permissions
                const parentIds = permissions?.flatMap(permission => permission.parent_id?.split(',').map(id => parseInt(id.trim())));
                setCheckedParentIds(parentIds);


                // Initialize checkedChildIds
                const childIds = permissions?.reduce((acc, permission) => {
                    const childIdsArr = permission?.child_id?.split(",").map(id => parseInt(id));
                    return { ...acc, ...childIdsArr?.reduce((obj, childId) => ({ ...obj, [childId]: true }), {}) };
                }, {});
                setCheckedChildIds(childIds);

                // Initialize checkedActions
                const updatedCheckedActions = {};

                // Iterate over the permissions received from the API
                permissions.forEach(permission => {
                    // Iterate over each key in the permission object
                    for (const action in permission) {
                        // Skip non-action keys such as id, parent_id, child_id, etc.
                        if (action !== "id" && action !== "parent_id" && action !== "child_id" && action !== 'created_at' && action !== 'updated_at') {
                            // Check if the permission[action] exists and is a string
                            if (typeof permission[action] === "string" && permission[action].trim() !== "" && permission[action] !== "0") {
                                // If the action key doesn't exist in updatedCheckedActions, create an empty array
                                if (!updatedCheckedActions[action]) {
                                    updatedCheckedActions[action] = [];
                                }
                                // Split the values by comma, convert them to integers, and add them to the corresponding action array
                                updatedCheckedActions[action].push(...permission[action]?.split(",").map(id => parseInt(id)));
                            }
                        }
                    }
                });
                setCheckedActions(updatedCheckedActions);



                handleMgUsersPermissionShow();
            }
        } catch (error) {
            console.log(error)
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };

    const mgUserPermissionEdit = async (data) => {

        // Create a Set to store unique parent and child IDs
        const uniqueParentIds = new Set(checkedParentIds);
        const uniqueChildIds = new Set(Object.keys(checkedChildIds));

        // Convert the Sets back to arrays and join them into comma-separated strings
        const parent_id = [...uniqueParentIds].join(",");
        const child_id = [...uniqueChildIds].join(",");

        const payload = {
            user_id: userPermissionId, // Replace with the actual user ID
            parent_id,
            child_id,
        };

        // Iterate over the keys of checkedActions and include them in the payload
        for (const action in checkedActions) {
            payload[action] = checkedActions[action].join(",");
        }
        // console.log(payload)
        // return
        try {
            const id = userPermissionId;
            // console.log(data); 


            const response = await UpdateSubUserPermission(id, payload);
            const respdata = response.data.results.data;

            if (response.data.error_code == "200") {
                setPermissionShow(false)
                triggerAlert(
                    "success",
                    "success",
                    "Permissions got updated successfully"
                );
            } else {
                triggerAlert("error", "Oops...", "Something went wrong..");
            }
        } catch (error) {
            const response_data = error?.response?.data;
            if (response_data?.error_code === 401) {

                triggerAlert('error', 'Oops...', response_data?.message);
            } else {

                triggerAlert('error', 'Oops...', 'Something went wrong..');
            }
        }
    };

    // Function to handle checkbox change

    const handleCheckbox = (e, menuItem) => {
        const { id, menu_pid, page_action, submenu } = menuItem;
        const action = e.target.name;

        if (menu_pid === 0) {
            // Handle parent checkboxes
            if (e.target.checked) {
                setCheckedParentIds(prevIds => [...prevIds, id]);

                // Check all child and nested subchild checkboxes
                submenu && submenu.forEach(child => {
                    setCheckedChildIds(prevChildIds => ({ ...prevChildIds, [child.id]: true }));
                    child?.submenu && child?.submenu?.forEach(subChild => {
                        setCheckedChildIds(prevChildIds => ({ ...prevChildIds, [subChild.id]: true }));
                    });
                });

                // Store parent action
                if (page_action && action !== "") {
                    setCheckedActions(prevActions => ({
                        ...prevActions,
                        [action]: [...(prevActions[action] || []), id],
                    }));
                }
            } else {
                setCheckedParentIds(prevIds => prevIds?.filter(parentId => parentId !== id));

                // Uncheck all child and nested subchild checkboxes
                submenu && submenu?.forEach(child => {
                    setCheckedChildIds(prevChildIds => {
                        const updatedChildIds = { ...prevChildIds };
                        delete updatedChildIds[child.id];
                        return updatedChildIds;
                    });
                    child?.submenu && child?.submenu?.forEach(subChild => {
                        setCheckedChildIds(prevChildIds => {
                            const updatedChildIds = { ...prevChildIds };
                            delete updatedChildIds[subChild.id];
                            return updatedChildIds;
                        });
                    });
                });

                // Remove parent action
                if (page_action && action !== "") {
                    setCheckedActions(prevActions => ({
                        ...prevActions,
                        [action]: (prevActions[action] || [])?.filter(actionId => actionId !== id),
                    }));
                }
            }
        } else {
            // Handle child and subchild checkboxes
            if (e.target.checked) {
                setCheckedChildIds(prevChildIds => ({ ...prevChildIds, [id]: true }));

                // Store child action
                if (page_action && action !== "") {
                    setCheckedActions(prevActions => ({
                        ...prevActions,
                        [action]: [...(prevActions[action] || []), id],
                    }));
                }

                // Check parent if not already checked
                if (!checkedParentIds?.includes(menu_pid)) {
                    setCheckedParentIds(prevIds => [...prevIds, menu_pid]);
                }
            } else {
                setCheckedChildIds(prevChildIds => {
                    const updatedChildIds = { ...prevChildIds };
                    delete updatedChildIds[id];
                    return updatedChildIds;
                });

                // Remove child action
                if (page_action && action !== "") {
                    setCheckedActions(prevActions => ({
                        ...prevActions,
                        [action]: (prevActions[action] || [])?.filter(actionId => actionId !== id),
                    }));
                }

                // Uncheck parent if no child is checked
                const parentMenu = menuData?.find(menu => menu.id === menu_pid);
                const childIds = parentMenu?.submenu.map(submenu => submenu.id);
                const isNoChildChecked = childIds?.every(childId => !checkedChildIds[childId]);
                if (isNoChildChecked && checkedParentIds?.includes(menu_pid)) {
                    setCheckedParentIds(prevIds => prevIds?.filter(parentId => parentId !== menu_pid));
                }
            }
        }
    };

    function formatActionText(text) {
        return text.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    /////////////////////////////


    useEffect(() => {
        fetchSubUsers();
    }, []);
    return (
        <>
            {isLoading && (
                <div className='loader-overlay text-white'>
                    <Loader />
                </div>
            )}
            <div class="tab-pane fade show" id="pills-Invite-fill" role="tabpanel" aria-labelledby="pills-Invite-tab-fill">

                {!isFormVisible &&
                    <div className="mb-3 me-3 text-end">
                        <div className="btn-group">
                            <button type="button" class="btn btn-warning dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Action
                            </button>
                            <div class="dropdown-menu dropdown-menu-end py-2">
                                <a type='button' class="dropdown-item" onClick={toggleVisibility} ><i class="fa fa-user-plus font-size-14" ></i> Add</a>
                                <a type='button' class="dropdown-item" onClick={deleteSubUsers}><i class="fa fa-trash-o font-size-14"></i> Delete  </a>
                                <a type='button' class="dropdown-item" onClick={editSubUsers}><i class="fa fa-pencil-square-o font-size-14"></i> Edit  </a>
                            </div>
                        </div>

                    </div>
                }

                {isFormVisible ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-4">
                                <label className="form-label" htmlFor="city">
                                    Name <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="city"
                                    className="form-control"
                                    placeholder="Sam"
                                    {...register('name', {
                                        required: 'Name is required',
                                        pattern: {
                                            value: /^[a-zA-Z]*[\w -]*[a-zA-Z]$/,
                                            message: 'Invalid Name format',
                                        },
                                    })}
                                />
                                {errors.name && (
                                    <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                        {errors.name.message}
                                    </div>
                                )}
                            </div>
                            <div className="col-4">
                                <label className="form-label" htmlFor="email">
                                    Login Email <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="email"
                                    name="user_email"
                                    id="user_email"
                                    className="form-control"
                                    placeholder="sample@gmail.com"
                                    {...register('user_email', {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                            message: 'Invalid Email Id format',
                                        },
                                    })}
                                />
                                {errors.user_email && (
                                    <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                        {errors.user_email.message}
                                    </div>
                                )}
                            </div>
                            <div className="col-4">
                                <label className="form-label" htmlFor="phone_no">
                                    Phone <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    name="phone_no"
                                    id="phone_no"
                                    className="form-control"
                                    placeholder="0000000000"
                                    {...register('phone_no', {
                                        required: 'Phone no is required',
                                        maxLength: MaxLengthValidation(15),
                                        minLength: MinLengthValidation(10),
                                        pattern: onlyNumbers,
                                    })}
                                />
                                {errors.phone_no && (
                                    <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                        {errors.phone_no.message}
                                    </div>
                                )}
                            </div>
                            <div className="col-4">
                                <label className="form-label" htmlFor="password">
                                    Password <span style={{ color: 'red' }}>*</span>
                                </label>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="password"
                                            id="password"
                                            className="form-control"
                                            {...register('password', {
                                                required: 'Password is required',
                                                maxLength: MaxLengthValidation(25),
                                                minLength: MinLengthValidation(6),
                                                pattern: passwordPattern
                                            })}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue('watch_word', e.target.value);
                                            }}
                                        />
                                    )}
                                />
                                {errors.password && (
                                    <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                        {errors.password.message}
                                    </div>
                                )}
                                <Controller
                                    name="watch_word"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="hidden"
                                            {...register('watch_word')}
                                            value={watchword} // Assign the same value
                                        />
                                    )}
                                />
                            </div>

                            <div className="col-4">
                                <label className="form-label" htmlFor="role">
                                    Role <span style={{ color: 'red' }}>*</span>
                                </label>
                                <Controller
                                    control={control}
                                    name="role"
                                    rules={{
                                        required: "Role is required",
                                    }}
                                    render={({ field }) => (
                                        <select
                                            class="form-select"
                                            name="role"
                                            aria-label="Default select example"
                                            onChange={field.onChange}
                                            value={field.value}
                                        >
                                            <option selected="" hidden>Select</option>
                                            <option value="Admin">Admin</option>
                                            <option value="Suser">Sub User</option>
                                            <option value="Auser">Account User</option>
                                        </select>
                                    )}
                                />
                                {errors.role && (
                                    <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                        {errors.role.message}
                                    </div>
                                )}
                            </div>

                            <div className="col-4">
                                <label className="form-label" htmlFor="role">
                                    Status <span style={{ color: 'red' }}>*</span>
                                </label>
                                <Controller
                                    control={control}
                                    name="status"
                                    rules={{
                                        required: "Status is required",
                                    }}
                                    render={({ field }) => (
                                        <select
                                            class="form-select"
                                            name="status"
                                            aria-label="Default select example"
                                            onChange={field.onChange}
                                            value={field.value}
                                        >
                                            <option selected="" hidden>Select</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    )}
                                />
                                {errors.status && (
                                    <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                        {errors.status.message}
                                    </div>
                                )}
                            </div>


                        </div>
                        <input type="hidden" {...register('sub_uesr_id')} />
                        <button type="button" className="btn btn-warning mt-3" onClick={cancelForm}>Cancel</button>
                        <button type="submit" className="btn btn-primary mt-3 ms-2">Submit</button>
                    </form>
                ) : (
                    <div className="">
                        <div className="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered hover" cellspacing="0" width="100%">
                                    <thead class="text-nowrap">
                                        <tr style={{ backgroundColor: "#ededed" }}>
                                            <th scope="col">Name/Phone</th>
                                            <th scope="col">Role/Email</th>
                                            <th scope="col">Permissions</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subUserList.map(sub_user => (
                                            <tr onClick={() => handleTableRowClick(sub_user, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, sub_user.id)} className={selectedRowId === sub_user.id ? 'row_selected' : ''}>
                                                <td scope="row">
                                                    <div class="d-flex">
                                                        <div class="user-img img-fluid flex-shrink-0">
                                                            {/* <img src="assets/images/user/05.jpg" alt="story-img" class="rounded-circle avatar-40" loading="lazy"  /> */}
                                                        </div>
                                                        <div class=" ms-3 text-nowrap">
                                                            <h6>{sub_user.name}</h6>
                                                            <p class="mb-0">{sub_user.phone_no}</p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td >
                                                    {sub_user.role === "Suser" ? (
                                                        <span className="text-dark fw-bold mb-1 fs-6">
                                                            Sub User
                                                        </span>
                                                    ) : sub_user.role === "Auser" ? (
                                                        <span className="text-dark fw-bold mb-1 fs-6">
                                                            Account User
                                                        </span>
                                                    ) : (
                                                        <span className="text-dark fw-bold mb-1 fs-6">
                                                            {sub_user.role}
                                                        </span>
                                                    )}
                                                    <br />
                                                    {sub_user.user_email}
                                                </td>
                                                <td>

                                                    <a type='button' onClick={() => editSubUserPermission(sub_user.id, sub_user.name)} class="dropdown-item"  ><i class="fa fa-user-plus font-size-14"></i> Permissions  </a>
                                                </td>
                                                <td >
                                                    <div class="confirm-click-btn">
                                                        {sub_user.status === "active" ? (
                                                            <span class="badge bg-success border-radius  rounded-pill ">Active</span>
                                                        ) : (
                                                            <span class="badge bg-warning border-radius  rounded-pill ">Inactive</span>
                                                        )}
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                                <PaginationComponent {...props} />
                                <Modal
                                    size="lg"
                                    show={permissionShow}
                                    onHide={() => setPermissionShow(false)}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title id="example-modal-sizes-title-lg">
                                            User Permission
                                        </Modal.Title>
                                    </Modal.Header>
                                    <form
                                        id="creditCardForm4"
                                        class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container"
                                        onSubmit={handleSubmit4(mgUserPermissionEdit)}
                                    >
                                        <Modal.Body>
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="form-label w-100" for="paymentCard">
                                                        Sub Username
                                                    </label>
                                                    <input type="text"
                                                        className="form-control"
                                                        id="formrow-email-input"
                                                        {...register4('user_id',
                                                            // { required: 'Username is required' }
                                                        )}
                                                        name="user_id"
                                                        disabled
                                                    />

                                                    {errors4.user_id && (
                                                        <div
                                                            style={{ color: "red", fontSize: "14px", marginTop: "5px" }}
                                                        >
                                                            {errors4.user_id.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <br />
                                            <div class="row">
                                                <div className="col-md-12">
                                                    <Table
                                                        className="table table-bordered dt-responsive nowrap"
                                                    >
                                                        <thead>
                                                            <tr style={{ backgroundColor: "#ededed" }}>
                                                                <th>Name</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* Parent and SubItem list */}
                                                            {menuData.map(parent_menu => (
                                                                <React.Fragment key={parent_menu.id}>
                                                                    {/* Parent menu item */}
                                                                    <tr>
                                                                        <td>
                                                                            <div className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    checked={checkedParentIds.includes(parent_menu.id)}
                                                                                    onChange={e => handleCheckbox(e, parent_menu)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                    {parent_menu.menu_name}
                                                                                </label>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {/* Display parent menu actions */}
                                                                            {parent_menu.page_action && (
                                                                                <div className="d-flex justify-content-start flex-start">
                                                                                    {Object.entries(parent_menu.page_action)
                                                                                        .filter(([action, checked]) => checked == 1)
                                                                                        .map(([action, checked]) => (
                                                                                            <div className="form-check mx-3" key={action}>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="form-check-input"
                                                                                                    name={action}
                                                                                                    checked={checkedActions[action]?.includes(parent_menu.id)}
                                                                                                    onChange={e => handleCheckbox(e, parent_menu)}
                                                                                                />
                                                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                                    {formatActionText(action)}
                                                                                                </label>
                                                                                            </div>
                                                                                        ))}
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    {/* SubItem list */}
                                                                    {parent_menu?.submenu &&
                                                                        parent_menu?.submenu?.map(submenuItem => (
                                                                            <React.Fragment key={submenuItem.id}>
                                                                                <tr >
                                                                                    <td>
                                                                                        <div className="ms-3"> {/* Adjust margin for nested items */}
                                                                                            <div className="form-check">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="form-check-input"
                                                                                                    checked={checkedChildIds[submenuItem.id] || false}
                                                                                                    onChange={e => handleCheckbox(e, submenuItem)}
                                                                                                />
                                                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                                    {submenuItem.menu_name}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {/* Display submenu actions */}
                                                                                        {submenuItem.page_action && (
                                                                                            <div className="d-flex justify-content-start flex-start">
                                                                                                {Object.entries(submenuItem.page_action)
                                                                                                    .filter(([action, checked]) => checked == 1)
                                                                                                    .map(([action, checked]) => (
                                                                                                        <div className="form-check mx-3" key={action}>
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input"
                                                                                                                name={action}
                                                                                                                checked={checkedActions[action]?.includes(submenuItem.id)}
                                                                                                                onChange={e => handleCheckbox(e, submenuItem)}
                                                                                                            />
                                                                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                                                {formatActionText(action)}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    ))}
                                                                                            </div>
                                                                                        )}


                                                                                    </td>
                                                                                </tr>
                                                                                {submenuItem.submenu &&
                                                                                    submenuItem.submenu.map(nestedSubmenuItem => (
                                                                                        <React.Fragment key={nestedSubmenuItem.id}>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className="ms-5"> {/* Adjust margin for nested items */}
                                                                                                        <div className="form-check">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="form-check-input"
                                                                                                                checked={checkedChildIds[nestedSubmenuItem.id] || false}
                                                                                                                onChange={e => handleCheckbox(e, nestedSubmenuItem)}
                                                                                                            />
                                                                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                                                {nestedSubmenuItem.menu_name}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        {/* Display submenu actions */}

                                                                                                    </div>
                                                                                                </td>
                                                                                                <td >
                                                                                                    {/* Display submenu actions */}
                                                                                                    {nestedSubmenuItem.page_action && (
                                                                                                        <div className="d-flex justify-content-start flex-start">
                                                                                                            {Object.entries(nestedSubmenuItem.page_action)
                                                                                                                .filter(([action, checked]) => checked == 1)
                                                                                                                .map(([action, checked]) => (
                                                                                                                    <div className="form-check mx-3" key={action}>
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            className="form-check-input"
                                                                                                                            name={action}
                                                                                                                            checked={checkedActions[action]?.includes(nestedSubmenuItem.id)}
                                                                                                                            onChange={e => handleCheckbox(e, nestedSubmenuItem)}
                                                                                                                        />
                                                                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                                                            {formatActionText(action)}
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                        </div>
                                                                                                    )}

                                                                                                </td>
                                                                                            </tr>
                                                                                        </React.Fragment>
                                                                                    ))}
                                                                            </React.Fragment>
                                                                        ))}

                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>

                                                    </Table>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button
                                                type="submit"
                                                className="btn btn-primary  btn-rounded waves-effect waves-light btn-md me-md-2 px-5"
                                            >
                                                Submit
                                            </button>
                                        </Modal.Footer>
                                    </form>
                                </Modal>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Members;
