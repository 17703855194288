import React, { useEffect, useRef, useState } from 'react';
import PageTitle from '../../../common/PageTitle';
import { getCustomerId, triggerAlert, formatDateTime, getToken } from "../../../utils/CommonFunctions";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import * as html2pdf from 'html2pdf.js';
// import PdfGen from './PdfGen';
import Loader from "../../../common/components/Loader";
import { GetInvoiceData, GetInVoiceList, SendInvoiceData } from '../../../utils/ApiClient';
import jsPDF from 'jspdf';

export default function InvoiceHistory() {
    const currentDate = new Date(); // Move this to the top
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    const heading = 'Payments / Invoice';
    const customer_id = getCustomerId();
    const {
        register,
        formState: { errors },
        control,
        watch,
        setError,
        clearErrors,
        getValues,
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            period_start: oneMonthAgo,
            period_end: currentDate
        }
    });

    const [period_start, setPeriodStart] = useState(oneMonthAgo);
    const [period_end, setPeriodEnd] = useState(currentDate);
    const [minEndDate, setMinEndDate] = useState(period_start);
    const [currentPage, setCurrentPage] = useState(0);

    const [activeInvoiceTab, setActiveInvoiceTab] = useState('month_wise'); // Track the active tab: 'month' or 'date'
    const [showInvoiceButtons, setShowInvoiceButtons] = useState(false); //hide invoice buttons
    const [selectedInvoice, setSelectedInvoice] = useState(null); //show selected invoice preview
    const [isLoading, setIsLoading] = useState();
    const [isLoadingInvoice, setIsLoadingInvoice] = useState();
    const [isLoadingSent, setIsLoadingSent] = useState();
    const [invoiceHtml, setInvoiceHtml] = useState("");
    const [filterType, setFilterType] = useState("today"); // Track the active tab: 'month' or 'date'

    const selectedMonth = Number(watch('selected_month'));

    const btnColors = ['btn btn-primary', 'btn btn-success', 'btn btn-info'];

    const [invoices, setInvoices] = useState([]);
    const [totalPlansCost, setTotalPlansCost] = useState(0);
    const [invoiceTotalCost, setInvoiceTotalCost] = useState(0);
    const [sendInvoiceId, setSendInvoiceId] = useState(null);
    const [hasDataLoaded, setHasDataLoaded] = useState({
        month_wise: false,
        date_wise: false,
        current_month: false,
        last_3_month: false,
        last_6_month: false,
    });

    const props = {
        title: "Invoice | Pay As You Go",
        description: "Premium Multipurpose Admin & Dashboard Template"
    }

    const handleTabChange = (tab) => {
        setActiveInvoiceTab(tab);
        setShowInvoiceButtons(false);
        setSelectedInvoice(null);
        setValue('selected_month', ' ');

        if (tab === 'month_wise') {
            reset({ selected_month: '' }); // This resets the selected month
        }
        if (hasDataLoaded[tab]) {
            setShowInvoiceButtons(true);
        } else {
            if (tab !== 'month_wise') {
                fetchInvoiceData(tab);
                setShowInvoiceButtons(true);
            }
        }
    };
    const handleSearch = () => {
        setInvoices([]);
        setSelectedInvoice(null);

        // Get the current values of the form fields
        const selectedMonth = watch('selected_month');
        const { period_start, period_end } = getValues();

        // Clear previous errors for month and date range
        clearErrors(['selected_month', 'period_start', 'period_end']);

        // Handle validation for the selected month
        if (activeInvoiceTab === 'month_wise' && (!selectedMonth || selectedMonth.trim() === '')) {
            setError('selected_month', {
                type: 'manual',
                message: 'Month is required',
            });
            setShowInvoiceButtons(false);
            return; // Exit early if month validation fails
        }

        // Handle validation for the date range
        let hasError = false;

        if (!period_start) {
            setError('period_start', {
                type: 'manual',
                message: 'From Date is required',
            });
            hasError = true;
        }

        if (!period_end) {
            setError('period_end', {
                type: 'manual',
                message: 'To Date is required',
            });
            hasError = true;
        }

        if (hasError) {
            return; // Exit early if date range validation fails
        }

        // Check if start date is later than end date
        const startDate = new Date(period_start);
        const endDate = new Date(period_end);

        if (startDate > endDate) {
            setError('period_start', {
                type: 'manual',
                message: 'From date cannot be later than to date',
            });
            return; // Exit early if date range is invalid
        }

        // Proceed with data fetching if all validations pass
        setShowInvoiceButtons(true);
        fetchInvoiceData(activeInvoiceTab);
        setCurrentPage(0);
        fetchInvoiceData('date_wise', 1);
    };





    // Fetch Invoice Data
    const fetchInvoiceData = async (tab) => {
        setIsLoading(true);

        const api_input = {
            customer_id: customer_id,
            type: tab,
            from_date: "",
            to_date: ""
        };

        switch (tab) {
            case 'month_wise':
                api_input.month = selectedMonth;
                break;

            case 'date_wise':
                const formattedFromDate = period_start
                    ? formatDateTime(period_start, 'dd-mm-yyyy')
                    : '';

                const formattedToDate = period_end
                    ? formatDateTime(period_end, 'dd-mm-yyyy')
                    : '';

                api_input.from_date = formattedFromDate;
                api_input.to_date = formattedToDate;
                break;

            case 'current_month':
                break;

            case 'last_3_month':
                break;

            case 'last_6_month':
                break;

            default:
                return;
        }
        try {
            const response = await GetInVoiceList(api_input);
            const response_data = response.data;
            const invoiceData = response_data.results.data;

            setIsLoading(false);
            setHasDataLoaded((prev) => ({
                ...prev,
                [tab]: true,
            }));

            if (response_data.error_code === 200) {
                if (invoiceData && invoiceData.length > 0) {
                    setInvoices(invoiceData);
                }
            } else {
                triggerAlert('error', '', 'Something went wrong..');
            }

        } catch (error) {
            console.log(error);
            setIsLoading(false);
            triggerAlert('error', '', 'Something went wrong..');
        }
    };

    // Download functionality
    const downloadInvoiceAsPDF = (htmlContent) => {
        const pdfOptions = {
            margin: 10,
            filename: `invoice_${selectedInvoice.invoice_number}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        };
        html2pdf().set(pdfOptions).from(htmlContent).save(); //for downloading hidden element
    };

    const invoiceToPrintRef = useRef();
    const invoiceHeaderRef = useRef();

    // Print functionality
    const handlePrint = useReactToPrint({
        content: () => invoiceToPrintRef.current,
    });

    const sendInvoice = async (id) => {
        setSendInvoiceId(id);
    };

    useEffect(() => {
        if (selectedInvoice) {
            let calculatedPlansCost = 0;
            let calculatedTotalCost = 0;
            const invoiceTaxCost = parseFloat(selectedInvoice.plan_tax, 10) || 0;

            if (selectedInvoice.invoice_cat === 'I') {
                selectedInvoice.plan_option.forEach((plan) => {
                    const planTotal = parseFloat(plan.total) || 0;
                    calculatedPlansCost += planTotal;
                });
            } else {
                calculatedPlansCost = parseFloat(selectedInvoice.invoice_amount, 10) || 0;
            }

            const taxAmount = (calculatedPlansCost * invoiceTaxCost) / 100;
            calculatedTotalCost = calculatedPlansCost + taxAmount;
            setTotalPlansCost(calculatedPlansCost);
            setInvoiceTotalCost(calculatedTotalCost);
        }

    }, [activeInvoiceTab, selectedInvoice]);

    const datepickerfromRef = useRef(null);
    const datepickerToRef = useRef(null);

    // Open the datepicker when the calendar icon is clicked
    const handleClickDatepickerFromIcon = () => {
        if (datepickerfromRef.current && datepickerfromRef.current.input) {
            datepickerfromRef.current.input.focus();
        }
    };

    const handleClickDatepickerToIcon = () => {
        if (datepickerToRef.current) {
            datepickerToRef.current.setOpen(true);
        }
    };

    return (
        <>

            <div id="content-page" class="content-page">
                <div className="container">
                    {/* start page title  */}
                    <PageTitle heading="Invoice History" {...props} />
                    {/* {sendInvoiceId && <PdfGen data={sendInvoiceId} type='send_invoice' />} Render YourComponent if apiData is available */}
                    {isLoadingSent ? (
                        <div className='loader-overlay text-white'>
                            <Loader />
                        </div>
                    ) : null
                    }
                    <div className="row invoice-not-to-print">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <h4 className="card-title mb-4 h4-card">Invoice</h4>
                                    </div>

                                    {/* Nav tabs */}
                                    <ul className="nav nav-pills" role="tablist">
                                        <li className="nav-item waves-effect waves-light" role="presentation">
                                            <a className={`nav-link ${activeInvoiceTab === 'month_wise' ? 'active' : ''}`} onClick={() => handleTabChange('month_wise')} role="tab" aria-selected="true">
                                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                <span className="d-none d-sm-block">Month Wise</span>
                                            </a>
                                        </li>
                                        <li className="nav-item waves-effect waves-light" role="presentation">
                                            <a className={`nav-link mx-3 ${activeInvoiceTab === 'date_wise' || activeInvoiceTab === 'current_month' || activeInvoiceTab === 'last_3_month' || activeInvoiceTab === 'last_6_month' ? 'active' : ''}`} onClick={() => handleTabChange('date_wise')} role="tab" aria-selected="false">
                                                <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                <span className="d-none d-sm-block">Date Wise</span>
                                            </a>
                                        </li>
                                    </ul>

                                    {/* Tab panes */}
                                    <div className="tab-content p-3 text-muted">
                                        {/* Month Wise Tab Pane */}
                                        <div className={`tab-pane ${activeInvoiceTab === 'month_wise' ? 'active show' : ''}`} id="home-1" role="tabpanel">
                                            <form>
                                                <div className="row align-items-center">
                                                    <div className="col-md-2">
                                                        <label className="form-label" htmlFor="selectMonth">Select Month</label>
                                                        <select className="form-select" id="selectMonth" aria-label="Default select example" {...register('selected_month', { required: 'Month is required' })} name="selected_month">
                                                            <option value="" hidden>Select Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                        {errors.selected_month && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.selected_month.message}</div>}
                                                    </div>
                                                    <div className="col-md-2 mt-2">
                                                        <button type="button" className="btn btn-warning px-4 btn-rounded waves-effect waves-light mt-4" onClick={handleSearch}>
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        {/* Date Wise Tab Pane */}
                                        <div className={`tab-pane ${activeInvoiceTab === 'date_wise' || activeInvoiceTab === 'current_month' || activeInvoiceTab === 'last_3_month' || activeInvoiceTab === 'last_6_month' ? 'active show' : ''}`} id="profile-1" role="tabpanel">
                                            <form>
                                                <div className="row align-items-center">


                                                    <div className="col-md-3 mb-3 d-flex flex-column position-relative">
                                                        <label htmlFor="fromDate" className="form-label">From</label>
                                                        <i
                                                            className="mdi mdi-calendar"
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                right: "20px", // Adjust this value as needed
                                                                transform: "translateY(-2%)",
                                                                zIndex: "1",
                                                                fontSize: "24px",
                                                                cursor: "pointer",
                                                                color: "#6c757d", // Optional: set a color for visibility
                                                            }}
                                                            onClick={handleClickDatepickerFromIcon}
                                                        ></i>
                                                        <Controller
                                                            control={control}
                                                            name="period_start"
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    className="px-2 form-control"
                                                                    placeholderText="MM/DD/YYYY"
                                                                    selected={field.value}
                                                                    onChange={(date) => {
                                                                        field.onChange(date);
                                                                        setPeriodStart(date);
                                                                        setMinEndDate(date); // Update minDate for period_end
                                                                        if (date) clearErrors("period_start");
                                                                    }}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    maxDate={new Date()}
                                                                    autoComplete="off"
                                                                    ref={datepickerfromRef}
                                                                />
                                                            )}
                                                        />
                                                        {errors.period_start && (
                                                            <div style={{ color: 'red', fontSize: '14px', marginTop: '5px', position: 'absolute', bottom: '-25px' }}>
                                                                {errors.period_start.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-md-3 mb-3 d-flex flex-column position-relative">
                                                        <label htmlFor="toDate" className="form-label">To</label>
                                                        <i
                                                            className="mdi mdi-calendar"
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                right: "20px", // Adjust this value as needed
                                                                transform: "translateY(-2%)",
                                                                zIndex: "1",
                                                                fontSize: "24px",
                                                                cursor: "pointer",
                                                                color: "#6c757d",
                                                            }}
                                                            onClick={handleClickDatepickerToIcon}
                                                        ></i>
                                                        <Controller
                                                            control={control}
                                                            name="period_end"
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    className="px-2 form-control"
                                                                    placeholderText="MM/DD/YYYY"
                                                                    selected={field.value}
                                                                    onChange={(date) => {
                                                                        field.onChange(date);
                                                                        setPeriodEnd(date); // Update the state variable
                                                                        if (date) clearErrors("period_end");
                                                                    }}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    maxDate={new Date()}
                                                                    minDate={minEndDate}
                                                                    autoComplete="off"
                                                                    ref={datepickerToRef}
                                                                />
                                                            )}
                                                        />
                                                        {errors.period_end && (
                                                            <div style={{ color: 'red', fontSize: '14px', marginTop: '5px', position: 'absolute', bottom: '-25px' }}>
                                                                {errors.period_end.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-md-1 mt-3">
                                                        <button type="button" className="btn btn-warning px-2 btn-rounded waves-effect waves-light " onClick={handleSearch}>
                                                            Search
                                                        </button>
                                                    </div>

                                                    <div className="col-md-4 mt-3 mr-2">
                                                        <div className="d-flex gap-2 flex-wrap">
                                                            <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light px-2" onClick={() => handleTabChange('current_month')}>
                                                                Current Month
                                                            </button>
                                                            <button type="button" className="btn btn-success btn-rounded waves-effect waves-light px-2" onClick={() => handleTabChange('last_3_month')}>
                                                                Last three Months
                                                            </button>
                                                            <button type="button" className="btn btn-info btn-rounded waves-effect waves-light px-2" onClick={() => handleTabChange('last_6_month')}>
                                                                Last Six Months
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Row END */}
                    {isLoading ? (
                        <Loader />
                    ) : (
                        showInvoiceButtons && (
                            <div className="row invoice-not-to-print">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="col-md-12">
                                                <div className="button-items">
                                                    {invoices && invoices.length <= 0 ? (
                                                        <p className="fw-semibold d-flex justify-content-center align-items-center">
                                                            No data available
                                                        </p>
                                                    ) : (
                                                        invoices.map((invoice) => (
                                                            <button
                                                                type="button"
                                                                key={invoice.invoice_id}
                                                                onClick={() => {
                                                                    setIsLoadingInvoice(true);
                                                                    setSelectedInvoice(invoice);
                                                                    setTimeout(() => {
                                                                        setIsLoadingInvoice(false);
                                                                    }, 1000);
                                                                    setInvoiceHtml(invoice.invoice_cat);
                                                                }}
                                                                className={`btn btn-info btn-rounded waves-effect waves-light ${selectedInvoice === invoice ? 'active' : ''} mb-2`}
                                                                style={{ marginRight: '10px' }}
                                                            >
                                                                INVOICE # {invoice.invoice_number}
                                                            </button>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* end card-body */}
                                    </div>
                                    {/* end card */}
                                </div>
                                {/* end col */}
                            </div>
                        )
                    )}

                    {/* -----row start */}
                    {isLoadingInvoice ? (
                        <Loader />
                    ) : (
                        selectedInvoice ? (
                            <>
                                <div className="row">
                                    <div className="col-xl-9 col-md-8 col-12 mb-md-0 mb-4">
                                        {/* Download JSX - Monthly bill*/}
                                        {invoiceHtml === 'I' &&
                                            <div id='invoice-preview-card-data' ref={invoiceToPrintRef}>
                                                <div >
                                                    <div className="card invoice-preview-card px-5 py-2" style={{ border: "3px solid #ee6724" }} >
                                                        <div className='whole_body' >
                                                            <div className="card-body px-0 py-0">
                                                                <div className="row d-flex justify-content-between flex-wrap mb-2">
                                                                    <div className="col-4 mb-xl-0 mb-4 d-flex flex-column">

                                                                        <span className="mb-0 fw-semibold">Vitel Global Communications LLC., </span>
                                                                        <span className="mb-0">295 Durham Avenue, Suite D,</span>
                                                                        <span className="mb-0">South Plainfiled, New Jersey, USA.</span>
                                                                        <span className="mb-0">Tel:732-444-3132, Fax:732-444-3436</span>
                                                                        <span className="mb-0">info@vitelglobal.com</span>
                                                                    </div>
                                                                    <div className="col-4 d-flex svg-illustration justify-content-center flex-column align-items-center">
                                                                        <img src="/assets/images/companyLogo.png" width="250" alt='Vitelglobal' />
                                                                        <div className='mt-5 fw-semibold'>INVOICE</div>
                                                                    </div>

                                                                    <div className='col-4 d-flex flex-column justify-content-center align-items-end'>

                                                                        <div className="mb-0 pt-1">
                                                                            <span>Invoice:</span>
                                                                            <span className="fw-semibold">    #{selectedInvoice?.invoice_number ? selectedInvoice.invoice_number : '-'}</span>
                                                                        </div>
                                                                        <div className="mb-0 pt-1">
                                                                            <span>Date:</span>
                                                                            <span className="fw-semibold">    {selectedInvoice?.invoice_date ? selectedInvoice?.invoice_date : '-'}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <hr className="my-0 mb-3" />
                                                            <div className="card-body" style={{ border: "1px solid #ee6724" }}>
                                                                <div className="row" >
                                                                    <div className="col-md-12">
                                                                        <h5 className="mb-4 h5-card text-center">Customer Information</h5>
                                                                        <table className='col-md-10'>
                                                                            <tbody className="row">
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Company Name :</td>
                                                                                    <td className='col-7'>{selectedInvoice?.company_name}</td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Contact Name :</td>
                                                                                    <td className="col-7" >{selectedInvoice?.user_name}</td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Email Id :</td>
                                                                                    <td className="col-7" >{selectedInvoice?.email}</td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Address :</td>
                                                                                    <td className="col-7" >{selectedInvoice?.address + ', ' + selectedInvoice?.zipcode + ', ' + selectedInvoice?.city + ', ' + selectedInvoice?.state + ', ' + selectedInvoice?.country + '.'} </td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Phone :</td>
                                                                                    <td className="col-7" >{selectedInvoice?.phone}</td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Plan Type :</td>
                                                                                    <td className="col-7" >Pay As You Go</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h5 className="mb-4 mt-3  h5-card text-center">Billing Information</h5>
                                                            <div className="table-responsive border-top">
                                                                <table className="table m-0 px-4">
                                                                    <thead style={{ backgroundColor: "#ee6724", color: "#fff" }}>
                                                                        <tr>
                                                                            <th className="text-white p-0">DESCRIPTION</th>
                                                                            <th className="text-white p-0">QUANTITY</th>
                                                                            <th className="text-white p-0">UNIT PRICE</th>
                                                                            <th className="text-white p-0">BILL MONTHS</th>
                                                                            <th className="text-white p-0">TOTAL</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {selectedInvoice?.plan_option.map((plan) => (
                                                                            <tr style={{ backgroundColor: plan.description === 'Number of Paid DIDs' ? 'white' : '#efefef' }}>
                                                                                <td className="text-nowrap p-0">{plan?.description}</td>
                                                                                <td className="text-nowrap p-0">{plan?.quantity}</td>
                                                                                <td className='p-0'>$ {plan?.unit_price}</td>
                                                                                <td className='p-0'>{plan?.billing_months}</td>
                                                                                <td className='p-0'>$ {plan?.total}</td>
                                                                            </tr>
                                                                        ))}

                                                                        <tr>
                                                                            <td className="text-nowrap p-0">Tax</td>
                                                                            <td className="text-nowrap p-0"></td>
                                                                            <td className='p-0'></td>
                                                                            <td className='p-0'>{selectedInvoice?.plan_tax}%</td>
                                                                            <td className='p-0'>${(selectedInvoice?.invoice_amount * selectedInvoice?.plan_tax) / 100}</td>
                                                                        </tr>
                                                                        {selectedInvoice?.card_type !== "AmExCard" ? (
                                                                            <>
                                                                                {selectedInvoice?.discount_status === 'Y' ? (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="text-nowrap p-0">Discount</td>
                                                                                            <td className="text-nowrap p-0"></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'>${selectedInvoice?.discount_amt}</td>
                                                                                        </tr>
                                                                                        <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                                            <td className="text-white text-nowrap p-0">Grand Total</td>
                                                                                            <td className="text-nowrap p-0"></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='text-white p-0'>${invoiceTotalCost - selectedInvoice?.discount_amt}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                ) :
                                                                                    <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                                        <td className="text-white text-nowrap p-0">Grand Total</td>
                                                                                        <td className="text-nowrap p-0"></td>
                                                                                        <td className='p-0'></td>
                                                                                        <td className='p-0'></td>
                                                                                        <td className='text-white p-0'>${invoiceTotalCost}</td>
                                                                                    </tr>

                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {selectedInvoice.discount_status === 'Y' ? (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="text-nowrap p-0">Discount</td>
                                                                                            <td className="text-nowrap p-0"></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'>${selectedInvoice?.discount_amt}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-nowrap p-0">AmExCard Processing Fee - 4%</td>
                                                                                            <td className="text-nowrap p-0"></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'>${(((invoiceTotalCost - selectedInvoice?.discount_amt) * 0.04)).toFixed(3)}</td>
                                                                                        </tr>
                                                                                        <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                                            <td className="text-white text-nowrap p-0">Grand Total</td>
                                                                                            <td className="text-nowrap p-0"></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='text-white p-0'>${(invoiceTotalCost - selectedInvoice?.discount_amt) * 0.04 + (invoiceTotalCost - selectedInvoice?.discount_amt)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='p-0 mt-3' align="left" height="15" colspan="5">Note : From January 1st, 2016, payments through Amex credit cards would attract 4% processing fee and you will be charged accordingly. If you would like to avoid the charges, we request you to move to other Debit/Credit cards or e-check payments.</td>
                                                                                        </tr>

                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="text-nowrap p-0">AmExCard Processing Fee - 4%</td>
                                                                                            <td className="text-nowrap p-0"></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'>${(invoiceTotalCost * 0.04).toFixed(3)}</td>
                                                                                        </tr>
                                                                                        <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                                            <td className="text-white text-nowrap p-0">Grand Total</td>
                                                                                            <td className="text-nowrap p-0"></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='p-0'></td>
                                                                                            <td className='text-white p-0'>${(invoiceTotalCost * 0.04) + invoiceTotalCost}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='p-0 mt-3' align="left" height="15" colspan="5">Note : From January 1st, 2016, payments through Amex credit cards would attract 4% processing fee and you will be charged accordingly. If you would like to avoid the charges, we request you to move to other Debit/Credit cards or e-check payments.</td>
                                                                                        </tr>

                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="card-body mx-3">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                                                        <span className="fw-semibold">Thanks for your business</span>
                                                                        {(selectedInvoice?.did_list_free_list.length > 0 || selectedInvoice?.did_list_paid_list.length > 0) &&
                                                                            <span>Scroll down for detailed invoice.</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {(selectedInvoice?.did_list_free_list.length > 0 || selectedInvoice?.did_list_paid_list.length > 0) &&
                                                    <>
                                                        <div className='html2pdf__page-break'>
                                                            {/* for page break */}
                                                        </div>

                                                        <div >
                                                            <div className="card invoice-preview-card px-5 py-2" style={{ border: "3px solid #ee6724" }}>
                                                                <div className='whole_body'>
                                                                    <div className="card-body px-0 py-0">
                                                                        <div className="row d-flex justify-content-between flex-wrap mb-2">
                                                                            <div className="col-4 mb-xl-0 mb-4 d-flex flex-column">

                                                                                <span className="mb-0 fw-semibold">Vitel Global Communications LLC., </span>
                                                                                <span className="mb-0">295 Durham Avenue, Suite D,</span>
                                                                                <span className="mb-0">South Plainfiled, New Jersey, USA.</span>
                                                                                <span className="mb-0">Tel:732-444-3132, Fax:732-444-3436</span>
                                                                                <span className="mb-0">info@vitelglobal.com</span>
                                                                            </div>
                                                                            <div className="col-4 d-flex svg-illustration justify-content-center flex-column align-items-center">
                                                                                <img src="/assets/images/companyLogo.png" width="250" alt='Vitelglobal' />
                                                                                <div className='mt-5 fw-semibold'>INVOICE</div>
                                                                            </div>

                                                                            <div className='col-4 d-flex flex-column justify-content-center align-items-end'>
                                                                                <div className="mb-0 pt-1">
                                                                                    <span>Invoice</span>
                                                                                    <span className="fw-semibold">    #{selectedInvoice?.invoice_number ? selectedInvoice.invoice_number : '-'}</span>
                                                                                </div>
                                                                                <div className="mb-0 pt-1">
                                                                                    <span>Date Issued</span>
                                                                                    <span className="fw-semibold">    {selectedInvoice?.invoice_date ? selectedInvoice?.invoice_date : '-'}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <hr className="my-0 mb-3" />

                                                                    <div className="card-body p-0">
                                                                        <div className="row p-0 text-center" >
                                                                            <h5 className=" h4-card">DID List</h5>
                                                                        </div>

                                                                    </div>
                                                                    <div className="table-responsive  ">
                                                                        <table className="table m-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th colspan="6" className='px-3'>DID</th>
                                                                                    <th colspan="6" className='text-end px-3'>Price</th>

                                                                                </tr>

                                                                            </thead>
                                                                            <tbody>
                                                                                {selectedInvoice?.did_list_free_list.map((freeDid) => (
                                                                                    <tr>
                                                                                        <td colspan="6" className='px-3'>{freeDid}</td>
                                                                                        <td colspan="6" className='text-end px-3'>$ 0.00</td>
                                                                                    </tr>
                                                                                ))}
                                                                                {selectedInvoice?.did_list_paid_list.map((paidDid) => (
                                                                                    <tr>
                                                                                        <td colspan="6" className='px-3'>{paidDid}</td>
                                                                                        <td colspan="6" className='text-end px-3'>$ {selectedInvoice?.dids_cost}</td>
                                                                                    </tr>
                                                                                ))}
                                                                                <tr>
                                                                                    <td colspan="6" className='fw-semibold px-3'>Total Amount</td>
                                                                                    <td colspan="6" className='text-end'>  <p className="fw-semibold mb-2 px-3">$ {selectedInvoice?.paid_dids * selectedInvoice?.dids_cost}</p></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="card-body mx-3">
                                                                        <div className="row">
                                                                            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                                                                <span className="fw-semibold">Thanks for your business</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        }
                                        {/* Download JSX - Refill bill*/}
                                        {invoiceHtml === 'R' &&
                                            <div id='invoice-preview-card-data' ref={invoiceToPrintRef} >
                                                <div >
                                                    <div className="card invoice-preview-card px-5 py-2" style={{ border: "3px solid #ee6724" }}>
                                                        <div className='whole_body'>
                                                            <div className="card-body px-0 py-0">
                                                                <div className="row d-flex justify-content-between flex-wrap mb-2">
                                                                    <div className="col-4 mb-xl-0 mb-4 d-flex flex-column">

                                                                        <span className="mb-0 fw-semibold">Vitel Global Communications LLC., </span>
                                                                        <span className="mb-0">295 Durham Avenue, Suite D,</span>
                                                                        <span className="mb-0">South Plainfiled, New Jersey, USA.</span>
                                                                        <span className="mb-0">Tel:732-444-3132, Fax:732-444-3436</span>
                                                                        <span className="mb-0">info@vitelglobal.com</span>
                                                                    </div>
                                                                    <div className="col-4 d-flex svg-illustration justify-content-center flex-column align-items-center">
                                                                        <img src="/assets/images/companyLogo.png" width="250" alt='Vitelglobal' />
                                                                        <div className='mt-5 fw-semibold'>INVOICE</div>
                                                                    </div>

                                                                    <div className='col-4 d-flex flex-column justify-content-center align-items-end'>
                                                                        <div className="mb-0 pt-1">
                                                                            <span>Invoice:</span>
                                                                            <span className="fw-semibold">    #{selectedInvoice?.invoice_number ? selectedInvoice.invoice_number : '-'}</span>
                                                                        </div>
                                                                        <div className="mb-0 pt-1">
                                                                            <span>Date:</span>
                                                                            <span className="fw-semibold">    {selectedInvoice?.invoice_date ? selectedInvoice?.invoice_date : '-'}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <hr className="my-0 mb-3" />
                                                            <div className="card-body" style={{ border: "1px solid #ee6724" }}>
                                                                <div className="row" >
                                                                    <div className="col-md-12">
                                                                        <h5 className="mb-4 h5-card text-center">Customer Information</h5>
                                                                        <table className='col-md-10'>
                                                                            <tbody className="row">
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Company Name :</td>
                                                                                    <td className='col-7'>{selectedInvoice?.company_name}</td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Contact Name :</td>
                                                                                    <td className="col-7" >{selectedInvoice?.user_name}</td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Email Id :</td>
                                                                                    <td className="col-7" >{selectedInvoice?.email}</td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Address :</td>
                                                                                    <td className="col-7" >{selectedInvoice?.address + ', ' + selectedInvoice?.zipcode + ', ' + selectedInvoice?.city + ', ' + selectedInvoice?.state + ', ' + selectedInvoice?.country + '.'} </td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Phone :</td>
                                                                                    <td className="col-7" >{selectedInvoice?.phone}</td>
                                                                                </tr>
                                                                                <tr className="d-flex justify-content-around">
                                                                                    <td className="col-3">Plan Type :</td>
                                                                                    <td className="col-7" >Pay As You Go</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h5 className="mb-4 mt-3  h5-card text-center">Billing Information</h5>
                                                            <div className="table-responsive border-top">
                                                                <table className="table m-0 px-4">
                                                                    <thead style={{ backgroundColor: "#ee6724", color: "#fff" }}>
                                                                        <tr>
                                                                            <th className="text-white p-0">DESCRIPTION</th>
                                                                            {/* <th className=" p-0">QUANTITY</th> */}
                                                                            <th className="text-white p-0">UNIT PRICE</th>

                                                                            <th className="text-white p-0">TOTAL</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="text-nowrap p-0">{selectedInvoice?.comments}</td>

                                                                            <td className='p-0'>${selectedInvoice?.invoice_amount}</td>

                                                                            <td className='p-0'>${selectedInvoice?.invoice_amount}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className="text-nowrap p-0">Tax</td>
                                                                            <td className="text-nowrap p-0">{selectedInvoice?.plan_tax}%</td>
                                                                            <td className='p-0'>${(selectedInvoice?.invoice_amount * selectedInvoice?.plan_tax) / 100}</td>
                                                                        </tr>
                                                                        {selectedInvoice?.card_type !== "AmExCard" ? (
                                                                            <>
                                                                                {selectedInvoice?.discount_status === 'Y' ? (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="text-nowrap p-0">Discount</td>
                                                                                            <td className="text-nowrap p-0"></td>

                                                                                            <td className='p-0'>${selectedInvoice?.discount_amt}</td>
                                                                                        </tr>
                                                                                        <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                                            <td className="text-white text-nowrap p-0">Grand Total</td>
                                                                                            <td className="text-white text-nowrap p-0"></td>

                                                                                            <td className='text-white p-0'>${invoiceTotalCost - selectedInvoice?.discount_amt}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                ) :
                                                                                    <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                                        <td className="text-white text-nowrap p-0">Grand Total</td>
                                                                                        <td className="text-white text-nowrap p-0"></td>

                                                                                        <td className='text-white p-0'>${invoiceTotalCost}</td>
                                                                                    </tr>

                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {selectedInvoice.discount_status === 'Y' ? (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="text-nowrap p-0">Discount</td>
                                                                                            <td className="text-nowrap p-0"></td>

                                                                                            <td className='p-0'>${selectedInvoice?.discount_amt}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-nowrap p-0">AmExCard Processing Fee - 4%</td>
                                                                                            <td className="text-nowrap p-0"></td>

                                                                                            <td className='p-0'>${(((invoiceTotalCost - selectedInvoice?.discount_amt) * 0.04)).toFixed(3)}</td>
                                                                                        </tr>
                                                                                        <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                                            <td className="text-white text-nowrap p-0">Grand Total</td>
                                                                                            <td className="text-white text-nowrap p-0"></td>

                                                                                            <td className='text-white p-0'>${((invoiceTotalCost - selectedInvoice?.discount_amt) * 0.04) + (invoiceTotalCost - selectedInvoice?.discount_amt)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='p-0 mt-3' align="left" height="15" colspan="5">Note : From January 1st, 2016, payments through Amex credit cards would attract 4% processing fee and you will be charged accordingly. If you would like to avoid the charges, we request you to move to other Debit/Credit cards or e-check payments.</td>
                                                                                        </tr>

                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="text-nowrap p-0">AmExCard Processing Fee - 4%</td>
                                                                                            <td className="text-nowrap p-0"></td>

                                                                                            <td className='p-0'>${(invoiceTotalCost * 0.04).toFixed(3)}</td>
                                                                                        </tr>
                                                                                        <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                                            <td className="text-white text-nowrap p-0">Grand Total</td>
                                                                                            <td className="text-white text-nowrap p-0"></td>

                                                                                            <td className='text-white p-0'>${(invoiceTotalCost * 0.04) + invoiceTotalCost}</td>
                                                                                        </tr>
                                                                                        <tr className='mt-4'>
                                                                                            <td className='p-0 mt-3' align="left" height="15" colspan="5">Note : From January 1st, 2016, payments through Amex credit cards would attract 4% processing fee and you will be charged accordingly. If you would like to avoid the charges, we request you to move to other Debit/Credit cards or e-check payments.</td>
                                                                                        </tr>

                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="card-body mx-3">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                                                        <span className="fw-semibold">Thanks for your business</span>
                                                                        {(selectedInvoice?.did_list_free_list.length > 0 || selectedInvoice?.did_list_paid_list.length > 0) &&
                                                                            <span>Scroll down for detailed invoice.</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><br />
                                            </div>
                                        }
                                    </div>
                                    {/* Invoice Actions */}
                                    <div className="col-xl-3 col-md-4 col-12 invoice-actions">
                                        <div className="card">
                                            <div className="card-body">
                                                <button className="btn btn-primary d-grid w-100 mb-2 waves-effect waves-light" onClick={() => sendInvoice(selectedInvoice.invoice_id)}>
                                                    <span className="d-flex align-items-center justify-content-center text-nowrap"><i
                                                        className="ti ti-send ti-xs me-1"></i>Send Invoice</span>
                                                </button>
                                                <button className="btn btn-primary d-grid w-100 mb-2 waves-effect" onClick={() => downloadInvoiceAsPDF(document.getElementById('invoice-preview-card-data'))}>
                                                    Download
                                                </button>
                                                <button className="btn btn-primary d-grid w-100 mb-2 waves-effect" onClick={handlePrint}>
                                                    Print
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}

                            </>
                        ) : null
                    )}


                </div>
            </div>
        </>
    )
}