import React, { useState, useEffect } from 'react';
import PageTitle from '../../../common/PageTitle';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getToken, triggerAlert, getCustomerId, transformText, ConfirmationAlert, simpleAlert } from '../../../utils/CommonFunctions';
import { MaxLengthValidation, MinLengthValidation, onlyAlphabetsandSpaces, onlyNumbers } from '../../../utils/Constants';
import { Button, Card, Col, Modal, Row, Table } from 'react-bootstrap';
import Loader from '../../../common/components/Loader';
import CommonTooltip from '../../../common/components/CommonTooltip';
import { fetchAddfunds, fetchUserInfoPayment, fetchPaymentPrimary, fetchPaymentCardsData, fetchAddCardSubmit, fetchAddBankSubmit, fetchCardUpdate, fetchUserDetailsData, fetchBankUpdate } from '../../../utils/ApiClient';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@mdi/font/css/materialdesignicons.min.css';

export default function BalanceDashbord() {
    const heading = 'Payments / Payment Details';
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const customer_id = getCustomerId();
    const token = getToken();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [data, setData] = useState([]);
    const [sendInvoiceId, setSendInvoiceId] = useState(null);
    const [userData, setUserData] = useState([]);

    const [cardData, setCardData] = useState([]);
    const [showPayment, setShowPayment] = useState(false);
    const [userPaymentDetails, setUserPaymentDetails] = useState(null);
    const [formUpdate, setFormUpdate] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("card");
    const [updatePaymentType, setUpdatePaymentType] = useState("");
    const [isEnabled, setIsEnabled] = useState(false);
    const [isEnabledLBN, setIsEnabledLBN] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch,
    } = useForm();

    const {
        register: registerPayment,
        handleSubmit: handleSubmitPayment,
        formState: { errors: errorsPayment },
        setValue: setValuePayment,
        reset: resetPayment,
        watch: watchPayment,
    } = useForm();

    const { register: registerAP, handleSubmit: handleSubmitAP, formState: { errors: errorsAP }, setValue: setValueAP, reset: resetAP, control: controlAP } = useForm();
    const { register: registerLBN, handleSubmit: handleSubmitLBN, formState: { errors: errorsLBN }, setValue: setValueLBN, reset: resetLBN, control: controlLBN } = useForm();

    const props = {
        title: "Payment Details | Pay As You Go",
        description: "Premium Multipurpose Admin & Dashboard Template"
    }
    const currentYear = new Date().getFullYear();
    const futureYears = 15;

    const options = [];
    for (let i = 0; i <= futureYears; i++) {
        const year = currentYear + i;
        options.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }

    const formReset = () => {
        reset();
    };


    const handlePaymentClose = () => {
        setShowPayment(false);
        formReset();

    }

    const handleAddShow = (type) => {
        fetchUserInfo();
        setTimeout(() => { setShowPayment(true); }, 1000)
        if (type === 'add') setFormUpdate(false);
    }

    const updateUserPaymentDetails = (item) => {
        if (item.type == "Card") {
            const month = String(item.expiry_month);
            // console.log('month', typeof (month))
            // setValue("card_number", `XXXXXXXXXXXX` + item.card_no);
            setValue("card_type", item.card_type);
            setValue("card_expiry_month", month);
            setValue("card_expiry_year", item.expiry_year);
            setValue("cvv_code", item.cvv_code);
        } else {
            // alert('hi')
            setValue("account_type", item.account_type);
            setValue("account_number", item.account_number);
            setValue("routing_number", item.routing_number);
            setValue("name_on_account", item.card_holder_name);
            setValue("echeck_type", item.echeck_type);
            setValue("bank_name", item.bank_name);
        }
    }

    //update payment details
    const updatePaymentDetails = (item, type) => {
        // console.log("updatePaymentDetails", item, type)
        setFormUpdate(true);
        setSelectedPaymentMethod(type); // type is lowercase
        setUserPaymentDetails(item);
        setUpdatePaymentType(item.type); // type is captilized
        if (item) updateUserPaymentDetails(item);
        handleAddShow('update');
    };



    const fetchCardsData = async () => {
        setIsLoadingTable(true);
        try {
            const response = await fetchPaymentCardsData();


            const response_data = response.data;
            // console.log('response_dataCards', response_data);

            if (response_data.error_code === 200) {
                const itemsArray = response_data.results;

                setCardData(itemsArray);


                setIsLoadingTable(false);
            }
        } catch (error) {
            setIsLoadingTable(false);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };

    //get userInfoData
    const fetchUserInfo = async (api_input) => {
        setIsLoading(true);

        try {
            // Fetch user information
            const response = await fetchUserInfoPayment(api_input);
            const response_data = response.data;

            // Check for successful response
            if (response_data.error_code === 200) {
                const userinformation = response_data.results; // Adjust based on API response structure
                const user = userinformation[0]; // Assuming we want the first user in the list

                // Set form values
                setValue('first_name', user.first_name);
                setValue('last_name', user.last_name);
                setValue('address', user.cust_address);
                setValue('city', user.city);
                setValue('country', user.country);
                setValue('state', user.state);
                setValue('zip', user.zipcode);
                setValue('company', user.company_name);
                setValue('email', user.email);
                setValue('phone_number', user.phone);
                // Optionally handle additional logic here

            } else {
                // Handle API error case
                triggerAlert("error", "Oops...", "Something went wrong with the response.");
            }
        } catch (error) {
            // Handle request error
            console.error('Fetch user info error:', error); // Log the error for debugging
            triggerAlert("error", "Oops...", "Something went wrong while fetching user info.");
        } finally {
            setIsLoading(false); // Ensure loading state is always updated
        }
    };




    const handleAddfunds = async (data) => {
        setIsLoading(true);
        const postData = {
            // user_id: String(customer_id),
            amount_collected: data.amount,
        };
        try {
            const response = await fetchAddfunds(postData);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                setIsLoading(false);
                triggerAlert("success", "Success", response_data.message);
                resetPayment();
                const invoice_id = response_data.results.invoice_id;
                setSendInvoiceId(invoice_id); // get this from API response

                // Reload the page after a short delay to show the success message first
                setTimeout(() => {
                    window.location.reload();
                }, 1500); // 1.5-second delay to ensure the user sees the success message
            } else {
                setIsLoading(false);
                triggerAlert("error", "Oops...", "Something went wrong..");
            }
        } catch (error) {
            const response_data = error.response.data;
            setIsLoading(false);
            triggerAlert("error", "Oops...", response_data.message);
        }
    };

    const handlePrimary = (id) => {
        ConfirmationAlert(
            "You want to set this card as the primary payment method? Please note that it will be used for monthly recurring charges.",
            "Continue",
            async () => {
                setIsLoading(true);
                const api_input = {
                    card_id: id,
                };

                try {
                    const response = await fetchPaymentPrimary(api_input);
                    const response_data = response.data;

                    if (response_data.error_code === 200) {
                        triggerAlert("success", "Success", response_data.message || "Success!!");
                        fetchCardsData(); // Fetch updated card data after setting primary
                    } else {
                        triggerAlert("error", "Oops...", "Action was unsuccessful");
                    }
                } catch (error) {
                    const response_data = error.response?.data;
                    triggerAlert(
                        "error",
                        "Oops...",
                        response_data ? response_data.message : "Something went wrong!"
                    );
                } finally {
                    setIsLoading(false); // Ensure loading state is cleared
                }
            }
        );
    };


    const AddCardSubmit = async (data) => {
        console.log(data); // For debugging purposes
        setIsLoading(true);

        try {
            let postData = { ...data }; // Initialize postData with form data
            let response;

            if (formUpdate) {
                // Handle update logic
                if (updatePaymentType === "Card") {
                    response = await fetchCardUpdate(postData);
                    data.card_id = userPaymentDetails.card_id;
                    data.primary_card_status = userPaymentDetails.primary_card_status;
                    postData = data;
                } else if (updatePaymentType === "Bank") {
                    response = await fetchBankUpdate(postData);
                    data.card_id = userPaymentDetails.card_id;
                    data.primary_card_status = userPaymentDetails.primary_card_status;
                    postData = data;
                } else {
                    throw new Error("Invalid payment type for update.");
                }
            } else {
                // Handle add logic
                if (selectedPaymentMethod === "card") {
                    response = await fetchAddCardSubmit(postData);
                } else if (selectedPaymentMethod === "bank") {
                    response = await fetchAddBankSubmit(postData);
                } else {
                    throw new Error("Selected payment method is not card or bank.");
                }
            }

            // Handle the API response
            const response_data = response.data;

            if (response_data.error_code === 200) {
                triggerAlert("success", "Success", response_data.message || "Payment method processed successfully");
                handlePaymentClose();
                formReset();
                fetchCardsData(); // Refresh the card data if needed
            } else {
                triggerAlert("info", "", response_data.message || "Something went wrong..");
            }
        } catch (error) {
            console.error(error); // For debugging purposes
            setIsLoading(false);
            const response_data = error.response ? error.response.data : { message: "Something went wrong.." };
            triggerAlert("info", "", response_data.message || "Something went wrong..");
        } finally {
            setIsLoading(false); // Ensure loading state is reset
        }
    };


    useEffect(() => {
        fetchCardsData();
        // getPaymentSettingDetails()

    }, []);

    const fetchUserDetails = async () => {
        try {

            const response = await fetchUserDetailsData();
            const response_data = response.data;
            console.log("data", response_data.error_code)
            if (response_data.error_code == 200) {
                const data = response.data.results;
                setUserData(data);


            } else {
                setUserData([])
            }
        } catch (error) {
            const response_data = error?.response?.data
        }
    }

    useEffect(() => {
        fetchUserDetails();
    }, []);
    return (
        <>
            <div class="position-relative">
            </div>
            <div id="content-page" class="content-page">
                <div class="container">
                    {/* <div class="row mt-3">
                        <div class="d-flex align-items-center justify-content-between flex-wrap mb-4">
                            <h4 class="fw-bold text-primary">Payment Settings</h4>
                        </div>
                    </div> */}
                    <PageTitle heading="Recharge" />
                    {isLoading &&
                        <div className='loader-overlay text-white'>
                            <Loader />
                        </div>
                    }
                    <div className="row">
                        {/* Current balance and make a payment */}
                        <div className="col-12 mb-3">
                            {/* <div className='card'>
								<div className='card-body'> */}
                            <div className="row">
                                <div className="col-md-2 text-center">
                                    <div className="card h-100">
                                        <div className="card-body pb-3">
                                            <h5 className="card-title mb-2 h4-card">Credit Balance</h5>
                                            <div className="d-flex justify-content-center align-items-start">
                                                <div className="flex-1 text-center">
                                                    <h4 className={`${userData.credit_balance < 0 ? 'text-danger' : ''}`}>
                                                        {userData.credit_balance ? userData.credit_balance : 0.00}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2 text-center">
                                    <div className="card h-100">
                                        <div className="card-body pb-3">
                                            <h5 className="card-title mb-2 h4-card">Wallet Balance</h5>
                                            <div className="d-flex justify-content-center align-items-start">
                                                <div className="flex-1 text-center">
                                                    <h4 className={`${userData.wallet_amount < 0 ? 'text-danger' : ''}`}>
                                                        $ {userData.wallet_amount ? userData.wallet_amount : 0.00}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="card h-100">
                                        <div className="card-body pb-3">
                                            <h5 className="card-title mb-2 h4-card">Make A Payment</h5>
                                            <form className="row g-3 align-items-start" onSubmit={handleSubmitPayment(handleAddfunds)}>
                                                <div className="col-12 col-lg-auto d-flex align-items-center mt-4">
                                                    <label className="form-label mb-0" htmlFor="paymentCvv">
                                                        I Would like to Pay
                                                    </label>
                                                </div>
                                                <div className="col-12 col-lg">
                                                    <div className="input-group">
                                                        <span className="input-group-text">$</span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="amount"
                                                            {...registerPayment("amount", {
                                                                required: "Please enter the amount",
                                                                pattern: {
                                                                    value: /^\d+(\.\d{1,2})?$/,
                                                                    message: "Invalid Amount",
                                                                },
                                                                maxLength: MaxLengthValidation(6)
                                                            })}
                                                            placeholder="Enter amount"
                                                        />
                                                    </div>
                                                    <div style={{ minHeight: '20px' }}>
                                                        {errorsPayment.amount && (
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "14px",
                                                                    marginTop: "5px",
                                                                }}
                                                            >
                                                                {errorsPayment.amount.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-auto">
                                                    <button type="submit" className="btn btn-primary">
                                                        Pay now
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div>
							</div> */}

                        </div>
                        {/* Saved Cards / Bank Info */}
                        <div className='col-12'>
                            <div class="card">
                                <div class="card-body">
                                    <div className='d-flex justify-content-between'>
                                        <h5 className="card-title mb-2 h4-card">Saved Cards / Bank Info</h5>
                                        {cardData?.length == 0 && <span className="text-danger fs-5">*Note: Please provide your card or bank information in order to proceed.</span>}
                                        <button
                                            type="button"
                                            className="btn  btn-primary waves-effect waves-light"
                                            onClick={() => handleAddShow('add')}
                                        >
                                            Add Payment Details
                                        </button>
                                    </div>

                                    <Table bordered responsive className='mt-5'>
                                        <thead className="table-light">
                                            <tr >
                                                <th>Card / Bank</th>
                                                <th>Card Number / Account Number</th>
                                                <th>Exp Date</th>
                                                {/* <th>Status</th> */}
                                                <th>Priority</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        {isLoadingTable ? (
                                            <tr>
                                                <td colSpan={6} className="text-center">
                                                    <Loader />
                                                </td>
                                            </tr>

                                        ) : (
                                            <tbody>
                                                {cardData.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="6" className="text-center">No data available</td>
                                                    </tr>
                                                ) : (
                                                    cardData?.map((item, index) => (
                                                        <tr key={item.card_id}>
                                                            <td>{item.type == 'Card' ?
                                                                item.card_type ? item.card_type === "Visa"
                                                                    ? <span>Visa <i class="fab fa-cc-visa font-size-20 ms-2"></i></span>
                                                                    : item.card_type === "DiscoverCard"
                                                                        ? <span>Discover <i class="fab fa-cc-discover font-size-20 ms-2"></i></span>
                                                                        : item.card_type === "MasterCard"
                                                                            ? <span>Master <i class="fab fa-cc-mastercard font-size-20 ms-2"></i></span>
                                                                            : item.card_type === "AmExCard" ? <span>AmEx <i class="fab fa-cc-amex font-size-20 ms-2"></i></span>
                                                                                : item.card_type : '-'
                                                                : item.account_type ? transformText(item.account_type, 'capitalize') : '-'}</td>
                                                            <td>{item.type == 'Card' ? item.card_no ? 'XXXX XXXX XXXX ' + item.card_no : '-' : item.account_number ? item.account_number : '-'}</td>
                                                            <td>{item.type == 'Card' ? item.expiry_month + '/' + item.expiry_year : '-'}</td>
                                                            <td>{item.primary_card_status !== "" ? item?.primary_card_status == 1 ? 'Primary' :
                                                                <>
                                                                    <span>
                                                                        Secondary</span>
                                                                    <CommonTooltip
                                                                        message="Set as primary"
                                                                        placement="top"
                                                                    >

                                                                        <i
                                                                            className="mdi mdi-checkbox-marked-circle-outline font-size-18 ms-2"
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => handlePrimary(item.card_id)}
                                                                        ></i>

                                                                    </CommonTooltip></> : '-'}</td>
                                                            <td>
                                                                <span type="button" onClick={() =>
                                                                    updatePaymentDetails(
                                                                        item,
                                                                        transformText(item.type, 'lowercase')
                                                                    )
                                                                }><i className='far fa-edit font-size-16 ms-3'></i></span>
                                                            </td>

                                                        </tr>
                                                    ))
                                                )}

                                            </tbody>
                                        )}
                                    </Table>
                                </div>
                            </div>
                        </div>




                    </div>

                    {/* Add and Edit Payment Modal Start */}

                    <Modal show={showPayment} onHide={handlePaymentClose} backdrop="static" size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>{formUpdate ? 'Update Payment Details' : 'Add Payment Details'}</Modal.Title>
                        </Modal.Header>
                        <form id="creditCardForm"
                            className=" g-3 fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container"
                            onsubmit="return false" novalidate="novalidate" onSubmit={handleSubmit(AddCardSubmit)}>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-6 col-md-4  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            id="first_name"
                                            className="form-control"
                                            {...register("first_name", {
                                                required: "First Name is required",
                                                pattern: onlyAlphabetsandSpaces,
                                            })}
                                            placeholder="Enter First name"
                                        />
                                        {errors.first_name && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.first_name.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-4  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            id="last_name"
                                            className="form-control"
                                            {...register("last_name", {
                                                required: "Last Name is required",
                                                pattern: onlyAlphabetsandSpaces,
                                            })}
                                            placeholder="Enter Last name"

                                        />
                                        {errors.last_name && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.last_name.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-4  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            Company Name
                                        </label>
                                        <input
                                            type="text"
                                            name="company"
                                            className="form-control"
                                            {...register("company")}
                                            placeholder="company name"

                                            style={{
                                                backgroundColor: "#f0f0f0", // Background color for read-only/disabled
                                                cursor: "not-allowed", // Cursor style for read-only/disabled
                                            }}
                                            readOnly
                                        />
                                        {errors.company && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.company.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-4 mt-2  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            Email Address
                                        </label>
                                        {/* <input type="text" id="email" className="form-control" {...register('email', )} defaultValue={user.company_name} readOnly disabled placeholder="John Doe" /> */}
                                        <input
                                            type="text"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                            {...register("email")}
                                            placeholder="abcd@gmail.com"
                                            readOnly
                                            style={{
                                                backgroundColor: "#f0f0f0", // Background color for read-only/disabled
                                                cursor: "not-allowed", // Cursor style for read-only/disabled
                                            }}
                                        />

                                        {errors.email && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.email.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-4 mt-2  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            Address
                                        </label>
                                        <input
                                            type="text"
                                            id="address"
                                            className="form-control"
                                            {...register("address", {
                                                required: "Address is required",
                                                //   pattern: {
                                                //     value: /^[A-Za-z]+$/,
                                                //     message: "Invalid Address",
                                                //   },
                                            })}
                                            placeholder="Enter Address"

                                        />
                                        {errors.address && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.address.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-4 mt-2  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            id="city"
                                            className="form-control"
                                            {...register("city", {
                                                required: "City is required",
                                                pattern: onlyAlphabetsandSpaces,
                                            })}
                                            placeholder="Enter City"

                                        />
                                        {errors.city && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.city.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-4 mt-2  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            Country
                                        </label>
                                        <input
                                            type="text"
                                            id="country"
                                            className="form-control"
                                            {...register("country", {
                                                required: "Country is required",
                                                pattern: onlyAlphabetsandSpaces,
                                            })}
                                            placeholder="Enter Country"

                                        />
                                        {errors.country && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.country.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-4 mt-2  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            State
                                        </label>
                                        <input
                                            type="text"
                                            id="state"
                                            className="form-control"
                                            {...register("state", {
                                                required: "State is required",
                                                pattern: onlyAlphabetsandSpaces,
                                            })}
                                            placeholder="Enter state"

                                        />
                                        {errors.state && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.state.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-4 mt-2  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            Zip Code
                                        </label>
                                        <input
                                            type="text"
                                            id="zip"
                                            className="form-control"
                                            {...register("zip", {
                                                required: "Zip is required",
                                                pattern: onlyNumbers,
                                            })}
                                            placeholder="Enter Zip code"

                                        />
                                        {errors.zip && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.zip.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-4 mt-2  ">
                                        <label
                                            className="form-label"
                                            for="paymentName"
                                        >
                                            Phone
                                        </label>
                                        <input
                                            type="text"
                                            name="phone_number"
                                            id="phone_number"

                                            className="form-control" // Changed class to className
                                            placeholder="Enter Phone number"
                                            {...register("phone_number", {
                                                required: "Phone is required",
                                                pattern: onlyNumbers,
                                                maxLength: MaxLengthValidation(15), // Import and apply MaxLengthValidation correctly
                                                minLength: MinLengthValidation(10), // Import and apply MinLengthValidation correctly
                                            })}
                                        />
                                        {errors.phone_number && ( // Changed from errors.phone_number to errors.phone_number
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.phone_number.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 d-flex align-items-center">
                                        <h6>Payment Information</h6>
                                        {formUpdate ? (
                                            <>
                                                <hr />
                                                <p className="badge bg-primary ms-3 font-size-18" style={{ marginTop: "15px"}}>
                                                    {updatePaymentType} Details
                                                </p>
                                            </>
                                        ) : (
                                            <div className="ms-5">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="paymentMethod"
                                                        id="paymentCard"
                                                        value="card"
                                                        checked={
                                                            selectedPaymentMethod === "card"
                                                        }
                                                        onChange={() => {
                                                            // formReset();
                                                            setValue("account_type", "");
                                                            setValue("account_number", "");
                                                            setValue("routing_number", "");
                                                            setValue("name_on_account", "");
                                                            setValue("echeck_type", "");
                                                            setValue("bank_name", "");
                                                            setSelectedPaymentMethod("card");
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="paymentCard"
                                                    >
                                                        Card
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="paymentMethod"
                                                        id="paymentBank"
                                                        value="bank"
                                                        checked={
                                                            selectedPaymentMethod === "bank"
                                                        }
                                                        onChange={() => {
                                                            // formReset();
                                                            setValue("card_number", "");
                                                            setValue("card_type", "");
                                                            setValue("card_expiry_month", "");
                                                            setValue("card_expiry_year", "");
                                                            setValue("card_cvv", "");
                                                            setSelectedPaymentMethod("bank");
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="paymentBank"
                                                    >
                                                        Bank
                                                    </label>
                                                </div>
                                            </div>

                                        )}
                                    </div>
                                </div>

                                {selectedPaymentMethod === "card" && (
                                    <>
                                        {/* Card details form */}
                                        <div className="row mt-3">
                                            {/* CardDetails form */}
                                            <div className="col-6 col-md-6">
                                                <label
                                                    className="form-label w-100"
                                                    for="paymentCard"
                                                >
                                                    Card Number
                                                </label>
                                                <div className="input-group input-group-merge has-validation">
                                                    <input
                                                        id="paymentCard"
                                                        name="card_number"
                                                        maxlength="16"
                                                        {...register("card_number", {
                                                            required:
                                                                "Card Number is required",
                                                            pattern: onlyNumbers,
                                                        })}
                                                        className="form-control credit-card-mask"
                                                        type="text"
                                                        placeholder="XXXXXXXXXXXXXXXX"
                                                        aria-describedby="paymentCard2"
                                                    />
                                                </div>
                                                {errors.card_number && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {errors.card_number.message}
                                                    </div>
                                                )}
                                                <div className="fv-plugins-message-container invalid-feedback"></div>
                                            </div>

                                            <div className="col-6 col-md-6  ">
                                                <label
                                                    className="form-label"
                                                    for="paymentName"
                                                >
                                                    Card Type
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <select
                                                        className="form-select"
                                                        name="card_type"
                                                        aria-label="Default select example"
                                                        {...register("card_type", {
                                                            required:
                                                                "Card Type is required",
                                                        })}
                                                    >
                                                        <option value="">
                                                            Select Card Type
                                                        </option>
                                                        <option value="MasterCard">
                                                            MasterCard
                                                        </option>
                                                        <option value="Visa">
                                                            Visa
                                                        </option>
                                                        <option value="AmExCard">
                                                            American Express
                                                        </option>
                                                        <option value="DiscoverCard">
                                                            Discover
                                                        </option>
                                                    </select>
                                                </div>
                                                {errors.card_type && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {errors.card_type.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-4 mt-2">
                                                <label
                                                    className="form-label"
                                                    for="paymentExpiryDate"
                                                >
                                                    Expiry Month
                                                </label>

                                                {/* <input
                                              name="card_expiry_month"
                                              maxlength="2"
                                              // style={{ width: '3rem' }}
                                              {...register("card_expiry_month", {
                                                required: "Month is required",
                                                pattern: {
                                                  value: /^\d{2}$/,
                                                  message: 'Please enter exactly two numbers',
                                                },
                                              })}
                                              className="form-control credit-card-mask"
                                              type="text"
                                              placeholder="MM"
                                              aria-describedby="paymentCard2"
                                            /> */}
                                                <select
                                                    className="form-select"
                                                    name="card_expiry_month"
                                                    aria-label="Default select example"
                                                    {...register(
                                                        "card_expiry_month",
                                                        {
                                                            required: "Month is required",
                                                        }
                                                    )}
                                                >
                                                    <option value="">
                                                        Select Month
                                                    </option>
                                                    <option value="01">1</option>
                                                    <option value="02">2</option>
                                                    <option value="03">3</option>
                                                    <option value="04">4</option>
                                                    <option value="05">5</option>
                                                    <option value="06">6</option>
                                                    <option value="07">7</option>
                                                    <option value="08">8</option>
                                                    <option value="09">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </select>
                                                {errors.card_expiry_month && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {
                                                            errors.card_expiry_month
                                                                .message
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-4 mt-2">
                                                <label
                                                    className="form-label"
                                                    for="paymentExpiryDate"
                                                >
                                                    Expiry Year
                                                </label>

                                                {/* <input
                                              name="card_expiry_year"
                                              // style={{ width: '5rem' }}
                                              maxlength="4"
                                              {...register("card_expiry_year", {
                                                required: "Year is required",
                                                pattern: {
                                                  value: /^\d{4}$/,
                                                  message: 'Please enter exactly four numbers',
                                                },
                                              })}
                                              className="form-control credit-card-mask"
                                              type="text"
                                              placeholder="YYYY"
                                            /> */}
                                                <select
                                                    className="form-select"
                                                    name="card_expiry_year"
                                                    aria-label="Default select example"
                                                    {...register("card_expiry_year", {
                                                        required: "Year is required",
                                                    })}
                                                >
                                                    <option value="">
                                                        Select Year
                                                    </option>
                                                    {options}
                                                </select>
                                                {errors.card_expiry_year && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {
                                                            errors.card_expiry_year
                                                                .message
                                                        }
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-4 mt-2">
                                                <label
                                                    className="form-label"
                                                    for="paymentCvv"
                                                >
                                                    CVV
                                                </label>
                                                {/* <div className="input-group input-group-merge"> */}
                                                <input
                                                    type="password"
                                                    id="paymentCvv"
                                                    className="form-control cvv-code-mask"
                                                    maxlength="3"
                                                    name="card_cvv"
                                                    {...register("card_cvv", {
                                                        required:
                                                            "CVV Code is required",

                                                        pattern: {
                                                            value: /^[0-9]{3,4}$/,
                                                            message: "Invalid CVV format",
                                                        },
                                                    })}
                                                    placeholder="Enter Cvv Code"
                                                />

                                                {/* </div> */}
                                                {errors.card_cvv && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {" "}
                                                        {errors.card_cvv.message}
                                                    </div>
                                                )}
                                            </div>
                                            {watch("card_type") ===
                                                "AmExCard" && (
                                                    <div className="mt-4">
                                                        <p className="text-danger">
                                                            Note : From January 1st, 2016,
                                                            payments through Amex credit
                                                            cards would attract 4%
                                                            processing fee and you will be
                                                            charged accordingly. If you
                                                            would like to avoid the charges,
                                                            we request you to move to other
                                                            Debit/Credit cards or e-check
                                                            payments.
                                                        </p>
                                                    </div>
                                                )}
                                        </div>
                                    </>
                                )}

                                {selectedPaymentMethod === "bank" && (
                                    <>
                                        {/* Bank details form */}
                                        <div className="row mt-3">
                                            <div className="col-6 col-md-4  ">
                                                <label
                                                    className="form-label"
                                                    for="account_type"
                                                >
                                                    Account Type
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <select
                                                        className="form-select"
                                                        name="account_type"
                                                        {...register("account_type", {
                                                            required:
                                                                "Account Type is required",
                                                        })}
                                                    >
                                                        <option value="">
                                                            Select Account Type
                                                        </option>
                                                        <option value="checking">
                                                            Checking
                                                        </option>
                                                        <option value="business">
                                                            Business Checking
                                                        </option>
                                                        <option value="savings">
                                                            Savings
                                                        </option>
                                                    </select>
                                                </div>
                                                {errors.account_type && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {errors.account_type.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-4  ">
                                                <label
                                                    className="form-label"
                                                    for="routing_number"
                                                >
                                                    Routing Number
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        className="form-control cvv-code-mask"
                                                        name="routing_number"
                                                        maxlength="9"
                                                        {...register("routing_number", {
                                                            required:
                                                                "Routing Number is required",
                                                            pattern: onlyNumbers,
                                                        })}
                                                        placeholder="Enter Routing Number"
                                                    />
                                                </div>
                                                {errors.routing_number && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {errors.routing_number.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-4  ">
                                                <label
                                                    className="form-label"
                                                    for="account_number"
                                                >
                                                    Account Number
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        className="form-control cvv-code-mask"
                                                        name="account_number"
                                                        maxlength="17"
                                                        {...register("account_number", {
                                                            required:
                                                                "Account Number is required",
                                                            pattern: onlyNumbers,
                                                        })}
                                                        placeholder="Enter Account Number"
                                                    />
                                                </div>
                                                {errors.account_number && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {errors.account_number.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-4 mt-2  ">
                                                <label
                                                    className="form-label"
                                                    for="name_on_account"
                                                >
                                                    Name on Account
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        className="form-control cvv-code-mask"
                                                        name="name_on_account"
                                                        maxlength="22"
                                                        {...register(
                                                            "name_on_account",
                                                            {
                                                                required:
                                                                    "Name on Account is required",

                                                                pattern:
                                                                    onlyAlphabetsandSpaces,
                                                            }
                                                        )}
                                                        placeholder="Enter Name on Account"
                                                    />
                                                </div>
                                                {errors.name_on_account && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {errors.name_on_account.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-4 mt-2  ">
                                                <label
                                                    className="form-label"
                                                    for="echeck_type"
                                                >
                                                    eCheck Type
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <select
                                                        className="form-select"
                                                        name="echeck_type"
                                                        {...register("echeck_type", {
                                                            required:
                                                                "eCheck Type is required",
                                                        })}
                                                    >
                                                        <option value="">
                                                            Select eCheck Type
                                                        </option>
                                                        <option value="PPD">PPD</option>
                                                        <option value="TEL">TEL</option>
                                                        <option value="WEB">WEB</option>
                                                        <option value="CCD">CCD</option>
                                                    </select>
                                                </div>
                                                {errors.echeck_type && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {errors.echeck_type.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-4 mt-2  ">
                                                <label
                                                    className="form-label"
                                                    for="bank_name"
                                                >
                                                    Bank Name
                                                </label>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="text"
                                                        className="form-control cvv-code-mask"
                                                        name="bank_name"
                                                        maxlength="50"
                                                        {...register("bank_name", {
                                                            required:
                                                                "Bank Name is required",

                                                            pattern:
                                                                onlyAlphabetsandSpaces,
                                                        })}
                                                        placeholder="Enter Bank Name"
                                                    />
                                                </div>
                                                {errors.bank_name && (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {errors.bank_name.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}

                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button"
                                    className="btn btn-warning  btn-rounded waves-effect waves-light btn-md me-md-2 px-5"
                                    onClick={handlePaymentClose}>Cancel</button>
                                <button type="submit"
                                    className="btn btn-primary  btn-rounded waves-effect waves-light btn-md me-md-2 px-5"
                                >Save</button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                    {/* Add and Edit Payment Modal end */}
                </div>

            </div>
        </ >
    );
}

