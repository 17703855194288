import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Templates from './pages/Whatsapp/Broadcast/Templates';
import WAInbox from './pages/Whatsapp/Broadcast/WAInbox';
import ScheduleBroadcast from './pages/Whatsapp/Broadcast/ScheduleBroadcast';
import BroadcastHistory from './pages/Whatsapp/Broadcast/BroadcastHistory';
import CreateTicket from './pages/Whatsapp/Support/CreateTicket';
import ShowTicket from './pages/Whatsapp/Support/ShowTicket';
import ListTicket from './pages/Whatsapp/Support/ListTicket';
import Analytics from './pages/Whatsapp/Analytics/Analytics';
import Automation from './pages/Whatsapp/Chatbots/Automation';
import ComingSoon from './common/ComingSoon';

import Login from './pages/Login/Login';
import PrivateRoutes from './common/PrivateRoutes';
import Dashboard from './pages/Whatsapp/Dashboard';
import AllContacts from './pages/Whatsapp/Contacts/AllContacts';
import Groups from './pages/Whatsapp/Contacts/Groups';
import Optout from './pages/Whatsapp/Contacts/OptOut';
import Recharge from './pages/Whatsapp/Payments/Recharge';
import BalanceHistory from './pages/Whatsapp/Payments/BalanceHistory';
import InvoiceHistory from './pages/Whatsapp/Payments/InvoiceHistory';
import PaymentSetting from './pages/Whatsapp/Payments/PaymentSetting';
import ChatBot from './pages/Whatsapp/Chatbots/ChatBot';
import CreateChatBot from './pages/Whatsapp/Chatbots/CreateChatBot';
import EditChatBot from './pages/Whatsapp/Chatbots/EditChatBot';

import Signup from './pages/Signup/Signup';
import SmsInbox from './pages/SMS/Messages/SmsInbox';
import ChangePassword from './pages/Login/ChangePassword';
import Compose from './pages/SMS/Messages/Compose';
import Template from './pages/SMS/Messages/Template';
import ManageUsers from './pages/Profile/ManageUsers';

import MyNumber from './pages/SMS/MyNumbers/my_number';
import PendingInvitation from './pages/PendingInvitation/InvitationRegister';
import ChooseAPlan from './pages/Whatsapp/Payments/ChooseAPlan';
import OrderNumbers from './pages/SMS/MyNumbers/OrderNumbers';
import Brands from './pages/SMS/CampaignRegistry/Brands';
import Campaign from './pages/SMS/CampaignRegistry/Campaign';
import CreditHistory from './pages/Whatsapp/Payments/CreditHistory';
import Settings from './pages/Whatsapp/Settings/Settings';
import LoginDashboard from './pages/Dashboard/dashboard';

import WorkSpace from './pages/WorkSpace/WorkSpace';
import Landing from './pages/Landing/Landing';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Layout>
          <Routes>
            <Route exact path="/coming-soon" element={<ComingSoon />} />
            <Route exact path="/" element={<Templates />} />
            <Route exact path="/whatsapp/broadcast/templates" element={<Templates />} />
            <Route exact path="/whatsapp/broadcast/inbox" element={<Inbox />} />
            <Route exact path="/whatsapp/broadcast/schedule_broadcast" element={<ScheduleBroadcast />} />
            <Route exact path="/whatsapp/broadcast/broadcast_history" element={<BroadcastHistory />} />
            <Route exact path="/whatsapp/support/create-ticket" element={<CreateTicket />} />
            <Route exact path="/whatsapp/support/show-ticket" element={<ShowTicket />} />
            <Route exact path="/whatsapp/analytics" element={<Analytics />} />
            <Route exact path="/whatsapp/chatbot/analytics" element={<Automation />} />
          </Routes>
        </Layout> */}
          {/* Public Routes without Layout */}
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/Invitation_register" element={<PendingInvitation />} />
          <Route path="/workspace/workspace" element={<WorkSpace />} />
          <Route path="/landing/landing" element={<Landing />} />
          {/* Routes that require Layout */}
          <Route element={<PrivateRoutes />}>
            {/* <Route element={<Layout />}> */}
            {/* Whatsapp  urls*/}
            <Route path="/whatsapp/settings" element={<Settings />} />
            <Route path="/whatsapp/templates" element={<Templates />} />
            <Route path="/whatsapp" element={<Dashboard />} />
            <Route path="/contacts" element={<AllContacts />} />
            <Route path="/contacts/allcontacts" element={<AllContacts />} />
            <Route path="/contacts/groups" element={<Groups />} />
            <Route path="/contacts/optout" element={<Optout />} />
            <Route path="/payments/recharge" element={<Recharge />} />
            <Route path="/payments/chooseaplan" element={<ChooseAPlan />} />
            <Route path="/payments" element={<Recharge />} />

            <Route path="/payments/balancehistory" element={<BalanceHistory />} />
            <Route path="/payments/credithistory" element={<CreditHistory />} />

            <Route path="/payments/invoicehistory" element={<InvoiceHistory />} />
            <Route path="/payments/paymentsetting" element={<PaymentSetting />} />
            <Route path="/whatsapp/chatbot/chatbot" element={<ChatBot />} />
            <Route path="/whatsapp/chatbot/create_chatbot" element={<CreateChatBot />} />
            <Route path="/whatsapp/chatbot/edit_chatbot" element={<EditChatBot />} />

            <Route exact path="/whatsapp/chatbot/automation" element={<Automation />} />
            <Route exact path="/whatsapp/broadcast/templates" element={<Templates />} />
            <Route exact path="/whatsapp/broadcast/inbox" element={<WAInbox />} />
            <Route exact path="/whatsapp/broadcast/schedule_broadcast" element={<ScheduleBroadcast />} />
            <Route exact path="/whatsapp/broadcast/broadcast_history" element={<BroadcastHistory />} />

            <Route exact path="/support" element={<ListTicket />} />
            <Route exact path="/support/create-ticket" element={<CreateTicket />} />
            <Route exact path="/support/show-ticket" element={<ShowTicket />} />
            <Route exact path="/support/list-ticket" element={<ListTicket />} />
            <Route exact path="/whatsapp/analytics" element={<Analytics />} />
            {/* SMS  urls*/}
            <Route exact path="/sms" element={<Compose />} />
            <Route exact path="/sms/messages/compose" element={<Compose />} />
            <Route exact path="/sms/messages/inbox" element={<SmsInbox key="inbox" type="IN" />} />
            <Route exact path="/sms/messages/sent_items" element={<SmsInbox key="sent_items" type="OUT" />} />
            <Route exact path="/sms/messages/history" element={<SmsInbox key="history" type="ALL" />} />
            <Route exact path="/sms/messages/template" element={<Template />} />
            <Route exact path="/sms/campaign_registry/brands" element={<Brands />} />
            <Route exact path="/sms/campaign_registry/campaign" element={<Campaign />} />

            <Route path="/comingsoon" element={<ComingSoon />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/manage_users" element={<ManageUsers />} />

            <Route exact path="/sms/order_numbers" element={<OrderNumbers />} />
            <Route exact path="/sms/my_number" element={<MyNumber />} />

            <Route exact path="/dashboard" element={<LoginDashboard />} />

            {/* </Route> */}
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
