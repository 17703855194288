// apiClient.js

import axios from 'axios';
import { getOnlyToken } from './CommonFunctions';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://192.168.200.140:8000/';
const apiClient = axios.create({
	baseURL: BASE_URL,
});

// Add a request interceptor to set the Authorization header dynamically
apiClient.interceptors.request.use((config) => {
	const token = getOnlyToken();
	if (token) {
		config.headers['Authorization'] = `${token}`;
	}
	// Dynamically set 'Content-Type' header for FormData
	if (config.data instanceof FormData) {
		config.headers['Content-Type'] = 'multipart/form-data';
	} else {
		config.headers['Content-Type'] = 'application/json';
	}
	return config;
}, (error) => {
	return Promise.reject(error);
});

// Define common API methods
export const _get = (url, config = {}) => {
	return apiClient.get(url, config);
};

export const _delete = (url, config = {}) => {
	return apiClient.delete(url, config);
};

export const _put = (url, data = {}, config = {}) => {
	return apiClient.put(url, data, config);
};

export const _post = (url, data = {}, config = {}) => {
	return apiClient.post(url, data, config);
};


// Define API Calls
export const getCcCards = () => {
	const response = _get('/services/get_cc_cards');
	return response;
};
export const login = () => {
	const response = _post('/login');
	return response;
};

//////////////////// SMS APIs //////////////////////////////////////////
export const fetchCompanyContactList = (params) => {
	const response = _get(`/sms/get_company_number_list/`);
	return response;
}
export const fetchSMSContactList = (params) => {
	const response = _get(`/sms/get_contact_list?page_number=${params?.page}&page_size=${params?.page_size}&keyword=${params.keyword}&selected_number=${params?.number}`);
	return response;
}

export const fetchSMSUserChatHistory = (params) => {
	const response = _get(`/sms/get_user_sms_history?page_number=${params?.page}&page_size=${params?.page_size}&selected_number=${params?.number}&user_number=${params?.user_number}&msg_type=${params?.msg_type}`);
	return response;
}

export const sendSMSOrMMS = (api_input) => {
	const response = _post('/sms/send_sms_mms/', api_input);
	return response;
}
export const updateMessageSeenStatus = (api_input) => {
	const response = _put('/sms/update_message_seen_status/', api_input);
	return response;
}
//////////////////// SMS APIs End //////////////////////////////////////////

/////////////////// Login apis /////////////////////////
export const loginSubmit = (api_input) => {
	return _post('/customer/login/', api_input);
};


export const resendOtp = (api_input) => {
	return _post('customer/login/resend_two_fa_code/', api_input);
};

export const otpVerification = (api_input) => {
	return _post('customer/login/two_fa_verify/', api_input);
};


export const captchrefresh = (api_input) => {
	return _get('customer/GenerateCaptcha/', api_input);
};

export const passwordReset = (api_input) => {
	return _post('customer/reset_password/', api_input);
};




//////////////////////////////////////////////////////////////////
export const registerSubmit = (api_input) => {
	return _post('/customer/register/', api_input);
};

// Sub user
export const fetchSubUsersList = (params) => {
	const response = _post(`/subusers/list/`, params);
	return response;
}

export const createSubUser = (params) => {
	const response = _post(`/subusers/create/`, params);
	return response;
}

export const editSubUser = (id, params) => {
	const response = _put(`/subusers/update/${id}/`, params);
	return response;
}

export const deleteSubUser = (id) => {
	const response = _delete(`/subusers/delete/${id}/`);
	return response;
}

// Pending Invitation
export const fetchSubUsersInvitationList = (params) => {
	const response = _post(`/subusers/pending_sub_user_invitation/`, params);
	return response;
}

export const sentSubUserInvitation = (params) => {
	const response = _post(`/subusers/sent_sub_user_invitation/`, params);
	return response;
}

// Sub user permission
export const fetchSubUserPermission = (params) => {
	const response = _get(`/subusers/permission_details/${params?.sub_user_id}`);
	return response;
}


export const UpdateSubUserPermission = (sub_user_id, params) => {
	const response = _post(`/subusers/sub_user_permission/${sub_user_id}/`, params);
	return response;
}

//////////////////////Template//////////////////////////////////
export const templateList = (params) => {
	const response = _get(`/sms/get_all_message_template_list?page_number=${params.page}&page_size=${params.page_size}&keyword=${params.keyword}`);
	return response;
};

export const createTemplate = (params) => {
	const response = _post(`/sms/create_template/`, params);
	return response;
}

export const editTemplate = (id, params) => {
	const response = _put(`/sms/update_template/${id}/update/`, params);
	return response;
}

export const deleteTemplate = (id, params) => {
	const response = _delete(`/sms/delete_template?template_id=${id}`, params);
	return response;
}


///////////////////////////////////////////////////////////////////////////////////////

// Tickets
export const fetchTicketListData = (params) => {
	const response = _post(`/ticket/tickets_list/`, params);
	return response;
}

export const fetchTicketDetailsData = (params) => {
	const response = _post(`/ticket/ticket_details/${params?.ticket_number}/`);
	return response;
}

export const addTicketReply = (ticket_number, params) => {
	const response = _put(`/ticket/ticket_update/${ticket_number}/update/`, params);
	return response;
}


export const fetchParentTickect = (api_input) => {
	return _get('ticket/get_ticket_parent_category/', api_input);
};

export const fetchParentCreateTickect = (parcategory, api_input) => {
	return _get(`/ticket/get_ticket_category/?parent_category=${parcategory}`, api_input);
};

export const fetchCreateTicket = (api_input) => {
	return _post(`ticket/create_ticket/`, api_input);
};





////////////// My Number ///////////////////////////////


export const fetchAllMynumbers = (params) => {
	const response = _get(`/my_numbers/get_number_list?page_number=${params?.page}&page_size=${params?.page_size}&keyword=${params?.keyword}`);
	return response;
}

export const fetchMyNumberList = (params) => {
	const response = _get(`/my_numbers/get_number_list?page_number=${params?.page}&page_size=${params?.page_size}&keyword=${params?.number}`);
	return response;
}

export const fetchNumberChatHistory = (params) => {
	const response = _get(`/my_numbers/get_message_history?page_number=${params?.page}&page_size=${params?.page_size}&selected_number=${params?.number}`);
	return response;
}

export const makePrimary = (params) => {
	const response = _put(`/my_numbers/make_as_number_primary/`, params);
	return response;
}

export const downloadingCsv = (params) => {
	const response = _get(`/my_numbers/download_message_history?selected_number=${params?.selected_number}`);
	return response;
}

export const AddMyNumbers = (params) => {
	const response = _post(`/my_numbers/create_my_number/`, params);
	return response;
}



export const exportToCsv = (data, filename) => {
	const csvContent = Object.keys(data[0]).join(",") + "\n" +
		data.map(row => Object.values(row).join(",")).join("\n");

	const blob = new Blob([csvContent], { type: 'text/csv' }); // for large amount of data 
	const url = URL.createObjectURL(blob);

	const link = document.createElement("a");
	link.setAttribute("href", url);
	link.setAttribute("download", filename + ".csv");
	document.body.appendChild(link);
	link.click();

	// Clean up
	URL.revokeObjectURL(url);
	document.body.removeChild(link);
};



////////////////// signup page /////////////////////////
export const RegisterUser = (api_input) => {
	const response = _post('customer/register/', api_input);
	return response;
}

export const Verify_OTP = (api_input) => {
	const response = _post('customer/verify_otp/', api_input);
	return response;
}

export const ReSendOTP = (api_input) => {
	const response = _post('customer/resend_otp/', api_input);
	return response;
}

////  contact Optout list ///////////
export const fetchOptoutLists = (params) => {
	const response = _get(`/ticket/opted_out_list?page_number=${params?.page}&page_size=${params?.page_size}&keyword=${params?.keyword}`);
	return response;
}


////////////// User Details ///////////////////////////////
export const fetchUserDetailsData = () => {
	const response = _get(`/my_numbers/get_customer_details/`);
	return response;
}



///////////////////payment//////////////////////////////////

export const fetchAddfunds = (params) => {
	const response = _post(`/billing/recharge
`, params);
	return response;
}


export const fetchPaymentCardsData = () => {
	const response = _get(`/billing/get_cc_cards`,);
	return response;
};


// API call to add a card
export const fetchAddCardSubmit = (api_input) => {
	return _post(`/billing/create_customer_profile_card`, api_input);
};

// API call to add a bank account
export const fetchAddBankSubmit = (api_input) => {
	return _post(`/billing/create_customer_profile_bank`, api_input);
};

export const fetchCardUpdate = (api_input) => {
	return _post(`/billing/update_card_details`, api_input);
};

export const fetchBankUpdate = (api_input, token) => {
	return _post(`/billing/update_bank_details`, api_input, token);
};

export const fetchPaymentPrimary = (api_input) => {
	return _post(`/billing/update_primary_card_status`, api_input);
};

export const fetchUserInfoPayment = (api_input) => {
	return _get(`/billing/tariff_user_get`, { params: api_input });
};

///////////////////// balance history //////////////////////

export const fetchBalanceHistory = (api_input) => {
	return _post(`/payments/get_balance_history/`, api_input);
};

export const fetchDownloadHistory = (api_input) => {
	return _post(`payments/get_balance_history_download/`, api_input);
};

export const fetchCreditHistory = (api_input) => {
	return _post(`/payments/get_credit_history/`, api_input);
};


export const fetchDownloadCreditHistory = (api_input) => {
	return _post(`/payments/get_credit_history_download/`, api_input);
};

///////////////// All contacts//////////////////////////////////

export const CreateCoustomerContacts = (api_input) => {
	const response = _post(`contact/create_contacts/`, api_input)
	return response
}
export const fetchUsers = (params) => {
	const response = _get(`contact/contacts_list/?page_number=${params.page}&page_size=${params.page_size}&keyword=${params.keyword}`)
	return response
}
export const UpdateUser = (api_input) => {
	const response = _put(`contact/update_contact/${api_input.id}/`, api_input)
	return response
}

export const UploadBulk = (api_input) => {

	const response = _post("/contact/bulk_upload/", api_input)
	return response
}
export const DeleteUser = (api_input) => {
	const response = _post("/contact/delete/", api_input)
	return response
}

export const fetchGroups = (params) => {
	const response = _get(`/contact/customer_groups/?keyword=${params?.keyword}&page_number=${params?.page}&page_size=${params?.page_size}`)
	return response
}

export const deletelistGroup = (api_input) => {
	const response = _delete(`contact/customer_groups/delete/${api_input.id}/`)
	return response
}


export const UploadBulkGroup = (api_input) => {
	const response = _post(`contact/upload_bulk_groups/`, api_input)
	return response
}

///////////////// Payment settings //////////////////////////////////
export const updateGroup = (api_input) => {
	const response = _put(`/contact/customer_groups/update/${api_input.id}/`, api_input)
	return response
}

export const createGroupWhatsapp = (api_input) => {
	const response = _post("contact/create_customer_group/", api_input)
	return response
}

export const PaymentDetailsGet = () => {
	const response = _get("payments/get_payment_settings_data/")
	return response
}
export const PaymentSaveAuto = (api_input) => {

	const response = _post("payments/auto_payment_settings/", api_input)
	return response
}
export const saveLowBalanceSave = (api_input) => {

	const response = _post("payments/low_balance_notification_settings/", api_input)
	return response
}
///////////////////////////// Invoices ////////////////////////////////////////////////////////

export const GetInVoiceList = (api_input) => {
	const response = _post("payments/invoice_list/", api_input)
	return response
}

export const GetInvoiceData = (params) => {
	const response = _get(`payments/get_single_invoice_data?invoice_id=${params}`)
	return response
}


export const SendInvoiceData = (api_input) => {
	const response = _post("payments/send_invoice/", api_input)
	return response
}

///////////////////////////// whatsapp schedule broadcast//////////////////////////
export const fetchScheduleBroadcast = (params) => {
	const response = _get(`boardcasting/schedule_broadcast_get/?page_number=${params?.page_number}&page_size=${params?.page_size}`);
	return response;
}
export const deleteScheduleBroadcast = (params) => {
	const response = _delete(`boardcasting/schedule_broadcast_delete/${params?.id}/`)
	return response;
}
export const addScheduleBroadcast = (api_input) => {
	const response = _post(`boardcasting/schedule_broadcast/`, api_input)
	return response;
}
export const updateScheduleBroadcast = (api_input) => {
	const response = _put(`boardcasting/schedule_broadcast_update/${api_input.id}/`, api_input)
	return response;
}

export const fetchTicketCount = (api_input) => {
	return _post(`customer/get_dashboard_counts/`, api_input);
};

///////////////// Menu sidebar //////////////////
export const fetchListSidebar = () => {
	return _get(`customer/get_main_menu_data/`);
};

export const fetchMenuItemsApi = () => {
	return _get(`customer/get_customer_menu_data/
`);
};



///////////////// payments ///////////////////////
export const fetchChoosePlan = async () => {
	const response = await _get(`/billing/plans/`);
	return response.data; // Assuming the response has a data property with the plan array
};

export const fetchCreatePlan = async (api_input) => {
	const response = await _post(`/billing/create_plan/`, api_input);
	return response.data;
};

///////////////////////////////////// Order numbers //////////////////////////

export const getStateList = () => {
	return _get(`order_numbers/available_state_list/`);
}
export const getRateCenterList = (api_input) => {
	return _post(`order_numbers/ratecenter_list/`, api_input);
}
export const getLocalDidList = (api_input) => {
	return _post(`order_numbers/local_did_list/`, api_input);
}
export const orderDid = (api_input) => {
	return _post(`order_numbers/order_did/`, api_input);
}

/////////////////////////// Broadcast history ///////////////////////////////////////////
export const FetchBroadcastHistory = (api_input) => {
	const response = _get(`boardcasting/broadcast_history/counts/${api_input}`)
	return response
}

export const fetchDataListBroadcast = (api_input) => {
	const response = _get(`boardcasting/broadcast_history/list/${api_input}`)
	return response
}


export const fetchExportHistory = (api_input) => {
	const response = _post("/boardcasting/broadcast_history_export/", api_input)
	return response
}


export const singleDownload = async (api_input) => {
	const response = _post("/boardcasting/broadcast_history_single_download/", api_input)
	return response
}

export const fetchCustomerMenu = async () => {
	const response = await _get(`/customer/get_customer_menu_data/`);
	return response.data; // Assuming the response has a data property with the plan array
};


export const DeliverStatus = (api_input) => {
	const response = _post("analytics/delivery_status/", api_input)
	return response
}
export const TotalChatList = (api_input) => {
	const response = _post("analytics/total_chat_list/", api_input)
	return response
}
export const TemplateDetails = (api_input) => {
	const response = _post("analytics/template_details/", api_input)
	return response
}

/////////////////////// Coomon country and state api ////////////////////////////////

export const fetchCountryList = () => {
	const response = _get(`customer/countries_list/`)
	return response
}
export const fetchStateList = (api_input) => {
	const response = _get(`customer/states_by_country_code/${api_input}/`)
	return response
}

///////////////////// Brands ///////////////////////////
export const fetchBrands = (api_input) => {
	const response = _post(`campaign/createBrand`, api_input);
	return response
}
export const createCampaign = (api_input) => {
	const response = _post(`campaign/createCampaign`, api_input);
	return response
}
// ///////////////////////////////////////////////////////////
export const listContact = (params) => {
	const response = _get(`/sms/get_all_contact_list/?keyword=${params.keyword}`);
	return response
}

// ////////////////////////////////////////////////////////////
export const FetchSettingData = () => {
	const response = _get(`/whatsapp/get_user_whatsapp_data/`);
	return response
}

export const UpdateSetting = (api_input) => {
	const response = _put(`/whatsapp/update_user_whatsapp_data/`, api_input);
	return response
}
///////////////////////whatsapp inbox apis ///////////////
export const fetchWAContactList = (params) => {
	const response = _get(`whatsapp/get_whatsapp_contact_list/?select_key=${params.select_key}&page_number=${params.page_number}&page_size=${params.page_size}&keyword=${params.keyword}`);
	return response
}
export const sendWhatsappMessage = (api_input) => {
	const response = _post('whatsapp/send_whatsapp_message/', api_input);
	return response;
}
export const sendWhatsappLocation = (api_input) => {
	const response = _post('whatsapp/send_whatsapp_message_location/', api_input);
	return response;
}
export const fetchWhatsappUserChatHistory = (params) => {
	const response = _get(`whatsapp/get_whatsapp_message_history/?user_number=${params?.user_number}&page_number=${params?.page}&page_size=${params?.page_size}`);
	return response;
}

