import React from 'react'

export default function Automation() {
    return (
        <>
            <div>
                <div class="position-relative">
                </div>
                <div id="content-page" class="content-page">
                    <div class="container">
                        <div class="row w-100 mb-4 mt-3">
                            <div class="d-flex align-items-center justify-content-between flex-wrap">
                                <h4 class="fw-bold text-primary">Chatbots Automation</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-lg-12">
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <div class="header-title">
                                            <h5 class="card-title text-warning">Sahiti@vitelglobal.com </h5>
                                            <p class="mb-0">Chatbots Automation</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <a type="button" class="btn btn-primary   d-flex align-items-center">
                                                <span class=" ">Chatbots List</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <h5 class="mb-3 fw-500">Working hours</h5>
                                        <form>
                                            <div class="row align-items-center">
                                                <div class="col-md-1">
                                                    <p>Sunday</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <p class="text-center">To</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <select class="form-select" id="exampleFormControlSelect1">
                                                            <option value="">Select Chatbot</option>
                                                            <option value="786">Alex</option>
                                                            <option value="776">Vitel sales test</option>
                                                            <option value="759">Test1</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-success px-4">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-md-1">
                                                    <p>Monday</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <p class="text-center">To</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <select class="form-select" id="exampleFormControlSelect1">
                                                            <option value="">Select Chatbot</option>
                                                            <option value="786">Alex</option>
                                                            <option value="776">Vitel sales test</option>
                                                            <option value="759">Test1</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-success px-4">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-md-1">
                                                    <p>Tuesday</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <p class="text-center">To</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <select class="form-select" id="exampleFormControlSelect1">
                                                            <option value="">Select Chatbot</option>
                                                            <option value="786">Alex</option>
                                                            <option value="776">Vitel sales test</option>
                                                            <option value="759">Test1</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-success px-4">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-md-1">
                                                    <p>Wednesday</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <p class="text-center">To</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <select class="form-select" id="exampleFormControlSelect1">
                                                            <option value="">Select Chatbot</option>
                                                            <option value="786">Alex</option>
                                                            <option value="776">Vitel sales test</option>
                                                            <option value="759">Test1</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-success px-4">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-md-1">
                                                    <p>Thursday</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <p class="text-center">To</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <select class="form-select" id="exampleFormControlSelect1">
                                                            <option value="">Select Chatbot</option>
                                                            <option value="786">Alex</option>
                                                            <option value="776">Vitel sales test</option>
                                                            <option value="759">Test1</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-success px-4">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-md-1">
                                                    <p>Friday</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <p class="text-center">To</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <select class="form-select" id="exampleFormControlSelect1">
                                                            <option value="">Select Chatbot</option>
                                                            <option value="786">Alex</option>
                                                            <option value="776">Vitel sales test</option>
                                                            <option value="759">Test1</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-success px-4">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-md-1">
                                                    <p>Saturday</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <p class="text-center">To</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input type="time" class="form-control" id="exampleInputtime" value="13:45" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <select class="form-select" id="exampleFormControlSelect1">
                                                            <option value="">Select Chatbot</option>
                                                            <option value="786">Alex</option>
                                                            <option value="776">Vitel sales test</option>
                                                            <option value="759">Test1</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-success px-4">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label class="form-label" for="exampleFormControlSelect1">Default chatbot</label>
                                                        <select class="form-select" id="exampleFormControlSelect1">
                                                            <option value="">Select Chatbot</option>
                                                            <option value="786">Alex</option>
                                                            <option value="776">Vitel sales test</option>
                                                            <option value="759">Test1</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-3  text-end">
                                                    <button class="btn btn-success px-4" type="submit">Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
