import React, { useState, useRef, useEffect } from 'react';

export default function VideoRecording({ onRecordingComplete }) {
    const [isRecording, setIsRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [elapsedTime, setElapsedTime] = useState(0); // State to track elapsed time
    const mediaStreamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const timerRef = useRef(null);
    const videoRef = useRef(null);

    const constraints = { video: { width: { max: 320 } }, audio: true };

    useEffect(() => {
        // Request camera access and display video when component mounts
        const requestCameraAccess = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                mediaStreamRef.current = stream;
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (e) {
                console.error('getUserMedia() failed: ' + e);
                // Handle error, e.g., show an alert or notify the user
            }
        };

        requestCameraAccess();

        // Cleanup function to stop the stream when component unmounts
        return () => {
            if (mediaStreamRef.current) {
                mediaStreamRef.current.getTracks().forEach((track) => track.stop());
            }
        };
    }, []);

    const startRecording = async () => {
        try {
            const stream = mediaStreamRef.current;
            const recorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
            mediaRecorderRef.current = recorder;

            recorder.ondataavailable = (event) => {
                setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
            };
            recorder.start(100);
            setIsRecording(true);

            // Start the timer
            timerRef.current = setInterval(() => {
                setElapsedTime((prevTime) => prevTime + 1);
            }, 1000);
        } catch (e) {
            console.error('Exception while creating MediaRecorder: ' + e);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);

            // Stop the timer
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        }
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
        });
    };

    const downloadRecording = async () => {
        if (recordedChunks.length) {
            // const blob = new Blob(recordedChunks, { type: 'video/webm' });
            const blob = new Blob(recordedChunks, { type: 'video/mp4' });
            const base64String = await getBase64(blob);

            const base64_files = [{
                file_name: 'video_file.mp4', // Updated to .webm
                file_type: 'video/mp4', // Correct MIME type
                file_size: blob.size,
                file: base64String,
            }]


            // console.log('Base64 File Data:', JSON.stringify(base64FileData));

            // Pass Base64 file data back to parent component
            if (onRecordingComplete) {
                onRecordingComplete(base64_files);
            }

            // // Trigger download as an optional feature
            // const url = URL.createObjectURL(blob);
            // const a = document.createElement('a');
            // document.body.appendChild(a);
            // a.style.display = 'none';
            // a.href = url;
            // a.download = 'recording.webm';
            // a.click();
            // URL.revokeObjectURL(url);

            setRecordedChunks([]); // Clear recorded chunks
        }
    };

    return (
        <div className='d-flex justify-content-between flex-column align-items-center'>


            <video ref={videoRef} width="320" height="240" autoPlay muted />
            <div>Recording Time: {elapsedTime} seconds</div> {/* Display elapsed time */}
            <div>

                <button className='btn btn-primary' onClick={startRecording} disabled={isRecording}>Start Recording</button>
                <button className='btn btn-primary ms-2' onClick={downloadRecording} disabled={recordedChunks.length === 0}>Send Recording</button>
                {/* <button className='btn btn-warning' onClick={stopRecording} disabled={!isRecording}>Stop Recording</button> */}
                <button className='btn btn-warning ms-2' onClick={stopRecording} disabled={!isRecording}>Stop Recording</button>

            </div>
        </div>
    );
};
